import { createSlice } from '@reduxjs/toolkit';
import { MedewerkerState } from './types';
import { getCurrentMedewerkerData, updateMedewerker } from './actions';

const initialState: MedewerkerState = {
	currentMedewerkerData: null,
	loading: false,
	error: null,
	updating: false,
};

export const currentMedewerkerDataSlice = createSlice({
	name: 'currentMedewerkerData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentMedewerkerData.pending, state => {
				state.loading = true;
				state.currentMedewerkerData = null;
			})
			.addCase(getCurrentMedewerkerData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentMedewerkerData = payload;
			})
			.addCase(getCurrentMedewerkerData.rejected, (state, { payload }) => {
				state.loading = false;
				state.currentMedewerkerData = null;
				state.error = payload ?? null;
			})
			.addCase(updateMedewerker.pending, state => {
				state.updating = true;
			})
			.addCase(updateMedewerker.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentMedewerkerData = payload;
			})
			.addCase(updateMedewerker.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			});
	},
});

export const currentMedewerkerDataReducer = currentMedewerkerDataSlice.reducer;
