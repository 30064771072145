import { createSlice } from '@reduxjs/toolkit';
import { EmotieSoortCode } from '@zig.community/community-components';

import {
	getBerichten,
	BerichtenState,
	getBericht,
	likeBericht,
	deleteBerichtEmotie,
	createReactie,
	createBericht,
	resetBerichten,
	deleteBericht,
	updateBericht,
	refetchLastGetBerichten,
	setFilters,
	resetFilters,
	deleteInappropriateReports,
	updateBerichtInList,
} from '.';
import { Bericht, BerichtEmotie } from '../../api/models';

const initialState: BerichtenState = {
	berichten: null,
	selectedBericht: null,
	loading: false,
	error: null,
	loadingCreatingReactie: false,
	errorCreatingReactie: null,
	loadingCreatingBericht: false,
	errorCreatingBericht: null,
	loadingUpdatingBericht: false,
	errorUpdatingBericht: null,
	communityId: null,
	filters: {
		showAll: true,
		showReported: false,
		showNIE: false,
		showPRI: true,
	},
};

export const berichtenSlice = createSlice({
	name: 'berichten',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getBerichten.pending, state => {
				state.loading = true;
			})
			.addCase(getBerichten.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				const previousBerichten = state.berichten?.berichten ?? ([] as Bericht[]);
				state.communityId = meta.arg.communityId;
				state.berichten = {
					...payload,
					berichten: [...previousBerichten, ...payload.berichten],
				};
			})
			.addCase(getBerichten.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(resetBerichten.pending, state => {
				state.loading = true;
				state.berichten = {
					berichten: [],
					totalItems: 0,
				};
			})
			.addCase(resetBerichten.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				if (meta.arg.communityId) {
					state.communityId = meta.arg.communityId;
				}
				state.berichten = payload;
			})
			.addCase(resetBerichten.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(getBericht.pending, state => {
				state.loading = true;
				state.selectedBericht = null;
			})
			.addCase(getBericht.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.selectedBericht = payload;
			})
			.addCase(getBericht.rejected, (state, { payload }) => {
				state.loading = false;
				state.selectedBericht = null;
				state.error = payload ?? null;
			})
			.addCase(likeBericht.fulfilled, (state, { payload }) => {
				const berichtId = payload.bericht.id;
				const likedMessage = state.berichten?.berichten.find(b => b.id === berichtId);
				if (likedMessage) {
					likedMessage.emoties = addAndRemoveOtherEmoEmoties(likedMessage.emoties, payload);
				}
				if (state.selectedBericht && state.selectedBericht.id === berichtId) {
					state.selectedBericht.emoties = addAndRemoveOtherEmoEmoties(state.selectedBericht.emoties, payload);
				} else if (state.selectedBericht) {
					const likedReaction = state.selectedBericht.reacties?.find(b => b.id === berichtId);
					if (likedReaction) {
						likedReaction.emoties = addAndRemoveOtherEmoEmoties(likedReaction.emoties, payload);
					}
				}
			})
			.addCase(deleteBerichtEmotie.fulfilled, (state, { meta }) => {
				const emotieId = meta.arg.emotieId;
				state.berichten?.berichten.forEach(b => {
					b.emoties = b.emoties.filter(e => e.id !== emotieId);
				});
				if (state.selectedBericht) {
					state.selectedBericht.emoties = state.selectedBericht.emoties.filter(e => e.id !== emotieId);
					state.selectedBericht.reacties?.forEach(r => {
						r.emoties = r.emoties.filter(e => e.id !== emotieId);
					});
				}
			})
			.addCase(createReactie.pending, state => {
				state.loadingCreatingReactie = true;
			})
			.addCase(createReactie.fulfilled, (state, { payload }) => {
				const selectedBericht = state.selectedBericht;
				if (selectedBericht) {
					selectedBericht.reacties?.push(payload);
					state.berichten?.berichten.find(b => b.id === selectedBericht.id)?.reacties?.push(payload);
				}
				state.loadingCreatingReactie = false;
			})
			.addCase(createReactie.rejected, (state, { payload }) => {
				state.loadingCreatingReactie = false;
				state.errorCreatingReactie = payload ?? null;
			})
			.addCase(createBericht.pending, state => {
				state.loadingCreatingBericht = true;
				state.errorCreatingBericht = null;
			})
			.addCase(createBericht.fulfilled, state => {
				state.loadingCreatingBericht = false;
			})
			.addCase(createBericht.rejected, (state, { payload }) => {
				state.loadingCreatingBericht = false;
				state.errorCreatingBericht = payload ?? null;
			})
			.addCase(updateBericht.pending, state => {
				state.loadingUpdatingBericht = true;
				state.errorUpdatingBericht = null;
			})
			.addCase(updateBericht.fulfilled, (state, { payload }) => {
				state.loadingUpdatingBericht = false;

				updateStateBerichten(state.berichten?.berichten, payload);
				updateStateBerichten(state.selectedBericht?.reacties, payload);
			})
			.addCase(updateBericht.rejected, (state, { payload }) => {
				state.loadingUpdatingBericht = false;
				state.errorUpdatingBericht = payload ?? null;
			})
			.addCase(deleteBericht.fulfilled, (state, { meta }) => {
				const berichtId = meta.arg.berichtId;
				if (state.berichten) {
					const { totalItems, berichten } = state.berichten;
					state.berichten = {
						totalItems: totalItems - 1,
						berichten: berichten.filter(b => b.id !== berichtId),
					};
					state.selectedBericht = state.selectedBericht
						? {
								...state.selectedBericht,
								reacties: state.selectedBericht?.reacties?.filter(r => r.id !== berichtId),
						  }
						: null;
					state.berichten.berichten.forEach(
						bericht => (bericht.reacties = bericht?.reacties?.filter(r => r.id !== berichtId))
					);
				}
			})
			.addCase(refetchLastGetBerichten.pending, state => {
				state.loading = true;
			})
			.addCase(refetchLastGetBerichten.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (!payload) {
					return state;
				}
				const previousBerichten = state.berichten?.berichten ?? ([] as Bericht[]);
				const previousBerichtenIds = previousBerichten.map(b => b.id);
				const newBerichten = payload?.berichten.filter(b => !previousBerichtenIds.includes(b.id)) ?? ([] as Bericht[]);
				state.berichten = {
					...payload,
					berichten: [...previousBerichten, ...newBerichten],
				};
			})
			.addCase(refetchLastGetBerichten.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(setFilters, (state, { payload }) => {
				state.filters = { ...state.filters, ...payload };
			})
			.addCase(resetFilters, state => {
				state.filters = { ...initialState.filters };
			})
			.addCase(deleteInappropriateReports.fulfilled, (state, { meta }) => {
				const berichtId = meta.arg.berichtId;
				let berichtNoLongerReported: string | null = null;

				if (state.selectedBericht?.id === berichtId) {
					removeOngEmoties(state.selectedBericht);
				}
				const comment = state.selectedBericht?.reacties?.find(r => r.id === berichtId);
				if (comment) {
					removeOngEmoties(comment);
					const parentBericht = state.berichten?.berichten.find(b => b.reacties?.some(r => r.id === berichtId));
					if (parentBericht) {
						parentBericht.aantalOngepast = parentBericht.aantalOngepast - 1;
						if (parentBericht.aantalOngepast < 1) {
							berichtNoLongerReported = parentBericht.id;
						}
					}
				}
				const bericht = state.berichten?.berichten.find(b => b.id === berichtId);
				if (bericht) {
					removeOngEmoties(bericht);
					berichtNoLongerReported = bericht.id;
				}

				if (state.filters.showReported && state.berichten && berichtNoLongerReported) {
					state.berichten = {
						totalItems: state.berichten.totalItems - 1,
						berichten: state.berichten?.berichten.filter(b => b.id !== berichtNoLongerReported),
					};
				}
			})
			.addCase(updateBerichtInList, (state, { payload }) => {
				updateStateBerichten(state.berichten?.berichten, payload);

				if (state.selectedBericht && state.selectedBericht.id === payload.id) {
					state.selectedBericht = payload;
				}
			});
	},
});

const addAndRemoveOtherEmoEmoties = (emoties: BerichtEmotie[], emotie: BerichtEmotie) => {
	return [...emoties.filter(e => e.soort?.code !== EmotieSoortCode.EMO || e.relatie.id !== emotie.relatie.id), emotie]; // Leave ONG emoties
};

const removeOngEmoties = (bericht: { aantalOngepast: number; emoties: BerichtEmotie[] }) => {
	const ongEmotieCount = bericht.emoties.filter(e => e.soort?.code === EmotieSoortCode.ONG).length;
	bericht.aantalOngepast = bericht.aantalOngepast - ongEmotieCount;
	bericht.emoties = [...bericht.emoties.filter(e => e.soort?.code !== EmotieSoortCode.ONG)];
};

const updateStateBerichten = (berichten?: Bericht[], payload?: Bericht) => {
	if (berichten && payload) {
		const updatedBerichtIndex = berichten.findIndex(b => b.id === payload.id);
		if (updatedBerichtIndex !== -1) {
			berichten.splice(updatedBerichtIndex, 1, payload);
		}
	}
};

export const berichtenReducer = berichtenSlice.reducer;
