import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactieInput, IBericht } from '@zig.community/community-components';

import { Bericht } from '../../ZigCommunity/Bericht';
import { useAppDispatch, useAppSelector } from '../../../../../global/redux/store';
import { createReactie, getBericht, selectBerichten } from '../../../redux/berichten';
import UploadFile from '../../../../../global/components/UploadFile/UploadFile';
import FileItem from '../../../../../global/components/UploadFile/FileItem/FileItem';
import { Bericht as BerichtType } from '../../../api/models';
import { Comment } from './Comment/Comment';
import EditMessage from '../EditMessage/EditMessage';
import ErrorMessage from '../../../../../global/components/ErrorMessage/ErrorMessage';
import { useUploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import styles from './SingleMessage.module.scss';

const sortByDate = (items: IBericht[]) => {
	const compareByDatum = (a: IBericht, b: IBericht) => {
		const datumA = a.aanmaakdatum ? new Date(a.aanmaakdatum).getTime() : 0;
		const datumB = b.aanmaakdatum ? new Date(b.aanmaakdatum).getTime() : 0;

		return datumA - datumB;
	};

	return items.sort(compareByDatum);
};

export interface SingleMessageProps {
	id: string;
	communityId: string;
	backToBerichtenList: () => void;
	initShowInput?: boolean;
	onEditBericht: (bericht: BerichtType) => void;
}

const SingleMessage: FC<SingleMessageProps> = ({
	id,
	communityId,
	backToBerichtenList,
	initShowInput,
	onEditBericht,
}) => {
	const { t } = useTranslation();
	const { selectedBericht, loading, error, loadingCreatingReactie, errorCreatingReactie } =
		useAppSelector(selectBerichten);
	const dispatch = useAppDispatch();

	const [value, setValue] = useState<string>('');
	const [showInput, setShowInput] = useState(initShowInput);
	const [inputNode, setInputNode] = useState<HTMLZdsInputTextElement | null>(null);
	const [endOfReactiesNode, setEndOfReactiesNode] = useState<HTMLDivElement | null>(null);
	const [editReactie, setEditReactie] = useState<BerichtType | null>(null);
	const uploadButtonRef = useRef<HTMLZdsButtonElement>(null);
	const reactiesContainerRef = useRef<HTMLDivElement>(null);

	const {
		fileData: allFileData,
		handleAddFile,
		handleRemoveFile,
	} = useUploadFileData({
		maxFiles: 1,
		mode: 'SWAP_LAST',
	});

	const fileData = allFileData[0];

	const canSend = !fileData?.error && !loadingCreatingReactie;

	const inputRef = useCallback(
		(node: HTMLZdsInputTextElement) => {
			setInputNode(node);
		},
		[showInput]
	);

	const endOfReactiesRef = useCallback(
		(node: HTMLDivElement) => {
			setEndOfReactiesNode(node);
		},
		[showInput]
	);

	useEffect(() => {
		dispatch(getBericht({ berichtId: id }));
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (editReactie) {
			window.scrollTo(0, 0);
		}
	}, [editReactie]);

	useEffect(() => {
		if (showInput && inputNode) {
			setTimeout(() => {
				inputNode.focusComponent();
			}, 500);
		}
	}, [showInput, inputNode]);

	useEffect(() => {
		if (!loadingCreatingReactie && endOfReactiesNode && showInput) {
			scrollToEndOFReacties();
		}
	}, [loadingCreatingReactie, endOfReactiesNode]);

	const scrollToEndOFReacties = () => {
		setTimeout(() => {
			endOfReactiesNode?.scrollIntoView();
		}, 0);
	};

	const handleSubmit = () => {
		if (!canSend) return;

		dispatch(
			createReactie({
				communityId,
				categoryId: selectedBericht?.categorie?.id,
				berichtId: id,
				value,
				soort: selectedBericht?.soort?.code,
				fileData: { base64: fileData?.base64, name: fileData?.file?.name ?? null },
			})
		);
		if (fileData) {
			handleRemoveFile(fileData.id);
		}
		setValue('');
	};

	const handleSetReactie = (newReactie: string) => {
		setValue(newReactie);
	};

	const handleOnComment = () => {
		setShowInput(prev => !prev);
	};

	const handleAddFileClick = () => {
		if (uploadButtonRef.current) {
			uploadButtonRef.current.dispatchEvent(new CustomEvent('zdsClick'));
		}
	};

	const handleEditedReactie = () => {
		setEditReactie(null);
		const reactieId = editReactie?.id;

		setTimeout(() => {
			if (reactieId) {
				document.getElementById(reactieId)?.scrollIntoView();
			}
		}, 200);
	};

	if (error) {
		return <ErrorMessage message={t(error)} />;
	}

	if (loading || !selectedBericht) {
		return <h3>{t('global:common.loading')}...</h3>;
	}

	const reacties = selectedBericht.reacties ? sortByDate(selectedBericht.reacties as any) : [];

	return (
		<div className={styles.berichtDetailsContainer} data-testid="berichtDetailsContainer">
			{editReactie && (
				<div className={styles.editReactieContainer}>
					<EditMessage bericht={editReactie} parentBericht={selectedBericht} onBackButtonClick={handleEditedReactie} />
				</div>
			)}
			<Bericht
				content={selectedBericht as any}
				community={selectedBericht.community as any}
				isSingle={true}
				onCloseBericht={backToBerichtenList}
				onEditBericht={onEditBericht}
				onComment={handleOnComment}
			/>
			<div className={styles.reactiesOuterContainer}>
				<div className={styles.reactiesContainer} ref={reactiesContainerRef}>
					{reacties.length > 0 && (
						<div data-testid="reactiesContainer">
							{reacties.map(reactie => (
								<Comment key={reactie.id} content={reactie} onEdit={setEditReactie} />
							))}
						</div>
					)}
					<div ref={endOfReactiesRef} />
				</div>
				{showInput && (
					<div className={styles.inputContainer}>
						{fileData?.file && (
							<div className="zds-margin-bottom-4">
								<FileItem {...fileData} onRemove={() => handleRemoveFile(fileData.id)} />
							</div>
						)}
						{errorCreatingReactie && <h3>{t(errorCreatingReactie)}</h3>}
						<div data-testid="reactieInputContainer">
							<ReactieInput
								reactie={value}
								onReactie={handleSetReactie}
								bijlage={null}
								onAddBijlage={handleAddFileClick}
								onRemoveBijlage={() => console.log('onRemoveBijlage')}
								onSend={handleSubmit}
								isSending={(loadingCreatingReactie || undefined) as boolean}
								ref={inputRef}
							/>
							<UploadFile onChange={handleAddFile} buttonHidden ref={uploadButtonRef} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SingleMessage;
