import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import {
	getCurrentCommunityCategoryData,
	selectCurrentCommunityCategoryData,
} from '../../redux/currentCommunityCategoryData';
import CommunityCategorySettings from './TabsContent/CommunityCategorySettings/CommunityCategorySettings';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import CommunityCategoryMembers from './TabsContent/CommunityCategoryMembers/CommunityCategoryMembers';
import styles from './CommunityCategoryInformation.module.scss';

const CommunityCategoryInformation: FC = () => {
	const { t } = useTranslation();
	const { currentCommunityCategoryData, updating } = useAppSelector(selectCurrentCommunityCategoryData);
	const dispatch = useAppDispatch();
	const [selectedTab, setSelectedTab] = useState(0);
	const [title, setTitle] = useState('');
	const params = useParams<{ id: string }>();
	const categoryId = params.id;

	const communityId = currentCommunityCategoryData?.community.id;

	useEffect(() => {
		if (categoryId) {
			void dispatch(getCurrentCommunityCategoryData(categoryId));
		}
	}, [params]);

	const handleCommunityCategoryNameChange = (name: string) => {
		setTitle(name);
	};

	const tabsData = [
		{
			name: t('community:communityCategoryInformationPage.tabs.settings'),
			content: currentCommunityCategoryData ? (
				<CommunityCategorySettings onCommunityCategoryNameChange={handleCommunityCategoryNameChange} />
			) : null,
		},
		{
			name: t('community:communityCategoryInformationPage.tabs.followers'),
			content: <CommunityCategoryMembers />,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.communityManagement'), link: '/communitybeheer' },
		{ label: t('community:communitiesPage.header.title'), link: `/communitybeheer/community` },
		{ label: t('community:community.community'), link: `/communitybeheer/community/${communityId}` },
		{ label: t('community:communityCategoryInformationPage.header.title') },
	];

	const saveButton = (
		<SaveButton
			dataTestId="updateCommunityCategoryDataSubmitButton"
			formId="updateCommunityCategoryForm"
			disabled={updating}
		/>
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div className="zds-flex zds-flex-1 zds-gap-4">
				<div className="zds-flex zds-flex-1 zds-flex-col zds-gap-4">
					<div>
						<BackButton />
					</div>
					<PageHeader
						title={title}
						iconName="message-lines"
						iconLibrary="fa-solid"
						breadcrumbs={breadcrumbs}
						status={currentCommunityCategoryData?.status?.code}
						contentEnd={selectedTab === 0 && saveButton}
					/>
					<Pane className={styles.contentPane}>
						<Tabs tabsData={tabsData} onTabChange={setSelectedTab} />
					</Pane>
				</div>
			</div>
		</PageLayout>
	);
};

export default CommunityCategoryInformation;
