import { useTranslation } from 'react-i18next';
import Appbar from '../../global/components/Appbar/Appbar';

import { WrapperComponent } from '../../routes';
import { useAppPermissions } from '../../global/hooks/useAppPermissions/useAppPermissions';
import NotFound from '../../global/components/NotFound/NotFound';
import styles from './OrganizationManagementAppWrapper.module.scss';

const OrganizationManagementAppWrapper: WrapperComponent = ({ children }) => {
	const { t } = useTranslation('global');
	const hasAppPermissions = useAppPermissions();

	const content = !hasAppPermissions('ORG') ? <NotFound /> : children;

	return (
		<div className={styles.root}>
			<Appbar tempRelativeAppSwitcherPath="/organisatiebeheer" appName={t('apps.organizationManagement')} />
			<div className={styles.content}>{content}</div>
		</div>
	);
};

export default OrganizationManagementAppWrapper;
