import { FC, useState } from 'react';

import RelatieAPI from '../../api';
import { NatuurlijkPersoonInfo } from '../../api/models';
import AdvancedSelectInput, {
	AdvancedSelectItem,
} from '../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';

export interface RelatiesAdvancedSelectProps {
	selectedValues?: AdvancedSelectItem[];
	ignoreValues?: AdvancedSelectItem[];
	onChange: (data: AdvancedSelectItem[]) => void;
	validationError?: string;
	explanationText?: string;
	placeholder?: string;
	disabled?: boolean;
}

const RelatiesAdvancedSelect: FC<RelatiesAdvancedSelectProps> = ({
	selectedValues,
	ignoreValues,
	onChange,
	validationError,
	explanationText,
	placeholder,
	disabled,
}) => {
	const [relaties, setRelaties] = useState<NatuurlijkPersoonInfo[] | null>(null);
	const [error, setError] = useState('');

	const searchRelaties = async (term: string) => {
		if (disabled) {
			setRelaties([]);
			return;
		}
		try {
			const result = await RelatieAPI.getNatuurlijkPersoonRelaties(term, 0, 10);
			setRelaties(result);
		} catch {
			setError('global:form.errorMessage.get');
		}
	};

	return (
		<AdvancedSelectInput
			data={relaties ? relaties.map(r => ({ ...r, label: r.naam })) : null}
			selectedValues={selectedValues}
			ignoreValues={ignoreValues}
			searchData={searchRelaties}
			dataError={error}
			validationError={validationError}
			onChange={onChange}
			explanationText={explanationText}
			placeholder={placeholder}
			disabled={disabled}
		/>
	);
};

export default RelatiesAdvancedSelect;
