import { FormData, FormSubmitData } from '../../components/Form/Form.types';
import { formatToISODateString } from '../date/formatToISODateString';

export const validateDates = (data: FormSubmitData, initFormData: FormData, dateFormat: string) => {
	const { einddatum, begindatum } = data;

	const einddatumFC = initFormData.einddatum;

	if (!einddatumFC) return null;

	const today = new Date();
	today.setHours(0, 0, 0, 0);

	const begindatumTime = new Date(begindatum as string).getTime();
	const einddatumTime = new Date(einddatum as string).getTime();
	const initEinddatum = formatToISODateString(einddatumFC.value as string, dateFormat);

	if (einddatum && initEinddatum !== einddatum && today.getTime() > einddatumTime) {
		return 'global:form.errorMessage.endDateAfterToday';
	}

	if ((begindatum && einddatum && begindatumTime >= einddatumTime) || (einddatum && !begindatum)) {
		return 'global:form.errorMessage.endDateAfterStartDate';
	}

	return null;
};
