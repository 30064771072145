import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsDivider, ZdsForm } from '@zig-design-system/ui-components-react';

import { FormControl, FormData, FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { Community, Language } from '../../../../api/models';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import { selectCurrentCommunityData, updateCommunity } from '../../../../redux/currentCommunityData';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import LanguageSelect, {
	LanguageData,
} from '../../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { AdvancedSelectItem } from '../../../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';
import { DEFAULT_LANGUAGE, useLanguageSelect } from '../../../../hooks/useLanguageSelect/useLanguageSelect';
import { getDateFormat } from '../../../../../../global/utils/date/getDatePattern';
import { useRenderFormControl } from '../../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { getFormSubmitData } from '../../../../../../global/utils/form/getFormSubmitData';
import { validateDates } from '../../../../../../global/utils/form/validateDates';
import { updateFormData } from '../../../../../../global/utils/form/updateFormData';
import { getFormDataFromSchema } from '../../../../../../global/utils/form/getFormDataFromSchema';
import { useNaamPreviewData } from '../../../../hooks/useNaamPreviewData.ts/useNaamPreviewData';
import { useCommunityUpdateFormSchema } from '../../../../hooks/form/schema/create/community/useCommunityUpdateFormSchema';
import RelatiesAdvancedSelect from '../../../../components/RelatiesAdvancedSelect/RelatiesAdvancedSelect';
import { RolRelatieSubmitData, getRolRelatieSubmitData } from '../../../../utils/form/getRolRelatieSubmitData';
import { FormImage } from '../../../../../../global/components/Form/FormImage/FormImage';
import { UpdateCommunityBijlagen } from '../../../../api';
import { getCommunityMembers } from '../../../../utils/getCommunityMembers/getCommunityMembers';
import styles from './CommunitySettings.module.scss';

export interface CommunitySettingsProps {
	onCommunityNameChange: (name: string) => void;
}

const MAX_FILE_SIZE = 1024;

const getManagersAdvancedSelectItems = (community: Community | null): AdvancedSelectItem[] => {
	if (!community?.rolRelaties) return [];

	const managers = getCommunityMembers(community, 'COM').map(manager => manager.relatie);

	return managers.map(manager => ({ id: manager.id ?? '', label: manager.naam ?? '' }));
};

const CommunitySettings: FC<CommunitySettingsProps> = ({ onCommunityNameChange }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const hasPermission = usePermissions();
	const { currentCommunityData, updating, error } = useAppSelector(selectCurrentCommunityData);
	const [formData, setFormData] = useState<FormData | null>(null);
	const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const [selectedManagers, setSelectedManagers] = useState<AdvancedSelectItem[]>(
		getManagersAdvancedSelectItems(currentCommunityData)
	);
	const [selectedManagersValidationError, setSelectedManagersValidationError] = useState('');
	const dateFormat = getDateFormat(i18n.language);
	const errorMessage = error ?? validationErrorMessage;
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();
	const canEdit = hasPermission('COM_WIJ_COM_DET');
	const canEditManagers = canEdit && hasPermission('COM_WIJ_COM_LED');

	const formSchema = useCommunityUpdateFormSchema({
		data: formData,
		language: selectedLanguage,
		selectedFields: ['naam', 'toelichting', 'omschrijving', 'begindatum', 'einddatum'],
	});

	const { naam } = useNaamPreviewData(formData, selectedLanguage);

	const getCommunityDataBijlagenBasedOnCode = (code: string) => {
		return currentCommunityData?.bijlagen?.filter(doc => doc.detailSoort?.code === code)[0];
	};

	const [backgroundPicture, setBackgroundPicture] = useState(getCommunityDataBijlagenBasedOnCode('ACH'));
	const [profilePicture, setProfilePicture] = useState(getCommunityDataBijlagenBasedOnCode('PRO'));
	const [formSubmitted, setFormSubmitted] = useState(false);

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema, currentCommunityData]);

	useEffect(() => {
		onCommunityNameChange(naam);
	}, [naam]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async () => {
		if (!canEdit || !currentCommunityData?.id || !formData || updating) return;
		if (!formData) return;

		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (
			selectedLanguage !== DEFAULT_LANGUAGE &&
			!((formData?.naam.languageData?.[DEFAULT_LANGUAGE] as string).trim() ?? '')
		) {
			return setValidationErrorMessage('community:createCommunityPage.form.dutchNameMissingErrorMessage');
		}

		const datesInvalidError = validateDates(formSubmitData, formData, dateFormat);
		if (datesInvalidError) {
			return setValidationErrorMessage(datesInvalidError);
		}

		if (!validateManagers()) return;

		setValidationErrorMessage('');

		const bijlagen: UpdateCommunityBijlagen = [];

		if (backgroundPicture) {
			bijlagen.push({
				soort: {
					code: 'FOT',
				},
				detailSoort: {
					code: 'ACH',
				},
				bestand: backgroundPicture?.bestand,
			});
		}

		if (profilePicture) {
			bijlagen.push({
				soort: {
					code: 'FOT',
				},
				detailSoort: {
					code: 'PRO',
				},
				bestand: profilePicture?.bestand,
			});
		}

		handleUpdate(formSubmitData, bijlagen);
	};

	const isSelectedManager = (id: string) => !!selectedManagers.find(manager => manager.id === id);

	const handleUpdate = (formData: FormSubmitData, bijlagen: UpdateCommunityBijlagen) => {
		if (currentCommunityData?.id && !updating) {
			const managersSubmitData = selectedManagers.map(manager => getRolRelatieSubmitData('COM', manager.id));

			const membersSubmitData: RolRelatieSubmitData[] =
				getCommunityMembers(currentCommunityData, 'LID')
					?.filter(rr => !isSelectedManager(rr.relatie.id ?? ''))
					?.map(rr => ({ soort: rr.soort, relatie: { id: rr.relatie.id ?? '' } })) ?? [];

			const updateData = {
				id: currentCommunityData.id,
				payload: {
					formData,
					rolRelatiesSubmitData: [...membersSubmitData, ...managersSubmitData],
					bijlagen: bijlagen,
				},
			};
			void dispatch(updateCommunity(updateData));

			setFormSubmitted(true);
		}
	};

	const validateManagers = () => {
		if (!selectedManagers.length) {
			setSelectedManagersValidationError('community:createCommunityPage.form.communityManagerRequiredErrorMessage');
			return false;
		}
		return true;
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
		setValidationErrorMessage('');
	};

	const handleSelectedManagersChange = (newSelectedManagers: AdvancedSelectItem[]) => {
		setSelectedManagers(newSelectedManagers);
		setValidationErrorMessage('');
		if (newSelectedManagers.length) {
			setSelectedManagersValidationError('');
		}
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	if (!formSchema) return null;

	return (
		<>
			<div data-testid="communityForm" className={styles.container}>
				<ZdsForm onZdsSubmit={handleSubmit} id="updateCommunityForm">
					<div className={styles.formInputsContainer}>
						{renderFormControl('naam', 'large')}
						<div className="zds-flex zds-gap-3">
							<div className={styles.label}>{t('community:createCommunityPage.form.communityManager')}</div>
							<RelatiesAdvancedSelect
								selectedValues={selectedManagers}
								ignoreValues={[]}
								onChange={handleSelectedManagersChange}
								validationError={selectedManagersValidationError}
								placeholder={t('community:createCommunityPage.form.communityManagerPlaceholder') ?? ''}
								disabled={!canEditManagers}
							/>
						</div>
						<div className="zds-flex zds-flex-col zds-margin-bottom-8 zds-gap-6">
							<FormImage
								bijlagen={getCommunityDataBijlagenBasedOnCode('ACH')}
								backgroundPicture={backgroundPicture}
								setBackgroundPicture={setBackgroundPicture}
								canEdit={canEdit}
								maxFilesSize={MAX_FILE_SIZE}
								imageTitle={t('community:communityInformationPage.backgroundPicture')}
								imageClassName={styles.backgroundPicture}
								fixedHeightDefaultImage
								formSubmitted={formSubmitted}
								setFormSubmitted={setFormSubmitted}
							/>
							<FormImage
								bijlagen={getCommunityDataBijlagenBasedOnCode('PRO')}
								backgroundPicture={profilePicture}
								setBackgroundPicture={setProfilePicture}
								canEdit={canEdit}
								maxFilesSize={MAX_FILE_SIZE}
								imageTitle={t('community:communityInformationPage.profilePicture')}
								imageClassName={styles.profilePicture}
								formSubmitted={formSubmitted}
								setFormSubmitted={setFormSubmitted}
							/>
						</div>
						{renderFormControl('toelichting', 'large')}
						{renderFormControl('omschrijving', 'large')}
						<ZdsDivider className={styles.divider} />
						<div className="zds-headline-lg zds-color-inherit">
							{t('community:createCommunityPage.form.publicationStatus')}
						</div>
						{renderFormControl('begindatum', 'large')}
						{renderFormControl('einddatum', 'large')}
					</div>
				</ZdsForm>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			{errorMessage && <ErrorMessage message={t(errorMessage)} />}
		</>
	);
};

export default CommunitySettings;
