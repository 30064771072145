import { FC } from 'react';
import { ZdsAlert } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import styles from './Alert.module.scss';

interface AlertProps {
	type: 'error' | 'info' | 'success' | 'warning';
	title: string;
	message?: string;
	show: boolean;
	onAnimationEnd: () => void;
}

const Alert: FC<AlertProps> = ({ type, title, message, show, onAnimationEnd }) => {
	const containerClasses = classNames(styles.container, { [styles['container--animation']]: show });

	return (
		<div className={containerClasses} onAnimationEnd={onAnimationEnd}>
			<ZdsAlert type={type}>
				<div className="zds-flex zds-items-center">{title}</div>
				{message && <div slot="body">{message}</div>}
			</ZdsAlert>
		</div>
	);
};

export default Alert;
