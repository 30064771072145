import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ZdsBadge } from '@zig-design-system/ui-components-react';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { getCurrentClusterData, selectCurrentClusterData } from '../../redux/currentClusterData';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ClusterOverview from './TabsContent/ClusterOverview/ClusterOverview';
import { clusterFormId } from '../../components/ClusterForm/ClusterForm';
import { getStatus } from '../../../../global/utils/getStatus/getStatus';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import ClusterEenheden from './TabsContent/ClusterEenheden/ClusterEenheden';

export const TABS = ['edit', 'eenheden', 'contactpersonen'];

export interface ClusterInformationProps {
	tab?: number;
}

const ClusterInformation: FC<ClusterInformationProps> = ({ tab = 0 }) => {
	const { currentClusterData, updating } = useAppSelector(selectCurrentClusterData);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const hasPermission = usePermissions();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams<{ id: string }>();

	const isExternal = !!currentClusterData?.idExtern;

	useEffect(() => {
		const clusterId = params.id;
		if (clusterId) {
			void dispatch(getCurrentClusterData(clusterId));
		}
	}, [params]);

	useEffect(() => {
		if (currentClusterData?.naam) {
			setName(currentClusterData.naam);
		}
	}, [currentClusterData]);

	const handleTabChange = (tab: number) => {
		if (location.pathname.split('/').pop() !== TABS[tab]) {
			navigate(`../${TABS[tab]}`, { relative: 'path', replace: true });
		}
	};

	const tabsData = [
		{
			name: t('vastgoed:clusterInformationPage.tabs.overview'),
			content: <ClusterOverview onNameChange={setName} />,
		},
		{
			name: t('vastgoed:clusterInformationPage.tabs.units'),
			content: <ClusterEenheden tableTestId="clusterEenhedenTable" />,
		},
		{
			name: t('vastgoed:clusterInformationPage.tabs.contactPersons'),
			content: <div />,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.porfolioManagement'), link: '/vastgoedbeheer' },
		{ label: t('vastgoed:clustersPage.header.title'), link: '/vastgoedbeheer/clusters' },
		{ label: t('vastgoed:clusterInformationPage.header.title') },
	];

	const saveButton = tab === 0 && hasPermission('VAS_WIJ_CLU') && (
		<SaveButton dataTestId="updateClusterDataSubmitButton" formId={clusterFormId} disabled={updating} />
	);

	const exclamationBadge = (
		<div className="zds-headline-lg">
			<ZdsBadge color="danger">{t('vastgoed:clusterInformationPage.header.exclamationBadgeLabel')}</ZdsBadge>
		</div>
	);

	const headerEndContent = isExternal ? exclamationBadge : saveButton;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={name}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				status={getStatus(currentClusterData?.begindatum, currentClusterData?.einddatum)}
				contentEnd={headerEndContent}
			/>
			<Pane className="zds-flex-1">
				<Tabs tabsData={tabsData} onTabChange={handleTabChange} initActiveTab={tab} />
			</Pane>
		</PageLayout>
	);
};

export default ClusterInformation;
