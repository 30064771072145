import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsDivider, ZdsForm, ZdsLink } from '@zig-design-system/ui-components-react';

import styles from './ClusterForm.module.scss';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { FormColumn } from '../../../../global/components/Form/Form';
import { FormControl, FormData, FormSubmitData } from '../../../../global/components/Form/Form.types';
import { useRenderFormControl } from '../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { getDateFormat } from '../../../../global/utils/date/getDatePattern';
import { getFormDataFromSchema } from '../../../../global/utils/form/getFormDataFromSchema';
import { getFormSubmitData } from '../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../global/utils/form/updateFormData';
import { validateDates } from '../../../../global/utils/form/validateDates';
import { useClusterFormSchema } from '../../hooks/form/schema/useClusterFormSchema';
import { Cluster } from '../../../communitybeheer/api/models';

export const clusterFormId = 'clusterFormId';

export interface ClusterFormProps {
	onNameChange: (name: string) => void;
	onSubmit: (formSubmitData: FormSubmitData) => void;
	clusterData: Cluster | null;
	error?: string | null;
	isUpdate?: boolean;
}

const ClusterForm: FC<ClusterFormProps> = ({ onNameChange, clusterData, onSubmit, error, isUpdate = false }) => {
	const { t, i18n } = useTranslation();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [validationError, setValidationError] = useState('');
	const dateFormat = getDateFormat(i18n.language);
	const navigate = useNavigate();
	const isExternal = !!clusterData?.idExtern;
	const parentId = clusterData?.bovenliggendeCluster?.id;

	const formSchema = useClusterFormSchema(clusterData, isExternal);

	useEffect(() => {
		setValidationError('');
		setFormData(formSchema ? getFormDataFromSchema(formSchema) : null);
	}, [formSchema]);

	useEffect(() => {
		onNameChange((formData?.naam?.value as string) ?? '');
	}, [formData]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl) => {
		updateFormData({ event, formData, inputData, setFormData });
	};

	const handleSubmit = async () => {
		if (!formData) return;

		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		const datesInvalidError = validateDates(formSubmitData, formData, dateFormat);
		if (datesInvalidError) {
			return setValidationError(datesInvalidError);
		}

		setValidationError('');
		onSubmit(formSubmitData);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
	});

	const errorMessage = error ?? validationError;

	if (!formSchema) return null;

	return (
		<div data-testid={clusterFormId} className="zds-margin-left-8">
			<div className={styles.formContainer}>
				<ZdsForm onZdsSubmit={handleSubmit} id={clusterFormId}>
					<div className="zds-flex zds-flex-col zds-flex-1 zds-gap-4">
						<div className="zds-headline-lg">{t('vastgoed:clusterInformationPage.form.generalSettings')}</div>
						<div className="zds-flex zds-gap-8">
							<FormColumn>
								{renderFormControl('code')}
								{renderFormControl('naam')}
								{renderFormControl('soort')}
							</FormColumn>
							<FormColumn>
								{renderFormControl('owner')}
								{isUpdate && renderFormControl('idBron')}
								{isUpdate && renderFormControl('idExtern')}
								<div className="zds-flex zds-gap-3 zds-margin-y-2">
									<div className={styles.label}>{t('vastgoed:clusterInformationPage.form.mainCluster')}</div>
									{parentId ? (
										<ZdsLink onZdsClick={() => navigate(`/vastgoedbeheer/clusters/${parentId}`)}>
											{clusterData?.bovenliggendeCluster?.naam}
										</ZdsLink>
									) : (
										<div>{t('global:common.none')}</div>
									)}
								</div>
							</FormColumn>
						</div>
						<FormColumn>{renderFormControl('omschrijving')}</FormColumn>
						<ZdsDivider className={styles.divider} />
						<div className="zds-headline-lg">{t('vastgoed:clusterInformationPage.form.clusterStatus')}</div>
						<FormColumn>
							{renderFormControl('begindatum')}
							{renderFormControl('einddatum')}
						</FormColumn>
					</div>
				</ZdsForm>
			</div>
			{errorMessage && <ErrorMessage message={t(errorMessage)} />}
		</div>
	);
};

export default ClusterForm;
