import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import { Cluster } from '../../../communitybeheer/api/models';
import DataTable from '../../../../global/components/DataTable/DataTable';
import { useMapClustersToTableData } from '../../hooks/table/useMapClustersToTableData/useMapClustersToTableData';
import SubheaderItemsCount from '../../../../global/components/DataTable/SubheaderItemsCount/SubheaderItemsCount';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';

export interface ClustersTableProps {
	clusters?: { [key: number]: Cluster[] };
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
}

const ClustersTable: FC<ClustersTableProps> = ({ clusters, onPageChange, pageSize, itemsNum }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasPermission = usePermissions();

	const tableData = useMapClustersToTableData(clusters ?? null);

	const handleRowClick = (id: string) => {
		navigate(`/vastgoedbeheer/clusters/${id}/edit`);
	};

	const navigateToClusterCreate = () => {
		navigate('/vastgoedbeheer/clusters/create');
	};

	const addNewCommunityButton = (
		<ZdsButton data-testid="addNewCommunityButton" onZdsClick={navigateToClusterCreate}>
			<ZdsIcon library="fa-regular" name="plus" size="xs" />
			{t('global:common.addNew')}
		</ZdsButton>
	);

	return (
		<DataTable
			data={tableData}
			tableTestId="clustersTable"
			onRowClick={handleRowClick}
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
			header={{
				title: t('vastgoed:clustersPage.table.title') ?? '',
				endContent: hasPermission('VAS_AAN_CLU') ? addNewCommunityButton : null,
				subheader: {
					Component: SubheaderItemsCount,
					props: {
						labelTotal: {
							singular: t('vastgoed:vastgoed.cluster').toLowerCase(),
							plural: t('vastgoed:vastgoed.clusters').toLowerCase(),
						},
					},
				},
			}}
		/>
	);
};

export default ClustersTable;
