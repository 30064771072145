import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Eenheid } from '../../../api/models';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';

export const useMapClusterEenhedenToTableData = (
	eenheden: {
		[key: number]: Eenheid[];
	} | null,
	canEdit: boolean,
	preselected: string[]
): TableData | null => {
	const { t } = useTranslation();

	const header: TableData['header'] = {
		labels: [
			t('vastgoed:vastgoed.straatnaam'),
			t('vastgoed:vastgoed.huisnummer'),
			t('vastgoed:vastgoed.huisnummerToevoeging'),
			t('vastgoed:vastgoed.postcode'),
			t('vastgoed:vastgoed.woonplaats'),
			t('global:domainShared.soort'),
			t('vastgoed:vastgoed.etage'),
			t('global:domainShared.status'),
		],
		columnIds: [
			'adres.straatnaam',
			'adres.huisnummer',
			'adres.huisnummerToevoeging',
			'adres.postcode',
			'adres.woonplaats',
			'soort.code',
			'etage',
			'status.code',
		],
	};

	const getPage = useCallback(
		(eenheden: Eenheid[]): TableRow[] => {
			return eenheden.map(eenheid => {
				return {
					id: eenheid.id ?? '',
					isSelectable: canEdit,
					isPreselected: preselected.includes(eenheid.id),
					cells: [
						{ mainText: eenheid.adres?.straatnaam ?? '' },
						{ mainText: eenheid.adres?.huisnummer ?? '' },
						{ mainText: eenheid.adres?.huisnummerToevoeging ?? '' },
						{ mainText: eenheid.adres?.postcode ?? '' },
						{ mainText: eenheid.adres?.woonplaats ?? '' },
						{ mainText: getReferentiedataNaam(t, 'EENHEIDSOORT', eenheid.soort?.code) },
						{ mainText: eenheid.etage?.toString() ?? '' },
						{ mainText: getReferentiedataNaam(t, 'EENHEIDSTATUS', eenheid.status?.code) },
					],
				};
			});
		},
		[eenheden, canEdit, preselected]
	);

	const pages = useTablePages(eenheden, getPage, preselected);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
