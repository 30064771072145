import { Operation } from './parseFilters.types';

export const parseCompareValue = (value: string) => {
	const trimValue = value.trim();

	if (value && !isNaN(Number(value))) {
		return ['eq', value];
	}
	const operations: { [key: string]: Operation } = { '<=': 'lte', '>=': 'gte', '<': 'lt', '>': 'gt', '=': 'eq' };

	const key = Object.keys(operations).find(key => trimValue.startsWith(key));
	if (!key) return [];

	const operation = operations[key];
	const valueToCompare = trimValue.slice(key.length).trim();

	if (!valueToCompare) return [];

	return [operation, valueToCompare];
};
