import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import DataTable, { SortableOption } from '../../../../global/components/DataTable/DataTable';
import { useMapCommunitiesToTableData } from '../../hooks/table/useMapCommunitiesToTableData/useMapCommunitiesToTableData';
import { Community } from '../../api/models';
import { useBerichtPermissions } from '../../hooks/useBerichtPermissions/useBerichtPermissions';
import CommunitiesTableSubheader from './CommunitiesTableSubheader/CommunitiesTableSubheader';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';

export interface CommunitiesTableProps {
	communitiesData?: { [key: number]: Community[] };
	onPageChange: (page: number) => void;
	onSearchChange?: (searchData: { [key: string]: string }) => void;
	onSortChange?: (sortData: { columnId: string; sort: SortableOption }) => void;
	pageSize: number;
	itemsNum: number;
	currentPage: number;
	sortData: {
		[key: string]: SortableOption;
	};
}

const CommunitiesTable: FC<CommunitiesTableProps> = ({
	communitiesData,
	onPageChange,
	onSearchChange,
	onSortChange,
	pageSize,
	itemsNum,
	currentPage,
	sortData,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasBerichtPermission = useBerichtPermissions();
	const hasPermission = usePermissions();

	const handleRowClick = (id: string) => {
		navigateToCommunity(id);
	};

	const handleReportedNumberClicked = (id: string) => {
		navigateToCommunity(id, true);
	};

	const navigateToCommunity = (id: string, reported?: boolean) => {
		navigate(`/communitybeheer/community/${id}?reported=${reported ? 'true' : 'false'}`, {
			state: { fromPage: currentPage },
		});
	};

	const navigateToCommunityCreate = () => {
		navigate('/communitybeheer/community/create');
	};

	const tableData = useMapCommunitiesToTableData(
		communitiesData ?? null,
		sortData,
		handleReportedNumberClicked,
		hasBerichtPermission('COM_LEZ_MEL_ONG')
	);

	const addNewCommunityButton = (
		<ZdsButton data-testid="addNewCommunityButton" onZdsClick={navigateToCommunityCreate}>
			<ZdsIcon library="fa-regular" name="plus" size="xs" />
			{t('global:common.addNew')}
		</ZdsButton>
	);

	return (
		<DataTable
			data={tableData}
			initPage={currentPage}
			totalItemsNum={itemsNum}
			onRowClick={handleRowClick}
			tableTestId="communitiesTable"
			pageSizes={[pageSize]}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
			onSearchChange={onSearchChange}
			onSortChange={onSortChange}
			showHeaderWithNoItems
			header={{
				title: t('community:communitiesPage.table.title') ?? '',
				endContent: hasPermission('COM_AAN_COM') ? addNewCommunityButton : null,
				subheader: {
					Component: CommunitiesTableSubheader,
					props: {
						labelTotal: {
							singular: 'community:communitiesPage.table.subtitle.singular',
							plural: 'community:communitiesPage.table.subtitle.plural',
						},
					},
				},
			}}
		/>
	);
};

export default CommunitiesTable;
