import { FormSchema } from '../../../../../global/components/Form/Form.types';
import { Medewerker } from '../../../api/models';
import { ZdsInputDate, ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';
import { getStringTranslation } from '../../../../../global/utils/getStringTranslation';
import { getFormattedDate } from '../../../../../global/utils/date/formatDate/formatDate';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import i18n, { TFunction } from 'i18next';
import { getReferentiedataSelectOptions } from '../../../../../global/utils/form/getReferentiedataSelectOptions';

export interface MedewerkerUpdateFormSchema {
	personalDetailsSchema: FormSchema;
	employmentDetailsSchema: FormSchema;
}

export const medewerkerUpdateFormSchema = (
	currentMedewerkerData: Medewerker,
	t: TFunction,
	computeNaam: () => string
): MedewerkerUpdateFormSchema => {
	const dateFormat = getDateFormat(i18n.language);

	const personalDetailsSchema: FormSchema = {
		formControls: [
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						value: computeNaam(),
						disabled: true,
					},
				},
			},
		],
		layout: {
			columns: [['naam']],
		},
	};

	const employmentDetailsSchema: FormSchema = {
		title: t('organisatie:medewerker.medewerker.title') ?? '',
		formControls: [
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.soort'),
						value: currentMedewerkerData.soort?.code ?? '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'MEDEWERKERSOORT',
						code: currentMedewerkerData.soort?.code ?? '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},

			{
				name: 'omschrijving',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.omschrijving'),
						value: currentMedewerkerData.omschrijving,
					},
				},
			},
			{
				name: 'begindatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.begindatum'),
						value: getFormattedDate(currentMedewerkerData.begindatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'einddatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.einddatum'),
						value: getFormattedDate(currentMedewerkerData.einddatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'eindeWerkzaamhedenDatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.eindeWerkzaamhedenDatum'),
						value: getFormattedDate(currentMedewerkerData.eindeWerkzaamhedenDatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
		],
		layout: {
			columns: [
				['soort', 'omschrijving'],
				['begindatum', 'einddatum', 'eindeWerkzaamhedenDatum'],
			],
		},
	};

	return { personalDetailsSchema, employmentDetailsSchema };
};
