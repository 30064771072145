import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Cluster } from '../../../../communitybeheer/api/models';
import { getRelatieNaam } from '../../../../relatiebeheer/utils/getRelatieNaam';

export const getClusterOwnerNames = (cluster: Cluster) =>
	cluster.rolRelaties
		?.filter(rol => rol.soort?.code === 'EIG')
		.map(rol => getRelatieNaam(rol.relatie))
		.filter(name => name)
		.join(', ') ?? '';

export const useMapClustersToTableData = (
	clusters: {
		[key: number]: Cluster[];
	} | null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [
			t('global:domainShared.code'),
			t('global:domainShared.naam'),
			t('global:domainShared.soort'),
			t('global:domainShared.owner'),
			t('global:domainShared.omschrijving'),
		],
	};

	const getPage = useCallback((clusters: Cluster[]) => {
		return clusters.map(cluster => {
			return {
				id: cluster.id ?? '',
				cells: [
					{ mainText: cluster.code ?? '' },
					{ mainText: cluster.naam ?? '' },
					{ mainText: getReferentiedataNaam(t, 'CLUSTERSOORT', cluster.soort?.code) },
					{ mainText: getClusterOwnerNames(cluster) },
					{ mainText: cluster.omschrijving ?? '' },
				],
			};
		});
	}, []);

	const pages = useTablePages(clusters, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
