import { RouteInfo } from '../../routes';
import DemoAccount from './pages/DemoAccount';
import DemoAppManagement from './pages/DemoAppManagement';
import DemoCasixMaintenance from './pages/DemoCasixMaintenance';
import DemoCasixMutations from './pages/DemoCasixMutations';
import DemoChat from './pages/DemoChat';
import DemoCms from './pages/DemoCms';
import DemoKnowledgeBase from './pages/DemoKnowledgeBase';
import LayoutPocMain from './pages/layout-poc/LayoutPocMain';
import DemoLocalEyz from './pages/DemoLocalEyz';
import DemoNotify from './pages/DemoNotify';
import DemoPortfolio from './pages/DemoPortfolio';
import DemoSkarp from './pages/DemoSkarp';
import DemoTenant360, { DemoTenant360CurrentProduct } from './pages/DemoTenant360/DemoTenant360';
import DemoUserAdmin from './pages/DemoUserAdmin';
import HomePage from './pages/HomePage/HomePage';
import LayoutPocVariant1 from './pages/layout-poc/LayoutPocVariant1';
import LayoutPocVariant2 from './pages/layout-poc/LayoutPocVariant2';
import LayoutPocVariant3 from './pages/layout-poc/LayoutPocVariant3';

const routes: RouteInfo[] = [
	{
		path: '',
		Component: () => <HomePage />,
	},
	{
		path: 'user-admin',
		Component: () => <DemoUserAdmin />,
	},
	{
		path: 'app-management',
		Component: () => <DemoAppManagement />,
	},
	{
		path: 'portfolio',
		Component: () => <DemoPortfolio />,
	},
	{
		path: 'tenant-360',
		subRoutes: [
			{
				path: '',
				Component: () => <DemoTenant360 />,
			},
			{
				path: 'current-product',
				Component: () => <DemoTenant360CurrentProduct />,
			},
		],
	},
	{
		path: 'cms',
		Component: () => <DemoCms />,
	},
	{
		path: 'skarp',
		Component: () => <DemoSkarp />,
	},
	{
		path: 'knowledge-base',
		Component: () => <DemoKnowledgeBase />,
	},
	{
		path: 'notify',
		Component: () => <DemoNotify />,
	},
	{
		path: 'chat',
		Component: () => <DemoChat />,
	},
	{
		path: 'localeyz',
		Component: () => <DemoLocalEyz />,
	},
	{
		path: 'casix-maintenance',
		Component: () => <DemoCasixMaintenance />,
	},
	{
		path: 'casix-mutations',
		Component: () => <DemoCasixMutations />,
	},
	{
		path: 'account',
		Component: () => <DemoAccount />,
	},
	{
		path: 'layout-poc',
		subRoutes: [
			{
				path: '',
				Component: () => <LayoutPocMain />,
			},
			{
				path: 'variant-1',
				Component: () => <LayoutPocVariant1 />,
			},
			{
				path: 'variant-2',
				Component: () => <LayoutPocVariant2 />,
			},
			{
				path: 'variant-3',
				Component: () => <LayoutPocVariant3 />,
			},
		],
	},
];

export default routes;
