import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { Medewerkerrol } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { ListResponse } from '../../../../global/api';
import { SortableOption } from '../../../../global/components/DataTable/DataTable';

export const getMedewerkerrollen = createAsyncThunk<
	ListResponse<Medewerkerrol>,
	{
		offset?: number;
		limit?: number;
		clear?: boolean;
		searchData?: {
			[key: string]: string;
		};
		sortData?: {
			[p: string]: SortableOption;
		};
	},
	{ rejectValue: string }
>('organisatieData/getMedewerkerrollen', async ({ offset, limit, searchData, sortData } = {}, { rejectWithValue }) => {
	return OrganisatieAPI.getMedewerkerrollen({ offset, limit, searchData, sortData }).catch(error => {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	});
});
