import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../global/redux/store';
import { CurrentCommunityDataState } from '.';
import { CommunityCategorie } from '../../api/models';

export const selectCurrentCommunityData = (state: RootState): CurrentCommunityDataState =>
	state.communities.currentCommunityData;

export const selectCurrentCommunityCategories = (state: RootState) =>
	state.communities.currentCommunityData.currentCommunityCategories;

export const selectLoadingCommunityCategories = (state: RootState) =>
	state.communities.currentCommunityData.loadingCommunityCategories;

export const selectErrorCommunnityCategories = (state: RootState) =>
	state.communities.currentCommunityData.errorCommunnityCategories;

export const selectCategoriesNum = (state: RootState): number =>
	state.communities.currentCommunityData.categoriesTotalNum;

export const selectCategoriesActiveNum = (state: RootState): number =>
	state.communities.currentCommunityData.categoriesActiveNum;

export const selectCategoriesInactiveNum = createSelector(
	[selectCategoriesNum, selectCategoriesActiveNum],
	(totalNum, activeNum) => totalNum - activeNum
);

export const selectCurrentCommunityCategoriesData = createSelector(
	[
		selectCurrentCommunityCategories,
		selectLoadingCommunityCategories,
		selectErrorCommunnityCategories,
		selectCategoriesNum,
		selectCategoriesActiveNum,
		selectCategoriesInactiveNum,
	],
	(categories, loading, error, totalNum, activeNum, inactiveNum) => {
		const categoriesWithFollowersNum: {
			[key: number]: {
				category: CommunityCategorie;
				followers: number;
			}[];
		} = {};

		if (categories) {
			Object.entries(categories).forEach(([pageNum, categories]) => {
				categoriesWithFollowersNum[+pageNum] = categories.map(getCategoryWithFollowersNum);
			});
		}

		return {
			categories: categories ? categoriesWithFollowersNum : null,
			loading,
			error,
			totalNum,
			activeNum,
			inactiveNum,
		};
	}
);

const getCategoryWithFollowersNum = (category: CommunityCategorie) => ({
	category,
	followers: category.rolRelaties?.filter(rol => rol.soort?.code === 'VOL').length ?? 0,
});
