import { createSlice } from '@reduxjs/toolkit';

import { getCommunities, CommunitiesState } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: CommunitiesState = { itemsNum: 0, communities: null, loading: false, error: null };

export const communitiesSlice = createSlice({
	name: 'communities',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCommunities.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCommunities.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.communities = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.communities);
			})
			.addCase(getCommunities.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const communitiesReducer = communitiesSlice.reducer;
