import { TFunction } from 'i18next';
import { ZdsInputText } from '@zig-design-system/ui-components-react';

import { Community } from '../../../../../api/models';
import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import ChipArrayInput from '../../../../../../../global/components/Form/ChipArrayInput/ChipArrayInput';

export const generateUpdateCommunityMembersFormSchema = (communityData: Community, t: TFunction): FormSchema => {
	const toewijzing = communityData.toewijzing?.code
		? getReferentiedataSelectOptions({
				t,
				soort: 'COMMUNITYTOEWIJZINGSOORT',
				code: communityData.toewijzing?.code,
				disabled: false,
		  }).filter(r => r.value === communityData.toewijzing?.code)[0].label
		: '';

	const schema: FormSchema = {
		formControls: [
			{
				name: 'toewijzing',
				submitPath: 'toewijzing.code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.toewijzing'),
						value: toewijzing,
						disabled: true,
					},
				},
			},
			{
				name: 'clusters',
				control: {
					type: ChipArrayInput,
					props: {
						label: `${getStringTranslation(t, 'vastgoed:vastgoed.cluster')}(s)`,
						value: communityData.clusters
							.map(cluster => (cluster ? cluster.naam : null))
							.filter(c => !!c)
							.map(v => ({ key: v, label: v })) as any,
						deletable: false,
						disabled: true,
					},
				},
				layoutOptions: { fullWidth: true },
			},
		],
		noSubmit: ['clusters', 'toewijzing'],
		layout: { columns: [['toewijzing', 'clusters']] },
	};

	return schema;
};
