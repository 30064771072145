export const formatToISODateString = (input?: string | null, format = ''): string | null => {
	const parts = input?.match(/(\d+)/g);

	if (!parts) return null;

	let i = 0;
	const fmt: { [key: string]: number } = {};

	format.replace(/(yyyy|dd|MM)/g, part => {
		fmt[part] = i++;
		return '';
	});

	return `${parts[fmt['yyyy']]}-${parts[fmt['MM']]}-${parts[fmt['dd']]}`;
};
