import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { getReferentiedataSelectOptions } from '../../../../../global/utils/form/getReferentiedataSelectOptions';
import { FormSchema } from '../../../../../global/components/Form/Form.types';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { getFormattedDate } from '../../../../../global/utils/date/formatDate/formatDate';
import { getStringTranslation } from '../../../../../global/utils/getStringTranslation';
import { getDateFormControlData } from '../../../../../global/utils/form/getDateFormControlData';
import { Cluster } from '../../../../communitybeheer/api/models';
import TextArea from '../../../../../global/components/Form/TextArea/TextArea';
import { getClusterOwnerNames } from '../../table/useMapClustersToTableData/useMapClustersToTableData';

export const useClusterFormSchema = (cluster: Cluster | null, disabled: boolean): FormSchema | null => {
	const { t, i18n } = useTranslation();
	const [schema, setSchema] = useState<FormSchema | null>(null);
	const dateFormat = getDateFormat(i18n.language);

	useEffect(() => {
		if (!cluster) return setSchema(null);

		const newSchema: FormSchema = {
			formControls: [
				{
					name: 'naam',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.naam'),
							value: cluster.naam,
							required: true,
							...(disabled && { disabled: true }),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'idExtern',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.externeCode'),
							value: cluster.idExtern,
							disabled: true,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'idBron',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.bron'),
							value: cluster.idBron,
							disabled: true,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'code',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.code'),
							value: cluster.code,
							...(disabled && { disabled: true }),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'soort',
					submitPath: 'soort.code',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(t, 'global:domainShared.soort'),
							value: cluster.soort?.code,
							...(disabled && { disabled: true }),
							required: true,
						},
						options: getReferentiedataSelectOptions({
							t,
							soort: 'CLUSTERSOORT',
							code: cluster.soort?.code,
						}),
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'omschrijving',
					control: {
						type: TextArea,
						props: {
							label: getStringTranslation(t, 'global:domainShared.omschrijving'),
							placeholder: getStringTranslation(t, 'vastgoed:clusterInformationPage.form.descriptionPlaceholder'),
							value: cluster.omschrijving,
							...(disabled && { disabled: true }),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'owner',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.owner'),
							value: getClusterOwnerNames(cluster),
							disabled: true,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				getDateFormControlData({
					t,
					dateFormat,
					name: 'begindatum',
					value: getFormattedDate(cluster.begindatum, dateFormat),
					disabled,
				}),
				getDateFormControlData({
					t,
					dateFormat,
					name: 'einddatum',
					value: getFormattedDate(cluster.einddatum, dateFormat),
					disabled,
				}),
			],
			noSubmit: ['owner'],
		};

		setSchema(newSchema);
	}, [cluster, disabled]);

	return schema;
};
