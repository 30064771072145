import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import {
	createContactgegeven,
	deleteContactgegeven,
	selectContactgegevensData,
	selectCurrentRelatieData,
	updateContactgegeven,
} from '../../../../redux/currentRelatieData';
import ContactsTable from '../../../../components/ContactsTable/ContactsTable';
import { FormSchema, FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { generateCreateContactgegevenFormSchema } from '../../../../utils/form/schema/create/contactgegeven';
import Form from '../../../../../../global/components/Form/Form';
import { Contactgegeven } from '../../../../api/models';
import SaveButton from '../../../../../../global/components/Form/SaveButton';
import ConfirmationModal from '../../../../../../global/components/ConfirmationModal/ConfirmationModal';
import styles from './Contacts.module.scss';

const Contacts: FC = () => {
	const { currentRelatieData, error } = useAppSelector(selectCurrentRelatieData);
	const {
		contactgegevens,
		loading: loadingContactgegevens,
		error: errorContactgegevens,
		createDone,
		updateDone,
		deleteDone,
	} = useAppSelector(selectContactgegevensData);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [formSchema, setFormSchema] = useState<FormSchema | null>(null);
	const [currentContactgegeven, setCurrentContactgegeven] = useState<Contactgegeven | null>(null);
	const [contactgegevenIdToDelete, setContactgegevenIdToDelete] = useState<string | null>(null);
	const modalRef = useRef<HTMLZdsModalElement | null>(null);

	useEffect(() => {
		setFormSchema(generateCreateContactgegevenFormSchema(currentContactgegeven, t, submitDisabled));
	}, [currentContactgegeven, submitDisabled]);

	useEffect(() => {
		if (currentContactgegeven) {
			setSubmitDisabled(false);
		}
	}, [currentContactgegeven]);

	useEffect(() => {
		if (
			createDone ||
			updateDone ||
			(deleteDone &&
				currentContactgegeven &&
				contactgegevenIdToDelete &&
				currentContactgegeven.id === contactgegevenIdToDelete)
		) {
			setCurrentContactgegeven(null);
			setSubmitDisabled(true);
		}
		if (deleteDone) {
			setContactgegevenIdToDelete(null);
		}
	}, [createDone, updateDone, deleteDone]);

	const handleSubmit = (data: FormSubmitData) => {
		if (currentRelatieData?.id && !loadingContactgegevens) {
			if (!currentContactgegeven) {
				const createData = {
					relatieId: currentRelatieData.id,
					body: data,
				};
				void dispatch(createContactgegeven(createData));
			} else {
				const updateData = {
					id: currentContactgegeven.id,
					body: data,
				};
				void dispatch(updateContactgegeven(updateData));
			}
		}
	};

	const handleAddNewClick = () => {
		setCurrentContactgegeven(null);
		setSubmitDisabled(false);
	};

	const handleRowClick = (contact: Contactgegeven) => {
		setCurrentContactgegeven(contact);
	};

	const handleContactgegevenDeleteIconClick = (id: string) => {
		setContactgegevenIdToDelete(id);
		modalRef?.current?.show();
	};

	const handleModalSave = () => {
		if (contactgegevenIdToDelete) {
			dispatch(
				deleteContactgegeven({
					id: contactgegevenIdToDelete,
				})
			);
		}
	};

	const finalError = error ?? errorContactgegevens;

	const formClasses = classNames({
		'zds-margin-top-6': true,
		[styles.muted]: submitDisabled,
	});

	return (
		<div className="zds-margin-left-8">
			<ContactsTable
				contacts={contactgegevens}
				onAddNew={handleAddNewClick}
				onRowClick={handleRowClick}
				onDeleteIconClick={handleContactgegevenDeleteIconClick}
				selectedIds={currentContactgegeven?.id ? [currentContactgegeven.id] : undefined}
			/>
			{formSchema && (
				<div data-testid="contactForm" className={formClasses}>
					<Form schema={formSchema} onSubmit={handleSubmit} formId="contactForm" />
					<div className="zds-flex zds-justify-content-end">
						<SaveButton dataTestId="contactFormSubmitButton" formId="contactForm" disabled={submitDisabled} />
					</div>
				</div>
			)}
			{finalError && <ErrorMessage message={t(finalError)} />}
			<ConfirmationModal
				ref={modalRef}
				title={t('relatie:relationInformationPage.deleteContactDetailQuestion')}
				onSave={handleModalSave}
				isDelete
			/>
		</div>
	);
};

export default Contacts;
