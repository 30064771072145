import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { CommunityCategorie } from '../../../api/models';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';
import StatusBadge from '../../../../relatiebeheer/components/StatusBadge/StatusBadge';

export const useMapCommunityCategoriesToTableData = (
	categories: {
		[key: number]: {
			category: CommunityCategorie;
			followers: number;
		}[];
	} | null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [t('global:domainShared.naam'), t('community:community.followers'), t('community:community.messages')],
		width: [null, 112, 112],
	};

	const getPage = useCallback(
		(
			pageCategories: {
				category: CommunityCategorie;
				followers: number;
			}[]
		): TableRow[] => {
			return pageCategories.map(categoryData => {
				return {
					id: categoryData.category.id,
					cells: [
						{
							mainText: categoryData.category.naam,
							endElement: <StatusBadge status={categoryData.category.status?.code ?? ''} />,
						},
						{ mainText: categoryData.followers.toString() },
						{ mainText: categoryData.category.aantalBerichten?.toString() ?? '' },
					],
				};
			});
		},
		[]
	);

	const pages = useTablePages(categories, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
