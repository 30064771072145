import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ZdsButton, ZdsDivider, ZdsIcon } from '@zig-design-system/ui-components-react';

import { FormData, FormSchema } from '../../../../global/components/Form/Form.types';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import Spinner from '../../../../global/components/Spinner/Spinner';
import styles from './StepContent.module.scss';

type ButtonOptions = { label: string; onClick: () => void };

export interface StepContentProps {
	children: ReactNode;
	formSchema: FormSchema | null;
	formData: FormData | null;
	loading?: boolean;
	errorMessage?: string;
	backButton?: ButtonOptions;
	nextButton?: ButtonOptions;
}

const StepContent: FC<StepContentProps> = ({
	formSchema,
	formData,
	loading,
	errorMessage,
	backButton,
	nextButton,
	children,
}) => {
	const { t } = useTranslation();

	if (!formSchema || !formData) return null;

	return (
		<div className="zds-flex zds-flex-col zds-justify-content-between zds-flex-1 zds-gap-6">
			<div className={styles.formContainer}>
				{children}
				{loading && (
					<div className={styles.spinnerContainer}>
						<Spinner />
					</div>
				)}
				{errorMessage && <ErrorMessage message={t(errorMessage)} />}
			</div>
			<div className="zds-flex zds-flex-col zds-gap-6">
				<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
				<div className="zds-flex zds-justify-content-between">
					{backButton && (
						<ZdsButton preset="neutral" onZdsClick={backButton.onClick}>
							<ZdsIcon name="arrow-left" library="fa-solid" />
							{t(backButton.label)}
						</ZdsButton>
					)}
					{nextButton && (
						<ZdsButton onZdsClick={nextButton.onClick} disabled={loading}>
							{t(nextButton.label)}
							<ZdsIcon name="arrow-right" library="fa-solid" />
						</ZdsButton>
					)}
				</div>
			</div>
		</div>
	);
};

export default StepContent;
