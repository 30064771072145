import { TFunction } from 'i18next';

import { FormSchema } from '../../../../../../global/components/Form/Form.types';
import { generateCreateNatuurlijkPersoonFormSchema } from './natuurlijkPersoon';
import { generateCreateRechtsPersoonFormSchema } from './rechtsPersoon';
import { generateCreateRelatiegroepFormSchema } from './relatieGroep';

export const generateCreateRelatieFormSchema = (soort: string, t: TFunction, language: string): FormSchema | null => {
	switch (soort) {
		case 'NAT':
			return generateCreateNatuurlijkPersoonFormSchema(t, language);
		case 'REC':
			return generateCreateRechtsPersoonFormSchema(t);
		case 'GRO':
			return generateCreateRelatiegroepFormSchema(t);
		default:
			return null;
	}
};
