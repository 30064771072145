import { combineReducers } from '@reduxjs/toolkit';

import { eenhedenDataReducer } from './eenhedenData';
import { clustersDataReducer } from './clustersData';
import { currentClusterDataReducer } from './currentClusterData';
import { overeenkomstenDataReducer } from './overeenkomstenData/slice';

export const vastgoedReducer = combineReducers({
	eenhedenData: eenhedenDataReducer,
	clustersData: clustersDataReducer,
	currentClusterData: currentClusterDataReducer,
	overeenkomstenData: overeenkomstenDataReducer,
});
