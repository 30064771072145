import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { sideMenuInfo } from '../../routes';
import BackButton from '../../../../global/components/BackButton/BackButton';
import ClusterForm, { clusterFormId } from '../../components/ClusterForm/ClusterForm';
import { Cluster, RolRelatie } from '../../../communitybeheer/api/models';
import SaveButton from '../../../../global/components/Form/SaveButton';
import { useAppSelector } from '../../../../global/redux/store';
import { selectUserRelatie } from '../../../../global/redux/shared/authorizationData';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { useNavigate } from 'react-router';
import VastgoedAPI from '../../api';

const CreateCluster: FC = () => {
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const currentUserRelatie = useAppSelector(selectUserRelatie);
	const navigate = useNavigate();

	const breadcrumbs = [
		{ label: t('global:apps.porfolioManagement'), link: '/vastgoedbeheer' },
		{ label: t('vastgoed:clustersPage.header.title'), link: '/vastgoedbeheer/clusters' },
		{ label: t('vastgoed:clusterInformationPage.header.title') },
	];

	const saveButton = <SaveButton dataTestId="updateClusterDataSubmitButton" formId={clusterFormId} />;
	const rolRelaties = [
		{ relatie: currentUserRelatie.medewerker?.organisatie.relatie, soort: { code: 'EIG' } },
	] as unknown as RolRelatie[];
	const defaultClusterData = { rolRelaties, soort: { code: 'BUU' } } as unknown as Cluster;

	const handleCreateCluster = async (data: FormSubmitData) => {
		if (loading) return;
		try {
			setLoading(true);
			const payload = { ...data, rolRelaties } as Cluster;
			const cluster = await VastgoedAPI.createCluster(payload);
			navigate(`/vastgoedbeheer/clusters/${cluster.id}/edit`);
		} catch {
			setError(t('global:form.errorMessage.create') ?? '');
		} finally {
			setLoading(false);
		}
	};

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={name}
				iconName="object-group"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={saveButton}
				status="CON"
			/>
			<Pane className="zds-flex-1">
				<ClusterForm
					onNameChange={setName}
					onSubmit={handleCreateCluster}
					clusterData={defaultClusterData}
					error={error}
				/>
			</Pane>
		</PageLayout>
	);
};

export default CreateCluster;
