export const getFormattedDate = (dateString?: string | Date | null, format = 'yyyy-MM-dd'): string => {
	if (!dateString) return '';

	const date = new Date(dateString);

	const chars: { [key: string]: string } = {
		dd: date.getDate().toString().padStart(2, '0'),
		MM: (date.getMonth() + 1).toString().padStart(2, '0'),
		yyyy: date.getFullYear().toString(),
	};

	return format.replace(/(yyyy|dd|MM)/g, m => chars[m]);
};
