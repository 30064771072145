export const convertFileToBase64 = (file: File | null): Promise<string | null> => {
	return new Promise((resolve, reject) => {
		if (!file) return resolve(null);

		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result as string);
		};
		fileReader.onerror = error => {
			reject(error);
		};
	});
};
