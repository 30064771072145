import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormData, FormSchema, FormSubmitData } from '../../../../../../../global/components/Form/Form.types';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { Language, Vertaling } from '../../../../../api/models';
import { getDateFormat } from '../../../../../../../global/utils/date/getDatePattern';
import { getFormattedDate } from '../../../../../../../global/utils/date/formatDate/formatDate';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import TextArea from '../../../../../../../global/components/Form/TextArea/TextArea';
import { getDateFormControlData } from '../../../../../../../global/utils/form/getDateFormControlData';
import { useInputLanguageDataValue } from '../../../../useInputLanguageDataValue/useInputLanguageDataValue';

interface CommunityCategoryInitInfoFormSchemaOptions {
	language: string;
	data: FormData | null;
	initData?: FormSubmitData | null;
	selectedFields?: string[];
	dateOptions?: { withLabel?: boolean };
}

export const useCommunityCategoryCreateFormSchema = ({
	data,
	initData,
	language,
	selectedFields,
	dateOptions = { withLabel: true },
}: CommunityCategoryInitInfoFormSchemaOptions): FormSchema | null => {
	const { t, i18n } = useTranslation();
	const [schema, setSchema] = useState<FormSchema | null>(null);
	const dateFormat = getDateFormat(i18n.language);
	const [initialized, setInitialized] = useState(!initData);

	const begindatumValue = initialized
		? data?.begindatum.value
		: getFormattedDate(initData?.begindatum as string, dateFormat);

	const einddatumValue = initialized
		? data?.einddatum.value
		: getFormattedDate(initData?.einddatum as string, dateFormat);

	const soortValue = initialized ? (data?.soort.value as any)?.code ?? 'ALG' : (initData?.soort as any)?.code;

	const getInputLanguageData = useInputLanguageDataValue({
		formData: data,
		vertalingen: initData?.vertalingen as unknown as Vertaling[],
		initialized,
	});

	useEffect(() => {
		const newSchema: FormSchema = {
			formControls: [
				{
					name: 'naam',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'community:createCategoryPage.form.categoryName'),
							placeholder: getStringTranslation(t, 'community:createCategoryPage.form.categoryNamePlaceholder'),
							required: language === Language.Dutch,
							languageData: getInputLanguageData('naam'),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'omschrijving',
					control: {
						type: TextArea,
						props: {
							label: getStringTranslation(t, 'global:domainShared.omschrijving'),
							placeholder: getStringTranslation(t, 'community:createCategoryPage.form.internalDescriptionPlaceholder'),
							counterShowThreshold: 200,
							counterWarnThreshold: 256,
							maxlength: 256,
							explanationText: getStringTranslation(
								t,
								'community:createCategoryPage.form.internalDescriptionInputExplanation'
							),
							languageData: getInputLanguageData('omschrijving'),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'soort',
					submitPath: 'soort.code',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(t, 'global:domainShared.soort'),
							value: soortValue,
						},
						options: getReferentiedataSelectOptions({
							t,
							soort: 'COMMUNITYCATEGORIESOORT',
						}),
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'toelichting',
					control: {
						type: TextArea,
						props: {
							label: getStringTranslation(t, 'global:domainShared.toelichting'),
							placeholder: getStringTranslation(t, 'community:createCategoryPage.form.publicDescriptionPlaceholder'),
							counterShowThreshold: 200,
							counterWarnThreshold: 256,
							maxlength: 256,
							explanationText: getStringTranslation(
								t,
								'community:createCategoryPage.form.publicDescriptionInputExplanation'
							),
							languageData: getInputLanguageData('toelichting'),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				getDateFormControlData({
					t,
					dateFormat,
					name: 'begindatum',
					value: begindatumValue,
					required: true,
					withLabel: dateOptions.withLabel,
				}),
				getDateFormControlData({
					t,
					dateFormat,
					name: 'einddatum',
					value: einddatumValue,
					withLabel: dateOptions.withLabel,
				}),
			],
		};

		const allFieldNames = newSchema.formControls.map(fc => fc.name);

		if (selectedFields) {
			newSchema.noSubmit = allFieldNames.filter(name => !selectedFields.includes(name));
		}

		setSchema(newSchema);
		setInitialized(true);
	}, [language]);

	return schema;
};
