import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
	const { t } = useTranslation('global');

	return (
		<div className="zds-flex zds-flex-1 zds-justify-content-center zds-margin-top-16">
			<div className="zds-flex zds-justify-content-center zds-col-6 zds-headline-xxl zds-color-primary">
				{t('notFound.title')}
			</div>
		</div>
	);
};

export default NotFound;
