import { combineReducers } from '@reduxjs/toolkit';
import { organisatieDataReducer } from './organisatieData';
import { currentOrganisatieDataReducer } from './currentOrganisatieData';
import { medewerkersReducer } from './medewerkers';
import { medewerkerrollenReducer } from './medewerkerrollen';
import { currentMedewerkerrolDataReducer } from './currentMedewerkerrolData';
import { currentMedewerkerDataReducer } from './currentMedewerkerData';

export const organisatieReducer = combineReducers({
	organisatieData: organisatieDataReducer,
	currentOrganisatieData: currentOrganisatieDataReducer,
	medewerkers: medewerkersReducer,
	currentMedewerkerData: currentMedewerkerDataReducer,
	medewerkerrollen: medewerkerrollenReducer,
	currentMedewerkerrolData: currentMedewerkerrolDataReducer,
});
