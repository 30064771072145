import { useTranslation } from 'react-i18next';
import Appbar from '../../global/components/Appbar/Appbar';

import { WrapperComponent } from '../../routes';
import { useAppPermissions } from '../../global/hooks/useAppPermissions/useAppPermissions';
import NotFound from '../../global/components/NotFound/NotFound';
import styles from './RelationManagementAppWrapper.module.scss';

const RelationManagementAppWrapper: WrapperComponent = ({ children }) => {
	const { t } = useTranslation('global');
	const hasAppPermissions = useAppPermissions();

	const content = !hasAppPermissions('REL') ? <NotFound /> : children;

	return (
		<div className={styles.root}>
			<Appbar tempRelativeAppSwitcherPath="/relatiebeheer" appName={t('apps.relationManagement')} />
			<div className={styles.content}>{content}</div>
		</div>
	);
};

export default RelationManagementAppWrapper;
