import { TFunction } from 'i18next';
import { ZdsInputDate, ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { Organisatie } from '../../../../../../api/models';
import { FormSchema } from '../../../../../../../../global/components/Form/Form.types';
import { getStringTranslation } from '../../../../../../../../global/utils/getStringTranslation';
import { getReferentiedataSelectOptions } from '../../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getFormattedDate } from '../../../../../../../../global/utils/date/formatDate/formatDate';
import { getDateFormat } from '../../../../../../../../global/utils/date/getDatePattern';
import { isRechtspersoon } from '../../../../../../../relatiebeheer/api/models';
import { COLUMN_SECTION_HEADER_PREFIX } from '../../../../../../../../global/utils/form/createFormColumns';

export const generateUpdateOrganisatieFormSchema = (
	organisatieData: Organisatie,
	t: TFunction,
	language: string
): FormSchema => {
	const dateFormat = getDateFormat(language);
	const relatieData = isRechtspersoon(organisatieData.relatie) ? organisatieData.relatie : null;

	const schema: FormSchema = {
		formControls: [
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						value: organisatieData.code,
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: organisatieData.soort?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'ORGANISATIESOORT',
						code: organisatieData.soort?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'omschrijving',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.omschrijving'),
						value: organisatieData.omschrijving,
					},
				},
			},
			{
				name: 'begindatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'global:domainShared.begindatum'),
						value: getFormattedDate(organisatieData.begindatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'einddatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'global:domainShared.einddatum'),
						value: getFormattedDate(organisatieData.einddatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						value: relatieData?.naam,
						disabled: true,
					},
				},
			},
		],
		layout: {
			columns: [
				[
					'code',
					'omschrijving',
					'begindatum',
					'einddatum',
					`${COLUMN_SECTION_HEADER_PREFIX}${t('relatie:relatie.relatie')}:`,
					'naam',
				],
				['soort'],
			],
		},
		noSubmit: ['naam'],
	};

	return schema;
};
