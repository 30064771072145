import { SortableOption } from '../../components/DataTable/DataTable';

export const getSortingData = ({
	sortData = {},
}: {
	sortData?: {
		[p: string]: SortableOption;
	};
}) => {
	return Object.entries(sortData)
		.map(([param, value]) => {
			return `${param} ${value}`;
		})
		.filter(Boolean);
};
