import { FC } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import styles from './BackButton.module.scss';

export interface BackButtonProps {
	path?: any;
}

const BackButton: FC<BackButtonProps> = ({ path }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(path || -1);
	};

	return (
		<ZdsButton preset="neutral" variant="text" className={styles.backButton} onZdsClick={handleOnClick}>
			<ZdsIcon name="arrow-left" library="fa-solid" size="xs" />
			{t('global:common.back')}
		</ZdsButton>
	);
};

export default BackButton;
