import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';
import ArrayForm, { TableSchema } from '../../../components/ArrayForm/ArrayForm';

export interface IamLoginMethod {
	type: string;
	options?: object;
}

interface CreateLoginMethodsProps {
	loginMethods: IamLoginMethod[];
	setLoginMethods: (loginMethods: IamLoginMethod[]) => void;
}

const CreateLoginMethods: FC<CreateLoginMethodsProps> = ({ loginMethods, setLoginMethods }) => {
	const { t } = useTranslation(['global', 'organisatie']);

	const [loginMethodType, setLoginMethodType] = useState<string | null>(null);

	const supportedLoginMethods = [
		{ label: 'Microsoft Office (standaard Zig app)', value: 'office365-zig' },
		{ label: 'Microsoft Office (aangepaste app)', value: 'office365' },
		{ label: 'OIDC voor Office', value: 'oidc-office365' },
	];

	const loginMethodsTableSchema: TableSchema = {
		columns: [
			{
				header: 'Type',
				mapper: (item: IamLoginMethod) => ({
					mainText: supportedLoginMethods.find(method => method.value === item.type)?.label ?? item.type,
				}),
			},
		],
	};

	const loginMethodsFormElements = (
		<div className="zds-flex zds-flex-col zds-flex-1 zds-gap-y-4">
			<ZdsSelect
				label={t('Type') ?? ''}
				data-testid="type-select"
				name="type"
				onZdsInputDidUpdate={e => setLoginMethodType(e.target.value)}
			>
				{supportedLoginMethods.map(({ value, label }) => (
					<option key={value} value={value} label={label} />
				))}
			</ZdsSelect>
			{loginMethodType !== 'office365-zig' && <ZdsInputText name="appId" label={t('App ID') ?? ''} />}
			{loginMethodType === 'oidc-office365' && (
				<>
					<ZdsInputText name="clientId" label={t('Client ID') ?? ''} />
					<ZdsInputText name="clientSecret" label={t('Client secret') ?? ''} />
					<ZdsInputText name="name" label={t('Te tonen naam') ?? ''} />
				</>
			)}
		</div>
	);

	return (
		<>
			<div className="zds-headline-md">{t('Inlogmethodes')}</div>
			<ArrayForm
				formElements={loginMethodsFormElements}
				tableSchema={loginMethodsTableSchema}
				items={loginMethods}
				onChange={setLoginMethods}
				formId="createLoginMethod"
				formTestId="createLoginMethodTest"
			/>
		</>
	);
};

export default CreateLoginMethods;
