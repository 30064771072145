export interface RolRelatieSubmitData {
	soort: {
		code: string;
	};
	relatie: {
		id: string;
	};
}

export const getRolRelatieSubmitData = (type: string, id: string): RolRelatieSubmitData => ({
	soort: {
		code: type,
	},
	relatie: {
		id,
	},
});
