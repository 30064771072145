import { useEffect, useState } from 'react';

import { MAX_FILE_SIZE } from '../../components/UploadFile/UploadFile';

export interface UploadFileData {
	id: string;
	file: File | null;
	error: string | null;
	base64: string | null;
}

export type UplodFileMode = 'ADD' | 'SWAP_LAST';

export interface UploadFileOptions {
	maxFiles: number;
	maxFilesSize?: number; // in KB
	mode?: UplodFileMode;
	initFileData?: UploadFileData[];
}

export const useUploadFileData = ({
	maxFiles,
	initFileData,
	maxFilesSize = MAX_FILE_SIZE,
	mode = 'ADD',
}: UploadFileOptions) => {
	const [fileData, setFileData] = useState<UploadFileData[]>(initFileData ?? []);
	const [validationError, setValidationError] = useState(false);

	useEffect(() => {
		setValidationError(validateFiles());
	}, [fileData]);

	const handleRemoveFile = (id: string) => {
		setFileData(oldFileData => oldFileData.filter(fd => id !== fd.id));
	};

	const handleAddFile = (newFile: UploadFileData, replaceFileOnError?: boolean) => {
		if (mode === 'ADD' && fileData.length >= maxFiles) return;

		if (replaceFileOnError === false && newFile.error) {
			setFileData(oldFileData => [
				...oldFileData.slice(0, oldFileData.length - (mode === 'SWAP_LAST' ? 1 : 0)),
				{
					...oldFileData[oldFileData.length - (mode === 'SWAP_LAST' ? 1 : 0)],
					error: newFile.error,
				},
			]);
			return;
		}

		setFileData(oldFileData => [...oldFileData.slice(0, oldFileData.length - (mode === 'SWAP_LAST' ? 1 : 0)), newFile]);
	};

	const removeErrorFromLastFile = () => {
		setFileData(oldFileData => [
			...oldFileData.slice(0, oldFileData.length - (mode === 'SWAP_LAST' ? 1 : 0)),
			{
				...oldFileData[oldFileData.length - (mode === 'SWAP_LAST' ? 1 : 0)],
				error: null,
			},
		]);
	};

	const validateFiles = () => {
		const totalSize = fileData.reduce((sum, file) => sum + (file.file?.size ?? 0), 0);
		return totalSize > maxFilesSize * 1024;
	};

	return { fileData, handleAddFile, handleRemoveFile, removeErrorFromLastFile, validationError };
};
