import { SideMenuInfo } from '../../../../global/components/SideMenu/SideMenu';

const layoutPocSideMenuInfo: SideMenuInfo = {
	linkBase: '/demo-zds/layout-poc',
	sections: [
		{
			items: [
				{
					title: 'Main',
					link: '',
					iconName: 'house',
				},
				{
					title: 'Variant 1',
					link: 'variant-1',
					iconName: '1',
				},
				{
					title: 'Variant 2',
					link: 'variant-2',
					iconName: '2',
				},
				{
					title: 'Variant 3',
					link: 'variant-3',
					iconName: '3',
				},
			],
		},
	],
};

export default layoutPocSideMenuInfo;
