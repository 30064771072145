import { createSlice } from '@reduxjs/toolkit';

import { getCurrentMedewerkerrolData, updateMedewerkerrol, CurrentMedewerkerrolDataState } from '.';

const initialState: CurrentMedewerkerrolDataState = {
	currentMedewerkerrolData: null,
	loading: false,
	updating: false,
	error: null,
};

export const currentMeddewerkerrolDataSlice = createSlice({
	name: 'currentMedewerkerrolData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentMedewerkerrolData.pending, state => {
				state.loading = true;
				state.currentMedewerkerrolData = null;
				state.error = null;
			})
			.addCase(getCurrentMedewerkerrolData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentMedewerkerrolData = payload;
			})
			.addCase(getCurrentMedewerkerrolData.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(updateMedewerkerrol.pending, state => {
				state.updating = true;
			})
			.addCase(updateMedewerkerrol.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentMedewerkerrolData = payload;
			})
			.addCase(updateMedewerkerrol.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			});
	},
});

export const currentMedewerkerrolDataReducer = currentMeddewerkerrolDataSlice.reducer;
