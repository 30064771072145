import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Relatie } from '../../api/models';
import { relatiesoortCodes } from '../../../../global/utils/zigDataSdk/zigDataSdk';
import { useMapRelatiesToTableData } from '../../hooks/table/useMapRelatiesToTableData/useMapRelatiesToTableData';
import DataTable from '../../../../global/components/DataTable/DataTable';

export interface RelationsTableProps {
	relatieData?: { [key: number]: Relatie[] } | Relatie[];
	onPageChange?: (page: number) => void;
	itemsNum?: number;
	pageSize?: number;
}

const RelationsTable: FC<RelationsTableProps> = ({ relatieData, itemsNum, pageSize, onPageChange }) => {
	const navigate = useNavigate();

	const handleRowClick = (id: string) => {
		if (!relatieData) return;

		const relatie = Object.values(relatieData)
			.flat()
			.find(rel => rel.id === id);

		if (relatie?.soort?.code && relatiesoortCodes.includes(relatie.soort.code)) {
			navigate(`/relatiebeheer/relatie/${id}`);
		}
	};

	const tableData = useMapRelatiesToTableData(relatieData ?? null);

	return (
		<DataTable
			data={tableData}
			pageSizes={[pageSize ?? 0]}
			onRowClick={handleRowClick}
			tableTestId="relationsTable"
			totalItemsNum={itemsNum}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange?.(page);
			}}
		/>
	);
};

export default RelationsTable;
