import { FC, ReactNode, useEffect } from 'react';
import { setStateAndRedirectToLogin } from '../../utils/auth/setStateAndRedirectToLogin';

interface AuthGuardProps {
	children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
	const accessToken = localStorage.getItem('accessToken');

	useEffect(() => {
		if (!accessToken) {
			setStateAndRedirectToLogin();
		}
	}, []);

	if (!accessToken) return null;

	return <> {children} </>;
};

export default AuthGuard;
