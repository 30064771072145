import { formatToISODateString } from './formatToISODateString';

const getTimeStringPadded = (t: number) => String(t).padStart(2, '0');

export const getLocalDateTimeString = (dateString: string): string => {
	const date = new Date(dateString);

	return `${formatToISODateString(dateString, 'yyyy-MM-dd')}T${getTimeStringPadded(
		date.getHours()
	)}:${getTimeStringPadded(date.getMinutes())}`;
};
