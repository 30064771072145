import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { WrapperComponent } from '../../routes';

const Constants = {
	callbackUrl: `${window.location.origin}/demo/oauth_callback`,
	apiUrl: 'https://nextwave-api.zigtools.nl',
	hexiaLoginUrl: 'https://login.hexia.io',
};

interface User {
	token: string;
	username: string;
	email: string;
}

interface AuthContextType {
	accessToken?: string;
	user: User | null;
	login: (token: string, username: string, email: string) => void;
	logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
	accessToken: '',
	user: null,
	login: () => {
		console.log('test');
	},
	logout: () => {
		console.log('test');
	},
});

export const Organisatie = () => {
	const { accessToken, logout } = useContext(AuthContext);
	const [organisatie, setOrganisatie] = useState(null);

	const postOrganisatie = () => {
		axios
			.post(
				`${Constants.apiUrl}/organisaties`,
				{ name: 'Test 123' },
				{
					headers: {
						'Content-Type': 'application/json',
						authorization: 'Bearer ' + accessToken,
					},
				}
			)
			.then(response => {
				console.log(response.data);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		axios
			.get(`${Constants.apiUrl}/organisaties/1`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + accessToken,
				},
			})
			.then(response => {
				console.log(response.data);
				setOrganisatie(response.data);
			})
			.catch(() => {
				window.localStorage.removeItem('accesstoken');
				window.location.href = window.location.pathname;
			});
	}, []);

	return (
		<React.Fragment>
			<div>{JSON.stringify(organisatie)}</div>

			<div>
				<form>
					<input name={'name'} type={'text'} />
					<input value={'Opslaan'} onClick={postOrganisatie} type={'button'} />
				</form>
			</div>

			<div>
				<button onClick={logout}>Uitloggen</button>
			</div>
		</React.Fragment>
	);
};

export const Profile = () => {
	const { accessToken, logout } = useContext(AuthContext);
	const [relatie, setRelatie] = useState(null);

	useEffect(() => {
		axios
			.get(`${Constants.apiUrl}/relaties/8`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + accessToken,
				},
			})
			.then(response => {
				console.log(response.data);
				setRelatie(response.data);
			})
			.catch(() => {
				window.localStorage.removeItem('accesstoken');
				window.location.href = window.location.pathname;
			});
	}, []);

	return (
		<React.Fragment>
			<div>
				<div>{JSON.stringify(relatie)}</div>
				<div>
					<button onClick={logout}>Uitloggen</button>
				</div>
			</div>
		</React.Fragment>
	);
};

export const Logout = () => {
	return (
		<div>
			<h1>Uitgelogd</h1>
		</div>
	);
};

export const Protected = ({ children }: any) => {
	const { accessToken } = useContext(AuthContext);

	if (!accessToken) {
		const state = '1250';
		//login_prompt=show
		window.location.href = `${Constants.hexiaLoginUrl}?redirect_uri=${encodeURIComponent(
			Constants.callbackUrl
		)}&state=${state}`;
		return null;
	}

	return <div>{children}</div>;
};

export const OAuthCallback = () => {
	const params = new URLSearchParams(window.location.search);
	axios
		.post(`${Constants.apiUrl}/auth/login`, {
			code: params.get('code'),
			state: params.get('state'),
			callbackUrl: Constants.callbackUrl,
			stateVerifier: '1250',
		})
		.then(response => {
			console.log(response);
			window.localStorage.setItem('accesstoken', response.data.tokenSet.accessToken); // + '|' + response.data.realm + '|' + response.data.client
			window.location.href = '/demo';
		})
		.catch(() => {
			console.log('Login failure');
		});

	return null;
};

const DemoAppWrapper: WrapperComponent = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);
	const accessToken = window.localStorage.getItem('accesstoken') || '';

	const login = (token: string, username: string, email: string) => {
		setUser({ token, username, email });
		window.localStorage.setItem('user', JSON.stringify({ username, email }));
	};

	const logout = () => {
		window.localStorage.removeItem('accesstoken');
		window.location.href = '/demo/logout';
	};

	return <AuthContext.Provider value={{ user, accessToken, login, logout }}>{children}</AuthContext.Provider>;
};

export default DemoAppWrapper;
