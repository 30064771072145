import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import { SideMenuInfo } from '../../../../global/components/SideMenu/SideMenu';
import ContentPane from '../../components/ContentPane/ContentPane';
import DemoZdsPage from '../../components/DemoZdsPage/DemoZdsPage';
import styles from './DemoTenant360.module.scss';

const sideMenuInfo: SideMenuInfo = {
	linkBase: '/demo-zds/tenant-360',
	sections: [
		{
			items: [
				{
					title: 'Home',
					link: '',
					iconName: 'house',
				},
				{
					title: 'Current product',
					link: 'current-product',
					iconName: 'image',
				},
			],
		},
	],
};

const DemoTenant360 = () => {
	return (
		<DemoZdsPage sideMenuInfo={sideMenuInfo}>
			<PageHeader title="Tenant 360" iconName="circle-user" iconLibrary="fa-regular" />
			<ContentPane>
				<div className="zds-headline-sm">Status</div>
				<p>EXISTING as KVS (but major update is desired)</p>

				<div className="zds-headline-sm">Actors</div>
				<p>Customer support, community manager?, social manager?</p>

				<div className="zds-headline-sm">Description</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Required services</div>
				<p>TBA</p>

				<div className="zds-headline-sm">User stories</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Future development plans</div>
				<p>TBA</p>
			</ContentPane>
		</DemoZdsPage>
	);
};

export const DemoTenant360CurrentProduct = () => {
	return (
		<DemoZdsPage sideMenuInfo={sideMenuInfo}>
			<div className={styles.exampleImageContainer}>
				<img src="images/kvs-example.png" />
			</div>
		</DemoZdsPage>
	);
};

export default DemoTenant360;
