import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsDivider, ZdsForm } from '@zig-design-system/ui-components-react';

import { FormControl, FormData, FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../../global/utils/date/getDatePattern';
import { Language } from '../../../../api/models';
import LanguageSelect, {
	LanguageData,
} from '../../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { validateDates } from '../../../../../../global/utils/form/validateDates';
import { getFormSubmitData } from '../../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../../global/utils/form/updateFormData';
import { useCommunityCategoryUpdateFormSchema } from '../../../../hooks/form/schema/create/communityCategory/useCommunityCategoryUpdateFormSchema';
import { useNaamPreviewData } from '../../../../hooks/useNaamPreviewData.ts/useNaamPreviewData';
import { useRenderFormControl } from '../../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import {
	selectCurrentCommunityCategoryData,
	updateCommunityCategory,
} from '../../../../redux/currentCommunityCategoryData';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { DEFAULT_LANGUAGE, useLanguageSelect } from '../../../../hooks/useLanguageSelect/useLanguageSelect';
import styles from './CommunityCategorySettings.module.scss';

export interface CommunityCategorySettingsProps {
	onCommunityCategoryNameChange: (name: string) => void;
}

const CommunityCategorySettings: FC<CommunityCategorySettingsProps> = ({ onCommunityCategoryNameChange }) => {
	const { t, i18n } = useTranslation();
	const { currentCommunityCategoryData, updating, error } = useAppSelector(selectCurrentCommunityCategoryData);
	const dispatch = useAppDispatch();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [validationErrorMessage, setValidationErrorMessage] = useState('');
	const dateFormat = getDateFormat(i18n.language);
	const errorMessage = error ?? validationErrorMessage;
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();

	const formSchema = useCommunityCategoryUpdateFormSchema({
		data: formData,
		language: selectedLanguage,
	});

	const { naam } = useNaamPreviewData(formData, selectedLanguage);

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema, currentCommunityCategoryData]);

	useEffect(() => {
		onCommunityCategoryNameChange(naam);
	}, [naam]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async () => {
		if (!formData) return;

		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (!validateDefaultLanguageData()) return;

		const datesInvalidError = validateDates(formSubmitData, formData, dateFormat);
		if (datesInvalidError) {
			return setValidationErrorMessage(datesInvalidError);
		}

		setValidationErrorMessage('');
		handleUpdate(formSubmitData);
	};

	const handleUpdate = (data: FormSubmitData) => {
		if (currentCommunityCategoryData?.id && !updating) {
			const updateData = {
				id: currentCommunityCategoryData.id,
				body: data,
			};
			void dispatch(updateCommunityCategory(updateData));
		}
	};

	const validateDefaultLanguageData = () => {
		if (!formData) return true;

		if (
			selectedLanguage !== DEFAULT_LANGUAGE &&
			!((formData?.naam.languageData?.[DEFAULT_LANGUAGE] as string).trim() ?? '')
		) {
			setValidationErrorMessage('community:createCategoryPage.form.dutchNameMissingErrorMessage');
			return false;
		}

		return true;
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
		setValidationErrorMessage('');
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	if (!formSchema) return null;

	return (
		<>
			<div data-testid="communityCategoryForm" className={styles.container}>
				<ZdsForm onZdsSubmit={handleSubmit} id="updateCommunityCategoryForm">
					<div className={styles.formInputsContainer}>
						{renderFormControl('naam', 'large')}
						{renderFormControl('soort', 'large')}
						{renderFormControl('toelichting', 'large')}
						{renderFormControl('omschrijving', 'large')}
						<ZdsDivider className={styles.divider} />
						<div className="zds-headline-lg zds-color-inherit">
							{t('community:createCommunityPage.form.publicationStatus')}
						</div>
						{renderFormControl('begindatum', 'large')}
						{renderFormControl('einddatum', 'large')}
					</div>
				</ZdsForm>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			{errorMessage && <ErrorMessage message={t(errorMessage)} />}
		</>
	);
};

export default CommunityCategorySettings;
