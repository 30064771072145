import { ZdsInputDate } from '@zig-design-system/ui-components-react';

import { FormData, FormSchema, FormSubmitData, InputDataType } from '../../components/Form/Form.types';
import { formatToISODateString } from '../date/formatToISODateString';

const getFieldValue = (key: string, schema: FormSchema, formData: FormData, dateFormat: string): InputDataType => {
	const fieldCustomUpdateFunction = schema.updateValueFunctions?.find(u => u.name === key)?.updateFunction;

	if (fieldCustomUpdateFunction) return fieldCustomUpdateFunction(formData);

	const isDatetimeTextInput = (schema.formControls.find(fc => fc.name === key)?.control.props as any)?.type?.includes(
		'datetime'
	);

	if (!isDatetimeTextInput && formData[key].type !== ZdsInputDate) return formData[key].value;

	const formattedDate = isDatetimeTextInput
		? (formData[key].value as string)
		: formatToISODateString(formData[key].value as string, dateFormat);

	return formattedDate ? new Date(formattedDate).toISOString() : null;
};

/**
 * Maps submitPath (defaults to form control name)
 * to an object that contains required nested properties and the input value
 * Example: (submitPath = 'soort.code') => { soort: { code : 'NAT' } }
 *
 * @param {string} name - Form control name
 * @returns {FormSubmitData | null}
 */
export const getFieldSubmitData = (
	name: string,
	schema: FormSchema,
	formData: FormData,
	dateFormat: string
): FormSubmitData | null => {
	const formControl = schema.formControls.find(fc => fc.name === name);

	if (!formControl) return null;

	const submitObject = formControl.submitObject;

	if (submitObject) {
		return submitObject(getFieldValue(name, schema, formData, dateFormat));
	}

	const submitKey = formControl.submitPath ?? name;

	return submitKey
		.split('.')
		.reverse()
		.reduce((acc: FormSubmitData | InputDataType, key) => {
			return { [key]: acc };
		}, getFieldValue(name, schema, formData, dateFormat)) as FormSubmitData;
};
