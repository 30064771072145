import { FC, UIEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	ApiNieuwsberichtInitieel,
	ApiPrikbordBerichtInitieel,
	BerichtSoortCode,
	CommunityType,
	CustomerContext,
	IBericht,
} from '@zig.community/community-components';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { deleteBericht, getBerichten, resetBerichten, selectBerichten, selectFilters } from '../../redux/berichten';
import { Bericht as BerichtComponent } from '../ZigCommunity/Bericht';
import SingleMessage from './SingleMessage/SingleMessage';
import CreateMessage from './CreateMessage/CreateMessage';
import Spinner from '../../../../global/components/Spinner/Spinner';
import EditMessage from './EditMessage/EditMessage';
import { Bericht } from '../../api/models';
import MessagesHeader from './MessagesHeader/MessagesHeader';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import CreateNewsMessageModal from '../CreateNewsMessageModal/CreateNewsMessageModal';
import ConfirmationModal from '../../../../global/components/ConfirmationModal/ConfirmationModal';
import Alert from '../../../../global/components/Alert/Alert';
import Pane from '../../../../global/components/Pane/Pane';
import styles from './Messages.module.scss';

export interface MessagesProps {
	communityId: string;
	onShowTimelineChange: (shown: boolean) => void;
}

const Messages: FC<MessagesProps> = ({ communityId, onShowTimelineChange }) => {
	const { t } = useTranslation();
	const { berichten, loading, error } = useAppSelector(selectBerichten);
	const filters = useAppSelector(selectFilters);
	const dispatch = useAppDispatch();
	const [page, setPage] = useState(0);
	const [selectedBericht, setSelectedBericht] = useState<string | null>(null);
	const [initShowInput, setInitShowInput] = useState(false);
	const [isCreateBerichtWizzardOpen, setIsCreateBerichtWizzardOpen] = useState(false);
	const [editBericht, setEditBericht] = useState<Bericht | null>(null);
	const [editNieuwsbericht, setEditNieuwsbericht] = useState<Bericht | null>(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState<string | null>(null);

	const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
	const modalRef = useRef<HTMLZdsModalElement | null>(null);
	const closeModalRef = useRef<HTMLZdsModalElement | null>(null);
	const deleteModalRef = useRef<HTMLZdsModalElement | null>(null);

	const isWizzardOpen = isCreateBerichtWizzardOpen || editBericht;
	const showTimeline = !selectedBericht && !isWizzardOpen;

	useEffect(() => {
		dispatch(resetBerichten({ communityId }));
		setPage(1);
	}, [filters, communityId]);

	useEffect(() => {
		onShowTimelineChange(showTimeline);
	}, [showTimeline]);

	const fetchData = () => {
		if (loading || !communityId) return;

		dispatch(getBerichten({ communityId, offset: page }));
		setPage(prevPage => prevPage + 1);
	};

	const backToBerichtenList = (scrollToLastPosition?: boolean) => {
		setSelectedBericht(null);
		setIsCreateBerichtWizzardOpen(false);

		if (scrollToLastPosition) {
			setTimeout(() => {
				if (scrollableContainerRef?.current) {
					scrollableContainerRef.current.scrollTop = scrollPosition;
				}
			}, 200);
		}
	};

	const handleBackFromCreate = (refetch?: boolean) => {
		if (editBericht) {
			setEditBericht(null);
			if (selectedBericht) return;
		}
		if (refetch) {
			dispatch(resetBerichten({ communityId }));
			setPage(1);
		}
		backToBerichtenList(!refetch);
	};

	const handleBackFromEdit = () => {
		if (editBericht) {
			setEditBericht(null);
			if (selectedBericht) return;
		}
		backToBerichtenList(true);
	};

	const handleSelectBericht = (berichtId: string, showInput: boolean) => {
		setSelectedBericht(berichtId);
		setInitShowInput(showInput);
	};

	const handleScroll = (event: UIEvent<HTMLDivElement>) => {
		setScrollPosition(event.currentTarget.scrollTop);
	};

	const openCreateBerichtWizzard = () => {
		setIsCreateBerichtWizzardOpen(true);
	};

	const openCreateNieuwsberichtWizzard = () => {
		modalRef?.current?.show();
	};

	const openEditBericht = (bericht: Bericht) => {
		if (bericht.soort?.code === BerichtSoortCode.PRIKBORD) {
			return setEditBericht(bericht);
		}
		setEditNieuwsbericht(bericht);
		openCreateNieuwsberichtWizzard();
	};

	const handleShowDiscardMessageModal = () => {
		closeModalRef?.current?.show();
	};

	const handleShowDeleteMessageModal = () => {
		deleteModalRef?.current?.show();
	};

	const handleConfirmationModalSave = () => {
		modalRef?.current?.hide('close-and-clear');
		setEditNieuwsbericht(null);
	};

	const handleSaveNewsMessage = () => {
		setAlertMessage(
			t(
				`community:communityInformationPage.messages.createNewsMessage.alertMessage.${
					editNieuwsbericht ? 'edited' : 'created'
				}`
			)
		);
		setShowSuccessAlert(true);
		setEditNieuwsbericht(null);
		if (!editNieuwsbericht) {
			handleBackFromCreate(true);
		}
	};

	const mainContent = () => (
		<>
			{selectedBericht && !editBericht && (
				<div data-testid="berichtDetailsContainer" className="zds-flex zds-flex-col zds-flex-1">
					<SingleMessage
						id={selectedBericht}
						communityId={communityId ?? ''}
						backToBerichtenList={() => backToBerichtenList(true)}
						initShowInput={initShowInput}
						onEditBericht={openEditBericht}
					/>
				</div>
			)}
			{editBericht && <EditMessage bericht={editBericht} onBackButtonClick={handleBackFromEdit} />}
			{isCreateBerichtWizzardOpen && <CreateMessage onBackButtonClick={handleBackFromCreate} />}
			{showTimeline && (
				<div
					className={styles.scrollableContainer}
					id="scrollableContainer"
					onScroll={handleScroll}
					ref={scrollableContainerRef}
				>
					{loading && <Spinner />}
					{!loading && berichten?.totalItems === 0 && (
						<div className="zds-flex zds-justify-content-center zds-headline-md">{t('global:search.noResults')}</div>
					)}
					{berichten && (
						<InfiniteScroll
							dataLength={berichten.berichten.length}
							next={fetchData}
							hasMore={berichten.totalItems > berichten.berichten.length}
							loader={null}
							scrollableTarget="scrollableContainer"
						>
							<div className={styles.messagesContainer} data-testid="communityBerichtenContainer">
								{berichten.berichten?.map(bericht => {
									const finalBericht = bericht as unknown as IBericht<
										ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel
									>;
									return (
										<div
											id={finalBericht.id}
											key={finalBericht.id}
											data-testid={`communityBericht-${finalBericht.id}`}
											className={styles.messageContainer}
										>
											<BerichtComponent
												onSelectBericht={() => {
													handleSelectBericht(finalBericht.id, false);
												}}
												content={finalBericht}
												community={bericht.community as unknown as CommunityType}
												onComment={() => {
													handleSelectBericht(finalBericht.id, true);
												}}
												onEditBericht={openEditBericht}
											/>
										</div>
									);
								})}
							</div>
						</InfiniteScroll>
					)}
				</div>
			)}
		</>
	);

	return (
		// provider needed for zig.community components
		<CustomerContext.Provider value={''}>
			<div className={styles.container}>
				{showTimeline && (
					<MessagesHeader
						onWriteBericht={openCreateBerichtWizzard}
						onCreateNieuwsbericht={openCreateNieuwsberichtWizzard}
					/>
				)}
				<Pane
					className={classNames({
						[styles.contentContainer]: true,
						[styles['contentContainer--fullHeight']]: !showTimeline,
					})}
				>
					{error && <ErrorMessage message={t(error)} />}
					{mainContent()}
				</Pane>
				<CreateNewsMessageModal
					initMessage={editNieuwsbericht}
					initActiveStep={1}
					ref={modalRef}
					onShowDiscardMessageModal={handleShowDiscardMessageModal}
					onSave={handleSaveNewsMessage}
					onDelete={handleShowDeleteMessageModal}
				/>
				<ConfirmationModal
					ref={closeModalRef}
					title={t(
						`community:communityInformationPage.messages.createNewsMessage.discardMessageQuestion.${
							editNieuwsbericht ? 'edit' : 'create'
						}`
					)}
					onSave={handleConfirmationModalSave}
					onClose={() => modalRef?.current?.show()}
					noCloseOnSave
					isDelete
				></ConfirmationModal>
				<ConfirmationModal
					ref={deleteModalRef}
					title={t('community:communityInformationPage.messages.deleteMessageQuestion')}
					onSave={() => {
						if (editNieuwsbericht) {
							dispatch(deleteBericht({ berichtId: editNieuwsbericht.id }));
						}
						handleConfirmationModalSave();
					}}
					onClose={() => modalRef?.current?.show()}
					noCloseOnSave
					isDelete
				></ConfirmationModal>
				<Alert
					type="success"
					title={alertMessage ?? ''}
					show={showSuccessAlert}
					onAnimationEnd={() => setShowSuccessAlert(false)}
				/>
			</div>
		</CustomerContext.Provider>
	);
};

export default Messages;
