import { createAsyncThunk } from '@reduxjs/toolkit';

import RelatieAPI from '../../api';
import { Relatie } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { ListResponse } from '../../../../global/api';

export const getRelatiesData = createAsyncThunk<
	ListResponse<Relatie>,
	{ term?: string; offset?: number; limit?: number; clear?: boolean },
	{ rejectValue: string }
>('relatieData/getRelatiesData', async ({ term, offset, limit } = {}, { rejectWithValue }) => {
	try {
		const response = await RelatieAPI.getRelaties(term, offset, limit);

		return response;
	} catch (error) {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	}
});
