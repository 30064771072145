import { FC } from 'react';

import { Medewerker } from '../../api/models';
import DataTable from '../../../../global/components/DataTable/DataTable';
import { useMapMedewerkersToTableData } from '../../hooks/table/useMapMedewerkersToTableData/useMapMedewerkersToTableData';
import { useNavigate } from 'react-router';

export interface MedewerkersTableProps {
	medewerkers?: { [key: number]: Medewerker[] };
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
}

const MedewerkersTable: FC<MedewerkersTableProps> = ({ medewerkers, onPageChange, pageSize, itemsNum }) => {
	const tableData = useMapMedewerkersToTableData(medewerkers ?? null);
	const navigate = useNavigate();

	const handleRowClick = (id: string) => {
		navigate(`/organisatiebeheer/medewerkers/${id}`);
	};

	return (
		<DataTable
			data={tableData}
			tableTestId="medewerkersTable"
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onRowClick={handleRowClick}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
		/>
	);
};

export default MedewerkersTable;
