import { RouteInfo } from '../../routes';
import { SideMenuInfo } from '../../global/components/SideMenu/SideMenu';
import Profile from '../../global/pages/Profile/Profile';
import Units from './pages/Units/Units';
import Clusters from './pages/Clusters/Clusters';
import CollectiveObjects from './pages/CollectiveObjects/CollectiveObjects';
import Preferences from '../../global/pages/Preferences/Preferences';
import ClusterInformation from './pages/ClusterInformation/ClusterInformation';
import CreateCluster from './pages/CreateCluster/CreateCluster';
import Agreements from './pages/Agreements/Agreements';

export const sideMenuInfo: SideMenuInfo = {
	linkBase: '/vastgoedbeheer',
	sections: [
		{
			items: [
				{
					title: 'vastgoed:vastgoed.eenheden',
					link: 'eenheden',
					iconName: 'house',
					permissions: ['VAS_LEZ_EEN'],
				},
				{
					title: 'vastgoed:vastgoed.clusters',
					link: 'clusters',
					iconName: 'object-group',
					permissions: ['VAS_LEZ_CLU'],
				},
				{
					title: 'vastgoed:vastgoed.collectiveObjects',
					link: 'collectieve-objecten',
					iconName: 'people-roof',
				},
				{
					title: 'vastgoed:vastgoed.overeenkomsten',
					link: 'overeenkomsten',
					iconName: 'house-user',
					permissions: ['VAS_LEZ_OVE'],
				},
			],
		},
	],
};

const routes: RouteInfo[] = [
	{
		path: 'eenheden',
		subRoutes: [
			{
				path: '',
				Component: () => <Units />,
				permissions: ['VAS_LEZ_EEN'],
			},
		],
	},
	{
		path: 'clusters',
		Component: () => <Clusters />,
		subRoutes: [
			{
				path: '',
				Component: () => <Clusters />,
				permissions: ['VAS_LEZ_CLU'],
			},
			{
				path: 'create',
				Component: () => <CreateCluster />,
				permissions: ['VAS_AAN_CLU'],
			},
			{
				path: ':id',
				subRoutes: [
					{
						path: 'edit',
						Component: () => <ClusterInformation tab={0} />,
						permissions: ['VAS_LEZ_CLU'],
					},
					{
						path: 'eenheden',
						Component: () => <ClusterInformation tab={1} />,
						permissions: ['VAS_LEZ_CLU'],
					},
					{
						path: 'contactpersonen',
						Component: () => <ClusterInformation tab={2} />,
						permissions: ['VAS_LEZ_CLU'],
					},
				],
			},
		],
	},
	{
		path: 'collectieve-objecten',
		Component: () => <CollectiveObjects />,
	},
	{
		path: 'overeenkomsten',
		Component: () => <Agreements />,
		permissions: ['VAS_LEZ_OVE'],
	},
	{
		path: 'persoonlijkeinstellingen',
		Component: () => <Preferences sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: 'profiel',
		Component: () => <Profile sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: '',
		redirect: '/vastgoedbeheer/eenheden',
		Component: () => null,
	},
];

export default routes;
