import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText } from '@zig-design-system/ui-components-react';
import ArrayForm, { TableSchema } from '../../../components/ArrayForm/ArrayForm';
import { FormSchema } from '../../../../../global/components/Form/Form.types';

export interface IamAllowedEmailDomain {
	allowedEmailDomain: string;
}

interface CreateAllowedDomainsProps {
	allowedEmailDomains: IamAllowedEmailDomain[];
	setAllowedEmailDomains: (allowedEmailDomains: IamAllowedEmailDomain[]) => void;
}

const CreateAllowedDomains: FC<CreateAllowedDomainsProps> = ({ allowedEmailDomains, setAllowedEmailDomains }) => {
	const { t } = useTranslation(['global', 'organisatie']);

	const allowedEmailDomainsFormSchema: FormSchema = {
		formControls: [
			{
				name: 'allowedEmailDomain',
				control: {
					type: ZdsInputText,
					props: {
						label: 'E-maildomein',
						required: true,
					},
				},
			},
		],
	};

	const allowedEmailDomainsTableSchema: TableSchema = {
		columns: [
			{
				header: 'E-maildomein',
				mapper: (item: IamAllowedEmailDomain) => ({ mainText: item.allowedEmailDomain }),
			},
		],
	};

	return (
		<>
			<div className="zds-headline-md">{t('Toegestane e-maildomeinen')}</div>
			<ArrayForm
				formSchema={allowedEmailDomainsFormSchema}
				tableSchema={allowedEmailDomainsTableSchema}
				items={allowedEmailDomains}
				onChange={setAllowedEmailDomains}
				formId="createAllowedEmailDomain"
				formTestId="createAllowedEmailDomainTest"
			/>
		</>
	);
};

export default CreateAllowedDomains;
