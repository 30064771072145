import { useState } from 'react';
import { Language } from '../../api/models';
import { LANGUAGE_DATA, LanguageData } from '../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';

export const DEFAULT_LANGUAGE = Language.Dutch;

export const useLanguageSelect = (initLanguage = DEFAULT_LANGUAGE) => {
	const [selectedLanguage, setSelectedLanguage] = useState<LanguageData>(
		LANGUAGE_DATA.find(d => d.value === initLanguage) as LanguageData
	);

	return { selectedLanguage: selectedLanguage?.value, setSelectedLanguage };
};
