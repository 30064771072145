import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Overeenkomsten } from '../../../api/models';
import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { getLocaleDateString } from '../../../../../global/utils/date/getLocaleDateString';

export const useMapOvereenkomstenToTableData = (
	overeenkomsten: {
		[key: number]: Overeenkomsten[];
	} | null
): TableData | null => {
	const { t, i18n } = useTranslation();
	const header: TableData['header'] = {
		labels: [
			t('global:domainShared.code'),
			t('vastgoed:vastgoed.adres(sen)'),
			t('vastgoed:vastgoed.huurder(s)'),
			t('global:domainShared.begindatum'),
			t('global:domainShared.einddatum'),
			t('global:domainShared.status'),
		],
	};

	const getPage = useCallback(
		(overeenkomsten: Overeenkomsten[]): TableRow[] => {
			return overeenkomsten.map(o => {
				return {
					id: o.id ?? '',
					cells: [
						{
							mainText: o.code ?? '',
						},
						{
							mainText:
								o.eenheden
									?.map(e => e.naam)
									.join(', ')
									.slice(0, 100) ?? '',
						},
						{
							mainText:
								o.rolRelaties
									?.filter(r => r.relatie.naam !== null)
									?.map(r => r.relatie.naam)
									.join(', ')
									.slice(0, 100) ?? '',
						},
						{ mainText: (o.begindatum && getLocaleDateString(o.begindatum, i18n.language)) ?? '' },
						{ mainText: (o.einddatum && getLocaleDateString(o.einddatum, i18n.language)) ?? '' },
						{ mainText: getReferentiedataNaam(t, 'OVEREENKOMSTSTATUS', o.status?.code) },
					],
				};
			});
		},
		[overeenkomsten]
	);

	const pages = useTablePages(overeenkomsten, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
