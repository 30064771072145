import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsModal } from '@zig-design-system/ui-components-react';
import { ZdsModalCustomEvent } from '@zig-design-system/ui-components';

export interface ConfirmationModalProps {
	title: string;
	onSave?: () => void;
	onClose?: () => void;
	noCloseOnSave?: boolean;
	isDelete?: boolean;
}

const ConfirmationModal = forwardRef<HTMLZdsModalElement, ConfirmationModalProps>(
	({ title, onSave, onClose, noCloseOnSave, isDelete }, ref) => {
		const { t } = useTranslation();

		const handleModalHide = (event: ZdsModalCustomEvent<{ action: string }>) => {
			if (event.detail.action === 'save') {
				onSave?.();

				if (noCloseOnSave) return;
			}
			onClose?.();
		};

		return (
			<ZdsModal ref={ref} onZdsModalHide={handleModalHide}>
				<span className="zds-body zds-flex zds-justify-content-center zds-text-center" slot="body">
					{title}
				</span>
				<div slot="footer" className="zds-flex zds-flex-1 zds-justify-content-end zds-gap-3">
					<ZdsButton zds-modal-action="close" variant="outlined" preset={isDelete ? 'neutral' : 'primary'}>
						{t(isDelete ? 'global:common.cancel' : 'global:common.no')}
					</ZdsButton>
					<ZdsButton zds-modal-action="save" variant="contained" preset={isDelete ? 'danger' : 'primary'}>
						{t(isDelete ? 'global:common.delete' : 'global:common.yes')}
					</ZdsButton>
				</div>
			</ZdsModal>
		);
	}
);

export default ConfirmationModal;
