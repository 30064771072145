import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Userinfo } from '../../../api/models';
import AuthAPI from '../../../api';
import Constants from '../../../../auth.config';
import { saveTokenData } from '../../../utils/auth/tokenService';

export const getAuthData = createAsyncThunk<{ user: Userinfo }, string, { rejectValue: string }>(
	'authorizationData/getAuthData',
	async (callbackUrl, { rejectWithValue }) => {
		try {
			const params = new URLSearchParams(window.location.search);

			const body = {
				code: params.get('code'),
				state: params.get('state'),
				callbackUrl,
				stateVerifier: localStorage.getItem('state'),
			};

			const responseData = await AuthAPI.getAuthData(body);

			if (!responseData.tokens || !responseData.userInfo) {
				throw new Error('Auth error');
			}

			localStorage.removeItem('state');
			saveTokenData(responseData);

			const user = responseData.userInfo;

			const originPath = localStorage.getItem(Constants.originPathLsKey) ?? '/';
			window.location.href = originPath;

			return { user };
		} catch (error) {
			return rejectWithValue('Error while logging in');
		}
	}
);

export const clearError = createAction('authorizationData/clearError');
