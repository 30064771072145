import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { SortableOption, TableData } from '../../../../../global/components/DataTable/DataTable';
import { getRelatieNaam } from '../../../../relatiebeheer/utils/getRelatieNaam';
import { Community } from '../../../api/models';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import ChipsPopoutContent, { ChipsPopoutContentProps } from '../../../components/ChipsPopoutContent/ChipsPopoutContent';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';

const CLUSTERS_MAX_CHARS = 12;
const MANAGERS_MAX_CHARS = 30;

const getFinalNamesString = (names: string, maxChars: number) => {
	return names.length > maxChars ? `${names.slice(0, maxChars).trim()}...` : names;
};

const getClusterNames = (community: Community) =>
	community.clusters.map(cluster => (cluster ? cluster.naam : null)).filter(name => name);

const getManagerNames = (community: Community) =>
	community.rolRelaties
		?.filter(rol => rol.soort?.code === 'COM')
		.map(rol => getRelatieNaam(rol.relatie))
		.filter(name => name) ?? [];

const getMembersNum = (community: Community): number => {
	return community.rolRelaties?.filter(rol => rol.soort?.code === 'LID').length ?? 0;
};

export const useMapCommunitiesToTableData = (
	communities: { [key: number]: Community[] } | null,
	sortData: { [key: string]: SortableOption },
	onClick: (id: string) => void,
	showReports: boolean
): TableData | null => {
	const { t } = useTranslation();

	const getHeader = useCallback(() => {
		const columnIds = ['naam', null, null, null, 'aantalBerichten', 'aantalOngepast'];

		const headerData: TableData['header'] = {
			labels: [
				t('global:domainShared.naam'),
				`${t('vastgoed:vastgoed.cluster')}(s)`,
				t('community:community.manager'),
				t('community:community.members'),
				t('community:community.messages'),
			],
			columnIds,
			sortable: columnIds.map(id => (id ? sortData[id] || true : undefined)) as SortableOption[],
			width: [null, 170, 300, 107, 112],
		};

		if (showReports) {
			headerData.labels.push(t('community:community.reports'));
			headerData.width?.push(107);
		}

		return headerData;
	}, [sortData]);

	const getPage = useCallback((pageCommunities: Community[]) => {
		return pageCommunities.map(community => {
			const clusterNames = getClusterNames(community);
			const managerNames = getManagerNames(community);
			const clusterNamesString = clusterNames.join(', ');
			const managerNamesString = managerNames.join(', ');
			const clustersInfoData: ChipsPopoutContentProps['data'] = clusterNames.map(c => ({
				label: c ?? '',
				iconOptions: {
					className: 'zds-color-secondary',
					library: 'fa-solid',
					name: 'building',
					size: 'xs',
				},
			}));
			const managersInfoData: ChipsPopoutContentProps['data'] = managerNames.map(m => ({
				label: m ?? '',
				iconOptions: {
					className: 'zds-color-secondary',
					library: 'fa-solid',
					name: 'user',
					size: 'xs',
				},
			}));

			const communityRow: TableRow = {
				id: community.id,
				cells: [
					{
						mainText: community.naam,
						subText: getReferentiedataNaam(t, community.toewijzing?.soort, community.toewijzing?.code),
					},
					{
						mainText: getFinalNamesString(clusterNamesString, CLUSTERS_MAX_CHARS),
						nowrap: true,
						infoContent:
							clusterNames.length && clusterNamesString.length > CLUSTERS_MAX_CHARS ? (
								<ChipsPopoutContent data={clustersInfoData} title={`Clusters in ${community.naam}`} />
							) : null,
					},
					{
						mainText: getFinalNamesString(managerNamesString, MANAGERS_MAX_CHARS),
						nowrap: true,
						infoContent:
							managerNames.length && managerNamesString.length > MANAGERS_MAX_CHARS ? (
								<ChipsPopoutContent data={managersInfoData} title={`Managers in ${community.naam}`} />
							) : null,
					},
					{ mainText: getMembersNum(community).toString() },
					{ mainText: community.aantalBerichten?.toString() ?? '' },
				],
			};

			if (showReports) {
				communityRow.cells.push({
					mainText: community.aantalOngepast?.toString() ?? '',
					onClick: community.aantalOngepast ? () => onClick(community.id) : undefined,
				});
			}

			return communityRow;
		});
	}, []);

	const pages = useTablePages(communities, getPage);

	if (!pages) return null;

	return {
		header: getHeader(),
		body: { rows: pages },
	};
};
