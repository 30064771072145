import { ComponentProps, FC } from 'react';
import { ZdsSelect } from '@zig-design-system/ui-components-react';

export type SelectProps = ComponentProps<typeof ZdsSelect> & {
	explanationText?: string;
};

const Select: FC<SelectProps> = ({ explanationText, ...props }) => {
	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			{explanationText && <div className="zds-margin-bottom-2">{explanationText}</div>}
			<ZdsSelect className="zds-flex-1" {...props} />
		</div>
	);
};

export default Select;
