import { Suspense, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import routes, { RouteInfo, RouteInfoComponent, RouteInfoSubRoutes } from './routes';
import './global/i18n/i18n';
import AuthGuard from './global/components/AuthGuard/AuthGuard';
import { usePermissions } from './global/hooks/usePermissions/usePermissions';
import NotFound from './global/components/NotFound/NotFound';

const useRenderRoute = () => {
	const hasPermission = usePermissions();

	const renderRoute = useCallback((routeInfo: RouteInfo, WrapperComponent?: RouteInfoSubRoutes['WrapperComponent']) => {
		if (routeInfo.hasOwnProperty('subRoutes')) {
			// Render route with subroutes
			const routeInfoSubRoutes = routeInfo as RouteInfoSubRoutes;
			return (
				<Route path={routeInfoSubRoutes.path} key={routeInfoSubRoutes.path}>
					{routeInfoSubRoutes.subRoutes.map(subRouteInfo =>
						renderRoute(subRouteInfo, routeInfoSubRoutes.WrapperComponent || WrapperComponent)
					)}
				</Route>
			);
		}

		const hasRoutePermissions = !routeInfo.permissions || routeInfo.permissions.some(hasPermission);

		// Render route without subroutes
		const routeInfoComponent = routeInfo as RouteInfoComponent;

		if (!hasRoutePermissions) {
			routeInfoComponent.Component = NotFound;
		}

		let Component: React.ComponentType;
		if (WrapperComponent) {
			Component = () => (
				<WrapperComponent>
					<routeInfoComponent.Component />
				</WrapperComponent>
			);
		} else {
			Component = routeInfoComponent.Component;
		}

		let FinalComponent = Component;
		if (!routeInfoComponent.unprotected) {
			FinalComponent = () => (
				<AuthGuard>
					<Component />
				</AuthGuard>
			);
		}

		const routeProps: RouteProps = {
			path: routeInfoComponent.path,
		};

		if (routeInfoComponent.redirect) {
			routeProps.element = <Navigate to={routeInfoComponent.redirect} />;
		} else {
			routeProps.Component = () => (
				// Suspend while state is loaded from local storage
				<Suspense fallback={null}>
					<FinalComponent />
				</Suspense>
			);
		}

		return <Route key={routeInfoComponent.path} {...routeProps} />;
	}, []);

	return renderRoute;
};

const App = () => {
	const renderRoute = useRenderRoute();

	// Ignore 'ResizeObserver loop limit exceeded' error
	useEffect(() => {
		window.addEventListener('error', e => {
			if (e.message.includes('ResizeObserver loop')) {
				document.getElementById('webpack-dev-server-client-overlay-div')?.setAttribute('style', 'display: none');
				document.getElementById('webpack-dev-server-client-overlay')?.setAttribute('style', 'display: none');
			}
		});
	}, []);

	return (
		<Router>
			<Routes>{routes.map(routeInfo => renderRoute(routeInfo))}</Routes>
		</Router>
	);
};

export default App;
