import { TFunction } from 'i18next';

import { Relatie } from '../../../../api/models';
import { FormSchema } from '../../../../../../global/components/Form/Form.types';
import { generateUpdateNatuurlijkPersoonFormSchema } from './natuurlijkPersoon';
import { generateUpdateRechtsPersoonFormSchema } from './rechtsPersoon';
import { generateUpdateRelatiegroepFormSchema } from './relatieGroep';

export const generateUpdateRelatieFormSchema = (
	relatieData: Relatie,
	t: TFunction,
	language: string
): FormSchema | null => {
	switch (relatieData.soort?.code) {
		case 'NAT':
			return generateUpdateNatuurlijkPersoonFormSchema(relatieData, t, language);
		case 'REC':
			return generateUpdateRechtsPersoonFormSchema(relatieData, t);
		case 'GRO':
			return generateUpdateRelatiegroepFormSchema(relatieData, t);
		default:
			return null;
	}
};
