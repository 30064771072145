import { MutableRefObject, forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon, ZdsModal } from '@zig-design-system/ui-components-react';

import { useAppSelector } from '../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../communitybeheer/redux/currentCommunityData';
import Pane from '../../../../global/components/Pane/Pane';
import CreateNewsMessageStepper from './CreateNewsMessageStepper/CreateNewsMessageStepper';
import ChannelsAndContent from './ChannelsAndContent/ChannelsAndContent';
import { Bericht } from '../../api/models';
import styles from './CreateNewsMessageModal.module.scss';

export interface CreateNewsMessageModalProps {
	initActiveStep: number;
	onShowDiscardMessageModal: () => void;
	onSave: () => void;
	onDelete: () => void;
	initMessage?: Bericht | null;
}

const CreateNewsMessageModal = forwardRef<HTMLZdsModalElement, CreateNewsMessageModalProps>(
	({ initActiveStep, onShowDiscardMessageModal, onSave, onDelete, initMessage }, ref) => {
		const { t } = useTranslation();
		const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
		const [activeStep, setActiveStep] = useState(initActiveStep);
		const [showContent, setShowContent] = useState(false);
		const stepperRef = useRef<HTMLZdsStepperElement | null>(null);

		const close = (action = 'close') => {
			(ref as MutableRefObject<HTMLZdsModalElement | null>)?.current?.hide(action);
		};

		const handleModalHide = (
			e: CustomEvent<{
				action?: string;
			}>
		) => {
			if (e.detail.action === 'close-and-clear') {
				return setTimeout(() => {
					setShowContent(false);
				}, 500);
			}
			if (e.detail.action === 'delete') {
				return onDelete();
			}

			onShowDiscardMessageModal();
		};

		const handleModalShow = () => {
			setShowContent(true);
		};

		const getStepsContent = () => {
			if (activeStep === 1) {
				return (
					<ChannelsAndContent
						step={activeStep + 1}
						onSave={() => {
							close('close-and-clear');
							onSave();
						}}
						initMessage={initMessage}
					/>
				);
			}

			return null;
		};

		return (
			<div className={styles.modalContainer}>
				<ZdsModal ref={ref} onZdsModalHide={handleModalHide} onZdsModalShow={handleModalShow}>
					<div className="zds-body" slot="body">
						{showContent && (
							<>
								<div className="zds-flex zds-justify-content-between zds-items-center zds-padding-6">
									<div className="zds-headline-lg">
										{t('community:communityInformationPage.messages.header.news')} - {currentCommunityData?.naam}
									</div>
									<div>
										{initMessage && (
											<ZdsButton preset="neutral" variant="text" onZdsClick={() => close('delete')}>
												<ZdsIcon name="trash" library="fa-solid" size="sm"></ZdsIcon>
											</ZdsButton>
										)}
										<ZdsButton preset="neutral" variant="text" onZdsClick={() => close()}>
											<ZdsIcon name="close" size="sm"></ZdsIcon>
										</ZdsButton>
									</div>
								</div>
								<div className={styles.main}>
									<Pane className={styles.stepperContainer}>
										<h3> {t('community:communityInformationPage.messages.createNewsMessage.stepper.title')}</h3>
										<CreateNewsMessageStepper activeStep={activeStep} setActiveStep={setActiveStep} ref={stepperRef} />
									</Pane>
									<Pane className={styles.mainContainer}>{getStepsContent()}</Pane>
								</div>
							</>
						)}
					</div>
				</ZdsModal>
			</div>
		);
	}
);

export default CreateNewsMessageModal;
