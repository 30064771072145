import { SideMenuInfo } from '../../../global/components/SideMenu/SideMenu';
import { usePermissions } from '../../../global/hooks/usePermissions/usePermissions';
import { sideMenuInfo } from '../routes';

export const useOrganisatieSideMenu = () => {
	const hasPermission = usePermissions();

	const filteredSideMenuInfo: SideMenuInfo = {
		...sideMenuInfo,
		sections: [
			{
				...sideMenuInfo.sections[0],
				items: sideMenuInfo.sections[0].items.filter(i => !(i.link === 'medewerkers' && !hasPermission('ORG_LEZ_MED'))),
			},
			...sideMenuInfo.sections.slice(1),
		],
	};

	return filteredSideMenuInfo;
};
