import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';
import { createSlice } from '@reduxjs/toolkit';
import { OvereenkomstenDataState } from './types';
import { getOvereenkomsten } from './actions';

const initialState: OvereenkomstenDataState = {
	overeenkomsten: null,
	loading: false,
	error: null,
	itemsNum: 0,
};

export const overeenkomstenDataSlice = createSlice({
	name: 'overeenkomstenData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getOvereenkomsten.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.overeenkomsten = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getOvereenkomsten.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.overeenkomsten = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.overeenkomsten);
			})
			.addCase(getOvereenkomsten.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const overeenkomstenDataReducer = overeenkomstenDataSlice.reducer;
