import { createSlice } from '@reduxjs/toolkit';

import { AuthorizationDataSate, clearError, getAuthData } from '.';

export const initialState: AuthorizationDataSate = {
	loading: false,
	error: null,
	user: null,
};

export const authorizationDataSlice = createSlice({
	name: 'authorizationData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAuthData.pending, state => {
				state.loading = true;
				state.user = null;
				state.error = null;
			})
			.addCase(getAuthData.fulfilled, (state, { payload: { user } }) => {
				state.loading = false;
				state.user = user;
			})
			.addCase(getAuthData.rejected, (state, { payload }) => {
				state.loading = false;
				state.user = null;
				state.error = payload ?? null;
			})
			.addCase(clearError, state => {
				state.error = null;
			});
	},
});

export const authorizationDataReducer = authorizationDataSlice.reducer;
