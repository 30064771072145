import { FC, useState } from 'react';
import { ZdsButton, ZdsSelect } from '@zig-design-system/ui-components-react';
import { t } from 'i18next';
import AutocompleteInput from '../../../../global/components/AutocompleteInput/AutocompleteInput';
import InputWrapper from '../../../../global/components/Form/InputWrapper/InputWrapper';
import ToggleButton from '../../../../global/components/ToggleButton/ToggleButton';
import { SelectOptions } from '../../../../global/components/Form/Form.types';

interface AddMemberFormProps {
	rolItems: SelectOptions[];
	autocompleteItems: { key: string; value: string }[];
	selectedRelatie: string | null;
	isSaveDisabled: boolean;
	onFormToggle: () => void;
	onRolChange: (val: string | null) => void;
	onAutocompleteChange: (term: string) => void;
	onAutocompleteSelect: (id: string | null) => void;
	onSubmit: () => void;
}

const AddMemberForm: FC<AddMemberFormProps> = ({
	rolItems,
	autocompleteItems,
	selectedRelatie,
	isSaveDisabled,
	onFormToggle,
	onRolChange,
	onAutocompleteChange,
	onAutocompleteSelect,
	onSubmit,
}) => {
	const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
	const handleToggleForm = () => {
		setIsFormVisible(prev => !prev);
		onFormToggle();
	};
	const handleCancel = () => {
		setIsFormVisible(false);
		onFormToggle();
	};

	return (
		<>
			<div
				className="zds-flex zds-justify-content-end zds-padding-3 zds-margin-top-12"
				data-testid="add-new-community-member-button"
			>
				<ToggleButton icon="plus" onClick={handleToggleForm} />
			</div>
			{isFormVisible && (
				<>
					<div
						data-testid="add-new-community-member-form"
						className="zds-flex zds-flex-row zds-justify-content-center zds-gap-x-12"
					>
						<div className="zds-flex-1 zds-flex zds-flex-col zds-gap-y-4">
							<InputWrapper fullInputWidth labelPosition="top">
								<AutocompleteInput
									label={t('global:domainShared.naam') ?? ''}
									items={autocompleteItems}
									selectedItemKey={selectedRelatie}
									onAutocompleteChange={value => onAutocompleteChange(value)}
									onSelect={(item: any) => onAutocompleteSelect(item.key)}
								/>
							</InputWrapper>
						</div>
						<div className="zds-flex zds-flex-col zds-gap-y-4">
							<InputWrapper labelPosition="top">
								<ZdsSelect
									data-testid="soort-select"
									onZdsInputDidUpdate={e => onRolChange(e.target.value)}
									label={t('global:domainShared.rol') ?? ''}
								>
									{rolItems.map(({ value, label }) => (
										<option key={value} value={value} label={label} />
									))}
								</ZdsSelect>
							</InputWrapper>
						</div>
					</div>
					<div className="zds-text-right zds-margin-top-3 zds-margin-bottom-12">
						<ZdsButton onZdsClick={handleCancel} preset="secondary" variant="outlined">
							{t('global:common.cancel')}
						</ZdsButton>
						<ZdsButton className="zds-margin-left-3" disabled={isSaveDisabled} onZdsClick={onSubmit}>
							{t('global:common.save')}
						</ZdsButton>
					</div>
				</>
			)}
		</>
	);
};

export default AddMemberForm;
