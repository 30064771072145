import { FC, useState } from 'react';
import classNames from 'classnames';
import { ZdsCard, ZdsClickable, ZdsIcon } from '@zig-design-system/ui-components-react';
import {
	IBericht,
	CommunityType,
	NewEmotie,
	ApiEmotie,
	EmotieButtonOptions,
	BerichtBody,
	ApiNieuwsberichtInitieel,
	ApiPrikbordBerichtInitieel,
	Reageeroptie,
} from '@zig.community/community-components';

import { BerichtFooter } from './BerichtFooter';
import MessageMenu from '../Messages/messageMenu/MessageMenu';
import { Bericht as BerichtType } from '../../api/models';
import { InappropriateContentMessage } from '../Messages/InappropriateContentMessage/InappropriateContentMessage';
import { useBerichtPermissions } from '../../hooks/useBerichtPermissions/useBerichtPermissions';
import { useBerichtMenuOptions } from '../../hooks/useBerichtMenuOptions/useBerichtMenuOptions';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { selectUserRelatie } from '../../../../global/redux/shared/authorizationData';
import { getEmoties, getRolRelaties } from './mapBerichtRelatieUtils';
import { deleteBerichtEmotie, likeBericht } from '../../redux/berichten';
import BerichtHeader from './BerichtHeader';
import styles from './Bericht.module.scss';

export interface BerichtProps {
	content: IBericht<ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel>;
	community: CommunityType;
	isSingle?: boolean;
	onSelectBericht?: () => void;
	onCloseBericht?: () => void;
	onEditBericht?: (bericht: BerichtType) => void;
	onComment?: () => void;
	isPreview?: boolean;
}

export const Bericht: FC<BerichtProps> = ({
	content,
	community,
	isSingle = false,
	onSelectBericht,
	onCloseBericht,
	onEditBericht,
	onComment,
	isPreview,
}) => {
	const hasPermission = useBerichtPermissions();
	const relatie = useAppSelector(selectUserRelatie);
	const dispatch = useAppDispatch();
	const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
	const { showEdit, showDelete, showDeleteInappropriateReport } = useBerichtMenuOptions(
		content as unknown as BerichtType
	);
	const hasMenuItems = showEdit || showDelete || showDeleteInappropriateReport;

	if (!content || !community) return null;

	const handleAddBerichtEmotie = async (newEmotie: NewEmotie) => {
		if (isPreview) return;
		dispatch(likeBericht({ berichtId: newEmotie.bericht.id, relatieId: newEmotie.relatie.id }));
	};

	const handleDeleteBerichtEmotie = async (emotie: ApiEmotie) => {
		if (isPreview) return;
		dispatch(deleteBerichtEmotie({ emotieId: emotie.id }));
	};

	const finalContent: IBericht<ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel> = {
		...content,
		aanmaakdatum: new Date(content.aanmaakdatum),
		rolRelaties: getRolRelaties(content),
		emoties: getEmoties(content),
	};

	const emotieButtonOptions: EmotieButtonOptions = {
		isLoading: false,
		variant: 'large',
		onAddBerichtEmotie: handleAddBerichtEmotie,
		onDeleteBerichtEmotie: handleDeleteBerichtEmotie,
	};

	return (
		<div className={styles.messageContainer}>
			<MessageMenu
				bericht={finalContent}
				isMenuVisible={hasMenuItems && isMenuVisible}
				setIsMenuVisible={setIsMenuVisible}
				onDelete={onCloseBericht}
				onEdit={() => onEditBericht?.(finalContent as unknown as BerichtType)}
			/>
			<ZdsCard className={classNames(styles.cardContainer, styles.headerContainer)}>
				<div
					className={classNames({
						'zds-sticky zds-flex zds-items-center zds-padding-4': true,
						'zds-padding-bottom-0': !isSingle,
						[styles.singleMessageHeader]: isSingle,
					})}
					slot="body"
					data-testid="berichtHeaderContainer"
				>
					{isSingle && (
						<ZdsClickable onZdsClick={onCloseBericht}>
							<ZdsIcon
								className={`zds-items-center zds-padding-right-4 ${styles.cursorPointer}`}
								size="sm"
								name="arrow-left"
								library="fa-regular"
							/>
						</ZdsClickable>
					)}
					<BerichtHeader
						content={finalContent}
						community={community}
						currentRelatie={relatie}
						onShowMenu={() => hasMenuItems && setIsMenuVisible(!isMenuVisible)}
						showMenu={!isPreview}
					/>
				</div>
			</ZdsCard>
			{hasPermission('COM_LEZ_MEL_ONG') && (
				<div className="zds-padding-left-4">
					<InappropriateContentMessage bericht={finalContent} />
				</div>
			)}
			<ZdsCard className={styles.cardContainer}>
				<div slot="body">
					<BerichtBody
						content={finalContent as any}
						showBody={isSingle}
						isClickable={!!onSelectBericht}
						onBodyClick={onSelectBericht}
						onShowComments={onSelectBericht}
						emotieButtonOptions={emotieButtonOptions}
						currentRelatie={relatie}
					/>
				</div>
				{content.reageeroptie.code !== Reageeroptie.GEE && (
					<div slot="footer" className="zds-padding-left-3 zds-padding-right-3">
						<BerichtFooter
							content={finalContent}
							{...(onComment && { onComment })}
							currentRelatie={relatie}
							emotieButtonOptions={emotieButtonOptions}
						/>
					</div>
				)}
			</ZdsCard>
		</div>
	);
};
