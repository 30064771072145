import { ZdsContextMenu } from '@zig-design-system/ui-components-react';
import { ZdsButtonCustomEvent } from '@zig-design-system/ui-components';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './ContextMenu.module.scss';

interface ContextMenuProps {
	target: string;
	targetRef: React.MutableRefObject<any>;
	className: string;
	position?: HTMLZdsContextMenuElement['position'];
	onChange?: (isOpen: boolean) => void;
	children: React.ReactNode;
}

const ContextMenu = ({ target, targetRef, className, position, onChange, children }: ContextMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const contextMenuRef = useRef(null);

	// Open/close account menu when needed
	useEffect(() => {
		const handleDocumentClick = (event: MouseEvent | ZdsButtonCustomEvent<MouseEvent>) => {
			const targetElement = targetRef.current as HTMLDivElement | null;
			const contextMenuElement = contextMenuRef.current as HTMLDivElement | null;
			const eventTargetNode = event.target as Node;

			const isInsideTargetOrMenu =
				targetElement?.contains(eventTargetNode) || contextMenuElement?.contains(eventTargetNode);

			if (isInsideTargetOrMenu) {
				setIsOpen(oldIsOpen => !oldIsOpen);

				// Prevent visual bug where context menu moves immediately after first render
				setTimeout(() => {
					setIsVisible(true);
				}, 100);
			} else {
				setIsOpen(false);
			}
		};

		document.addEventListener('click', handleDocumentClick);
		document.addEventListener('zdsClick', handleDocumentClick as EventListener);

		return () => {
			document.removeEventListener('click', handleDocumentClick);
			document.removeEventListener('zdsClick', handleDocumentClick as EventListener);
		};
	}, [targetRef]);

	useEffect(() => {
		onChange?.(isOpen);
	}, [isOpen]);

	const contextMenuClasses = classNames({
		[className]: true,
		[styles.contextMenu]: true,
		[styles.visible]: isVisible,
	});

	return (
		<ZdsContextMenu
			ref={contextMenuRef}
			open={isOpen || undefined}
			target={target}
			className={contextMenuClasses}
			position={position}
		>
			{children}
		</ZdsContextMenu>
	);
};

export default ContextMenu;
