import { FC } from 'react';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';
import { ZdsButtonCustomEvent } from '@zig-design-system/ui-components';

interface ToggleButtonProps {
	icon: string;
	onClick?: (event: ZdsButtonCustomEvent<MouseEvent>) => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({ icon, onClick }) => (
	<ZdsButton preset="secondary" aspectSquare variant="outlined" onZdsClick={onClick} pill>
		<ZdsIcon name={icon} library="fa-light" size="md" />
	</ZdsButton>
);

export default ToggleButton;
