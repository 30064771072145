import { AppCode } from '../../api/models';
import { selectUser } from '../../redux/shared/authorizationData';
import { useAppSelector } from '../../redux/store';

export const useAppPermissions = () => {
	const user = useAppSelector(selectUser);

	return (appCode: AppCode): boolean => {
		return !!user?.functies?.some(f => f.applicatie?.code === appCode);
	};
};
