export const getStatus = (begindatum?: Date, einddatum?: Date): string => {
	if (!begindatum) return 'INA';

	const todayDate = new Date();
	todayDate.setHours(0, 0, 0, 0);
	const begindatumDate = new Date(begindatum);
	begindatumDate.setHours(0, 0, 0, 0);
	const einddatumDate = new Date(einddatum ?? '');
	einddatumDate.setHours(0, 0, 0, 0);

	const todayTime = todayDate.getTime();
	const begindatumTime = begindatumDate.getTime();
	const einddatumTime = einddatumDate.getTime();

	if (begindatumTime <= todayTime && (!einddatum || einddatumTime >= todayTime)) {
		return 'ACT';
	}

	return 'INA';
};
