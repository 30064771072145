import { FC } from 'react';

import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { useOrganisatieSideMenu } from '../../hooks/useOrganisatieSideMenu';

const HomePage: FC = () => {
	const sideMenuInfo = useOrganisatieSideMenu();
	return <PageLayout sideMenuInfo={sideMenuInfo}>...</PageLayout>;
};

export default HomePage;
