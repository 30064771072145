import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCurrentCommunityData, selectCurrentCommunityData } from '../../redux/currentCommunityData';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import CommunitySettings from './TabsContent/CommunitySettings/CommunitySettings';
import CommunityCategories from './TabsContent/CommunityCategories/CommunityCategories';
import CommunityMembers from './TabsContent/CommunityMembers/CommunityMembers';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import styles from './EditCommunity.module.scss';

export const TABS = ['edit', 'leden', 'categorie'];

export interface EditCommunityProps {
	tab?: number;
}

const EditCommunity: FC<EditCommunityProps> = ({ tab = 0 }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { currentCommunityData, updating } = useAppSelector(selectCurrentCommunityData);
	const hasPermission = usePermissions();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams<{ id: string }>();
	const [title, setTitle] = useState('');
	const communityId = params.id;
	const canEdit = hasPermission('COM_WIJ_COM_DET');

	useEffect(() => {
		if (communityId) {
			void dispatch(getCurrentCommunityData(communityId));
		}
	}, [params]);

	const handleTabChange = (tab: number) => {
		if (location.pathname.split('/').pop() !== TABS[tab]) {
			navigate(`../${TABS[tab]}`, { relative: 'path', replace: true });
		}
	};

	const handleCommunityNameChange = (name: string) => {
		setTitle(name);
	};

	const tabsData = [
		{
			name: t('community:communityInformationPage.tabs.settings'),
			content: currentCommunityData ? <CommunitySettings onCommunityNameChange={handleCommunityNameChange} /> : null,
		},
		{
			name: t('community:communityInformationPage.tabs.members'),
			content: <CommunityMembers />,
		},
		{
			name: t('community:communityInformationPage.tabs.category'),
			content: <CommunityCategories />,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.communityManagement'), link: '/communitybeheer' },
		{ label: t('community:communitiesPage.header.title'), link: '/communitybeheer/community' },
		{ label: t('community:communityInformationPage.header.title') },
	];

	const saveButton = (
		<SaveButton dataTestId="updateCommunityDataSubmitButton" formId="updateCommunityForm" disabled={updating} />
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div className="zds-flex zds-flex-1 zds-gap-4">
				<div className="zds-flex zds-flex-1 zds-flex-col zds-gap-4">
					<div>
						<BackButton />
					</div>
					<PageHeader
						title={(title || currentCommunityData?.naam) ?? ''}
						iconName="users"
						iconLibrary="fa-light"
						breadcrumbs={breadcrumbs}
						status={currentCommunityData?.status?.code}
						contentEnd={canEdit && tab === 0 && saveButton}
					/>
					<Pane className={styles.contentPane}>
						<Tabs tabsData={tabsData} onTabChange={handleTabChange} initActiveTab={tab} />
					</Pane>
				</div>
			</div>
		</PageLayout>
	);
};

export default EditCommunity;
