import axios, { AxiosResponse } from 'axios';

import { LoginResponseResource } from './models';

export const Endpoints = {
	login: 'auth/login',
	refresh: 'auth/refresh',
};

export type ListResponse<T> = { data: T[]; itemsNum: number };

export const getListResponse = <T>(response: AxiosResponse<T[]>): ListResponse<T> => {
	return { data: response.data, itemsNum: +response.headers['x-parameters-aantal'] };
};

const getAuthData = async (body: {
	code: string | null;
	state: string | null;
	callbackUrl: string;
	stateVerifier: string | null;
}): Promise<LoginResponseResource> => {
	const response = await axios.post<LoginResponseResource>(
		`${window.env.API_URL}/${Endpoints.login}`,
		JSON.stringify(body),
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		}
	);

	return response.data;
};

const refreshToken = async (body: { accessToken: string | null }): Promise<LoginResponseResource> => {
	const response = await axios.post<LoginResponseResource>(
		`${window.env.API_URL}/${Endpoints.refresh}`,
		JSON.stringify(body),
		{
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		}
	);

	return response.data;
};

export default {
	getAuthData,
	refreshToken,
};
