import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import { Contactgegeven } from '../../api/models';
import { mapContactsToTableData } from '../../utils/mapContactsToTableData';
import DataTable, { TableIconSettings } from '../../../../global/components/DataTable/DataTable';

export interface ContactsTableProps {
	contacts: Contactgegeven[];
	onAddNew: () => void;
	onRowClick: (contact: Contactgegeven) => void;
	onDeleteIconClick: (id: string) => void;
	selectedIds?: string[];
}

const PAGE_SIZE = 5;

const ContactsTable: FC<ContactsTableProps> = ({ contacts, onAddNew, onRowClick, onDeleteIconClick, selectedIds }) => {
	const { t } = useTranslation();

	const handleRowClick = (id: string) => {
		const contact = contacts.find(c => c.id === id);
		if (contact) {
			onRowClick(contact);
		}
	};

	const addNewContactButton = (
		<ZdsButton data-testid="addNewContactButton" onZdsClick={onAddNew}>
			<ZdsIcon library="fa-regular" name="plus" size="xs" />
			{t('global:common.addNew')}
		</ZdsButton>
	);

	return (
		<DataTable
			data={mapContactsToTableData(contacts, t)}
			pageSizes={[PAGE_SIZE]}
			header={{ title: t('relatie:relationInformationPage.tabs.contact') ?? '', endContent: addNewContactButton }}
			tableTestId="contactsTable"
			onRowClick={handleRowClick}
			iconOptions={{
				...TableIconSettings.delete,
				onIconClick: onDeleteIconClick,
				iconHeader: <ZdsIcon name="sliders-up" library="fa-regular" size="md" className="zds-padding-x-2" />,
			}}
			selectedIds={selectedIds}
		/>
	);
};

export default ContactsTable;
