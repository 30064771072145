import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsDivider, ZdsForm, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { Language } from '../../../api/models';
import LanguageSelect, { LanguageData } from '../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import FormImage from './FormImage/FormImage';
import { UploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { useLanguageSelect } from '../../../hooks/useLanguageSelect/useLanguageSelect';
import { useCommunityCreateFormSchema } from '../../../hooks/form/schema/create/community/useCommunityCreateFormSchema';
import styles from './GeneralInfo.module.scss';

export type GeneralInfoSubmitData = {
	formSubmitData: FormSubmitData;
	backgroundPhoto: UploadFileData | null;
	profilePhoto: UploadFileData | null;
};

export interface GeneralInfoProps {
	initData: GeneralInfoSubmitData | null;
	onSave: (data: GeneralInfoSubmitData) => void;
	onBack: (data: GeneralInfoSubmitData) => void;
}

const formId = 'createCommunityGeneralInfoForm';
const MAX_FILE_SIZE = 1024;

const GeneralInfo: FC<GeneralInfoProps> = ({ initData, onSave, onBack }) => {
	const { t, i18n } = useTranslation();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [backgroundPhoto, setBackgroundPhoto] = useState<UploadFileData | null>(initData?.backgroundPhoto ?? null);
	const [profilePhoto, setProfilePhoto] = useState<UploadFileData | null>(initData?.profilePhoto ?? null);
	const dateFormat = getDateFormat(i18n.language);
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();

	const formSchema = useCommunityCreateFormSchema({
		data: formData,
		initData: initData?.formSubmitData,
		language: selectedLanguage,
		selectedFields: ['toelichting'],
	});

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async (cb: (data: GeneralInfoSubmitData) => void) => {
		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		cb({ formSubmitData, backgroundPhoto, profilePhoto });
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
	};

	const handleSubmitClick = () => {
		setTimeout(() => {
			(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
		}, 0);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	const content = () =>
		formSchema &&
		formData && (
			<div className="zds-flex zds-flex-col zds-justify-content-between zds-flex-1 zds-gap-6">
				<div className="zds-flex zds-flex-col zds-gap-6 zds-flex-1">
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
					<div className={styles.formContainer}>
						<FormImage
							label={t('community:communityInformationPage.backgroundPicture')}
							text={t('community:createCommunityPage.form.backgroundPictureExplanation') ?? ''}
							type="background"
							layout="vertical"
							initImage={backgroundPhoto}
							onChange={setBackgroundPhoto}
							maxFilesSize={MAX_FILE_SIZE}
						/>
						<FormImage
							label={t('community:communityInformationPage.profilePicture')}
							text={t('community:createCommunityPage.form.profilePictureExplanation') ?? ''}
							type="profile"
							layout="horizontal"
							onChange={setProfilePhoto}
							initImage={profilePhoto}
							maxFilesSize={MAX_FILE_SIZE}
						/>
						<ZdsForm onZdsSubmit={() => handleSubmit(onSave)} id={formId}>
							<div className={styles.formInputsContainer}>{renderFormControl('toelichting', 'large')}</div>
						</ZdsForm>
					</div>
				</div>
				<div className="zds-flex zds-flex-col zds-gap-6">
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
					<div className="zds-flex zds-justify-content-between">
						<ZdsButton preset="neutral" onZdsClick={() => handleSubmit(onBack)}>
							<ZdsIcon name="arrow-left" library="fa-solid" />
							{t('global:common.back')}
						</ZdsButton>
						<ZdsButton onZdsClick={handleSubmitClick}>
							{t('global:common.next')}
							<ZdsIcon name="arrow-right" library="fa-solid" />
						</ZdsButton>
					</div>
				</div>
			</div>
		);

	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			<div className="zds-flex zds-justify-content-between zds-items-center  zds-margin-bottom-8">
				<div>
					<div className="zds-headline-lg">{t('community:createCommunityPage.stepper.generalInfo')}</div>
					<div className="zds-muted zds-margin-top-1">{t('community:createCommunityPage.generalInfoSubtitle')}</div>
				</div>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			{content()}
		</div>
	);
};

export default GeneralInfo;
