import { FC, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';
import {
	ZdsCheckbox,
	ZdsClickable,
	ZdsContextMenu,
	ZdsDataTableCell,
	ZdsIcon,
	ZdsLink,
} from '@zig-design-system/ui-components-react';
import { ZdsLinkCustomEvent, ZdsCheckboxCustomEvent } from '@zig-design-system/ui-components';

import { TableData } from '../DataTable';
import { debounce } from '../../../utils/debounce/debounce';
import styles from './DataTableContentRow.module.scss';

export interface TableCell {
	mainText: string;
	subText?: string;
	nowrap?: boolean;
	onClick?: () => void;
	infoContent?: ReactNode;
	endElement?: ReactNode;
	checkbox?: { checked: boolean };
}

export interface DataTableContentCellProps {
	cellId: string;
	header: TableData['header'];
	cellContent: TableCell;
	cellInd: number;
	onChange?: (value: boolean) => void;
}

const DataTableContentCell: FC<DataTableContentCellProps> = ({ cellContent, cellInd, cellId, header, onChange }) => {
	const [showInfo, setShowInfo] = useState(false);
	const infoIconRef = useRef(null);
	const contextMenuRef = useRef(null);

	const handleCellContentClick = (event: ZdsLinkCustomEvent<MouseEvent>, cell: TableCell) => {
		event.stopPropagation();
		cell.onClick?.();
	};

	const debouncedSetShowInfo = debounce(setShowInfo, 200);

	const handleShowInfo = () => {
		debouncedSetShowInfo(true);
	};
	const handleHideInfo = () => {
		debouncedSetShowInfo(false);
	};

	const CellContentWrapper = cellContent.onClick ? ZdsLink : 'div';
	const width = header.width?.[cellInd];
	const infoIconId = `infoIcon${cellId}`;

	return (
		<ZdsDataTableCell>
			<CellContentWrapper
				className={classNames('zds-flex zds-items-center zds-gap-3', {
					'zds-bold': cellContent.subText,
					'zds-justify-content-center': cellContent.checkbox,
					'zds-justify-content-between': !cellContent.checkbox,
				})}
				{...(cellContent.onClick && {
					onZdsClick: e => handleCellContentClick(e, cellContent),
				})}
				{...(width && { style: { width: `${width}px`, overflow: 'visible' } })}
			>
				{cellContent.mainText && (
					<div
						className={classNames('zds-flex-1', {
							[styles.nowrap]: cellContent.nowrap,
						})}
					>
						{cellContent.mainText}
						<div className="zds-muted">{cellContent.subText}</div>
					</div>
				)}
				{cellContent.endElement}
				{cellContent.checkbox && (
					<ZdsCheckbox
						checked={!!cellContent.checkbox.checked}
						onZdsInputDidUpdate={(e: ZdsCheckboxCustomEvent<{ shouldRenderInForm: boolean }>) =>
							onChange?.(e.detail.shouldRenderInForm)
						}
					/>
				)}
				{cellContent.infoContent && (
					<div className="zds-relative">
						<ZdsIcon
							name="circle-info"
							library="fa-light"
							size="sm"
							id={infoIconId}
							ref={infoIconRef}
							className="zds-color-primary"
							onMouseEnter={handleShowInfo}
							onMouseLeave={handleHideInfo}
						></ZdsIcon>
						<ZdsContextMenu
							ref={contextMenuRef}
							open={showInfo}
							target={`#${infoIconId}`}
							position="end"
							onMouseEnter={handleShowInfo}
							onMouseLeave={handleHideInfo}
							className={styles.infoContextMenu}
						>
							<ZdsClickable className="zds-flex zds-padding-3 zds-relative" onZdsClick={e => e.stopPropagation()}>
								{cellContent.infoContent}
							</ZdsClickable>
						</ZdsContextMenu>
					</div>
				)}
			</CellContentWrapper>
		</ZdsDataTableCell>
	);
};

export default DataTableContentCell;
