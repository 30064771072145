import { createSlice } from '@reduxjs/toolkit';

import { getOrganisatiesData, OrganisatieDataState } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: OrganisatieDataState = { itemsNum: 0, organisatieData: null, loading: false, error: null };

export const realtieDataSlice = createSlice({
	name: 'organisatieData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getOrganisatiesData.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.organisatieData = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getOrganisatiesData.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.organisatieData = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.organisatieData);
			})
			.addCase(getOrganisatiesData.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const organisatieDataReducer = realtieDataSlice.reducer;
