import classNames from 'classnames';
import Pane from '../../../../global/components/Pane/Pane';
import styles from './ContentPane.module.scss';

interface ContentPaneProps {
	children: React.ReactNode;
}

const ContentPane = ({ children }: ContentPaneProps) => {
	return <Pane className={classNames('zds-body', styles.contentPane)}>{children}</Pane>;
};

export default ContentPane;
