import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsDivider, ZdsForm, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { Language } from '../../../api/models';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import RelatiesAdvancedSelect from '../../../components/RelatiesAdvancedSelect/RelatiesAdvancedSelect';
import ClustersAdvancedSelect from '../../../components/ClustersAdvancedSelect/ClustersAdvancedSelect';
import Spinner from '../../../../../global/components/Spinner/Spinner';
import ErrorMessage from '../../../../../global/components/ErrorMessage/ErrorMessage';
import { AdvancedSelectItem } from '../../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { useCommunityCreateFormSchema } from '../../../hooks/form/schema/create/community/useCommunityCreateFormSchema';
import styles from './SetAudience.module.scss';

export type SetAudienceSubmitData = {
	formSubmitData: FormSubmitData;
	selectedMembers: AdvancedSelectItem[];
	selectedClusters: AdvancedSelectItem[];
};

export interface SetAudienceProps {
	initData: SetAudienceSubmitData | null;
	loading?: boolean;
	errorMessage?: string;
	selectedManagers?: AdvancedSelectItem[];
	onSave: (data: SetAudienceSubmitData) => void;
	onBack: (data: SetAudienceSubmitData) => void;
}

const formId = 'createCommunitySetAudienceForm';

const SetAudience: FC<SetAudienceProps> = ({ initData, loading, errorMessage, selectedManagers, onSave, onBack }) => {
	const { t, i18n } = useTranslation();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [selectedMembers, setSelectedMembers] = useState<AdvancedSelectItem[]>(initData?.selectedMembers ?? []);
	const [selectedClusters, setSelectedClusters] = useState<AdvancedSelectItem[]>(initData?.selectedClusters ?? []);
	const [selectedClustersValidationError, setSelectedClustersValidationError] = useState('');
	const dateFormat = getDateFormat(i18n.language);

	const formSchema = useCommunityCreateFormSchema({
		data: formData,
		initData: initData?.formSubmitData,
		selectedFields: ['toewijzing'],
	});

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	const validateClusters = () => {
		if (!selectedClusters.length) {
			setSelectedClustersValidationError('community:createCommunityPage.form.communityClusterRequiredErrorMessage');
			return false;
		}
		return true;
	};

	const handleSelectedMembersChange = (newSelectedMembers: AdvancedSelectItem[]) => {
		setSelectedMembers(newSelectedMembers);
	};

	const handleSelectedClustersChange = (newSelectedClusters: AdvancedSelectItem[]) => {
		setSelectedClusters(newSelectedClusters);
		if (newSelectedClusters.length) {
			setSelectedClustersValidationError('');
		}
	};

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async () => {
		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (!validateClusters()) return;

		setSelectedClustersValidationError('');
		onSave({ formSubmitData, selectedMembers, selectedClusters });
	};

	const handleBack = async () => {
		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		setSelectedClustersValidationError('');
		onBack({ formSubmitData, selectedMembers, selectedClusters });
	};

	const handleSubmitClick = () => {
		setTimeout(() => {
			validateClusters();
			(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
		}, 0);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
	});

	const content = () =>
		formSchema &&
		formData && (
			<div className="zds-flex zds-flex-col zds-flex-1 zds-gap-6 zds-relative">
				<div className="zds-flex zds-flex-col zds-gap-6 zds-flex-1">
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
					<div className={styles.formContainer}>
						<ZdsForm onZdsSubmit={handleSubmit} id={formId}>
							<div className={styles.formInputsContainer}>{renderFormControl('toewijzing', 'large')}</div>
						</ZdsForm>
						<div className="zds-flex zds-gap-3">
							<div className={styles.label}>{t('community:createCommunityPage.form.cluster(s)')}</div>
							<ClustersAdvancedSelect
								selectedValues={selectedClusters}
								onChange={handleSelectedClustersChange}
								validationError={selectedClustersValidationError}
								explanationText={t('community:createCommunityPage.form.clustersExplanation') ?? ''}
								placeholder={t('community:createCommunityPage.form.clustersPlaceholder') ?? ''}
							/>
						</div>
						<div className="zds-flex zds-gap-3">
							<div className={styles.label}>{t('community:createCommunityPage.form.member(s)')}</div>
							<RelatiesAdvancedSelect
								selectedValues={selectedMembers}
								ignoreValues={selectedManagers}
								onChange={handleSelectedMembersChange}
								explanationText={t('community:createCommunityPage.form.membersExplanation') ?? ''}
								placeholder={t('community:createCommunityPage.form.communityMemberPlaceholder') ?? ''}
							/>
						</div>
					</div>
				</div>
				{errorMessage && <ErrorMessage message={errorMessage} />}
				<div className={styles.spinnerContainer}>{loading && <Spinner />}</div>
				<div className="zds-flex zds-flex-col zds-gap-6">
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
					<div className="zds-flex zds-justify-content-between">
						<ZdsButton preset="neutral" onZdsClick={handleBack} disabled={loading}>
							<ZdsIcon name="arrow-left" library="fa-solid" />
							{t('global:common.back')}
						</ZdsButton>
						<ZdsButton onZdsClick={handleSubmitClick} disabled={loading}>
							{t('global:common.finish')}
							<ZdsIcon name="arrow-right" library="fa-solid" />
						</ZdsButton>
					</div>
				</div>
			</div>
		);

	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			<div className="zds-flex zds-justify-content-between zds-items-center  zds-margin-bottom-8">
				<div>
					<div className="zds-headline-lg">{t('community:createCommunityPage.stepper.setAudience')}</div>
					<div className="zds-muted zds-margin-top-1">{t('community:createCommunityPage.setAudienceSubtitle')}</div>
				</div>
			</div>
			{content()}
		</div>
	);
};

export default SetAudience;
