import { FC, useCallback, useState } from 'react';
import { ZdsSelect } from '@zig-design-system/ui-components-react';
import { ZdsSelectCustomEvent } from '@zig-design-system/ui-components';
import { useTranslation } from 'react-i18next';

import { SideMenuInfo } from '../../components/SideMenu/SideMenu';
import PageLayout from '../../components/PageLayout/PageLayout';
import Pane from '../../components/Pane/Pane';
import { DEFAULT_LANGUAGE } from '../../i18n/config';
import styles from './Preferences.module.scss';

const languages = [
	{
		locale: 'nl',
		label: 'global:languages.dutch',
	},
	{
		locale: 'en',
		label: 'global:languages.english',
	},
];

interface PreferencesProps {
	sideMenuInfo: SideMenuInfo;
}

const Preferences: FC<PreferencesProps> = ({ sideMenuInfo }) => {
	const { t, i18n } = useTranslation();
	const browserLanguage = languages.find(({ locale }) => i18n.language?.includes(locale));
	const initLanguage = browserLanguage ? browserLanguage.locale : DEFAULT_LANGUAGE;
	const [language, setLanguage] = useState(initLanguage);

	const handleLangChange = useCallback((event: ZdsSelectCustomEvent<string>) => {
		const inputElement = event.target;
		const lang = inputElement?.value;

		if (lang) {
			setLanguage(lang);
			void i18n.changeLanguage(lang);
		}
	}, []);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<Pane className={styles.contentPane}>
				<div className="zds-headline-xxl">{t('account.preferences')}</div>
				<div className="zds-margin-top-6">
					<ZdsSelect
						data-testid="preferencesLanguageSelector"
						label={t('global:common.language') ?? ''}
						value={language}
						name="language"
						onZdsInputDidUpdate={handleLangChange}
					>
						{languages.map(lang => (
							<option key={lang.label} value={lang.locale} label={t(lang.label) ?? ''} />
						))}
					</ZdsSelect>
				</div>
			</Pane>
		</PageLayout>
	);
};

export default Preferences;
