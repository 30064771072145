import { SetStateAction } from 'react';
import { Language } from '../../../apps/communitybeheer/api/models';
import { FormControl, FormData, ZdsInput } from '../../components/Form/Form.types';
import { getFormInputValue } from './getFormInputValue';

export const updateFormData = ({
	event,
	formData,
	inputData,
	setFormData,
	language = Language.Dutch,
	modifyFormData,
}: {
	event: CustomEvent;
	formData: FormData | null;
	inputData: FormControl;
	setFormData: (value: SetStateAction<FormData | null>) => void;
	language?: Language;
	modifyFormData?: (fd: FormData) => void;
}) => {
	const value = getFormInputValue(event, inputData);

	const inputLanguageData = inputData.control.props.languageData;

	const currentFormDataInputValue = inputLanguageData
		? formData?.[inputData.name].languageData?.[language]
		: formData?.[inputData.name]?.value;

	if (currentFormDataInputValue === value) return;

	if (formData)
		setFormData(oldFormData => {
			const newFormData: FormData = {
				...oldFormData,
				[inputData.name]: {
					value,
					type: inputData.control.type as ZdsInput,
					...(inputLanguageData && { languageData: { ...inputLanguageData, [language]: value } }),
				},
			};

			if (modifyFormData) {
				modifyFormData(newFormData);
			}

			return newFormData;
		});
};
