import { TFunction } from 'i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';

const SOORT = 'REC';

export const generateCreateRechtsPersoonFormSchema = (t: TFunction): FormSchema => {
	const schema: FormSchema = {
		title: t('global:common.legalInformation') ?? '',
		formControls: [
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: SOORT,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIESOORT',
						code: SOORT,
						disabled: true,
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIEDETAILSOORT',
						parentSoort: SOORT,
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'organisatievorm',
				submitPath: 'organisatievorm.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.organisatievorm'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'ORGANISATIEVORM',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						required: true,
					},
				},
			},
			{
				name: 'kvkNummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.kvkNummer'),
					},
				},
			},
			{
				name: 'btwNummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.btwNummer'),
					},
				},
			},
			{
				name: 'globaalLocatienummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.globaalLocatienummer'),
					},
				},
			},
		],
		layout: {
			columns: [
				['code', 'naam', 'organisatievorm'],
				['kvkNummer', 'globaalLocatienummer', 'btwNummer'],
			],
		},
	};

	return schema;
};
