import { FC, useEffect } from 'react';
import { t } from 'i18next';

import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Tabs from '../../../../global/components/Tabs/Tabs';
import Pane from '../../../../global/components/Pane/Pane';
import AgreementsTable from '../../components/AgreementsTable/AgreementsTable';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getOvereenkomsten, selectOvereenkomstenData } from '../../redux/overeenkomstenData';
import { useMapOvereenkomstenToTableData } from '../../hooks/table/useMapOvereenkomstenToTableData/useMapOvereenkomstenToTableData';

const PAGE_SIZE = 20;

const Agreements: FC = () => {
	const dispatch = useAppDispatch();
	const { overeenkomsten, error, loading, itemsNum } = useAppSelector(selectOvereenkomstenData);

	const handleGetOvereenkomsten = (page: number, clear?: boolean) => {
		dispatch(getOvereenkomsten({ offset: page, limit: PAGE_SIZE, clear }));
	};

	useEffect(() => {
		handleGetOvereenkomsten(0, true);
	}, []);

	const tableData = useMapOvereenkomstenToTableData(overeenkomsten);

	const AgreementsContent = (
		<>
			{loading && <h3 data-testid="overeenkomstenListLoading">{t('global:common.loading')}...</h3>}
			{tableData && (
				<AgreementsTable
					tableData={tableData}
					onPageChange={handleGetOvereenkomsten}
					pageSize={PAGE_SIZE}
					itemsNum={itemsNum}
				/>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: AgreementsContent,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.porfolioManagement'), link: '/vastgoedbeheer' },
		{ label: t('vastgoed:agreementsPage.header.title') },
	];

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('vastgoed:agreementsPage.header.title')}
				iconName="house-user"
				iconLibrary="fa-light"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className="zds-flex-1">
				<Tabs tabsData={tabsData} />
			</Pane>
		</PageLayout>
	);
};

export default Agreements;
