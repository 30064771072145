import api from '../../../axiosConfig';
import { ListResponse, getListResponse } from '../../../global/api';
import { FormSubmitData } from '../../../global/components/Form/Form.types';
import { getSearchFilterData } from '../../../global/utils/api/getSearchFilterData';
import { FilterMap } from '../../../global/utils/api/parseFilters.types';
import { BerichtFilters } from '../redux/berichten';
import { RolRelatieSubmitData } from '../utils/form/getRolRelatieSubmitData';
import {
	Bericht,
	BerichtEmotie,
	Community,
	CommunityCategorie,
	CreateBerichtEmotiePayload,
	CreateBerichtPayload,
	Informatieobject,
	NatuurlijkPersoonInfo,
	RolRelatiePayload,
	UpdateBerichtPayload,
} from './models';
import { SortableOption } from '../../../global/components/DataTable/DataTable';
import { getSortingData } from '../../../global/utils/api/getSortingData';

export const Endpoints = {
	communities: 'bco/v1/communities',
	communitycategorieen: 'bco/v1/communitycategorieen',
	berichten: 'bco/v1/berichten',
	berichtemoties: 'bco/v1/berichtemoties',
	relaties: 'relaties/v1/relaties',
};

const CommunitiesFilterMap: FilterMap = {
	naam: 'contains',
	aantalBerichten: 'compare',
	aantalOngepast: 'compare',
};

export const getImage = async (path?: string) => {
	if (!path) return path;

	const image = await api.get(path, { responseType: 'arraybuffer' });
	const data = new Uint8Array(image.data);
	const raw = data.reduce(function (d, byte) {
		return d + String.fromCharCode(byte);
	}, '');
	const base64 = btoa(raw);

	return `data:image/png;base64, ${base64}`; // TMP
};

export const getBijlagen = async <T extends { bijlagen?: Informatieobject[] }>(obj: T): Promise<Informatieobject[]> => {
	const bijlagen = await Promise.all(
		obj.bijlagen?.map(async o => {
			const bestand = await getImage(o?.bestandslocatie);

			const newBijlage = { ...o, bestand };
			delete newBijlage.bestandslocatie;

			return newBijlage;
		}) ?? []
	);

	return bijlagen;
};

const getCommunities = async ({
	term,
	offset = 0,
	limit = 100,
	searchData = {},
	sortData = {},
}: {
	term?: string;
	offset?: number;
	limit?: number;
	searchData?: {
		[key: string]: string;
	};
	sortData?: {
		[p: string]: SortableOption;
	};
}): Promise<ListResponse<Community>> => {
	const searchFilterData = getSearchFilterData({ searchData, filterMap: CommunitiesFilterMap })
		.filter(Boolean)
		.join(' and ');

	const parsedSortData = getSortingData({ sortData })[0];
	const response = await api.get<Community[]>(Endpoints.communities, {
		params: {
			naam: term,
			$filter: searchFilterData,
			pagina: offset,
			$orderby: parsedSortData,
		},
		paramsSerializer: { indexes: null },
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getCommunity = async (id: string): Promise<Community> => {
	const response = await api.get<Community>(`${Endpoints.communities}/${id}`, {
		headers: { 'x-parameters-taal': 'all' },
	});

	response.data.bijlagen = await getBijlagen(response.data);

	return response.data;
};

export type UpdateCommunityBijlagen = {
	soort: {
		code: string;
	};
	detailSoort: { code: string };
	bestand: string | undefined;
}[];

export interface UpdateCommunityPayload {
	formData: FormSubmitData;
	rolRelatiesSubmitData: RolRelatieSubmitData[];
	bijlagen: UpdateCommunityBijlagen;
}

const updateCommunity = async (
	id: string,
	{ formData, rolRelatiesSubmitData, bijlagen }: UpdateCommunityPayload
): Promise<Community> => {
	const finalPayload = {
		...formData,
		rolRelaties: rolRelatiesSubmitData,
		bijlagen,
	};

	const response = await api.patch<Community>(`${Endpoints.communities}/${id}`, finalPayload, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return response.data;
};

const getCommunityCategory = async (id: string): Promise<CommunityCategorie> => {
	const response = await api.get<CommunityCategorie>(`${Endpoints.communitycategorieen}/${id}`, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return response.data;
};

const updateCommunityCategory = async (id: string, body: FormSubmitData): Promise<CommunityCategorie> => {
	const response = await api.patch<CommunityCategorie>(`${Endpoints.communitycategorieen}/${id}`, body, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return response.data;
};

const getNumberOfActiveCommunityCategories = async (
	communityId: string
): Promise<{ aantalCategorieenActief: number }> => {
	const response = await api.get<{ aantalCategorieenActief: number }>(`${Endpoints.communities}/${communityId}`, {
		params: { $fields: ['aantalCategorieenActief'] },
	});

	return response.data;
};

const getCommunityCategories = async (
	communityId: string,
	offset = 0,
	limit = 20,
	options?: { status?: string; infDomain?: boolean }
): Promise<ListResponse<CommunityCategorie>> => {
	const $fields = options?.infDomain ? null : ['default', 'aantalBerichten'];
	const statusFilter = options?.status ? ` and status eq ${options?.status}` : '';

	const response = await api.get<CommunityCategorie[]>(Endpoints.communitycategorieen, {
		params: {
			$filter: `community[id] eq ${communityId}${statusFilter}`,
			pagina: offset,
			$fields,
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getBerichten = async (
	communityId: string,
	offset = 0,
	limit = 10,
	filters: BerichtFilters
): Promise<{ berichten: Bericht[]; totalItems: number }> => {
	const { showReported, showNIE, showPRI } = filters;
	const reportedFilter = showReported ? ' and aantalOngepast gt 0' : '';

	const soortFilterValues = [];
	if (showNIE) soortFilterValues.push('NIE');
	if (showPRI) soortFilterValues.push('PRI');
	const soortFilter = !soortFilterValues.length
		? ' and soort nin NIE,PRI'
		: ` and soort in ${soortFilterValues.join(',')}`;

	const bufferSeconds = 5;
	const now = new Date();
	const publishedUntilTime = new Date(now.getTime() + bufferSeconds * 1000).toISOString();

	const response = await api.get<Bericht[]>(Endpoints.berichten, {
		params: {
			$filter: `community[id] eq ${communityId} and detailSoort eq INI and status nin VBO,VER and publicatiedatum lte ${publishedUntilTime}${reportedFilter}${soortFilter}`,
			pagina: offset,
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
			'x-parameters-taal': 'all',
		},
	});

	const berichten = await Promise.all(
		// Map bijlagen to contain bestand so the Community components can use it
		response.data.map(async bericht => {
			bericht.bijlagen = await getBijlagen(bericht);

			return bericht;
		})
	);

	return { berichten, totalItems: +response.headers['x-parameters-aantal'] };
};

const getBericht = async (berichtId: string, language = 'all'): Promise<Bericht> => {
	const { data: bericht } = await api.get<Bericht>(`${Endpoints.berichten}/${berichtId}`, {
		headers: { 'x-parameters-taal': language },
	});
	bericht.bijlagen = await getBijlagen(bericht);

	if (bericht.reacties) {
		for (const reactie of bericht.reacties) {
			reactie.bijlagen = await getBijlagen(reactie);
		}
	}

	return bericht;
};

const getBerichtEmoties = async (berichtId: string): Promise<BerichtEmotie[]> => {
	const { data: bericht } = await api.get<BerichtEmotie[]>(Endpoints.berichtemoties, {
		params: {
			$filter: `bericht[id] eq ${berichtId}`,
		},
	});

	return bericht;
};

export const getBijlagenPayload = (
	fileData: { base64: string | null; name: string | null }[],
	soort = 'FOT',
	detailSoort?: string
) => {
	return fileData.map(fd => getBijlagePayload(fd, soort, detailSoort)).filter(d => d?.bestand);
};

export const getBijlagePayload = (
	fileData: { base64: string | null; name: string | null },
	soort = 'FOT',
	detailSoort?: string
) => {
	return {
		soort: {
			code: soort,
		},
		bestand: fileData.base64 ?? '',
		bestandsnaam: fileData.name,
		...(detailSoort && { detailSoort: { code: detailSoort } }),
	};
};

const createBericht = async ({
	soort = 'PRI',
	detailSoort,
	reageeroptie,
	communityId,
	categoryId,
	bovenliggendBerichtId,
	value,
	fileData,
	author,
}: {
	soort?: string;
	detailSoort: string;
	reageeroptie?: string;
	communityId: string;
	categoryId?: string;
	bovenliggendBerichtId?: string;
	value: string;
	author: string;
	fileData: {
		base64: string | null;
		name: string | null;
	};
}): Promise<Bericht> => {
	const payload: CreateBerichtPayload = {
		soort: {
			code: soort,
		},
		detailSoort: {
			code: detailSoort,
		},
		status: {
			code: 'PUB',
		},
		beschrijving: value,
		community: {
			id: communityId,
		},
		rolRelaties: [
			{
				soort: { code: 'AUT' },
				relatie: { id: author },
			},
		],
	};

	if (categoryId) {
		payload.categorie = { id: categoryId };
	}

	if (reageeroptie) {
		payload.reageeroptie = {
			code: reageeroptie,
		};
	}

	if (bovenliggendBerichtId) {
		payload.bovenliggendBericht = {
			id: bovenliggendBerichtId,
		};
	}

	payload.bijlagen = getBijlagenPayload([fileData]);

	const { data } = await api.post<Bericht>(`${Endpoints.berichten}`, payload);

	return getBericht(data.id);
};

const createInitialBericht = async ({
	communityId,
	categoryId,
	value,
	fileData,
	author,
}: {
	communityId: string;
	categoryId?: string;
	value: string;
	author: string;
	fileData: {
		base64: string | null;
		name: string | null;
	};
}): Promise<Bericht> => {
	return createBericht({ detailSoort: 'INI', reageeroptie: 'OPE', communityId, categoryId, value, fileData, author });
};

const createReactieBericht = async ({
	communityId,
	bovenliggendBerichtId,
	value,
	fileData,
	author,
	soort,
}: {
	communityId: string;
	bovenliggendBerichtId: string;
	value: string;
	author: string;
	soort?: string;
	fileData: {
		base64: string | null;
		name: string | null;
	};
}): Promise<Bericht> => {
	return createBericht({
		detailSoort: 'REA',
		bovenliggendBerichtId,
		communityId,
		value,
		fileData,
		author,
		soort,
	});
};

const updateBericht = async ({
	id,
	value,
	fileData,
}: {
	id: string;
	value: string;
	fileData: {
		base64: string | null;
		name: string | null;
	} | null;
}): Promise<Bericht> => {
	const payload: UpdateBerichtPayload = {
		beschrijving: value,
	};

	if (fileData) payload.bijlagen = getBijlagenPayload([fileData]);

	await api.patch<Bericht>(`${Endpoints.berichten}/${id}`, payload);

	return getBericht(id);
};

const deleteBericht = async (id: string): Promise<void> => {
	return api.delete(`${Endpoints.berichten}/${id}`);
};

const createBerichtEmotie = async (body: CreateBerichtEmotiePayload): Promise<BerichtEmotie> => {
	const { data: berichtEmotie } = await api.post<BerichtEmotie>(Endpoints.berichtemoties, body);

	return berichtEmotie;
};

const deleteBerichtEmotie = async (berichtEmotieId: string): Promise<boolean> => {
	await api.delete<void>(`${Endpoints.berichtemoties}/${berichtEmotieId}`);

	return true;
};

const getNatuurlijkPersoonRelaties = async (
	term?: string,
	offset = 0,
	limit = 20
): Promise<NatuurlijkPersoonInfo[]> => {
	const filterRelatieNaam = term ? ` and naam contains ${term}` : '';

	const response = await api.get<NatuurlijkPersoonInfo[]>(Endpoints.relaties, {
		params: {
			pagina: offset,
			$filter: `soort eq NAT${filterRelatieNaam}`,
			$fields: ['naam', 'id', 'soort'],
			expand: '',
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return response.data;
};

const createCommunityRolRelatie = async (communityId: string, body: RolRelatiePayload): Promise<void> => {
	await api.post<void>(`${Endpoints.communities}/${communityId}/rolrelaties`, body);
};

const deleteCommunityRolRelatie = async (communityId: string, rolRelatieId: string): Promise<void> => {
	await api.delete<void>(`${Endpoints.communities}/${communityId}/rolrelaties/${rolRelatieId}`);
};

const deleteCommunityCategoryRolRelatie = async (communityCategoryId: string, rolRelatieId: string): Promise<void> => {
	await api.delete<void>(`${Endpoints.communitycategorieen}/${communityCategoryId}/rolrelaties/${rolRelatieId}`);
};

const deleteInappropriateReports = async (berichtId: string): Promise<void> => {
	await api.delete<void>(`${Endpoints.berichten}/${berichtId}/berichtemoties?soort=ONG`);
};

const createCommunityCategoryRolRelatie = async (
	communityCategoryId: string,
	body: RolRelatiePayload
): Promise<void> => {
	await api.post<void>(`${Endpoints.communitycategorieen}/${communityCategoryId}/rolrelaties`, body);
};

const createNieuwsbericht = async ({
	payload,
	fileData,
}: {
	payload: FormSubmitData;
	fileData: {
		base64: string | null;
		name: string | null;
	}[];
}): Promise<Bericht> => {
	const finalPayload = { ...payload, bijlagen: getBijlagenPayload(fileData) };

	const { data } = await api.post<Bericht>(`${Endpoints.berichten}`, finalPayload, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return getBericht(data.id);
};

const updateNieuwsbericht = async ({
	id,
	payload,
	fileData,
}: {
	id: string;
	payload: FormSubmitData;
	fileData: {
		base64: string | null;
		name: string | null;
	}[];
}): Promise<Bericht> => {
	const finalPayload = { ...payload, bijlagen: getBijlagenPayload(fileData) };

	await api.patch(`${Endpoints.berichten}/${id}`, finalPayload, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return getBericht(id);
};

export interface CreateCommunityPayload {
	payload: FormSubmitData;
	clustersSubmitData:
		| {
				id: string;
		  }[]
		| null;
	rolRelatiesSubmitData: RolRelatieSubmitData[];
	fileData: ({
		base64: string | null;
		name: string | null;
	} | null)[];
}

const createCommunity = async ({
	payload,
	fileData,
	clustersSubmitData,
	rolRelatiesSubmitData,
}: CreateCommunityPayload): Promise<Community> => {
	const [bgFileData, profileFileData] = fileData;
	const bgPayload = bgFileData ? getBijlagePayload(bgFileData, 'FOT', 'ACH') : null;
	const profilePayload = profileFileData ? getBijlagePayload(profileFileData, 'FOT', 'PRO') : null;

	const finalPayload = {
		...payload,
		clusters: clustersSubmitData,
		rolRelaties: rolRelatiesSubmitData,
		bijlagen: [bgPayload, profilePayload].filter(Boolean),
	};

	const { data } = await api.post<Community>(`${Endpoints.communities}`, finalPayload, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return getCommunity(data.id);
};

export interface CreateCommunityCategoryPayload {
	payload: FormSubmitData;
	rolRelatiesSubmitData: {
		soort: {
			code: string;
		};
		relatie: {
			id: string;
		};
	}[];
}

const createCommunityCategory = async ({
	payload,
	rolRelatiesSubmitData,
}: CreateCommunityCategoryPayload): Promise<CommunityCategorie> => {
	const finalPayload = {
		...payload,
		rolRelaties: rolRelatiesSubmitData,
	};

	const { data } = await api.post<CommunityCategorie>(`${Endpoints.communitycategorieen}`, finalPayload, {
		headers: { 'x-parameters-taal': 'all' },
	});

	return getCommunityCategory(data.id);
};

export default {
	getCommunities,
	getCommunity,
	updateCommunity,
	getCommunityCategories,
	getCommunityCategory,
	createCommunityCategory,
	updateCommunityCategory,
	getBerichten,
	getBericht,
	getBerichtEmoties,
	createInitialBericht,
	deleteBericht,
	updateBericht,
	createReactieBericht,
	createBerichtEmotie,
	deleteBerichtEmotie,
	getNatuurlijkPersoonRelaties,
	createCommunityRolRelatie,
	deleteCommunityRolRelatie,
	deleteCommunityCategoryRolRelatie,
	deleteInappropriateReports,
	createCommunityCategoryRolRelatie,
	createNieuwsbericht,
	updateNieuwsbericht,
	createCommunity,
	getNumberOfActiveCommunityCategories,
};
