import { FC, useEffect, useState } from 'react';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import BackButton from '../../../../global/components/BackButton/BackButton';
import { useOrganisatieSideMenu } from '../../hooks/useOrganisatieSideMenu';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SaveButton from '../../../../global/components/Form/SaveButton';
import Form from '../../../../global/components/Form/Form';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import {
	MedewerkerUpdateFormSchema,
	medewerkerUpdateFormSchema,
} from '../../hooks/form/schema/medewerkerUpdateFormSchema';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';
import SelectMedewerkerollenTable from '../../components/SelectMedewerkerollenTable/SelectMedewerkerollenTable';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getMedewerkerrollen, selectMedewerkerrollen } from '../../redux/medewerkerrollen';
import {
	getCurrentMedewerkerData,
	selectCurrentMedewerkerData,
	updateMedewerker,
} from '../../redux/currentMedewerkerData';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import Pane from '../../../../global/components/Pane/Pane';
import { SortableOption } from '../../../../global/components/DataTable/DataTable';

import styles from './MedewerkerInformation.module.scss';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';

export const PAGE_SIZE = 20;
export const PAGE_PARAM_NAME = 'page';

const getInitPage = (param: string | null) => {
	if (!param || isNaN(Number(param))) return 0;

	return Number(param);
};

const MedewerkerInformation: FC = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const sideMenuInfo = useOrganisatieSideMenu();
	const hasPermission = usePermissions();

	const params = useParams<{ id: string }>();

	const { currentMedewerkerData, loading, error, updating } = useAppSelector(selectCurrentMedewerkerData);
	const { medewerkerrollen, itemsNum } = useAppSelector(selectMedewerkerrollen);

	const [currentPage, setCurrentPage] = useState(getInitPage(searchParams.get(PAGE_PARAM_NAME)));
	const [formSchema, setFormSchema] = useState<MedewerkerUpdateFormSchema | null>(null);
	const [sortData, setSortData] = useState<{ [key: string]: SortableOption }>({});
	const [searchData, setSearchData] = useState<{ [key: string]: string }>({});
	const [selectedMedewerkerrollen, setSelectedMedewerkerrollen] = useState<string[]>([]);

	useEffect(() => {
		if (params.id) {
			void dispatch(getCurrentMedewerkerData(params.id));
		}
	}, [params]);

	useEffect(() => {
		setFormSchema(currentMedewerkerData ? medewerkerUpdateFormSchema(currentMedewerkerData, t, computeNaam) : null);

		setSelectedMedewerkerrollen(currentMedewerkerData?.rollen?.map(ar => ar.id) ?? []);
	}, [currentMedewerkerData]);

	useEffect(() => {
		handlePageChange(currentPage);
	}, []);

	useEffect(() => {
		handleGetMedewerkerrollen();
	}, [currentPage, searchData, sortData]);

	const handleGetMedewerkerrollen = () => {
		dispatch(getMedewerkerrollen({ offset: currentPage, limit: PAGE_SIZE, searchData, sortData }));
	};

	const computeNaam = () => {
		return `${currentMedewerkerData?.relatie.voornaam ?? ''} ${currentMedewerkerData?.relatie.tussenvoegsels ?? ''} ${
			currentMedewerkerData?.relatie.achternaam ?? ''
		}`;
	};

	const updateSearchParams = (newPage: number) => {
		if (newPage !== 0) {
			setSearchParams({ [PAGE_PARAM_NAME]: newPage.toString() });
			return;
		}
		searchParams.delete(PAGE_PARAM_NAME);
		setSearchParams(searchParams);
	};

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		updateSearchParams(newPage);
	};

	const handleSearchChange = (data: { [key: string]: string }) => {
		setSearchData(data);
	};

	const handleSortChange = (data: { columnId: string; sort: SortableOption }) => {
		setSortData(data.columnId ? { [data.columnId]: data.sort } : {});
	};

	const handleSelectionChange = (id: string, checked: boolean) => {
		if (checked && !selectedMedewerkerrollen.includes(id)) {
			return setSelectedMedewerkerrollen(old => Array.from(new Set([...old, id])));
		} else if (!checked && selectedMedewerkerrollen.includes(id)) {
			setSelectedMedewerkerrollen(old => old.filter(medewerkerrollenId => medewerkerrollenId !== id));
		}
	};

	const handleOnSubmit = (data: FormSubmitData) => {
		if (currentMedewerkerData?.id && !updating && hasPermission('ORG_BEH_MED')) {
			if (currentMedewerkerData?.id && !updating) {
				const updateData = {
					id: currentMedewerkerData.id,
					payload: data,
					medewerkerrollen: selectedMedewerkerrollen,
				};
				void dispatch(updateMedewerker(updateData));
			}
		}
	};

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:medewerkerInformationPage.firstHeader.title'), link: '/organisatiebeheer/medewerkers' },
		{ label: t('organisatie:medewerkerInformationPage.secondHeader.title') },
	];

	const saveButton = hasPermission('ORG_BEH_MED') && (
		<SaveButton
			dataTestId="updateMedewerkerDataSubmitButton"
			formId="employmentDetailsMedewerkerForm"
			disabled={updating}
		/>
	);

	const navigateToRelatiePage = () => {
		navigate(`/relatiebeheer/relatie/${currentMedewerkerData?.relatie.id}`);
	};

	return (
		<>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{!loading && currentMedewerkerData && formSchema && (
				<PageLayout sideMenuInfo={sideMenuInfo}>
					<div>
						<BackButton />
					</div>

					<PageHeader
						title={computeNaam()}
						iconName="message-lines"
						iconLibrary="fa-regular"
						breadcrumbs={breadcrumbs}
						contentEnd={saveButton}
					/>

					<Pane>
						<div data-testid="updateMedewerkerForm">
							<div className="zds-headline-xl">{t('global:common.personalDetails')}</div>
							<div className={`${styles.personalDetailsFormContainer} zds-margin-bottom-8`}>
								<Form
									schema={formSchema.personalDetailsSchema}
									onSubmit={handleOnSubmit}
									formId="personalDetailsMedewerkerForm"
								/>
								<ZdsButton className="zds-margin-bottom-12" variant="outlined" onZdsClick={navigateToRelatiePage}>
									{t('organisatie:medewerkerInformationPage.button.text')}
									<ZdsIcon size="sm" name="arrow-right" library="fa-solid" sizingType="height" />
								</ZdsButton>
							</div>
							<div className="zds-margin-bottom-12">
								<Form
									schema={formSchema.employmentDetailsSchema}
									onSubmit={data => handleOnSubmit(data)}
									formId="employmentDetailsMedewerkerForm"
								/>
							</div>
							<div className={`${styles.afdelingenHeader} zds-headline-xl zds-margin-bottom-12`}>
								{t('organisatie:organisatie.afdelingen')}
							</div>
							<div className="zds-headline-xl zds-margin-bottom-8">{t('organisatie:organisatie.medewerkerrollen')}</div>
						</div>

						<SelectMedewerkerollenTable
							pageSize={PAGE_SIZE}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							onSearchChange={handleSearchChange}
							onSortChange={handleSortChange}
							onCheckedChange={handleSelectionChange}
							sortData={sortData}
							itemsNum={itemsNum}
							medewerkerrollen={medewerkerrollen}
							selectedMedewerkerollen={selectedMedewerkerrollen}
						/>
					</Pane>
				</PageLayout>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);
};

export default MedewerkerInformation;
