import { ComponentProps, ElementType } from 'react';
import {
	ZdsCheckbox,
	ZdsInputText,
	ZdsInputNumber,
	ZdsSelect,
	ZdsTextarea,
	ZdsSwitch,
	ZdsInputDate,
	ZdsRadioGroup,
} from '@zig-design-system/ui-components-react';

import ChipArrayInput from './ChipArrayInput/ChipArrayInput';
import TextArea from './TextArea/TextArea';
import Select from './Select/Select';
import { BerichtVertaling, Language } from '../../../apps/communitybeheer/api/models';

export type LanguageInputData = { [key in Language]: string };

export type Input<T extends ElementType> = {
	type: T;
	props: ComponentProps<T> & { value?: string; languageData?: LanguageInputData };
	validationMessages?: {
		[key: string]: string;
	};
};

export interface RadioButtonOptions {
	label: string;
	name: string;
	value: string;
	checked?: boolean;
}

export interface SelectOptions {
	value: string;
	label: string;
	disabled?: boolean;
}

export type ZdsInputTextType = Input<typeof ZdsInputText>;
export type ZdsInputDateType = Input<typeof ZdsInputDate>;
export type ZdsTextareaType = Input<typeof ZdsTextarea>;
export type ZdsInputNumberType = Input<typeof ZdsInputNumber>;
export type ZdsCheckboxType = Input<typeof ZdsCheckbox>;
export type ZdsSwitchType = Input<typeof ZdsSwitch>;
export type ZdsSelectType = Input<typeof ZdsSelect> & {
	options: SelectOptions[];
};
export type ZdsRadioGroupType = Input<typeof ZdsRadioGroup> & {
	options: RadioButtonOptions[];
};
export type ChipArrayInputType = Input<typeof ChipArrayInput>;
export type TextAreaType = Input<typeof TextArea>;
export type SelectType = Input<typeof Select>;

export type ZdsInput =
	| typeof ZdsInputText
	| typeof ZdsInputDate
	| typeof ZdsTextarea
	| typeof ZdsInputNumber
	| typeof ZdsCheckbox
	| typeof ZdsSwitch
	| typeof ZdsSelect
	| typeof TextArea
	| typeof Select;

export type FormControlData =
	| ZdsInputTextType
	| ZdsTextareaType
	| ZdsInputNumberType
	| ZdsCheckboxType
	| ZdsSwitchType
	| ZdsSelectType
	| ZdsInputDateType
	| ZdsRadioGroupType
	| ChipArrayInputType
	| TextAreaType
	| SelectType;

export interface InputLayoutOptions {
	fullWidth?: boolean;
	labelPosition?: 'top' | 'side';
	labelAlign?: 'center' | 'start';
}

export interface FormControl {
	name: string;
	submitPath?: string; // Example: 'soort.code' - if the submit path for a property is different than FC name
	submitObject?: (value: InputDataType) => { [key: string]: any }; // Used for more complex submit path
	control: FormControlData;
	layoutOptions?: InputLayoutOptions;
}

export interface FormColumnSection {
	title?: string;
}

export interface FormSchema {
	title?: string;
	formControls: FormControl[];
	layout?: {
		columns: string[][];
	};
	noSubmit?: string[];
	updateValueFunctions?: { name: string; updateFunction: (formData: FormData) => InputDataType }[];
}

export interface FormInputData {
	name: string;
	type: ZdsInput;
	value: InputDataType;
}

export type InputDataType = string | number | boolean | null | undefined;

export interface FormData {
	[key: string]: {
		type: ZdsInput;
		value: InputDataType;
		languageData?: LanguageInputData;
	};
}
export interface FormSubmitData {
	[key: string]: FormSubmitData | InputDataType | InputDataType[] | BerichtVertaling[];
}

export const isCheckbox = (value: FormControlData): value is ZdsCheckboxType => {
	return value.type === ZdsCheckbox;
};

export const isChipArrayInput = (value: FormControlData): value is ChipArrayInputType => {
	return value.type === ChipArrayInput;
};

export const isInputDate = (value: FormControlData): value is ZdsInputDateType => {
	return value.type === ZdsInputDate;
};

export const isRadioGroup = (value: FormControlData): value is ZdsRadioGroupType => {
	return value.type === ZdsRadioGroup;
};

export const isSelect = (value: FormControlData): value is ZdsSelectType => {
	return value.type === ZdsSelect || value.type === Select;
};

export const isTextArea = (value: FormControlData): value is ZdsTextareaType => {
	return value.type === ZdsTextarea || value.type === TextArea;
};

export const isSwitch = (value: FormControlData): value is ZdsSwitchType => {
	return value.type === ZdsSwitch;
};
