import { TFunction } from 'i18next';
import { getReferentiedataNaam } from '../../../../global/utils/translations/getReferentiedataNaam';
import { Contactgegeven } from '../../api/models';
import { TableData } from '../../../../global/components/DataTable/DataTable';

export const mapContactsToTableData = (contacts: Contactgegeven[], t: TFunction): TableData => {
	const header = {
		labels: [
			t('global:domainShared.soort'),
			t('global:domainShared.detailSoort'),
			t('global:domainShared.omschrijving'),
			t('global:domainShared.status'),
			t('global:domainShared.waarde'),
		],
	};
	const body = {
		rows: contacts.map(data => {
			return {
				id: data.id ?? '',
				cells: [
					{ mainText: getReferentiedataNaam(t, 'CONTACTGEGEVENSOORT', data.soort?.code) },
					{ mainText: getReferentiedataNaam(t, 'CONTACTGEGEVENDETAILSOORT', data.detailSoort?.code) },
					{ mainText: data.omschrijving ?? '' },
					{ mainText: getReferentiedataNaam(t, 'CONTACTGEGEVENSTATUS', data.status?.code) },
					{ mainText: data.waarde ?? '' },
				],
			};
		}),
	};

	return {
		header,
		body,
	};
};
