import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import { selectCurrentClusterData } from '../../../../redux/currentClusterData';
import { updateCluster } from '../../../../redux/clustersData';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import styles from './ClusterOverview.module.scss';
import ClusterForm from '../../../../components/ClusterForm/ClusterForm';

export const updateClusterFormId = 'updateClusterForm';

export interface ClusterOverviewProps {
	onNameChange: (name: string) => void;
}

const ClusterOverview: FC<ClusterOverviewProps> = ({ onNameChange }) => {
	const { currentClusterData, loading, updating, error } = useAppSelector(selectCurrentClusterData);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const hasPermission = usePermissions();

	const handleUpdateCluster = (data: FormSubmitData) => {
		if (!hasPermission('VAS_WIJ_CLU')) return;

		if (currentClusterData?.id && !updating) {
			const updateData = {
				id: currentClusterData.id,
				body: data,
			};
			dispatch(updateCluster(updateData));
		}
	};

	return (
		<div className={styles.container}>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{!loading && (
				<ClusterForm
					onNameChange={onNameChange}
					onSubmit={handleUpdateCluster}
					clusterData={currentClusterData}
					error={error}
					isUpdate={true}
				/>
			)}
		</div>
	);
};

export default ClusterOverview;
