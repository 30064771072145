import { createSlice } from '@reduxjs/toolkit';
import { MedewerkerrollenState, getMedewerkerrollen } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: MedewerkerrollenState = { itemsNum: 0, medewerkerrollen: null, loading: false, error: null };

export const medewerkerrollenSlice = createSlice({
	name: 'medewerkerrollen',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getMedewerkerrollen.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.medewerkerrollen = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getMedewerkerrollen.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.medewerkerrollen = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.medewerkerrollen);
			})
			.addCase(getMedewerkerrollen.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const medewerkerrollenReducer = medewerkerrollenSlice.reducer;
