import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import Form from '../../../../global/components/Form/Form';
import { FormSchema, FormSubmitData } from '../../../../global/components/Form/Form.types';
import { sideMenuInfo } from '../../routes';
import OrganisatieAPI from '../../api';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import styles from './CreateOrganization.module.scss';
import { generateCreateOrganisatieFormSchema } from '../../utils/form/schema/organization/create';
import InputWrapper from '../../../../global/components/Form/InputWrapper/InputWrapper';
import AutocompleteInput from '../../../../global/components/AutocompleteInput/AutocompleteInput';
import { Rechtspersoon } from '../../../relatiebeheer/api/models';
import { ZdsDivider } from '@zig-design-system/ui-components-react';
import { generateIamFormSchema } from '../../utils/form/schema/organization/create/iam';
import CreateAllowedDomains, { IamAllowedEmailDomain } from './CreateAllowedDomains/CreateAllowedDomains';
import CreateApplications, { IamApplication } from './CreateApplications/CreateApplications';
import CreateLoginMethods, { IamLoginMethod } from './CreateLoginMethods/CreateLoginMethods';

const CreateOrganization: FC = () => {
	const { t, i18n } = useTranslation(['global', 'organisatie']);
	const navigate = useNavigate();
	const [error, setError] = useState('');

	const [formSchema, setFormSchema] = useState<FormSchema | null>(null);
	const [iamFormSchema, setIamFormSchema] = useState<FormSchema | null>(null);
	const [rechtspersonen, setRechtspersonen] = useState<Rechtspersoon[] | null>(null);
	const [selectedRechtspersoon, setSelectedRechtspersoon] = useState<Rechtspersoon | null>(null);
	const [applications, setApplications] = useState<IamApplication[]>([]);
	const [allowedEmailDomains, setAllowedEmailDomains] = useState<IamAllowedEmailDomain[]>([]);
	const [loginMethods, setLoginMethods] = useState<IamLoginMethod[]>([]);

	useEffect(() => {
		setFormSchema(generateCreateOrganisatieFormSchema(t, i18n.language));
		setIamFormSchema(generateIamFormSchema(t));
		searchRechtspersonenByTerm('');
	}, []);

	const searchRechtspersonenByTerm = async (term: string) => {
		setSelectedRechtspersoon(null);
		const rechtspersonen: Rechtspersoon[] = await searchRechtspersonen({ term: term.trim(), limit: 10 });
		setRechtspersonen(rechtspersonen);
	};

	const searchRechtspersonen = async ({ term = '', offset = 0, limit = 10 }): Promise<Rechtspersoon[]> => {
		return OrganisatieAPI.getRechtspersoonRelaties(term, offset, limit);
	};

	const autocompleteItems = (rechtspersonen ?? []).map((r: Rechtspersoon) => ({
		key: r.id ?? '',
		value: r.naam ?? '',
	}));

	const handleSubmit = useCallback(
		async (data: FormSubmitData) => {
			try {
				const request: any = { ...data, relatie: { id: selectedRechtspersoon?.id } };

				request.iam = {
					realm: {
						name: document.getElementById('iam_realmName')?.getAttribute('value'),
						allowedEmailDomains: allowedEmailDomains.map(item => item.allowedEmailDomain),
						applications: applications.map(item => ({ ...item, urls: item.urls.split(' ') })),
						loginMethods,
					},
				};

				const id = await OrganisatieAPI.createOrganisatie(request);
				navigate(`/organisatiebeheer/organisatie/${id}`);
			} catch (error) {
				setError('global:form.errorMessage.create');
			}
		},
		[selectedRechtspersoon, allowedEmailDomains, applications, loginMethods]
	);

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organizationsPage.header.title'), link: '/organisatiebeheer/organisatie' },
		{ label: t('global:common.create') },
	];

	const saveButton = <SaveButton dataTestId="createOrganisatieSubmitButton" formId="createOrganisatieForm" />;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('organisatie:organizationsPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={saveButton}
			/>
			<Pane className={styles.contentPane}>
				{error && (
					<div className="zds-padding-bottom-6">
						<ErrorMessage message={t(error)} />
					</div>
				)}
				{formSchema && (
					<div data-testid="createOrganisatieForm" className="zds-margin-left-8 zds-margin-top-6">
						<div className="zds-headline-xl zds-margin-bottom-3">{t('relatie:relatie.relatie')}</div>

						<div className="zds-flex-1 zds-flex zds-flex-col zds-gap-y-4">
							{/*<div className="zds-margin-top-4">
								<div className="zds-headline-sm">{t('relatie:relatie.relatie')}:</div>
								<ZdsDivider />
							</div>*/}

							<InputWrapper label={t('global:domainShared.naam') ?? ''} fullInputWidth noLabelWidth>
								<AutocompleteInput
									items={autocompleteItems}
									selectedItemKey={selectedRechtspersoon?.id}
									onAutocompleteChange={value => searchRechtspersonenByTerm(value)}
									onSelect={(item: any) =>
										setSelectedRechtspersoon(rechtspersonen?.find(r => (r.id = item.key)) ?? null)
									}
								/>
							</InputWrapper>
						</div>

						<div className="zds-margin-top-4">
							<ZdsDivider />
						</div>

						<Form schema={formSchema} onSubmit={data => void handleSubmit(data)} formId="createOrganisatieForm" />

						{iamFormSchema && (
							<>
								<div className="zds-margin-top-4">
									<ZdsDivider />
								</div>

								<Form
									schema={iamFormSchema}
									formId="iamFormSchema"
									onSubmit={() => {
										return;
									}}
								/>

								<div className="zds-margin-top-4">
									<ZdsDivider />
								</div>

								<CreateAllowedDomains
									allowedEmailDomains={allowedEmailDomains}
									setAllowedEmailDomains={setAllowedEmailDomains}
								/>

								<div className="zds-margin-top-4">
									<ZdsDivider />
								</div>

								<CreateApplications applications={applications} setApplications={setApplications} />

								<div className="zds-margin-top-4">
									<ZdsDivider />
								</div>

								<CreateLoginMethods loginMethods={loginMethods} setLoginMethods={setLoginMethods} />
							</>
						)}
					</div>
				)}
			</Pane>
		</PageLayout>
	);
};

export default CreateOrganization;
