import { createSlice } from '@reduxjs/toolkit';

import { getCurrentClusterData, CurrentClusterDataState } from '.';
import { updateCluster } from '../clustersData';

const initialState: CurrentClusterDataState = {
	currentClusterData: null,
	loading: false,
	updating: false,
	error: null,
};

export const currentRealtieDataSlice = createSlice({
	name: 'currentClusterData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentClusterData.pending, state => {
				state.loading = true;
				state.currentClusterData = null;
			})
			.addCase(getCurrentClusterData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentClusterData = payload;
			})
			.addCase(getCurrentClusterData.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(updateCluster.pending, state => {
				state.updating = true;
			})
			.addCase(updateCluster.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentClusterData = payload;
			})
			.addCase(updateCluster.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			});
	},
});

export const currentClusterDataReducer = currentRealtieDataSlice.reducer;
