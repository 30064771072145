import { FormData } from '../../../../global/components/Form/Form.types';
import { Language, Vertaling } from '../../api/models';

export const getInputLanguageDataValue = ({
	formData,
	vertalingen,
	initialized,
	propName,
}: {
	formData: FormData | null;
	vertalingen?: Vertaling[];
	initialized: boolean;
	propName: string;
}) => {
	const initLanguageData = vertalingen?.reduce((acc, langData) => {
		acc[langData.taal] = langData;
		return acc;
	}, {} as { [key in Language]: Vertaling });

	return Object.values(Language).reduce((acc, lang) => {
		acc[lang] =
			(initialized
				? formData?.[propName].languageData?.[lang]
				: initLanguageData?.[lang]?.[propName as keyof Vertaling]) ?? '';
		return acc;
	}, {} as { [key in Language]: string });
};
