import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { Medewerker } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { ListResponse } from '../../../../global/api';

export const getMedewerkers = createAsyncThunk<
	ListResponse<Medewerker>,
	{ term?: string; offset?: number; limit?: number; clear?: boolean },
	{ rejectValue: string }
>('organisatieData/getMedewerkers', async ({ term, offset, limit } = {}, { rejectWithValue }) => {
	return OrganisatieAPI.getMedewerkers(term, offset, limit).catch(error => {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	});
});
