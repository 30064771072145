import { createAsyncThunk } from '@reduxjs/toolkit';

import VastgoedAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Cluster } from '../../../communitybeheer/api/models';
import { ListResponse } from '../../../../global/api';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';

export const getClusters = createAsyncThunk<
	ListResponse<Cluster>,
	{ offset?: number; limit?: number; clear?: boolean },
	{ rejectValue: string }
>('vastgoed/getClusters', async ({ offset, limit } = {}, { rejectWithValue }) => {
	return VastgoedAPI.getClusters({ offset, limit, expand: '' }).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});

export const updateCluster = createAsyncThunk<Cluster, { id: string; body: FormSubmitData }, { rejectValue: string }>(
	'currentClusterData/updateCluster',
	async ({ id, body }, { rejectWithValue }) => {
		try {
			await VastgoedAPI.updateCluster(id, body);
			const response = await VastgoedAPI.getCluster(id);

			return response;
		} catch (error) {
			return rejectWithValue('global:form.errorMessage.update');
		}
	}
);
