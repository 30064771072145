import { createAsyncThunk } from '@reduxjs/toolkit';

import RelatieAPI from '../../api';
import { Contactgegeven, Relatie } from '../../api/models';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getCurrentRelatieData = createAsyncThunk<Relatie, string, { rejectValue: string }>(
	'currentRelatieData/getRelatieData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await RelatieAPI.getRelatie(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateRelatie = createAsyncThunk<Relatie, { id: string; body: FormSubmitData }, { rejectValue: string }>(
	'currentRelatieData/updateRelatie',
	async ({ id, body }, { rejectWithValue }) => {
		try {
			await RelatieAPI.updateRelatie(id, body);
			const response = await RelatieAPI.getRelatie(id);

			return response;
		} catch (error) {
			return rejectWithValue('global:form.errorMessage.update');
		}
	}
);

export const createContactgegeven = createAsyncThunk<
	Contactgegeven,
	{ relatieId: string; body: FormSubmitData },
	{ rejectValue: string }
>('currentRelatieData/createContactgegeven', async ({ relatieId, body }, { rejectWithValue }) => {
	try {
		const contactgegevenPayload = { ...body, relatie: { id: relatieId } };
		const id = await RelatieAPI.createContactgegeven(contactgegevenPayload);

		return { id, ...contactgegevenPayload } as Contactgegeven;
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.create');
	}
});

export const updateContactgegeven = createAsyncThunk<
	Contactgegeven,
	{ id: string; body: FormSubmitData },
	{ rejectValue: string }
>('currentRelatieData/updateContactgegeven', async ({ id, body }, { rejectWithValue }) => {
	try {
		await RelatieAPI.updateContactgegeven(id, body);

		return { id, ...body } as Contactgegeven;
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});

export const deleteContactgegeven = createAsyncThunk<string, { id: string }, { rejectValue: string }>(
	'currentRelatieData/deleteContactgegeven',
	async ({ id }, { rejectWithValue }) => {
		try {
			await RelatieAPI.deleteContactgegeven(id);

			return id;
		} catch (error) {
			return rejectWithValue('global:form.errorMessage.delete');
		}
	}
);
