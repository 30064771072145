import DemoAppWrapper from './apps/demo/DemoAppWrapper';
import demoRoutes from './apps/demo/routes';
import DemoZdsAppWrapper from './apps/demo-zds/DemoZdsAppWrapper';
import demoZdsRoutes from './apps/demo-zds/routes';
import RelationManagementAppWrapper from './apps/relatiebeheer/RelationManagementAppWrapper';
import OrganizationManagementAppWrapper from './apps/organisatiebeheer/OrganizationManagementAppWrapper';
import relationManagementRoutes from './apps/relatiebeheer/routes';
import organizationManagementRoutes from './apps/organisatiebeheer/routes';
import communityManagementRoutes from './apps/communitybeheer/routes';
import porfolioManagementRoutes from './apps/vastgoedbeheer/routes';
import CommunityManagementAppWrapper from './apps/communitybeheer/CommunityManagementAppWrapper';
import OAuthCallback from './global/pages/OAuthCallback/OAuthCallback';
import PorfolioManagementAppWrapper from './apps/vastgoedbeheer/PorfolioManagementAppWrapper';
import { Permission } from './global/api/models';

interface RouteInfoBase {
	path: string;
	unprotected?: boolean;
	permissions?: Permission[];
}

export interface RouteInfoComponent extends RouteInfoBase {
	Component: React.FC;
	redirect?: string;
}

export interface RouteInfoSubRoutes extends RouteInfoBase {
	subRoutes: RouteInfo[];
	WrapperComponent?: WrapperComponent;
}

export type RouteInfo = RouteInfoComponent | RouteInfoSubRoutes;

interface WrapperComponentProps {
	children: React.ReactNode;
}

export type WrapperComponent = React.FC<WrapperComponentProps>;

const routes: RouteInfo[] = [
	{
		path: '',
		redirect: '/communitybeheer/community',
		Component: () => null,
	},
	{
		path: 'oauth_callback',
		Component: OAuthCallback,
		unprotected: true,
	},
	{
		path: 'demo',
		subRoutes: demoRoutes,
		WrapperComponent: DemoAppWrapper,
	},
	{
		path: 'demo-zds',
		subRoutes: demoZdsRoutes,
		WrapperComponent: DemoZdsAppWrapper,
	},
	{
		path: 'relatiebeheer',
		subRoutes: relationManagementRoutes,
		WrapperComponent: RelationManagementAppWrapper,
	},
	{
		path: 'organisatiebeheer',
		subRoutes: organizationManagementRoutes,
		WrapperComponent: OrganizationManagementAppWrapper,
	},
	{
		path: 'communitybeheer',
		subRoutes: communityManagementRoutes,
		WrapperComponent: CommunityManagementAppWrapper,
	},
	{
		path: 'vastgoedbeheer',
		subRoutes: porfolioManagementRoutes,
		WrapperComponent: PorfolioManagementAppWrapper,
	},
];

export default routes;
