import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { Medewerkerrol } from '../../api/models';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getCurrentMedewerkerrolData = createAsyncThunk<Medewerkerrol, string, { rejectValue: string }>(
	'currentMedewerkerrolData/getMedewerkerrolData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await OrganisatieAPI.getMedewerkerrol(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateMedewerkerrol = createAsyncThunk<
	Medewerkerrol,
	{ id: string; payload: FormSubmitData; applicatierollen: string[] },
	{ rejectValue: string }
>('currentMedewerkerrolData/updateMedewerkerrol', async ({ id, payload, applicatierollen }, { rejectWithValue }) => {
	try {
		await OrganisatieAPI.updateMedewerkerrol(id, payload, applicatierollen);
		return await OrganisatieAPI.getMedewerkerrol(id);
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});
