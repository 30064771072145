import { RootState } from '../../../../global/redux/store';
import { Bericht } from '../../api/models';
import { BerichtFilters, BerichtenState } from '.';

const filterDeletedOrHiddenReacties = (bericht: Bericht) => {
	return {
		...bericht,
		reacties: bericht.reacties?.filter(reactie => !['VBO', 'VER'].includes(reactie.status?.code ?? '')),
	};
};

export const selectBerichten = (state: RootState): BerichtenState => {
	const selectedBericht = state.communities.berichten.selectedBericht;
	const berichten = state.communities.berichten.berichten;
	return {
		...state.communities.berichten,
		selectedBericht: selectedBericht ? filterDeletedOrHiddenReacties(selectedBericht) : null,
		berichten: berichten
			? { ...berichten, berichten: berichten.berichten.map(filterDeletedOrHiddenReacties) ?? [] }
			: null,
	};
};

export const selectFilters = (state: RootState): BerichtFilters => state.communities.berichten.filters;
