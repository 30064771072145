import { ZdsChip } from '@zig-design-system/ui-components-react';
import { FC } from 'react';
import classNames from 'classnames';

import styles from './ChipArrayInput.module.scss';

export interface ChipArrayInputProps {
	label?: string;
	value: { key: string; label: string }[];
	deletable?: boolean;
	onDelete?: (key: string) => void;
	withBorder?: boolean;
}

const ChipArrayInput: FC<ChipArrayInputProps> = ({ label, value, deletable, onDelete, withBorder }) => {
	const inputContainerClasses = classNames(styles.inputContainer, {
		[styles['inputContainer--withBorder']]: withBorder,
	});

	return (
		<div>
			{label && <div className="zds-bold zds-margin-bottom-2">{label}</div>}
			<div className={inputContainerClasses}>
				{value.map(v => (
					<ZdsChip key={v.key} deletable={deletable} onZdsDelete={() => onDelete?.(v.key)}>
						<span slot="primary-text">{v.label}</span>
					</ZdsChip>
				))}
			</div>
		</div>
	);
};

export default ChipArrayInput;
