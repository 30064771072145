import { TFunction } from 'i18next';

import { SortableOption, TableData } from '../../../../global/components/DataTable/DataTable';
import { Applicatierol } from '../../../../global/api/models';

export const mapApplicatierollenToTableData = (
	applicatierollen: Applicatierol[],
	checked: string[],
	sortData: { [key: string]: SortableOption },
	checkboxColumnId: string,
	t: TFunction
): TableData => {
	const columnIds = ['naam', 'applicatie.naam', checkboxColumnId];
	const header = {
		labels: [t('organisatie:organisatie.applicatierol'), t('global:common.application'), t('global:common.assigned')],
		columnIds,
		width: [null, null, 140],
		sortable: columnIds.map(id => sortData[id] || true),
	};

	const body = {
		rows: applicatierollen.map(rol => {
			return {
				id: rol.id,
				cells: [
					{ mainText: rol.naam ?? '' },
					{ mainText: rol.applicatie?.naam },
					{ mainText: '', checkbox: { checked: checked.includes(rol.id) } },
				],
			};
		}),
	};

	return {
		header,
		body,
	};
};
