import { createAsyncThunk } from '@reduxjs/toolkit';

import VastgoedAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Eenheid } from '../../api/models';
import { ListResponse } from '../../../../global/api';

export const getEenheden = createAsyncThunk<
	ListResponse<Eenheid>,
	{ offset?: number; limit?: number; clear?: boolean },
	{ rejectValue: string }
>('vastgoed/getEenheden', async ({ offset, limit } = {}, { rejectWithValue }) => {
	return VastgoedAPI.getEenheden({ offset, limit }).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});
