import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { TFunction } from 'i18next';
import { generateCreateNatuurlijkPersoonFormSchema } from '../../../../../../relatiebeheer/utils/form/schema/create/natuurlijkPersoon';
import { generateAccountFormSchema } from '../../account/create';
import { ZdsInputDate, ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getDateFormat } from '../../../../../../../global/utils/date/getDatePattern';

interface MedewerkerFormSchema {
	relatieSchema: FormSchema;
	medewerkerSchema: FormSchema;
	accountSchema: FormSchema;
}

export const generateCreateMedewerkerFormSchema = (t: TFunction, language: string): MedewerkerFormSchema => {
	const dateFormat = getDateFormat(language);

	const medewerkerSchema: FormSchema = {
		title: getStringTranslation(t, 'organisatie:medewerker.medewerker.title'),
		formControls: [
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.soort'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'MEDEWERKERSOORT',
						code: '',
						disabled: false,
						hasEmptyValue: false,
					}),
				},
			},
			{
				name: 'omschrijving',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.omschrijving'),
					},
				},
			},
			{
				name: 'begindatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.begindatum'),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'eindeWerkzaamhedenDatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.medewerker.eindeWerkzaamhedenDatum'),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
		],
		layout: {
			columns: [
				['soort', 'omschrijving'],
				['begindatum', 'eindeWerkzaamhedenDatum'],
			],
		},
	};

	// Form consists of 3 subforms
	return {
		medewerkerSchema,
		relatieSchema: generateCreateNatuurlijkPersoonFormSchema(t, language),
		accountSchema: generateAccountFormSchema(t),
	};
};
