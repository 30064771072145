import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCommunities, selectCommunities } from '../../redux/communities';
import CommunitiesTable from '../../components/CommunitiesTable/CommunitiesTable';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { sideMenuInfo } from '../../routes';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { SortableOption } from '../../../../global/components/DataTable/DataTable';
import styles from './Communities.module.scss';

export const PAGE_SIZE = 20;
export const PAGE_PARAM_NAME = 'page';

const getInitPage = (param: string | null) => {
	if (!param || isNaN(Number(param))) return 0;

	return Number(param);
};

const Communities: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { communities, itemsNum, loading, error } = useAppSelector(selectCommunities);
	const [currentPage, setCurrentPage] = useState(getInitPage(searchParams.get(PAGE_PARAM_NAME)));
	const [searchData, setSearchData] = useState<{ [key: string]: string }>({});
	const [sortData, setSortData] = useState<{ [key: string]: SortableOption }>({});

	useEffect(() => {
		handleGetCommunities();
	}, [currentPage, searchData, sortData]);

	useEffect(() => {
		handlePageChange(currentPage);
	}, []);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		updateSearchParams(newPage);
	};

	const handleGetCommunities = () => {
		dispatch(getCommunities({ offset: currentPage, limit: PAGE_SIZE, searchData, sortData }));
	};

	const updateSearchParams = (newPage: number) => {
		if (newPage !== 0) {
			setSearchParams({ [PAGE_PARAM_NAME]: newPage.toString() });
			return;
		}
		searchParams.delete(PAGE_PARAM_NAME);
		setSearchParams(searchParams);
	};

	const handleSearchChange = (data: { [key: string]: string }) => {
		setSearchData(data);
	};

	const handleSortChange = (data: { columnId: string; sort: SortableOption }) => {
		setSortData(data.columnId ? { [data.columnId]: data.sort } : {});
	};

	const breadcrumbs = [
		{ label: t('global:apps.communityManagement'), link: '/communitybeheer' },
		{ label: t('community:communitiesPage.header.title') },
	];

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('community:communitiesPage.header.title')}
				iconName="message-lines"
				iconLibrary="fa-solid"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className={styles.contentPane}>
				{communities && (
					<CommunitiesTable
						communitiesData={communities}
						onPageChange={handlePageChange}
						onSearchChange={handleSearchChange}
						onSortChange={handleSortChange}
						pageSize={PAGE_SIZE}
						itemsNum={itemsNum}
						currentPage={currentPage}
						sortData={sortData}
					/>
				)}
				{loading && <h3 data-testid="communitiesListLoading">{t('global:common.loading')}...</h3>}
				{error && <ErrorMessage message={t(error)} />}
			</Pane>
		</PageLayout>
	);
};

export default Communities;
