import { Component, ReactNode } from 'react';
import { TFunction } from 'i18next';

import ErrorMessage from './ErrorMessage/ErrorMessage';

interface ErrorBoundaryProps {
	children: ReactNode;
	t: TFunction;
}

interface ErrorBoundaryState {
	error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { error: null };
	}

	static getDerivedStateFromError(error: Error) {
		console.error(error);
		return { error };
	}

	render() {
		if (this.state.error) {
			return (
				<div className="zds-padding-4">
					<ErrorMessage message={this.props.t('defaultErrorMessage')} />
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
