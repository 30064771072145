import { createSlice } from '@reduxjs/toolkit';

import {
	CurrentCommunityCategoryDataState,
	createCommunityCategoryRolRelatie,
	deleteCommunityCategoryRolRelatie,
	getCurrentCommunityCategoryData,
	updateCommunityCategory,
} from '.';

export const initialState: CurrentCommunityCategoryDataState = {
	currentCommunityCategoryData: null,
	loading: false,
	error: null,
	updating: false,
};

export const currentCommunityCategoryDataSlice = createSlice({
	name: 'currentCommunityCategoryData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentCommunityCategoryData.pending, state => {
				state.loading = true;
				state.currentCommunityCategoryData = null;
				state.error = null;
			})
			.addCase(getCurrentCommunityCategoryData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentCommunityCategoryData = payload;
			})
			.addCase(getCurrentCommunityCategoryData.rejected, (state, { payload }) => {
				state.loading = false;
				state.currentCommunityCategoryData = null;
				state.error = payload ?? null;
			})
			.addCase(updateCommunityCategory.pending, state => {
				state.updating = true;
				state.error = null;
			})
			.addCase(updateCommunityCategory.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentCommunityCategoryData = payload;
			})
			.addCase(updateCommunityCategory.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			})
			.addCase(deleteCommunityCategoryRolRelatie.pending, state => {
				state.error = null;
			})
			.addCase(deleteCommunityCategoryRolRelatie.rejected, (state, { payload }) => {
				state.error = payload ?? null;
			})
			.addCase(createCommunityCategoryRolRelatie.pending, state => {
				state.error = null;
			})
			.addCase(createCommunityCategoryRolRelatie.rejected, (state, { payload }) => {
				state.error = payload ?? null;
			});
	},
});

export const currentCommunityCategoryDataReducer = currentCommunityCategoryDataSlice.reducer;
