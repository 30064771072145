import { createAsyncThunk } from '@reduxjs/toolkit';

import CommunityAPI from '../../api';
import { CommunityCategorie, RolRelatiePayload } from '../../api/models';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getCurrentCommunityCategoryData = createAsyncThunk<CommunityCategorie, string, { rejectValue: string }>(
	'currentCommunityCategoryData/getCurrentCommunityCategoryData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await CommunityAPI.getCommunityCategory(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateCommunityCategory = createAsyncThunk<
	CommunityCategorie,
	{ id: string; body: FormSubmitData },
	{ rejectValue: string }
>('currentCommunityCategoryData/updateCommunityCategory', async ({ id, body }, { rejectWithValue }) => {
	try {
		await CommunityAPI.updateCommunityCategory(id, body);
		return CommunityAPI.getCommunityCategory(id);
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});

export const deleteCommunityCategoryRolRelatie = createAsyncThunk<
	void,
	{ communityCategoryId: string; rolRelatieId: string },
	{ rejectValue: string }
>(
	'currentCommunityData/deleteCommunityCategoryRolRelatie',
	async ({ communityCategoryId, rolRelatieId }, { rejectWithValue, dispatch }) => {
		try {
			await CommunityAPI.deleteCommunityCategoryRolRelatie(communityCategoryId, rolRelatieId);
			dispatch(getCurrentCommunityCategoryData(communityCategoryId));
		} catch (error) {
			return rejectWithValue('global:form.errorMessage.delete');
		}
	}
);

export const createCommunityCategoryRolRelatie = createAsyncThunk<
	void,
	{ id: string; body: RolRelatiePayload },
	{ rejectValue: string }
>('currentCommunityData/createCommunityCategoryRolRelatie', async ({ id, body }, { rejectWithValue, dispatch }) => {
	try {
		await CommunityAPI.createCommunityCategoryRolRelatie(id, body);
		dispatch(getCurrentCommunityCategoryData(id));
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.create');
	}
});
