import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import {
	getCommunityCategoriesData,
	selectCurrentCommunityCategoriesData,
	selectCurrentCommunityData,
} from '../../../../redux/currentCommunityData';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { useMapCommunityCategoriesToTableData } from '../../../../hooks/table/useMapCommunityCategoriesToTableData/useMapCommunityCategoriesToTableData';
import DataTable from '../../../../../../global/components/DataTable/DataTable';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';

export const PAGE_SIZE = 5;

const CommunityCategories: FC = () => {
	const dispatch = useAppDispatch();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const { categories, loading, error, totalNum, activeNum, inactiveNum } = useAppSelector(
		selectCurrentCommunityCategoriesData
	);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasPermission = usePermissions();

	useEffect(() => {
		handlePageChange(0);
	}, [currentCommunityData]);

	const handleRowClick = (id: string) => {
		navigate(id);
	};

	const handlePageChange = (page: number) => {
		if (!currentCommunityData || page < 0) return;
		dispatch(getCommunityCategoriesData({ communityId: currentCommunityData.id, offset: page, limit: PAGE_SIZE }));
	};

	const navigateToCommunityCategoryCreate = () => {
		if (!currentCommunityData) return;
		navigate(`/communitybeheer/community/${currentCommunityData.id}/categorie/create`);
	};

	const tableData = useMapCommunityCategoriesToTableData(categories);

	const addNewCommunityCategoryButton = (
		<ZdsButton data-testid="addNewCommunityCategoryButton" onZdsClick={navigateToCommunityCategoryCreate}>
			<ZdsIcon library="fa-regular" name="plus" size="xs" />
			{t('global:common.addNew')}
		</ZdsButton>
	);

	return (
		<div className="zds-flex zds-flex-col zds-flex-1 zds-justify-content-between">
			<div>
				{loading && <h3>{t('global:common.loading')}...</h3>}
				{!error && currentCommunityData && (
					<div className="zds-margin-top-4">
						{tableData && (
							<DataTable
								data={tableData}
								onRowClick={handleRowClick}
								tableTestId="communityCategoriesTable"
								pageSizes={[PAGE_SIZE]}
								onPageChange={page => {
									window.scrollTo(0, 0);
									handlePageChange(page);
								}}
								iconOptions={{
									name: 'pen',
									library: 'fa-solid',
									preset: 'muted',
									variant: 'text',
									size: 'sm',
								}}
								totalItemsNum={totalNum}
								header={{
									title: t('community:communitiesPage.categories.table.title') ?? '',
									subheader: { Component: TableSubheader, props: { activeNum, inactiveNum } },
									endContent: hasPermission('COM_WIJ_COM_DET') ? addNewCommunityCategoryButton : null,
								}}
							/>
						)}
					</div>
				)}
			</div>
			<div>{error && <ErrorMessage message={t(error)} />}</div>
		</div>
	);
};

const TableSubheader: FC<{ activeNum: number; inactiveNum: number }> = ({ activeNum, inactiveNum }) => {
	const { t } = useTranslation();

	const getActiveNumLabel = useCallback(() => {
		switch (activeNum) {
			case 0:
				return 'zero';
			case 1:
				return 'singular';
			default:
				return 'plural';
		}
	}, [activeNum]);

	const activeNumLabel = t(`community:communitiesPage.categories.table.subtitle.active.${getActiveNumLabel()}`, {
		num: activeNum,
	});
	const inactiveNumLabel = t(`community:communitiesPage.categories.table.subtitle.inactive`, { num: inactiveNum });

	return (
		<div className="zds-flex zds-gap-2 zds-items-center">
			<div className="zds-headline-md">{activeNumLabel}</div> <div className="zds-muted">{`(${inactiveNumLabel})`}</div>
		</div>
	);
};

export default CommunityCategories;
