import classNames from 'classnames';

import styles from './Pane.module.scss';

interface PaneProps {
	children: React.ReactNode;
	className?: string;
}

const Pane = ({ children, className }: PaneProps) => {
	return <div className={classNames(styles.pane, className)}>{children}</div>;
};

export default Pane;
