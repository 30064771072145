import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsStep, ZdsStepper } from '@zig-design-system/ui-components-react';
import { ZdsStepperCustomEvent } from '@zig-design-system/ui-components';

interface CreateCommunityStepperProps {
	activeStep: number;
	setActiveStep: (step: number) => void;
	steps: string[];
	baseLabel: string;
}

const CreateCommunityStepper = forwardRef<HTMLZdsStepperElement, CreateCommunityStepperProps>(
	({ activeStep, setActiveStep, steps, baseLabel }, ref) => {
		const { t } = useTranslation();

		const handleStepperNavigate = (event: ZdsStepperCustomEvent<any>) => {
			setActiveStep(event.detail.step);
		};

		return (
			<ZdsStepper variant="vertical" activeStep={activeStep} ref={ref} onZdsStepperNavigate={handleStepperNavigate}>
				{steps.map((step, i) => {
					return (
						<ZdsStep key={step} status={i < activeStep ? 'complete' : 'incomplete'} disabled={i !== activeStep}>
							{t(`${baseLabel}.${step}`)}
							{i === activeStep && <div slot="subtitle">{t('global:common.current')}</div>}
						</ZdsStep>
					);
				})}
			</ZdsStepper>
		);
	}
);

export default CreateCommunityStepper;
