import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCurrentRelatieData, selectCurrentRelatieData } from '../../redux/currentRelatieData';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import RelationsTable from '../../components/RelationsTable/RelationsTable';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import { getRelatieNaam } from '../../utils/getRelatieNaam';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import RelationOverview from './TabsContent/RelationOverview/RelationOverview';
import Contacts from './TabsContent/Contacts/Contacts';
import styles from './RelationInformation.module.scss';

const RelationInformation: FC = () => {
	const { currentRelatieData, loading, updating, error } = useAppSelector(selectCurrentRelatieData);
	const dispatch = useAppDispatch();
	const params = useParams<{ id: string }>();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => {
		if (params.id) {
			void dispatch(getCurrentRelatieData(params.id));
			setSelectedTab(0);
		}
	}, [params]);

	const tabsData = [
		{
			name: t('relatie:relationInformationPage.tabs.overview'),
			content: <RelationOverview />,
		},
		{
			name: t('relatie:relationInformationPage.tabs.addresses'),
			content: <div />,
		},
		{
			name: t('relatie:relationInformationPage.tabs.contact'),
			content: <Contacts />,
		},
	];

	if (currentRelatieData?.soort?.code === 'REC') {
		const ContactsContent = (
			<>
				{loading && <h3>{t('global:common.loading')}...</h3>}
				{!loading && <RelationsTable relatieData={currentRelatieData.relaties} />}
				{error && <ErrorMessage message={t(error)} />}
			</>
		);

		tabsData.push({
			name: t('relatie:relationInformationPage.tabs.contacts'),
			content: ContactsContent,
		});
	}

	if (currentRelatieData?.soort?.code === 'GRO') {
		const MembersContent = (
			<>
				{loading && <h3>{t('global:common.loading')}...</h3>}
				{!loading && <RelationsTable relatieData={currentRelatieData.relaties} />}
				{error && <ErrorMessage message={t(error)} />}
			</>
		);

		tabsData.push({
			name: t('relatie:relationInformationPage.tabs.members'),
			content: MembersContent,
		});
	}

	const breadcrumbs = [
		{ label: t('global:apps.relationManagement'), link: '/relatiebeheer' },
		{ label: t('relatie:relationsPage.header.title'), link: '/relatiebeheer/relatie' },
		{ label: t('relatie:relationInformationPage.header.title') },
	];

	const saveButton = <SaveButton dataTestId="updateRelatieDataSubmitButton" formId="relatieForm" disabled={updating} />;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={getRelatieNaam(currentRelatieData ?? undefined) ?? ''}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				status={currentRelatieData?.status?.code}
				contentEnd={selectedTab === 0 && saveButton}
			/>
			<Pane className={styles.contentPane}>
				<Tabs tabsData={tabsData} initActiveTab={selectedTab} onTabChange={setSelectedTab} />
			</Pane>
		</PageLayout>
	);
};

export default RelationInformation;
