import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { generateCreateRelatieFormSchema } from '../../utils/form/schema/create';
import Form from '../../../../global/components/Form/Form';
import RelatieAPI from '../../api';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import styles from './CreateRelation.module.scss';

const CreateRelation: FC = () => {
	const [searchParams] = useSearchParams();
	const { t, i18n } = useTranslation(['global', 'relatie']);
	const navigate = useNavigate();
	const [error, setError] = useState('');

	const soort = searchParams.get('soort');

	const formSchema = generateCreateRelatieFormSchema(soort ?? '', t, i18n.language);

	const handleSubmit = useCallback(async (data: FormSubmitData) => {
		try {
			const id = await RelatieAPI.createRelatie(data);
			navigate(`/relatiebeheer/relatie/${id}`);
		} catch (error) {
			setError('global:form.errorMessage.create');
		}
	}, []);

	const breadcrumbs = [
		{ label: t('global:apps.relationManagement'), link: '/relatiebeheer' },
		{ label: t('relatie:relationsPage.header.title'), link: '/relatiebeheer/relatie' },
		{ label: t('global:common.create') },
	];

	const saveButton = <SaveButton dataTestId="createRelatieSubmitButton" formId="createRelatieForm" />;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('relatie:relationsPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={saveButton}
			/>
			<Pane className={styles.contentPane}>
				{error && (
					<div className="zds-padding-bottom-6">
						<ErrorMessage message={t(error)} />
					</div>
				)}
				{formSchema && (
					<div data-testid="createRelatieForm" className="zds-margin-left-8 zds-margin-top-6">
						<Form schema={formSchema} onSubmit={data => void handleSubmit(data)} formId="createRelatieForm" />
					</div>
				)}
			</Pane>
		</PageLayout>
	);
};

export default CreateRelation;
