export const defaultValidationMessages = [
	{
		reason: 'patternMismatch',
		message: 'form.defaultValidationMessage.patternMismatch',
	},
	{
		reason: 'rangeOverflow',
		message: 'form.defaultValidationMessage.rangeOverflow',
	},
	{
		reason: 'rangeUnderflow',
		message: 'form.defaultValidationMessage.rangeUnderflow',
	},
	{
		reason: 'stepMismatch',
		message: 'form.defaultValidationMessage.stepMismatch',
	},
	{
		reason: 'tooLong',
		message: 'form.defaultValidationMessage.tooLong',
	},
	{
		reason: 'tooShort',
		message: 'form.defaultValidationMessage.tooShort',
	},
	{
		reason: 'valueMissing',
		message: 'form.defaultValidationMessage.valueMissing',
	},
	{
		reason: 'badInputInvalid',
		message: 'form.defaultValidationMessage.badInputInvalid',
	},
];
