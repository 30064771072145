import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import { Informatieobject } from '../../../../apps/communitybeheer/api/models';
import { useUploadFileData } from '../../../hooks/useUploadFileData/useUploadFileData';
import UploadFile from '../../UploadFile/UploadFile';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import styles from './FormImage.module.scss';

interface FormImageProps {
	bijlagen?: Informatieobject;
	backgroundPicture: Informatieobject | undefined;
	setBackgroundPicture: Dispatch<SetStateAction<Informatieobject | undefined>>;
	imageTitle: string;
	imageClassName?: string;
	fixedHeightDefaultImage?: boolean;
	canEdit?: boolean;
	maxFilesSize: number;
	formSubmitted?: boolean;
	setFormSubmitted: Dispatch<SetStateAction<boolean>>;
}

export const FormImage = ({
	bijlagen,
	backgroundPicture,
	setBackgroundPicture,
	imageTitle,
	imageClassName,
	fixedHeightDefaultImage = false,
	canEdit = false,
	maxFilesSize,
	formSubmitted,
	setFormSubmitted,
}: FormImageProps) => {
	const { fileData, handleAddFile, handleRemoveFile, removeErrorFromLastFile } = useUploadFileData({
		maxFiles: 1,
		maxFilesSize: maxFilesSize,
		mode: 'SWAP_LAST',
		initFileData: [
			{
				id: bijlagen?.id ?? '',
				file: bijlagen?.bestand
					? ({
							name: bijlagen?.bestandsnaam,
							size: bijlagen?.bestandsgrootte,
					  } as File)
					: null,
				error: null,
				base64: bijlagen?.bestand ?? null,
			},
		],
	});

	const file = useMemo(() => fileData[0], [fileData]);

	useEffect(() => {
		if (file?.error) {
			return;
		}

		if (file && file.id !== '') {
			setBackgroundPicture({
				id: file.id,
				bestand: file.base64 ?? undefined,
			});
		}
	}, [fileData]);

	useEffect(() => {
		if (formSubmitted) {
			removeErrorFromLastFile();
			setFormSubmitted(false);
		}
	}, [formSubmitted]);

	const handleDeletePicture = (fileId: string) => {
		handleRemoveFile(fileId);

		setBackgroundPicture(undefined);
	};

	const renderImage = (title: string, image?: Informatieobject, style?: string) => {
		return (
			<div className="zds-flex zds-gap-3">
				<div className={styles.imageLabel}>{title}</div>
				<div className="zds-flex zds-flex-1">
					{image ? (
						<img className={style} slot="media" src={image.bestand} alt="" />
					) : (
						<div className={classNames(style, styles.noImage, fixedHeightDefaultImage ? styles.fixedHeight : '')}>
							<ZdsIcon size="xxl" name="image" library="fa-solid" color="" />
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className="zds-flex zds-flex-col zds-margin-bottom-8 zds-gap-6">
			<div className="zds-flex zds-flex-row zds-items-end">
				{renderImage(imageTitle, backgroundPicture, imageClassName)}
				{canEdit && (
					<>
						<div className="zds-margin-left-4">
							<UploadFile maxSize={maxFilesSize} onChange={f => handleAddFile(f, false)} />
						</div>
						{file?.base64 && (
							<ZdsButton preset="neutral" variant="text" onZdsClick={() => handleDeletePicture(file?.id)}>
								<ZdsIcon name="trash" library="fa-solid" size="sm"></ZdsIcon>
							</ZdsButton>
						)}
					</>
				)}
			</div>
			{file?.error && <ErrorMessage message={t(file?.error)} />}
		</div>
	);
};
