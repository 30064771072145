import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { Relatie, isNatuurlijkPersoon } from '../../../api/models';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { getRelatieNaam } from '../../../utils/getRelatieNaam';

export const useMapRelatiesToTableData = (
	relaties:
		| {
				[key: number]: Relatie[];
		  }
		| Relatie[]
		| null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [
			t('relatie:relatie.relatiesoort'),
			t('relatie:relatie.voornaam'),
			t('relatie:relatie.tussenvoegsels'),
			t('relatie:relatie.achternaam'),
			t('global:domainShared.naam'),
		],
	};

	const getPage = useCallback((relaties: Relatie[]) => {
		return relaties.map(relatie => {
			return {
				id: relatie.id ?? '',
				cells: [
					{ mainText: getReferentiedataNaam(t, 'RELATIESOORT', relatie.soort?.code) },
					{ mainText: isNatuurlijkPersoon(relatie) ? relatie.voornaam ?? '' : '' },
					{ mainText: isNatuurlijkPersoon(relatie) ? relatie.tussenvoegsels ?? '' : '' },
					{ mainText: isNatuurlijkPersoon(relatie) ? relatie.achternaam ?? '' : '' },
					{ mainText: getRelatieNaam(relatie) },
				],
			};
		});
	}, []);

	const pages = useTablePages(relaties, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
