import { FC } from 'react';

const EmphasizedText: FC<{ text: string; emphasizedPart: string }> = ({ text, emphasizedPart }) => {
	let count = 0;
	if (!emphasizedPart) {
		return <span>{text}</span>;
	}
	const parts = text.split(new RegExp(`(${emphasizedPart})`, 'i'));
	const getKey = (part: string) => {
		count++;

		return `${part.replaceAll(' ', '')}-${count}`;
	};

	return (
		<span>
			{parts.map(part =>
				part.toLowerCase() === emphasizedPart.toLowerCase() ? <strong key={getKey(part)}>{part}</strong> : part
			)}
		</span>
	);
};

export default EmphasizedText;
