import { useCallback } from 'react';

import { getInputLanguageDataValue } from '../../utils/form/getInputLanguageDataValue';
import { FormData, LanguageInputData } from '../../../../global/components/Form/Form.types';
import { Vertaling } from '../../api/models';

export const useInputLanguageDataValue = ({
	formData,
	vertalingen,
	initialized,
}: {
	formData: FormData | null;
	vertalingen?: Vertaling[];
	initialized: boolean;
}) => {
	const getInputLanguageData = useCallback(
		(propName: string): LanguageInputData =>
			getInputLanguageDataValue({
				formData,
				initialized,
				vertalingen,
				propName,
			}),
		[formData, vertalingen, initialized]
	);

	return getInputLanguageData;
};
