import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import SideMenu, { SideMenuInfo } from '../SideMenu/SideMenu';
import ErrorBoundary from '../ErrorBoundary';
import styles from './PageLayoutWrapper.module.scss';

interface PageLayoutWrapperProps {
	sideMenuInfo?: SideMenuInfo;
	children: ReactNode;
}

const PageLayoutWrapper: FC<PageLayoutWrapperProps> = ({ sideMenuInfo, children }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.mainRow}>
			{sideMenuInfo && <SideMenu info={sideMenuInfo} />}
			<ErrorBoundary t={t}>{children}</ErrorBoundary>
		</div>
	);
};

export default PageLayoutWrapper;
