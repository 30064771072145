import { FC, useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { sideMenuInfo } from '../../routes';
import NewCategory, { NewCategorySubmitData } from './NewCategory/NewCategory';
import CommunityCategoryGeneralInfo, {
	CommunityCategoryGeneralInfoSubmitData,
} from './CommunityCategoryGeneralInfo/CommunityCategoryGeneralInfo';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { mergeFormsVertalingen } from '../../../../global/utils/form/mergeFormsVertalingen';
import CommunityAPI, { CreateCommunityCategoryPayload } from '../../api';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCurrentCommunityData, selectCurrentCommunityData } from '../../redux/currentCommunityData';
import { selectUser } from '../../../../global/redux/shared/authorizationData';
import styles from './CreateCommunityCategory.module.scss';
import CreateCommunityStepper from '../../components/CreateCommunityStepper/CreateCommunityStepper';

const CreateCommunityCategory: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectUser);
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const [activeStep, setActiveStep] = useState(0);
	const [title, setTitle] = useState('');
	const [newCategorySubmitData, setNewCategorySubmitData] = useState<NewCategorySubmitData | null>(null);
	const [generalInfoSubmitData, setGeneralInfoSubmitData] = useState<CommunityCategoryGeneralInfoSubmitData | null>(
		null
	);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const stepperRef = useRef<HTMLZdsStepperElement | null>(null);
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();

	useEffect(() => {
		if (generalInfoSubmitData && activeStep === 1) {
			handleSubmit();
		}
	}, [generalInfoSubmitData]);

	useEffect(() => {
		const communityId = params.id;
		if (communityId) {
			dispatch(getCurrentCommunityData(communityId));
		}
	}, [params]);

	const handleCommunityCategoryNameChange = (name: string) => {
		setTitle(name);
	};

	const getStepsContent = () => {
		switch (activeStep) {
			case 0:
				return (
					<NewCategory
						initData={newCategorySubmitData}
						onCommunityCategoryNameChange={handleCommunityCategoryNameChange}
						onSave={(data: NewCategorySubmitData) => {
							setNewCategorySubmitData(data);
							setActiveStep(1);
						}}
					/>
				);
			case 1:
				return (
					<CommunityCategoryGeneralInfo
						initData={generalInfoSubmitData}
						onSave={(data: CommunityCategoryGeneralInfoSubmitData) => {
							setGeneralInfoSubmitData(data);
						}}
						onBack={(data: CommunityCategoryGeneralInfoSubmitData) => {
							setGeneralInfoSubmitData(data);
							setActiveStep(0);
						}}
						loading={loading}
						errorMessage={error}
					/>
				);
			default:
				return null;
		}
	};

	const handleSubmit = () => {
		const allFormsSubmitData = [newCategorySubmitData?.formSubmitData, generalInfoSubmitData?.formSubmitData].filter(
			Boolean
		) as FormSubmitData[];

		const formSubmitData = allFormsSubmitData.reduce((acc, data) => ({ ...acc, ...data }), {});
		formSubmitData.vertalingen = mergeFormsVertalingen(allFormsSubmitData);

		createCommunity(formSubmitData, [{ soort: { code: 'AUT' }, relatie: { id: user?.relatieId ?? '' } }]);
	};

	const createCommunity = async (
		formSubmitData: FormSubmitData,
		rolRelatiesSubmitData: CreateCommunityCategoryPayload['rolRelatiesSubmitData']
	) => {
		try {
			setLoading(true);

			const result = await CommunityAPI.createCommunityCategory({
				payload: {
					...formSubmitData,
					community: { id: currentCommunityData?.id },
				},
				rolRelatiesSubmitData,
			});

			onSave(result.id);
		} catch {
			setError(t('global:form.errorMessage.create') ?? '');
		} finally {
			setLoading(false);
		}
	};

	const onSave = (id: string) => {
		if (!currentCommunityData) return;

		navigate(`/communitybeheer/community/${currentCommunityData.id}/categorie/${id}`);
	};

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={title}
				iconName="message-lines"
				iconLibrary="fa-solid"
				description={
					t('community:createCategoryPage.header.subtitle', {
						name: currentCommunityData?.naam ?? '',
					}) ?? ''
				}
			/>
			<div className={styles.container}>
				<Pane className={styles.stepperContainer}>
					<CreateCommunityStepper
						activeStep={activeStep}
						setActiveStep={setActiveStep}
						baseLabel="community:createCategoryPage.stepper"
						steps={['newCategory', 'generalInfo', 'publish']}
						ref={stepperRef}
					/>
				</Pane>
				<Pane className={styles.mainContainer}>{getStepsContent()}</Pane>
			</div>
		</PageLayout>
	);
};

export default CreateCommunityCategory;
