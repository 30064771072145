import { Language, Vertaling } from '../../../apps/communitybeheer/api/models';
import { FormSubmitData } from '../../components/Form/Form.types';

export const mergeFormsVertalingen = (allFormsSubmitData: FormSubmitData[]): Vertaling[] => {
	return Object.values(Language).map(lang =>
		allFormsSubmitData
			.filter(fsd => fsd.vertalingen)
			.reduce(
				(acc, formSubmitData) => ({
					...acc,
					...(formSubmitData.vertalingen as Vertaling[])?.find(v => v.taal === lang),
				}),
				{} as Vertaling
			)
	);
};
