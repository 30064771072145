import { ComponentProps, FC } from 'react';
import { ZdsTextarea } from '@zig-design-system/ui-components-react';

import UploadFile from '../../UploadFile/UploadFile';
import { UploadFileData } from '../../../hooks/useUploadFileData/useUploadFileData';

export type TextAreaProps = ComponentProps<typeof ZdsTextarea> & {
	explanationText?: string;
	setFileData?: (data: UploadFileData) => void;
	uploadDisabled?: boolean;
};

const TextArea: FC<TextAreaProps> = ({ setFileData, uploadDisabled, explanationText, ...props }) => {
	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			{explanationText && <div className="zds-margin-bottom-2">{explanationText}</div>}
			<ZdsTextarea className="zds-flex-1" {...props} />
			{setFileData && (
				<div>
					<UploadFile onChange={setFileData} disabled={uploadDisabled} />
				</div>
			)}
		</div>
	);
};

export default TextArea;
