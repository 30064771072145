import { TFunction } from 'i18next';
import { ZdsInputDate, ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { NatuurlijkPersoon } from '../../../../../api/models';
import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getFormattedDate } from '../../../../../../../global/utils/date/formatDate/formatDate';
import { getDateFormat } from '../../../../../../../global/utils/date/getDatePattern';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { getRelatieNaam } from '../../../../getRelatieNaam';
import { COLUMN_SECTION_HEADER_PREFIX } from '../../../../../../../global/utils/form/createFormColumns';

export const generateUpdateNatuurlijkPersoonFormSchema = (
	relatieData: NatuurlijkPersoon,
	t: TFunction,
	language: string
): FormSchema => {
	const dateFormat = getDateFormat(language);

	const schema: FormSchema = {
		title: t('global:common.personalDetails') ?? '',
		formControls: [
			{
				name: 'id',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.id'),
						value: relatieData.id,
						disabled: true,
						required: true,
					},
				},
			},
			{
				name: 'idExtern',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.externId'),
						value: relatieData.idExtern,
						disabled: true,
					},
				},
			},
			{
				name: 'idBron',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.bronId'),
						value: relatieData.idBron,
						disabled: true,
					},
				},
			},
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						value: relatieData.code,
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: relatieData.soort?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIESOORT',
						code: relatieData.soort?.code,
						disabled: true,
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: relatieData.detailSoort?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIEDETAILSOORT',
						parentSoort: relatieData.soort?.code,
						code: relatieData.detailSoort?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'aanhef',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.aanhef'),
						value: relatieData.aanhef,
					},
				},
			},
			{
				name: 'aanschrijfnaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.aanschrijfnaam'),
						value: relatieData.aanschrijfnaam,
					},
				},
			},
			{
				name: 'voorletters',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voorletters'),
						value: relatieData.voorletters,
					},
				},
			},
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						value: getRelatieNaam(relatieData),
						disabled: true,
					},
				},
			},
			{
				name: 'achternaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.achternaam'),
						value: relatieData.achternaam,
						required: true,
					},
				},
			},
			{
				name: 'roepnaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.roepnaam'),
						value: relatieData.roepnaam,
					},
				},
			},
			{
				name: 'voornaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voornaam'),
						value: relatieData.voornaam,
					},
				},
			},
			{
				name: 'initialen',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.initialen'),
						value: relatieData.initialen,
					},
				},
			},
			{
				name: 'tussenvoegsels',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.tussenvoegsels'),
						value: relatieData.tussenvoegsels,
					},
				},
			},
			{
				name: 'geboortedatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.geboortedatum'),
						value: getFormattedDate(relatieData.geboortedatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'geslacht',
				submitPath: 'geslacht.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.geslacht'),
						value: relatieData.geslacht?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'GESLACHT',
						code: relatieData.geslacht?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'overlijdensdatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.overlijdensdatum'),
						value: getFormattedDate(relatieData.overlijdensdatum, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'bsn',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.bsn'),
						value: relatieData.bsn,
					},
				},
			},
			{
				name: 'burgerlijkeStaat',
				submitPath: 'burgerlijkeStaat.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.burgerlijkeStaat'),
						value: relatieData.burgerlijkeStaat?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'BURGERLIJKESTAAT',
						code: relatieData.burgerlijkeStaat?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'nationaliteit',
				submitPath: 'nationaliteit.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.nationaliteit'),
						value: relatieData.nationaliteit?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'NATIONALITEIT',
						code: relatieData.nationaliteit?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'voorkeurstaal',
				submitPath: 'voorkeurstaal.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voorkeurstaal'),
						value: relatieData.voorkeurstaal?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'TAAL',
						code: relatieData.voorkeurstaal?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
		],
		layout: {
			columns: [
				[
					`${COLUMN_SECTION_HEADER_PREFIX}${t('global:domainShared.naam')}`,
					'code',
					'voorletters',
					'voornaam',
					'tussenvoegsels',
					'achternaam',
				],
				[
					`${COLUMN_SECTION_HEADER_PREFIX}${t('global:common.characteristics')}`,
					'geboortedatum',
					'geslacht',
					'aanhef',
					'aanschrijfnaam',
					'nationaliteit',
					'voorkeurstaal',
					'burgerlijkeStaat',
					'overlijdensdatum',
				],
			],
		},
		noSubmit: ['id', 'idExtern', 'idBron', 'naam', 'soort'],
		updateValueFunctions: [
			{
				name: 'naam',
				updateFunction: formData => {
					const { voornaam, tussenvoegsels, achternaam } = formData;

					return [voornaam?.value, tussenvoegsels?.value, achternaam?.value]
						.map(str => (str as string)?.trim())
						.filter(str => !!str)
						.join(' ')
						.trim();
				},
			},
		],
	};

	return schema;
};
