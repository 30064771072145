import { ChangeEvent, forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import { convertFileToBase64 } from '../../utils/convertFileToBase64';
import { UploadFileData } from '../../hooks/useUploadFileData/useUploadFileData';
import styles from './UploadFile.module.scss';

export interface UploadFileProps {
	onChange: (fileData: UploadFileData) => void;
	buttonHidden?: boolean;
	disabled?: boolean;
	maxSize?: number;
}

const FILE_TYPES = ['png', 'jpg', 'jpeg'];
export const MAX_FILE_SIZE = 10 * 1024; // in KB

const UploadFile = forwardRef<HTMLZdsButtonElement, UploadFileProps>(
	({ onChange, buttonHidden, disabled, maxSize = MAX_FILE_SIZE }, ref) => {
		const { t } = useTranslation();
		const [file, setFile] = useState('');
		const inputRef = useRef<HTMLInputElement>(null);

		const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
			const newFile = event.target.files?.[0] ?? null;
			const error = validateFile(newFile);
			const base64 = !error ? await convertFileToBase64(newFile) : null;
			setFile(event.target.value);
			onChange({ file: newFile, error, base64, id: Math.random().toString() });
		};

		const handleButtonClick = () => {
			setFile('');
			inputRef.current?.click();
		};

		const validateFile = (file: File | null): string | null => {
			if (!file) return null;

			if (!FILE_TYPES.some(type => file.type.includes(type))) {
				return t('global:form.errorMessage.fileType', {
					fileTypes: FILE_TYPES.toString().replace(/,/g, ', '),
				});
			}

			if (file.size > maxSize * 1024) {
				return t('global:form.errorMessage.fileSize', {
					filesize: `${(file.size / 1024).toFixed(0)}KB`,
					maxFilesize: `${maxSize.toFixed(0)}KB`,
				});
			}

			return null;
		};

		return (
			<>
				<ZdsButton
					variant="text"
					preset="neutral"
					aspect-square
					onZdsClick={handleButtonClick}
					className={classNames(styles.triggerButton, { [styles.hidden]: buttonHidden })}
					disabled={disabled}
					ref={ref}
				>
					<ZdsIcon library="fa-regular" name="image" />
				</ZdsButton>
				<input type="file" hidden value={file} onChange={handleChange} ref={inputRef} />
			</>
		);
	}
);

export default UploadFile;
