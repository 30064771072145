import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';
import { TFunction } from 'i18next';
import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';

export const generateAccountFormSchema = (t: TFunction): FormSchema => {
	const schema: FormSchema = {
		title: 'Accountgegevens',
		formControls: [
			{
				name: 'gebruikersnaam',
				control: {
					type: ZdsInputText,
					props: {
						id: 'gebruikersnaam',
						label: getStringTranslation(t, 'organisatie:medewerker.account.gebruikersnaam'),
						required: true,
					},
				},
			},
			{
				name: 'status',
				submitPath: 'status.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'organisatie:medewerker.account.status'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'ACCOUNTSTATUS',
						code: '',
						disabled: false,
						hasEmptyValue: false,
					}),
				},
			},
		],
		layout: {
			columns: [['gebruikersnaam', 'status']],
		},
	};

	return schema;
};
