import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputSearch, ZdsPopout } from '@zig-design-system/ui-components-react';

import EmphasizedText from './EmphasizedText/EmphasizedText';
import styles from './AutocompleteInput.module.scss';

export interface AutocompleteItem {
	key: string;
	value: string;
}

interface AutocompleteInputProps {
	items?: AutocompleteItem[];
	selectedItemKey?: string | null;
	label?: string;
	onAutocompleteChange: (value: string) => void;
	onSelect: (item: AutocompleteItem) => void;
}

const AutocompleteInput: FC<AutocompleteInputProps> = ({
	items,
	selectedItemKey,
	label,
	onAutocompleteChange,
	onSelect,
}) => {
	const { t } = useTranslation();
	const relatieInputRef = useRef<HTMLZdsInputSearchElement>(null);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const [value, setValue] = useState('');

	useEffect(() => {
		if (!selectedItemKey) {
			setValue('');
		}
	}, [selectedItemKey]);

	const selectItem = (item: AutocompleteItem) => {
		if (!relatieInputRef.current) {
			return;
		}
		setValue(item.value);
		relatieInputRef.current.blur();
		onSelect(item);
	};

	const onSearch = (searchTerm: string) => {
		if (value !== searchTerm) {
			onAutocompleteChange(searchTerm);
		}
		setValue(searchTerm);
	};

	const searchInputId = 'autocomplete-text-input';

	return (
		<>
			<ZdsInputSearch
				data-testid={searchInputId}
				id={searchInputId}
				value={value}
				onZdsSearch={e => onSearch(e.detail)}
				minSearchLength={1}
				ref={relatieInputRef}
				label={label}
				onFocus={() => setIsDropdownVisible(true)}
				onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)} // delay for ZdsItem onZdsClick to trigger
				className="zds-flex-1"
			/>
			<ZdsPopout
				data-testid="autocomplete-popout"
				target={`#${searchInputId}`}
				open={isDropdownVisible}
				className={styles.popout}
			>
				{(!items || items.length === 0) && t('global:search:noResults')}

				{items?.map(item => (
					<div
						key={item.key}
						onClick={() => selectItem(item)}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								selectItem(item);
							}
						}}
						className={styles.item}
					>
						<div slot="primary-text">
							{selectedItemKey && selectedItemKey === item.key && <strong>{item.value}</strong>}
							{selectedItemKey && selectedItemKey !== item.key && item.value}
							{!selectedItemKey && (
								<EmphasizedText text={item.value} emphasizedPart={relatieInputRef.current?.value ?? ''} />
							)}
						</div>
					</div>
				))}
			</ZdsPopout>
		</>
	);
};

export default AutocompleteInput;
