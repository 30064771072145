import { useEffect, useState } from 'react';

import { TableRow } from '../../components/DataTable/DataTableContentRow/DataTableContentRow';
import { TableBodyData } from '../../components/DataTable/DataTable';

export const useTablePages = <T,>(
	data: { [key: number]: T[] } | T[] | null,
	getPage: (page: T[]) => TableRow[],
	preselected?: string[]
): TableBodyData['rows'] | null => {
	const [pages, setPages] = useState<{
		[key: number]: TableRow[];
	}>({});
	const [rows, setRows] = useState<TableRow[] | null>(null);

	useEffect(() => {
		if (data) {
			if (Array.isArray(data)) {
				return setRows(getPage(data));
			}

			const newPagesData = Object.entries(data).reduce(
				(acc, [pageNum, pageData]) => {
					acc[+pageNum] = getPage(pageData);
					return acc;
				},
				{} as {
					[key: number]: TableRow[];
				}
			);

			setPages(oldPagesData => {
				return {
					...oldPagesData,
					...newPagesData,
				};
			});
		}
	}, [data, preselected]);

	if (!data) return null;

	if (rows) return rows.length ? rows : null;

	return Object.keys(pages)?.length ? pages : null;
};
