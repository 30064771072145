import { createSlice } from '@reduxjs/toolkit';

import { ProfileDataSate } from './types';
import { getProfileData } from './actions';

export const initialState: ProfileDataSate = {
	relatie: null,
	account: null,
	medewerker: null,
	loading: false,
	error: null,
};

export const profileDataSlice = createSlice({
	name: 'profileData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getProfileData.pending, state => {
				state.loading = true;
				state.relatie = null;
				state.account = null;
				state.medewerker = null;
				state.error = null;
			})
			.addCase(getProfileData.fulfilled, (state, { payload: { relatie, account, medewerker } }) => {
				state.loading = false;
				state.relatie = relatie;
				state.account = account;
				state.medewerker = medewerker;
			})
			.addCase(getProfileData.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const profileDataReducer = profileDataSlice.reducer;
