import { ZdsButton, ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';
import {
	CommunityType,
	IBericht,
	Relatie,
	RelatieAvatar,
	RelatieNaam,
	isPinned,
	useAuteur,
	usePostedAt,
} from '@zig.community/community-components';
import classNames from 'classnames';

import styles from './Bericht.module.scss';

export interface BerichtHeaderProps {
	content: IBericht;
	community: CommunityType;
	currentRelatie?: Relatie;
	onShowMenu: () => void;
	showMenu?: boolean;
}

const BerichtHeader = ({ content, community, currentRelatie, onShowMenu, showMenu }: BerichtHeaderProps) => {
	const relativeTime = usePostedAt();
	const auteur = useAuteur(content);

	return (
		<ZdsItem className={classNames('zds-grid', styles.header)}>
			<div slot="content-start">
				<RelatieAvatar relatie={auteur} currentRelatie={currentRelatie} />
			</div>
			<RelatieNaam relatie={auteur} />
			<div className="zds-flex zds-body-md zds-gap-1 zds-muted" slot="secondary-text">
				<span className="zds-flex-none">{relativeTime(content.aanmaakdatum)}</span>
				<span>|</span>
				<span className="zds-truncate">{community.naam}</span>
			</div>
			<div className="zds-flex zds-items-center" slot="content-end">
				{showMenu && (
					<ZdsButton aspectSquare pill onZdsClick={onShowMenu} preset="muted" variant="text" overhang="horizontal">
						<ZdsIcon size="sm" name="ellipsis-vertical" library="fa-regular" />
					</ZdsButton>
				)}
				{isPinned(content) && <ZdsIcon size="sm" name="thumbtack" library="fa-solid" className={styles.pinIcon} />}
			</div>
		</ZdsItem>
	);
};

export default BerichtHeader;
