import { ZdsDivider } from '@zig-design-system/ui-components-react';

import AppSection from './AppSection';
import AccountSection from './AccountSection';
import styles from './Appbar.module.scss';

interface AppSectionProps {
	tempRelativeAppSwitcherPath?: string;
	appName: string;
}

const Appbar = ({ tempRelativeAppSwitcherPath = '', appName }: AppSectionProps) => {
	return (
		<div className={styles.appbar}>
			<AppSection appName={appName} />
			<ZdsDivider vertical className={styles.divider} />
			<AccountSection tempRelativePath={tempRelativeAppSwitcherPath} />
		</div>
	);
};

export default Appbar;
