import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenuInfo } from '../../components/SideMenu/SideMenu';
import PageLayout from '../../components/PageLayout/PageLayout';
import Pane from '../../components/Pane/Pane';
import { useAppDispatch } from '../../redux/store';
import { getProfileData, selectProfileData } from '../../redux/shared/profileData';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/shared/authorizationData';
import Spinner from '../../components/Spinner/Spinner';
import ProfileSection from './ProfileSection';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { getReferentiedataNaam } from '../../utils/translations/getReferentiedataNaam';
import styles from './Profile.module.scss';

interface ProfileProps {
	sideMenuInfo: SideMenuInfo;
}

const Profile: FC<ProfileProps> = ({ sideMenuInfo }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const user = useSelector(selectUser);
	const { relatie, account, medewerker, loading, error } = useSelector(selectProfileData);

	useEffect(() => {
		if (user?.relatieId) {
			dispatch(getProfileData({ relatieId: user.relatieId }));
		}
	}, []);

	const hasData = relatie && account && !loading;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<Pane className={styles.contentPane}>
				{loading && <Spinner />}
				{hasData && (
					<>
						<div className="zds-headline-xxl">{t('account.profile')}</div>
						<div className="zds-margin-top-6">
							<ProfileSection
								title="account.personalData"
								data={[
									{ label: 'global:domainShared.naam', value: user?.naam },
									{ label: 'relatie:relatie.roepnaam', value: relatie.roepnaam },
									{
										label: 'relatie:relatie.geslacht',
										value: getReferentiedataNaam(t, relatie.geslacht?.soort, relatie.geslacht?.code),
									},
									{ label: 'relatie:relatie.geboortedatum', value: relatie.geboortedatum, isDate: true },
								]}
							/>
							<ProfileSection
								title="account.account"
								data={[
									{ label: 'global:common.username', value: account.gebruikersnaam },
									{
										label: 'global:domainShared.status',
										value: getReferentiedataNaam(t, account.status?.soort, account.status?.code),
									},
									{ label: 'global:domainShared.begindatum', value: account.begindatum, isDate: true },
									{ label: 'global:domainShared.einddatum', value: account.einddatum, isDate: true },
								]}
							/>
							<ProfileSection
								title="account.employeeData"
								data={[
									{ label: 'global:domainShared.omschrijving', value: medewerker?.omschrijving },
									{
										label: 'global:domainShared.soort',
										value: getReferentiedataNaam(t, medewerker?.soort?.soort, medewerker?.soort?.code),
									},
									{ label: 'organisatie:organisatie.organisatie', value: medewerker?.organisatie.relatie?.naam },
									{
										label: 'organisatie:organisatie.organisatorischeEenheden',
										value: medewerker?.organisatorischeEenheden.map(e => e.naam ?? ''),
									},
									{
										label: 'organisatie:organisatie.medewerkerrollen',
										value: medewerker?.rollen?.map(r => r.naam),
									},
								]}
							/>
						</div>
					</>
				)}
				{error && <ErrorMessage message={t(error)} />}
			</Pane>
		</PageLayout>
	);
};

export default Profile;
