import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityCategorieType, CommunityType, RelatieNatuurlijkPersoon } from '@zig.community/community-components';
import { ZdsProgressBar } from '@zig-design-system/ui-components-react';

import { Community, CommunityCategorie } from '../../../api/models';
import UploadFile from '../../../../../global/components/UploadFile/UploadFile';
import FileItem from '../../../../../global/components/UploadFile/FileItem/FileItem';
import { useAppSelector } from '../../../../../global/redux/store';
import { selectUserRelatie } from '../../../../../global/redux/shared/authorizationData';
import { UploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import MessageForm from './MessageForm';
import styles from './CreateMessage.module.scss';

interface WriteMessageProps {
	community: Community;
	categorie: CommunityCategorie | null;
	bericht: string;
	setBericht: (value: string) => void;
	setFileData: (fileData: UploadFileData) => void;
	removeFile: () => void;
	fileData?: UploadFileData;
	author?: RelatieNatuurlijkPersoon;
	loading?: boolean;
}

export const WriteMessage: FC<WriteMessageProps> = ({
	community,
	categorie,
	bericht,
	setBericht,
	setFileData,
	removeFile,
	fileData,
	author,
	loading,
}) => {
	const { t } = useTranslation();
	const relatie = useAppSelector(selectUserRelatie);

	if (!author) return null;

	return (
		<div className="zds-margin-top-1">
			{loading && <ZdsProgressBar indeterminate />}
			{community && (
				<div className={styles.formContainer}>
					<MessageForm
						relatie={author}
						currentRelatie={relatie}
						communityCategorie={categorie as CommunityCategorieType | null}
						community={community as unknown as CommunityType}
						placeholder={t('newPost.placeholder')}
						bericht={bericht}
						onChangeBericht={setBericht}
						required={!fileData?.file}
					/>
				</div>
			)}
			<UploadFile onChange={setFileData} />
			{fileData?.file && (
				<div className="zds-margin-top-4">
					<FileItem {...fileData} onRemove={removeFile} />
				</div>
			)}
		</div>
	);
};
