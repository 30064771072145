import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormSchema } from '../../../../../global/components/Form/Form.types';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { getStringTranslation } from '../../../../../global/utils/getStringTranslation';
import { getDateFormControlData } from '../../../../../global/utils/form/getDateFormControlData';
import { getFormattedDate } from '../../../../../global/utils/date/formatDate/formatDate';
import { getReferentiedataSelectOptions } from '../../../../../global/utils/form/getReferentiedataSelectOptions';
import { Medewerkerrol } from '../../../api/models';

export const useMedewerkerrolFormSchema = (currentMedewerkerrolData?: Medewerkerrol | null): FormSchema | null => {
	const { t, i18n } = useTranslation();
	const [schema, setSchema] = useState<FormSchema | null>(null);
	const dateFormat = getDateFormat(i18n.language);

	const isExternal = !!currentMedewerkerrolData?.idExtern || undefined;

	useEffect(() => {
		const newSchema: FormSchema = {
			title: t('global:common.generalSettings') ?? '',
			formControls: [
				{
					name: 'naam',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.naam'),
							value: currentMedewerkerrolData?.naam ?? '',
							required: true,
							disabled: isExternal,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'code',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.code'),
							value: currentMedewerkerrolData?.code ?? '',
							disabled: isExternal,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'idExtern',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.externeCode'),
							value: currentMedewerkerrolData?.idExtern ?? '',
							disabled: true,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'idBron',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(t, 'global:domainShared.bron'),
							value: currentMedewerkerrolData?.idBron ?? '',
							disabled: true,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'soort',
					submitPath: 'soort.code',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(t, 'global:domainShared.soort'),
							value: currentMedewerkerrolData?.soort?.code ?? '',
							disabled: isExternal,
						},
						options: getReferentiedataSelectOptions({
							t,
							soort: 'MEDEWERKERROL',
							code: currentMedewerkerrolData?.soort?.code ?? '',
							disabled: false,
							hasEmptyValue: true,
						}),
					},
					layoutOptions: { fullWidth: true },
				},
				getDateFormControlData({
					t,
					dateFormat,
					name: 'begindatum',
					value: getFormattedDate(currentMedewerkerrolData?.begindatum as unknown as string, dateFormat),
					withLabel: true,
					fullWidth: true,
					disabled: isExternal,
				}),
				getDateFormControlData({
					t,
					dateFormat,
					name: 'einddatum',
					value: getFormattedDate(currentMedewerkerrolData?.einddatum as unknown as string, dateFormat),
					withLabel: true,
					fullWidth: true,
					disabled: isExternal,
				}),
			],
			layout: {
				columns: [
					['code', 'naam', 'begindatum', 'einddatum'],
					['soort', 'idBron', 'idExtern'],
				],
			},
			noSubmit: ['idExtern', 'idBron'],
		};

		setSchema(newSchema);
	}, [currentMedewerkerrolData]);

	return schema;
};
