import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Medewerker } from '../../../api/models';
import { getRelatieNaam } from '../../../../relatiebeheer/utils/getRelatieNaam';
import { NatuurlijkPersoon } from '../../../../relatiebeheer/api/models';

export const useMapMedewerkersToTableData = (
	medewerkers: {
		[key: number]: Medewerker[];
	} | null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [
			t('global:domainShared.naam'),
			t('global:domainShared.soort'),
			t('global:account.gebruikersnaam'),
			t('global:account.account'),
		],
		expand: [],
	};

	const getStatus = (medewerker: Medewerker): string => {
		const relatie = medewerker.relatie as NatuurlijkPersoon;
		return getReferentiedataNaam(t, 'ACCOUNTSTATUS', relatie.account?.status?.code);
	};

	const getGebruikersnaam = (medewerker: Medewerker): string => {
		const relatie = medewerker.relatie as NatuurlijkPersoon;
		return relatie.account?.gebruikersnaam ?? '';
	};

	const getPage = useCallback((medewerkers: Medewerker[]) => {
		return medewerkers.map(medewerker => {
			return {
				id: medewerker.id ?? '',
				cells: [
					{ mainText: getRelatieNaam(medewerker.relatie) || t('global:form.errorMessage.missingData') },
					{ mainText: getReferentiedataNaam(t, 'MEDEWERKERSOORT', medewerker.soort?.code) },
					{ mainText: getGebruikersnaam(medewerker) },
					{ mainText: getStatus(medewerker) },
				],
				expand: [],
			};
		});
	}, []);

	const pages = useTablePages(medewerkers, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
