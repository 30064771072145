import { ComponentProps, FC } from 'react';
import { ZdsChip, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import styles from './ChipsPopoutContent.module.scss';

export interface ChipsPopoutContentProps {
	title: string;
	data: { label: string; iconOptions: ComponentProps<typeof ZdsIcon> }[];
}

const ChipsPopoutContent: FC<ChipsPopoutContentProps> = ({ title, data }) => {
	return (
		<div className={styles.container}>
			<div className={classNames('zds-headline-xs', styles.title)}>{title}</div>
			<div className={styles.chipsContainer}>
				{data.map(d => (
					<ZdsChip key={d.label}>
						<ZdsIcon {...d.iconOptions} slot="content-start" />
						<span slot="primary-text">{d.label}</span>
					</ZdsChip>
				))}
			</div>
		</div>
	);
};

export default ChipsPopoutContent;
