import { LoginResponseResource } from '../../api/models';
import AuthAPI from '../../api';
import { logout } from '../../utils/auth/logout';

let refreshTokenApiRequest: Promise<void> | null = null;

const sendCachedRefreshTokenRequest = () => {
	if (!refreshTokenApiRequest) {
		refreshTokenApiRequest = new Promise(resolve => {
			refreshToken().then(() => {
				refreshTokenApiRequest = null;
				resolve();
			});
		});
	}

	return refreshTokenApiRequest;
};

export const saveTokenData = (responseData: LoginResponseResource) => {
	if (!responseData.tokens) {
		return;
	}
	localStorage.setItem('accessToken', responseData.tokens.accessToken);
	localStorage.setItem('refreshToken', responseData.tokens.refreshToken);
	localStorage.setItem('idToken', responseData.tokens.idToken);
	localStorage.setItem('expiresAt', responseData.tokens.expiresAt.toString());
	localStorage.setItem('refreshTokenExpiresAt', responseData.tokens.refreshTokenExpiresAt.toString());
};

const isTokenExpired = (localStorageKey: string, addOneMinuteMargin = false): boolean => {
	const expiresAt = localStorage.getItem(localStorageKey);
	if (!expiresAt) {
		return false;
	}
	const isLessThan = addOneMinuteMargin ? 60000 : 0;
	const expiresTime = new Date(parseInt(expiresAt, 10) * 1000).getTime();
	const now = new Date().getTime();

	return expiresTime - now <= isLessThan;
};

const refreshToken = async () => {
	try {
		const accessToken = localStorage.getItem('accessToken');
		const refreshToken = localStorage.getItem('refreshToken');
		const body = { accessToken, refreshToken };

		const responseData = await AuthAPI.refreshToken(body);

		if (!responseData.tokens) {
			throw new Error('Auth error');
		}

		saveTokenData(responseData);
	} catch (error) {
		logout(window.location.origin);
	}
};

export const refreshTokenIfNeeded = async () => {
	if (isTokenExpired('expiresAt', true) && !isTokenExpired('refreshTokenExpiresAt')) {
		await sendCachedRefreshTokenRequest();
	}
};
