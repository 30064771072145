import { FC, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsIcon, ZdsButton, ZdsItem } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import { useAppSelector } from '../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../redux/currentCommunityData';
import { Informatieobject } from '../../api/models';
import Pane from '../../../../global/components/Pane/Pane';
import { getLocaleDateString } from '../../../../global/utils/date/getLocaleDateString';
import ContextMenu from '../../../../global/components/ContextMenu/ContextMenu';
import styles from './CommunityDetails.module.scss';

export const OPTIONS = [
	{ path: 'edit', label: 'community:communityInformationPage.editSettings' },
	{ path: 'leden', label: 'community:communityInformationPage.manageMembers' },
	{ path: 'categorie', label: 'community:communityInformationPage.manageCategories' },
];

const CommunityDetails: FC = () => {
	const { t, i18n } = useTranslation();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const location = useLocation();
	const navigate = useNavigate();
	const backgroundPicture = currentCommunityData?.bijlagen?.filter(doc => doc.detailSoort?.code === 'ACH')[0];
	const optionsButtonRef = useRef<HTMLZdsButtonElement>(null);

	const renderImage = (image?: Informatieobject, style?: string) => (
		<div className="zds-flex zds-gap-3">
			<div className="zds-flex zds-flex-1">
				{image ? (
					<img className={style} slot="media" src={image.bestand} alt="" />
				) : (
					<div className={classNames(style, styles.noImage)}>
						<ZdsIcon size="xxl" name="image" library="fa-solid" color="" />
					</div>
				)}
			</div>
		</div>
	);

	const membersNum = currentCommunityData?.rolRelaties?.filter(rr => rr.soort.code === 'LID').length ?? 0;
	const optionsButtonId = 'detailsOptions';

	return (
		<div className={styles.container}>
			{currentCommunityData && (
				<>
					{renderImage(backgroundPicture, styles.backgroundPicture)}
					<div>
						<div className="zds-headline-md zds-margin-bottom-3">
							{t('community:communityInformationPage.header.title')}
						</div>
						<Pane>
							<div className="zds-flex zds-justify-content-berween zds-items-start zds-margin-bottom-6">
								<div className="zds-flex-1">{currentCommunityData.naam}</div>
								<ZdsButton
									aspectSquare
									pill
									preset="muted"
									variant="text"
									overhang="horizontal"
									id={optionsButtonId}
									ref={optionsButtonRef}
								>
									<ZdsIcon size="sm" name="ellipsis-vertical" library="fa-regular" />
								</ZdsButton>
								<ContextMenu
									target={`#${optionsButtonId}`}
									targetRef={optionsButtonRef}
									className="zds-flex zds-flex-col"
									position="end"
								>
									{OPTIONS.map(({ path, label }) => (
										<ZdsItem
											key={path}
											onZdsClick={() => {
												navigate(`${location.pathname}/${path}`);
											}}
										>
											<div slot="primary-text">{t(label)}</div>
										</ZdsItem>
									))}
								</ContextMenu>
							</div>
							<div className="zds-flex">
								<div className={styles.infoLabel}>{t('global:domainShared.begindatum')}:</div>
								{currentCommunityData.begindatum && (
									<div>{getLocaleDateString(currentCommunityData.begindatum, i18n.language)}</div>
								)}
							</div>
							<div className="zds-flex">
								<div className={styles.infoLabel}>{t('global:domainShared.einddatum')}:</div>
								{currentCommunityData.einddatum && (
									<div>{getLocaleDateString(currentCommunityData.einddatum, i18n.language)}</div>
								)}
							</div>
							<div className="zds-flex zds-margin-top-6">
								<div className={styles.infoLabel}>{t('community:communityInformationPage.numberOfMembers')}:</div>
								<div>{membersNum}</div>
							</div>
							{currentCommunityData?.omschrijving && (
								<div className="zds-flex zds-flex-col zds-margin-top-6">
									<div>{t('global:domainShared.omschrijving').replace(/[^a-zA-Z\s]/g, '')}</div>
									<div>{currentCommunityData.omschrijving}</div>
								</div>
							)}
						</Pane>
					</div>
					<div>
						<div className="zds-headline-md zds-margin-bottom-3">{t('community:community.managers')}</div>
						<Pane className={styles.nonMembersPane}>
							{currentCommunityData?.rolRelaties
								?.filter(rr => rr.soort.code !== 'LID')
								.map(rr => (
									<div className={styles.row} key={rr.relatie.naam}>
										<div className={styles.infoLabel}>{rr.relatie.naam}</div>
										<div>{t(`referentiedata:COMMUNITYROLSOORT.${rr.soort.code}`)}</div>
									</div>
								))}
						</Pane>
					</div>
				</>
			)}
		</div>
	);
};

export default CommunityDetails;
