import { FC, ReactNode } from 'react';

import { SideMenuInfo } from '../SideMenu/SideMenu';
import PageLayoutWrapper from '../PageLayoutWrapper/PageLayoutWrapper';
import styles from './PageLayout.module.scss';

interface PageLayoutProps {
	sideMenuInfo?: SideMenuInfo;
	children: ReactNode;
	sideContent?: ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({ sideMenuInfo, children, sideContent }) => {
	return (
		<PageLayoutWrapper sideMenuInfo={sideMenuInfo}>
			<div className={styles.content}>
				<div className={styles.mainColumn}>{children}</div>
				{sideContent && <div className={styles.sideColumn}>{sideContent}</div>}
			</div>
		</PageLayoutWrapper>
	);
};

export default PageLayout;
