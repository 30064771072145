import { createAsyncThunk } from '@reduxjs/toolkit';
import { Medewerker } from '../../api/models';
import OrganisatieAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';

export const getCurrentMedewerkerData = createAsyncThunk<Medewerker, string, { rejectValue: string }>(
	'currentMedewerkerData/getMedewerkerData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await OrganisatieAPI.getMedewerker(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateMedewerker = createAsyncThunk<
	Medewerker,
	{ id: string; payload: FormSubmitData; medewerkerrollen: string[] },
	{ rejectValue: string }
>('currentMedewerkerData/updateMedewerker', async ({ id, payload, medewerkerrollen }, { rejectWithValue }) => {
	try {
		await OrganisatieAPI.updateMedewerker(id, payload, medewerkerrollen);
		const response = await OrganisatieAPI.getMedewerker(id);

		return response;
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});
