import { createAsyncThunk } from '@reduxjs/toolkit';

import CommunityAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Community } from '../../api/models';
import { ListResponse } from '../../../../global/api';
import { SortableOption } from '../../../../global/components/DataTable/DataTable';

export const getCommunities = createAsyncThunk<
	ListResponse<Community>,
	{
		term?: string;
		offset?: number;
		limit?: number;
		searchData?: {
			[key: string]: string;
		};
		sortData?: {
			[p: string]: SortableOption;
		};
	},
	{ rejectValue: string }
>('communities/getCommunities', async ({ term, offset, limit, searchData, sortData }, { rejectWithValue }) => {
	return CommunityAPI.getCommunities({ term, offset, limit, searchData, sortData }).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});
