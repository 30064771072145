import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsForm } from '@zig-design-system/ui-components-react';

import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { Language } from '../../../api/models';
import LanguageSelect, { LanguageData } from '../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { validateDates } from '../../../../../global/utils/form/validateDates';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import { useCommunityCategoryCreateFormSchema } from '../../../hooks/form/schema/create/communityCategory/useCommunityCategoryCreateFormSchema';
import { useNaamPreviewData } from '../../../hooks/useNaamPreviewData.ts/useNaamPreviewData';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import StepContent from '../../../components/StepContent/StepContent';
import { useLanguageSelect } from '../../../hooks/useLanguageSelect/useLanguageSelect';
import styles from './NewCategory.module.scss';

const DEFAULT_LANGUAGE = Language.Dutch;

export type NewCategorySubmitData = { formSubmitData: FormSubmitData };

export interface NewCategoryProps {
	initData: NewCategorySubmitData | null;
	onCommunityCategoryNameChange: (name: string) => void;
	onSave: (data: NewCategorySubmitData) => void;
}

const formId = 'createCommunityCategoryInitInfoForm';

const NewCategory: FC<NewCategoryProps> = ({ initData, onCommunityCategoryNameChange, onSave }) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [errorMessage, setErrorMessage] = useState('');
	const dateFormat = getDateFormat(i18n.language);
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();

	const formSchema = useCommunityCategoryCreateFormSchema({
		data: formData,
		initData: initData?.formSubmitData,
		language: selectedLanguage,
		dateOptions: { withLabel: false },
	});

	const { naam } = useNaamPreviewData(formData, selectedLanguage);

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	useEffect(() => {
		onCommunityCategoryNameChange(naam);
	}, [naam]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async () => {
		if (!formData) return;

		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (!validateDefaultLanguageData()) return;

		const datesInvalidError = validateDates(formSubmitData, formData, dateFormat);
		if (datesInvalidError) {
			return setErrorMessage(datesInvalidError);
		}

		setErrorMessage('');
		onSave({ formSubmitData });
	};

	const validateDefaultLanguageData = () => {
		if (!formData) return true;

		if (
			selectedLanguage !== DEFAULT_LANGUAGE &&
			!((formData?.naam.languageData?.[DEFAULT_LANGUAGE] as string).trim() ?? '')
		) {
			setErrorMessage('community:createCategoryPage.form.dutchNameMissingErrorMessage');
			return false;
		}

		return true;
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
		setErrorMessage('');
	};

	const handleSubmitClick = () => {
		setTimeout(() => {
			setErrorMessage('');
			validateDefaultLanguageData();
			(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
		}, 0);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	const content = () => (
		<ZdsForm onZdsSubmit={handleSubmit} id={formId}>
			<div className={styles.formInputsContainer}>
				{renderFormControl('naam', 'large')}
				{renderFormControl('omschrijving', 'large')}
				{renderFormControl('soort', 'large')}
				<div className="zds-headline-lg zds-color-inherit">
					{t('community:createCommunityPage.form.publicationStatus')}
				</div>
				<div className="zds-flex zds-gap-4">
					<div className={styles.label}>{`${t('global:domainShared.begindatum')} / ${t(
						'global:domainShared.einddatum'
					)}`}</div>
					<div className="zds-flex zds-items-center zds-gap-3">
						{renderFormControl('begindatum', 'large')}
						<div>{t('global:common.until').toLowerCase()}</div>
						{renderFormControl('einddatum', 'large')}
					</div>
				</div>
			</div>
		</ZdsForm>
	);

	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			<div className="zds-flex zds-justify-content-between zds-items-center  zds-margin-bottom-12">
				<div className="zds-headline-lg">{t('global:domainShared.naam')}</div>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			<StepContent
				formSchema={formSchema}
				formData={formData}
				errorMessage={errorMessage}
				backButton={{
					label: 'global:common.cancel',
					onClick: () => {
						navigate(`/communitybeheer/community/${params.id}/categorie`);
					},
				}}
				nextButton={{
					label: 'global:common.next',
					onClick: handleSubmitClick,
				}}
			>
				{content()}
			</StepContent>
		</div>
	);
};

export default NewCategory;
