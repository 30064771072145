import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsAdvancedSelect, ZdsIcon, ZdsAdvancedSelectItem } from '@zig-design-system/ui-components-react';
import { ZdsAdvancedSelectCustomEvent } from '@zig-design-system/ui-components';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { Informatieobject } from '../../../apps/communitybeheer/api/models';

const renderIcon = (image?: Informatieobject) => {
	const innerStyle = {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'var(--zds-color-grey-200)',
		backgroundColor: 'var(--zds-color-grey-50)',
	};
	return (
		<div style={{ width: '50px', height: '50px' }}>
			{image ? (
				<img src={image.bestand} alt="" style={innerStyle} />
			) : (
				<div style={innerStyle}>
					<ZdsIcon size="sm" name="object-group" library="fa-solid" />
				</div>
			)}
		</div>
	);
};

export interface AdvancedSelectItem {
	id: string;
	label: string;
	secondaryText?: string;
	icon?: Informatieobject;
}

export interface AdvancedSelectInputProps {
	data: AdvancedSelectItem[] | null;
	searchData: (term: string) => void;
	selectedValues?: AdvancedSelectItem[];
	ignoreValues?: AdvancedSelectItem[];
	onChange: (data: AdvancedSelectItem[]) => void;
	validationError?: string;
	dataError: string;
	explanationText?: string;
	placeholder?: string;
	iconType?: string;
	inputTextType?: string;
	multiple?: boolean;
	clearable?: boolean;
	showSelected?: boolean;
	disabled?: boolean;
}

const AdvancedSelectInput: FC<AdvancedSelectInputProps> = ({
	data,
	searchData,
	selectedValues = [],
	ignoreValues = [],
	onChange,
	validationError,
	dataError,
	explanationText,
	placeholder,
	iconType,
	inputTextType,
	multiple = true,
	clearable = true,
	showSelected,
	disabled,
}) => {
	const { t } = useTranslation();
	const [advancedSelectItems, setAdvancedSelectItems] = useState<AdvancedSelectItem[]>([]);
	const [allSearchedItems, setAllSearchedItems] = useState<AdvancedSelectItem[]>([]);
	const [initData, setInitData] = useState(false);
	const selectRef = useRef<HTMLZdsAdvancedSelectElement>(null);

	useEffect(() => {
		searchData('');
	}, []);

	useEffect(() => {
		if (initData && selectRef.current && selectedValues?.length) {
			selectRef.current.setValue(selectedValues.map(v => v.id));
		}
	}, [initData]);

	useEffect(() => {
		if (data) {
			setInitData(true);
		}
		const newRelatiesAdvancedSelectItems: AdvancedSelectItem[] = data ?? [];
		const allListItems: AdvancedSelectItem[] = [...selectedValues, ...newRelatiesAdvancedSelectItems];
		const allListItemsIds = [...new Set([...allListItems.map(v => v.id)])];
		const ignoreValuesIds = ignoreValues.map(i => i.id);

		const newItems = getItemsFromIdsList(allListItemsIds, allListItems).filter(i => !ignoreValuesIds.includes(i.id));

		setAdvancedSelectItems(newItems);
		setAllSearchedItems(old => [...old, ...allListItems]);
	}, [data]);

	const handleInputChange = (e: ZdsAdvancedSelectCustomEvent<string>) => {
		searchData(e.detail);
	};

	const handleChange = async () => {
		const values = (await selectRef.current?.getValue(true)) as string[];
		onChange(getItemsFromIdsList(values, allSearchedItems));
	};

	const getItemsFromIdsList = (ids: string[], items: AdvancedSelectItem[]): AdvancedSelectItem[] => {
		return ids.map(id => items.find(item => item.id === id) as AdvancedSelectItem).filter(Boolean);
	};

	return (
		<div className="zds-flex zds-flex-col zds-gap-1 zds-flex-1">
			{explanationText && <div className="zds-margin-bottom-2">{explanationText}</div>}
			{validationError && <ErrorMessage message={t(validationError)} />}
			<ZdsAdvancedSelect
				onZdsInputChange={handleInputChange}
				onZdsChange={handleChange}
				searchable
				{...(multiple && { multiple })}
				{...(clearable && { clearable: clearable && !disabled })}
				{...(iconType && { iconType })}
				{...(inputTextType && { inputTextType })}
				{...(showSelected && { showSelected })}
				placeholder={placeholder}
				ref={selectRef}
				disabled={disabled}
			>
				{advancedSelectItems.map(item => (
					<ZdsAdvancedSelectItem key={item.id} item-id={item.id}>
						<span slot="primary-text">{item.label}</span>
						{item.secondaryText && <span slot="secondary-text">{item.secondaryText}</span>}
						{iconType && <div slot="icon">{renderIcon(item.icon)}</div>}
					</ZdsAdvancedSelectItem>
				))}
			</ZdsAdvancedSelect>
			{dataError && <ErrorMessage message={t(dataError)} />}
		</div>
	);
};

export default AdvancedSelectInput;
