import { FC, useEffect, useState } from 'react';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../../global/redux/store';
import { getNatuurlijkPersoonRelaties, selectRelaties } from '../../../../../redux/relaties';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { createCommunityRolRelatie } from '../../../../../redux/currentCommunityData';
import { Community, RolRelatiePayload } from '../../../../../api/models';
import AddMemberForm from '../../../../../components/AddMemberForm/AddMemberForm';

const allowedCommunityrolSoortCodes = ['LID', 'COM'];

interface AddNewCommunityMemberProps {
	community: Community;
}

const AddNewCommunityMember: FC<AddNewCommunityMemberProps> = ({ community }) => {
	const dispatch = useAppDispatch();
	const { relaties } = useAppSelector(selectRelaties);
	const [selectedRol, setSelectedRol] = useState<string | null>(null);
	const [selectedRelatie, setSelectedRelatie] = useState<string | null>(null);

	useEffect(() => {
		searchRelaties('');
	}, []);

	const searchRelaties = (term: string) => {
		setSelectedRelatie(null);
		dispatch(getNatuurlijkPersoonRelaties({ term: term.trim(), limit: 10 }));
	};

	const handleSubmit = () => {
		if (!selectedRol || !selectedRelatie) {
			return;
		}

		const newRolRelatie: RolRelatiePayload = { soort: { code: selectedRol }, relatie: { id: selectedRelatie } };

		dispatch(
			createCommunityRolRelatie({
				id: community.id,
				body: newRolRelatie,
			})
		);
	};

	const handleToggleForm = () => {
		setSelectedRelatie(null);
	};

	const communityRolItems = getReferentiedataSelectOptions({
		t,
		soort: 'COMMUNITYROLSOORT',
	}).filter(c => allowedCommunityrolSoortCodes.includes(c.value));

	const autocompleteItems = (relaties ?? []).map(r => ({
		key: r.id,
		value: r.naam,
	}));

	const isSaveDisabled =
		!selectedRelatie ||
		!selectedRol ||
		!!community.rolRelaties?.some(({ relatie, soort }) => relatie.id === selectedRelatie && selectedRol === soort.code);

	return (
		<AddMemberForm
			rolItems={communityRolItems}
			autocompleteItems={autocompleteItems}
			selectedRelatie={selectedRelatie}
			isSaveDisabled={isSaveDisabled}
			onFormToggle={handleToggleForm}
			onRolChange={setSelectedRol}
			onAutocompleteChange={searchRelaties}
			onAutocompleteSelect={setSelectedRelatie}
			onSubmit={handleSubmit}
		/>
	);
};

export default AddNewCommunityMember;
