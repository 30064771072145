import { createAsyncThunk } from '@reduxjs/toolkit';

import { ListResponse } from '../../../../global/api';
import { Overeenkomsten } from '../../api/models';
import VastgoedAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getOvereenkomsten = createAsyncThunk<
	ListResponse<Overeenkomsten>,
	{ offset?: number; limit?: number; clear?: boolean },
	{
		rejectValue: string;
	}
>('vastgoed/getOvereenkomsten', async ({ offset, limit } = {}, { rejectWithValue }) => {
	return VastgoedAPI.getOvereenkomsten({ offset, limit }).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});
