import { ZdsButton, ZdsDrawer, ZdsIcon, ZdsSideSheet, ZdsTab, ZdsTabs } from '@zig-design-system/ui-components-react';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import styles from './layout-poc.module.scss';
import classNames from 'classnames';
import Pane from '../../../../global/components/Pane/Pane';
import SideMenu from '../../../../global/components/SideMenu/SideMenu';
import { useCallback, useRef, useState } from 'react';
import layoutPocSideMenuInfo from './layout-poc-side-menu-info';

const LayoutPocMain = () => {
	const [isSideSheetExpanded, setIsSideSheetExpanded] = useState(false);
	const drawerRef = useRef<HTMLZdsDrawerElement>(null);

	const dynamicSideSheetExpansionClasses = classNames({
		[styles.dynamicSideSheetExpansion]: true,
		[styles.dynamicSideSheetExpansionExpanded]: isSideSheetExpanded,
	});

	const toggleDynamicSideSheet = useCallback(() => {
		setIsSideSheetExpanded(!isSideSheetExpanded);
	}, [isSideSheetExpanded]);

	const openDrawer = useCallback(() => {
		drawerRef.current?.show();
	}, []);

	return (
		<div className={styles.pageContainer}>
			<div className={classNames(styles.utilityBar, styles.utilityBarLevel1)}>
				<div className="zds-margin-left-3 zds-flex zds-items-center zds-gap-2 zds-color-danger">
					<ZdsIcon name="triangle-exclamation" library="fa-solid"></ZdsIcon>
					Utility bar 1
				</div>
			</div>
			<div className={styles.mainRow}>
				<div className={styles.sideMenuContainer}>
					<SideMenu info={layoutPocSideMenuInfo} />
				</div>
				<div className={styles.sideSheetContainer}>
					<ZdsSideSheet position="left">
						<div slot="body">Left side sheet</div>
					</ZdsSideSheet>
				</div>
				<div className={styles.page}>
					<div className={styles.mainColumn}>
						<div className={classNames(styles.utilityBar, styles.utilityBarLevel2)}>
							<ZdsTabs>
								<ZdsTab active>Utility bar 2</ZdsTab>
								<ZdsTab>Tab 2</ZdsTab>
								<ZdsTab>Tab 3</ZdsTab>
							</ZdsTabs>
						</div>
						<div className={styles.mainColumnContent}>
							<div className={classNames(styles.utilityBar, styles.utilityBarLevel3)}>
								<ZdsButton variant="text" preset="neutral">
									<ZdsIcon name="arrow-left" library="fa-regular" size="sm"></ZdsIcon>
									Utility bar 3
								</ZdsButton>
							</div>
							<div className={styles.pageHeaderContainer}>
								<PageHeader title="Layout POC | Main" iconName="table-layout" iconLibrary="fa-regular" />
							</div>
							<div className={styles.contentColumns}>
								<Pane className={classNames('zds-body', styles.contentColumn, styles.contentColumnFixed)}>
									Side column
								</Pane>
								<Pane className={classNames('zds-body', styles.contentColumn)}>
									<div>Main column</div>
									<ZdsButton className="zds-margin-top-6" onZdsClick={openDrawer}>
										Open drawer
									</ZdsButton>
									<ZdsDrawer origin="right" ref={drawerRef} className={styles.drawer}>
										<div className={styles.drawerContent}>Drawer content</div>
									</ZdsDrawer>
								</Pane>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.dynamicSideSheetContainer}>
					<ZdsSideSheet position="right">
						<div slot="body" className={styles.dynamicSideSheetBodyWrapper}>
							<div className={dynamicSideSheetExpansionClasses}>
								<div className={styles.dynamicSideSheetExpansionInner}>Expandable side sheet</div>
							</div>
							<div className={styles.dynamicSideSheetNormal}>
								<ZdsButton
									className={styles.dynamicSideSheetButton}
									preset="loud"
									variant="text"
									size="sm"
									onZdsClick={toggleDynamicSideSheet}
								>
									<ZdsIcon name="book" library="fa-light"></ZdsIcon>
								</ZdsButton>
							</div>
						</div>
					</ZdsSideSheet>
				</div>
			</div>
		</div>
	);
};

export default LayoutPocMain;
