import { Permission } from '../../api/models';
import { selectUser } from '../../redux/shared/authorizationData';
import { useAppSelector } from '../../redux/store';

export const usePermissions = () => {
	const user = useAppSelector(selectUser);

	return (permission: Permission): boolean => {
		return !!user?.functies?.some(f => f.code === permission);
	};
};
