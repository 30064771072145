import { FC, useState } from 'react';
import classNames from 'classnames';
import { ApiEmotie, EmotieButtonOptions, IBericht, NewEmotie } from '@zig.community/community-components';

import { Reactie } from '../../../ZigCommunity/Reactie';
import { Bericht } from '../../../../api/models';
import MessageMenu from '../../messageMenu/MessageMenu';
import { useBerichtPermissions } from '../../../../hooks/useBerichtPermissions/useBerichtPermissions';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import { selectUserRelatie } from '../../../../../../global/redux/shared/authorizationData';
import { getEmoties, getRolRelaties } from '../../../ZigCommunity/mapBerichtRelatieUtils';
import { deleteBerichtEmotie, likeBericht } from '../../../../redux/berichten';
import styles from './Comment.module.scss';

export interface CommentProps {
	content: IBericht;
	onEdit: (bericht: Bericht) => void;
}

export const Comment: FC<CommentProps> = ({ onEdit, content }) => {
	const relatie = useAppSelector(selectUserRelatie);
	const hasPermission = useBerichtPermissions();
	const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const hasReported =
		hasPermission('COM_LEZ_MEL_ONG') && content.emoties.filter(e => e?.soort?.code === 'ONG').length > 0;

	const containerClasses = classNames(styles.commentContainer, { [styles.hasReported]: hasReported });

	const handleAddBerichtEmotie = async (newEmotie: NewEmotie) => {
		dispatch(likeBericht({ berichtId: newEmotie.bericht.id, relatieId: newEmotie.relatie.id }));
	};

	const handleDeleteBerichtEmotie = async (emotie: ApiEmotie) => {
		dispatch(deleteBerichtEmotie({ emotieId: emotie.id }));
	};

	const finalContent: IBericht = {
		...content,
		aanmaakdatum: new Date(content.aanmaakdatum),
		rolRelaties: getRolRelaties(content),
		emoties: getEmoties(content),
	};

	const emotieButtonOptions: EmotieButtonOptions = {
		isLoading: false,
		variant: 'large',
		onAddBerichtEmotie: handleAddBerichtEmotie,
		onDeleteBerichtEmotie: handleDeleteBerichtEmotie,
	};

	return (
		<div className={containerClasses} id={content.id}>
			<MessageMenu
				bericht={content}
				isMenuVisible={isMenuVisible}
				setIsMenuVisible={setIsMenuVisible}
				onEdit={() => onEdit({ ...content } as any)}
				options={{ edit: true, deleteInappropriteContentReports: true, delete: true }}
			/>
			<Reactie
				content={finalContent}
				currentRelatie={relatie}
				emotieButtonOptions={emotieButtonOptions}
				onShowMenu={() => setIsMenuVisible(prev => !prev)}
			/>
		</div>
	);
};
