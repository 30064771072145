import axios from 'axios';
import { logout } from './global/utils/auth/logout';
import { refreshTokenIfNeeded } from './global/utils/auth/tokenService';

const api = axios.create({
	baseURL: window.env.API_URL,
});

api.interceptors.request.use(
	config => {
		return refreshTokenIfNeeded().then(() => {
			const token = localStorage.getItem('accessToken');
			if (token) {
				config.headers['Authorization'] = `Bearer ${token}`;
			}
			const actAsOrganisation = localStorage.getItem('act-as-organisatie');
			if (actAsOrganisation) {
				config.headers['x-parameters-organisatie'] = actAsOrganisation;
			}

			return config;
		});
	},
	error => {
		console.log('REQUEST ERROR:', error);
	}
);

api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error?.response?.status === 401) {
			logout(window.location.href);
		}
		throw error;
	}
);

export default api;
