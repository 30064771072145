import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../../global/components/Form/Form';
import { FormSchema, FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import { deleteCommunityRolRelatie, selectCurrentCommunityData } from '../../../../redux/currentCommunityData';
import { generateUpdateCommunityMembersFormSchema } from '../../../../utils/form/schema/update/communityMembers';
import DataTable, { TableIconSettings } from '../../../../../../global/components/DataTable/DataTable';
import { mapCommunityRelationsToTableData } from '../../../../utils/mapCommunityRelationsToTableData';
import AddNewCommunityMember from './AddNewCommunityMember/AddNewCommunityMember';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import ConfirmationModal from '../../../../../../global/components/ConfirmationModal/ConfirmationModal';
import SubheaderItemsCount from '../../../../../../global/components/DataTable/SubheaderItemsCount/SubheaderItemsCount';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import { getCommunityMembers } from '../../../../utils/getCommunityMembers/getCommunityMembers';
import styles from './CommunityMembers.module.scss';

const PAGE_SIZE = 5;

const CommunityMembers: FC = () => {
	const { t } = useTranslation();
	const hasPermission = usePermissions();
	const dispatch = useAppDispatch();
	const { currentCommunityData, loading, error, errorUpdateCommunityRolRelatie } =
		useAppSelector(selectCurrentCommunityData);
	const [audienceFormSchema, setAudienceFormSchema] = useState<FormSchema | null>(null);
	const [rolRelatieIdToDelete, setRolRelatieIdToDelete] = useState<string | null>(null);
	const [validationError, setValidationError] = useState('');
	const modalRef = useRef<HTMLZdsModalElement | null>(null);
	const canEdit = hasPermission('COM_WIJ_COM_LED');

	useEffect(() => {
		setAudienceFormSchema(
			currentCommunityData ? generateUpdateCommunityMembersFormSchema(currentCommunityData, t) : null
		);
		setValidationError('');
	}, [currentCommunityData]);

	const handleSubmit = (data: FormSubmitData) => {
		// TBD
		console.log('submit data:', data);
	};

	const handleModalSave = () => {
		setValidationError('');
		if (currentCommunityData && rolRelatieIdToDelete) {
			const isManager =
				currentCommunityData.rolRelaties?.find(rr => rr.id === rolRelatieIdToDelete)?.soort.code === 'COM';

			const managers = getCommunityMembers(currentCommunityData, 'COM');

			if (isManager && managers.length === 1) {
				setValidationError(t('community:communityInformationPage.deleteLastManagerErrorMessage') ?? '');
			} else {
				dispatch(
					deleteCommunityRolRelatie({ communityId: currentCommunityData.id, rolRelatieId: rolRelatieIdToDelete })
				);
			}
		}
		handleModalClose();
	};

	const handleModalClose = () => {
		setRolRelatieIdToDelete(null);
	};

	const handleRolRelatieDeleteIconClick = (id: string) => {
		setRolRelatieIdToDelete(id);
		modalRef?.current?.show();
	};

	if (error) {
		return <ErrorMessage message={t(error)} />;
	}

	if (loading) {
		return <h3>{t('global:common.loading')}...</h3>;
	}

	const tableData = currentCommunityData?.rolRelaties
		? mapCommunityRelationsToTableData(currentCommunityData.rolRelaties, t)
		: null;

	return (
		<div className={styles.container}>
			{audienceFormSchema && (
				<>
					<div className="zds-bold zds-margin-bottom-2">{t('community:communityInformationPage.audience')}</div>
					<div className="zds-margin-bottom-4">{t('community:communityInformationPage.basedOnSettings')}:</div>
					<div data-testid="communityAudienceForm">
						<Form schema={audienceFormSchema} onSubmit={handleSubmit} formId="communityAudienceForm" />
					</div>
				</>
			)}
			{tableData && (
				<div className="zds-margin-top-16">
					<DataTable
						data={tableData}
						tableTestId="communityMembersTable"
						iconOptions={
							canEdit
								? {
										...TableIconSettings.delete,
										onIconClick: handleRolRelatieDeleteIconClick,
								  }
								: undefined
						}
						pageSizes={[PAGE_SIZE]}
						header={{
							title: `${t('global:common.all')} ${t('community:community.members').toLowerCase()}`,
							subheader: {
								Component: SubheaderItemsCount,
								props: {
									labelTotal: {
										singular: t('community:community.member').toLowerCase(),
										plural: t('community:community.members').toLowerCase(),
									},
								},
							},
						}}
					/>
				</div>
			)}
			{validationError && <ErrorMessage message={t(validationError)} />}
			{currentCommunityData && canEdit && <AddNewCommunityMember community={currentCommunityData} />}
			{errorUpdateCommunityRolRelatie && <h3>{t(errorUpdateCommunityRolRelatie)}</h3>}

			<ConfirmationModal
				ref={modalRef}
				title={t('community:communityInformationPage.deleteMemberQuestion')}
				onSave={handleModalSave}
				onClose={handleModalClose}
				isDelete
			/>
		</div>
	);
};

export default CommunityMembers;
