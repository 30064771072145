import { FC } from 'react';
import { t } from 'i18next';

import DataTable, { TableData } from '../../../../global/components/DataTable/DataTable';
import SubheaderItemsCount from '../../../../global/components/DataTable/SubheaderItemsCount/SubheaderItemsCount';

export interface AgreementsTableProps {
	tableData: TableData;
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
}

const AgreementsTable: FC<AgreementsTableProps> = ({ tableData, onPageChange, pageSize, itemsNum }) => {
	return (
		<DataTable
			data={tableData}
			tableTestId="agreementsTable"
			onPageChange={onPageChange}
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			header={{
				subheader: {
					Component: SubheaderItemsCount,
					props: {
						labelTotal: {
							singular: t('vastgoed:vastgoed.overeenkomst').toLowerCase(),
							plural: t('vastgoed:vastgoed.overeenkomsten').toLowerCase(),
						},
					},
				},
			}}
		/>
	);
};

export default AgreementsTable;
