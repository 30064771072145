import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import UploadFile from '../../../../../../global/components/UploadFile/UploadFile';
import { UploadFileData, useUploadFileData } from '../../../../../../global/hooks/useUploadFileData/useUploadFileData';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import styles from './FormImage.module.scss';

export interface FormImageProps {
	label: string;
	initImage?: UploadFileData | null;
	text?: string;
	type?: 'profile' | 'background';
	layout?: 'horizontal' | 'vertical';
	onChange: (fileData: UploadFileData | null) => void;
	maxFilesSize?: number;
}

const FormImage: FC<FormImageProps> = ({
	label,
	initImage,
	text,
	layout = 'vertical',
	type = 'background',
	onChange,
	maxFilesSize,
}) => {
	const { t } = useTranslation();
	const uploadButtonRef = useRef<HTMLZdsButtonElement>(null);

	const {
		fileData: allFileData,
		handleAddFile,
		handleRemoveFile,
	} = useUploadFileData({
		maxFiles: 1,
		mode: 'SWAP_LAST',
		initFileData: initImage ? [initImage] : [],
		maxFilesSize,
	});

	const fileData = allFileData[0];

	useEffect(() => {
		onChange(fileData?.base64 && !fileData?.error ? fileData : null);
	}, [fileData]);

	const handleAddFileClick = () => {
		if (uploadButtonRef.current) {
			uploadButtonRef.current.dispatchEvent(new CustomEvent('zdsClick'));
		}
	};

	const renderUploadButton = () => (
		<div className="zds-flex zds-gap-1">
			<div>
				<UploadFile onChange={handleAddFile} buttonHidden ref={uploadButtonRef} maxSize={maxFilesSize} />
				<ZdsButton variant="outlined" onZdsClick={handleAddFileClick}>
					{t('global:common.uploadFile')}
				</ZdsButton>
			</div>
			{renderDeleteIcon()}
		</div>
	);

	const renderDeleteIcon = () => {
		return (
			<>
				{fileData && !fileData.error && (
					<ZdsButton preset="neutral" variant="text" onZdsClick={() => handleRemoveFile(fileData.id)}>
						<ZdsIcon name="trash" library="fa-solid" size="sm"></ZdsIcon>
					</ZdsButton>
				)}
			</>
		);
	};

	return (
		<div className="zds-flex zds-gap-3">
			<div className={styles.label}>{label}</div>
			<div className="zds-flex zds-flex-col zds-gap-3 zds-flex-1">
				{text && <div>{text}</div>}
				{fileData?.error && <ErrorMessage message={t(fileData.error)} />}
				{layout === 'vertical' && renderUploadButton()}
				<div className="zds-flex zds-justify-content-between zds-items-center zds-flex-1">
					{fileData?.base64 ? (
						<div className="zds-relative zds-flex-1">
							<span className={styles.fade} />
							<img className={styles[`${type}Picture`]} src={fileData.base64} alt="" />
						</div>
					) : (
						<div className={classNames(styles[`${type}Picture`], styles.noImage)}>
							<ZdsIcon size="xxl" name="image" library="fa-light" color="" />
						</div>
					)}
					{layout === 'horizontal' && renderUploadButton()}
				</div>
			</div>
		</div>
	);
};
export default FormImage;
