import { Action, combineReducers, configureStore, PreloadedState, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import { relatieReducer } from '../../apps/relatiebeheer/redux';
import { organisatieReducer } from '../../apps/organisatiebeheer/redux';
import { authorizationDataReducer } from './shared/authorizationData';
import { communitiesReducer } from '../../apps/communitybeheer/redux';
import { profileDataReducer } from './shared/profileData';
import { vastgoedReducer } from '../../apps/vastgoedbeheer/redux';

const reducer = combineReducers({
	authorization: authorizationDataReducer,
	profile: profileDataReducer,
	relatie: relatieReducer,
	organisatie: organisatieReducer,
	communities: communitiesReducer,
	vastgoed: vastgoedReducer,
});

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['authorization'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: persistedReducer,
		preloadedState,
		middleware: [thunk],
	});
};

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<ThunkDispatch<AppStore, unknown, Action>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
