import { parseCompareValue } from './parseCompareValue';
import { FilterMap } from './parseFilters.types';

export const getSearchFilterData = ({
	searchData = {},
	filterMap,
}: {
	searchData?: { [key: string]: string };
	filterMap: FilterMap;
}) => {
	return Object.entries(searchData)
		.map(([param, value]) => {
			const operation = filterMap[param];
			if (!operation) return null;

			const [op, val] = operation === 'compare' ? parseCompareValue(value) : [operation, value];

			if (!op) return null;

			return `${param} ${op} ${val}`;
		})
		.filter(Boolean);
};
