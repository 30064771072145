import { Dispatch, FC, ReactElement, useEffect, useState } from 'react';
import { FormSchema, FormSubmitData } from '../../../../global/components/Form/Form.types';
import DataTable, { TableData } from '../../../../global/components/DataTable/DataTable';
import { TableRow } from '../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';
import Form from '../../../../global/components/Form/Form';
import ToggleButton from '../../../../global/components/ToggleButton/ToggleButton';
import deepmerge from 'deepmerge';
import SaveButton from '../../../../global/components/Form/SaveButton';
import { ZdsButtonCustomEvent, ZdsFormCustomEvent } from '@zig-design-system/ui-components';
import { ZdsForm } from '@zig-design-system/ui-components-react';
import { TableCell } from '../../../../global/components/DataTable/DataTableContentRow/DataTableContentCell';

interface ColumnSchema {
	header: string;
	mapper: (item: any) => TableCell;
}

export interface TableSchema {
	columns: ColumnSchema[];
}

export interface ArrayFormProps {
	formSchema?: FormSchema;
	formElements?: ReactElement;
	tableSchema: TableSchema;
	items: any[];
	onChange: Dispatch<any>;
	formId: string;
	formTestId: string;
}

const ArrayForm: FC<ArrayFormProps> = ({
	formSchema,
	formElements,
	tableSchema,
	items,
	onChange,
	formId,
	formTestId,
}) => {
	const [editedItem, setEditedItem] = useState<object | null>(null);
	const [editFormSchema, setEditFormSchema] = useState<FormSchema | undefined>(formSchema);
	const [formAction, setFormAction] = useState<string>('none');
	const [tableData, setTableData] = useState<TableData | null>(null);

	useEffect(() => {
		const rows: TableRow[] = items.map((item, index) => {
			return {
				id: String(index),
				cells: tableSchema.columns.map(column => column.mapper(item)),
			};
		});

		setTableData({
			header: { labels: tableSchema.columns.map(column => column.header) },
			body: { rows },
		});
	}, [items]);

	const editItemByIndex = (id: string) => {
		editItem(items[Number(id)], 'edit');
	};

	const editItem = (item: any, formAction: string) => {
		if ((formAction === 'add' && editedItem) || (formAction === 'edit' && editedItem === item)) {
			return setEditedItem(null);
		}

		if (formSchema) {
			const newSchema: FormSchema = deepmerge({}, formSchema);

			newSchema.formControls.forEach(formControl => {
				formControl.control.props.value = item[formControl.name] ?? undefined;
			});

			setEditFormSchema(newSchema);
		}

		setFormAction(formAction);
		setEditedItem(item);
	};

	const handleRemoveItem = (id: string, event: ZdsButtonCustomEvent<MouseEvent>) => {
		event.stopPropagation();
		items.splice(Number(id), 1);
		onChange([...items]);
	};

	const handleAddItem = () => {
		editItem({}, 'add');
	};

	const handleSubmit = (data: FormSubmitData) => {
		if (formAction === 'add') {
			items.push(data);
		} else {
			items.splice(items.indexOf(editedItem), 1, { ...editedItem, ...data });
		}

		onChange([...items]);
		setEditedItem(null);
	};

	const handleZdsSubmit = (event: ZdsFormCustomEvent<any>) => {
		const data = {} as any;

		const collectData = (node: any) => {
			node.childNodes.forEach((childNode: any) => {
				if (childNode.name && typeof childNode.value === 'string') {
					data[childNode.name] = childNode.value;
				}
				collectData(childNode);
			});
		};

		collectData(event.target);
		handleSubmit(data);
	};

	return (
		<>
			{tableData && (
				<DataTable
					data={tableData}
					tableTestId="medewerkersTable"
					onRowClick={editItemByIndex}
					iconOptions={{
						name: 'trash',
						library: 'fa-solid',
						preset: 'muted',
						variant: 'text',
						size: 'sm',
						onIconClick: handleRemoveItem,
					}}
				/>
			)}
			<div
				className="zds-flex zds-justify-content-end zds-padding-3 zds-margin-top-12"
				data-testid="add-new-community-member-button"
			>
				<ToggleButton icon="plus" onClick={handleAddItem} />
			</div>
			{editedItem && (
				<>
					{editFormSchema && <Form schema={editFormSchema} onSubmit={handleSubmit} formId={formId} />}
					{formElements && (
						<div className="zds-margin-bottom-4 zds-flex">
							<ZdsForm id={formId} onZdsSubmit={handleZdsSubmit}>
								{formElements}
							</ZdsForm>
						</div>
					)}
					<SaveButton dataTestId={formTestId} formId={formId} />
				</>
			)}
		</>
	);
};

export default ArrayForm;
