import { TFunction } from 'i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';

const SOORT = 'GRO';

export const generateCreateRelatiegroepFormSchema = (t: TFunction): FormSchema => {
	const schema: FormSchema = {
		title: t('global:common.about') ?? '',
		formControls: [
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: SOORT,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIESOORT',
						code: SOORT,
						disabled: true,
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIEDETAILSOORT',
						parentSoort: SOORT,
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						required: true,
					},
				},
			},
		],
		layout: {
			columns: [['code', 'naam'], ['detailSoort']],
		},
	};

	return schema;
};
