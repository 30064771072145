import { TFunction } from 'i18next';
import { ZdsInputDate, ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getFormattedDate } from '../../../../../../../global/utils/date/formatDate/formatDate';
import { getDateFormat } from '../../../../../../../global/utils/date/getDatePattern';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { COLUMN_SECTION_HEADER_PREFIX } from '../../../../../../../global/utils/form/createFormColumns';

const SOORT = 'NAT';

export const generateCreateNatuurlijkPersoonFormSchema = (t: TFunction, language: string): FormSchema => {
	const dateFormat = getDateFormat(language);

	const schema: FormSchema = {
		title: t('global:common.personalDetails') ?? '',
		formControls: [
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: SOORT,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIESOORT',
						code: SOORT,
						disabled: true,
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIEDETAILSOORT',
						parentSoort: SOORT,
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'aanhef',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.aanhef'),
					},
				},
			},
			{
				name: 'aanschrijfnaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.aanschrijfnaam'),
					},
				},
			},
			{
				name: 'voorletters',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voorletters'),
					},
				},
			},
			{
				name: 'achternaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.achternaam'),
						required: true,
					},
				},
			},
			{
				name: 'roepnaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.roepnaam'),
					},
				},
			},
			{
				name: 'voornaam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voornaam'),
					},
				},
			},
			{
				name: 'initialen',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.initialen'),
					},
				},
			},
			{
				name: 'tussenvoegsels',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.tussenvoegsels'),
					},
				},
			},
			{
				name: 'geboortedatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.geboortedatum'),
						value: getFormattedDate(undefined, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'geslacht',
				submitPath: 'geslacht.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.geslacht'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'GESLACHT',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'overlijdensdatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.overlijdensdatum'),
						value: getFormattedDate(undefined, dateFormat),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'bsn',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.bsn'),
					},
				},
			},
			{
				name: 'burgerlijkeStaat',
				submitPath: 'burgerlijkeStaat.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.burgerlijkeStaat'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'BURGERLIJKESTAAT',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'nationaliteit',
				submitPath: 'nationaliteit.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.nationaliteit'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'NATIONALITEIT',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'voorkeurstaal',
				submitPath: 'voorkeurstaal.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.voorkeurstaal'),
						value: '',
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'TAAL',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
		],
		layout: {
			columns: [
				[
					`${COLUMN_SECTION_HEADER_PREFIX}${t('global:domainShared.naam')}`,
					'code',
					'voorletters',
					'voornaam',
					'tussenvoegsels',
					'achternaam',
				],
				[
					`${COLUMN_SECTION_HEADER_PREFIX}${t('global:common.characteristics')}`,
					'geboortedatum',
					'geslacht',
					'aanhef',
					'aanschrijfnaam',
					'nationaliteit',
					'voorkeurstaal',
					'burgerlijkeStaat',
					'overlijdensdatum',
				],
			],
		},
	};

	return schema;
};
