import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Eenheid } from '../../../api/models';

export const useMapEenhedenToTableData = (
	eenheden: {
		[key: number]: Eenheid[];
	} | null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [
			t('global:domainShared.naam'),
			t('vastgoed:vastgoed.straatnaam'),
			t('vastgoed:vastgoed.huisnummer'),
			t('vastgoed:vastgoed.huisnummerToevoeging'),
			t('vastgoed:vastgoed.postcode'),
			t('vastgoed:vastgoed.woonplaats'),
			t('global:domainShared.soort'),
			t('global:domainShared.status'),
		],
		width: [null, null, 80, 80],
	};

	const getPage = useCallback((eenheden: Eenheid[]) => {
		return eenheden.map(eenheid => {
			return {
				id: eenheid.id ?? '',
				cells: [
					{ mainText: eenheid.naam ?? '' },
					{ mainText: eenheid.adres?.straatnaam ?? '' },
					{ mainText: eenheid.adres?.huisnummer ?? '' },
					{ mainText: eenheid.adres?.huisnummerToevoeging ?? '' },
					{ mainText: eenheid.adres?.postcode ?? '' },
					{ mainText: eenheid.adres?.woonplaats ?? '' },
					{ mainText: getReferentiedataNaam(t, 'EENHEIDSOORT', eenheid.soort?.code) },
					{ mainText: getReferentiedataNaam(t, 'EENHEIDSTATUS', eenheid.status?.code) },
				],
			};
		});
	}, []);

	const pages = useTablePages(eenheden, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
