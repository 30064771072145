import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormData } from '../../../../global/components/Form/Form.types';
import { Language } from '../../api/models';
import { SUPPORTED_LANGUAGES } from '../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';

const ONDERWERP_BESCHRIJVING_LIMIT = 50;
const DEFAULT_LANGUAGE = Language.Dutch;

type InputLanguagesData = { [key in Language]: string };

export const useNieuwsberichtPreviewData = (formData: FormData | null, language: Language) => {
	const { t } = useTranslation();
	const [onderwerp, setOnderwerp] = useState('');
	const [beschrijving, setBeschrijving] = useState('');

	useEffect(() => {
		const initFormDataValues: { [key: string]: InputLanguagesData } = {
			beschrijving: {} as InputLanguagesData,
			onderwerp: {} as InputLanguagesData,
		};
		const formDataValues = SUPPORTED_LANGUAGES.reduce((acc, lang) => {
			Object.keys(initFormDataValues).forEach(
				field => (acc[field][lang] = formData?.[field].languageData?.[lang] ?? '')
			);
			return acc;
		}, initFormDataValues);

		const beschrijvingFormDataValue = formDataValues.beschrijving;
		const onderwerpFormDataValue = formDataValues.onderwerp;

		const beschrijvingInputValue =
			language === DEFAULT_LANGUAGE
				? beschrijvingFormDataValue[DEFAULT_LANGUAGE]
				: beschrijvingFormDataValue[language] || beschrijvingFormDataValue[DEFAULT_LANGUAGE];

		const onderwerpInputValue =
			language === DEFAULT_LANGUAGE
				? onderwerpFormDataValue[DEFAULT_LANGUAGE]
				: (!beschrijvingFormDataValue[language] &&
						!onderwerpFormDataValue[language] &&
						onderwerpFormDataValue[DEFAULT_LANGUAGE]) ||
				  onderwerpFormDataValue[language];

		const onderwerpBeschrijvingValue =
			beschrijvingInputValue &&
			`${beschrijvingInputValue.slice(0, ONDERWERP_BESCHRIJVING_LIMIT)}${
				beschrijvingInputValue.length > ONDERWERP_BESCHRIJVING_LIMIT ? '...' : ''
			}`;

		const newOnderwerp =
			onderwerpInputValue ||
			onderwerpBeschrijvingValue ||
			(t('community:communityInformationPage.messages.createNewsMessage.form.titlePlaceholder') ?? '');

		const newBeschrijving =
			beschrijvingInputValue ||
			(t('community:communityInformationPage.messages.createNewsMessage.form.messagePlaceholder') ?? '');

		setOnderwerp(newOnderwerp.trim());
		setBeschrijving(newBeschrijving.trim());
	}, [formData, language]);

	return { onderwerp, beschrijving };
};
