import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import styles from './layout-poc.module.scss';
import classNames from 'classnames';
import Pane from '../../../../global/components/Pane/Pane';
import SideMenu from '../../../../global/components/SideMenu/SideMenu';
import layoutPocSideMenuInfo from './layout-poc-side-menu-info';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

const LayoutPocVariant1 = () => {
	return (
		<div className={styles.pageContainer}>
			{/* <div className={classNames(styles.utilityBar, styles.utilityBarLevel1)}></div> */}
			<div className={styles.mainRow}>
				<div className={styles.sideMenuContainer}>
					<SideMenu info={layoutPocSideMenuInfo} />
				</div>
				<div className={styles.page}>
					<div className={styles.mainColumn}>
						{/* <div className={classNames(styles.utilityBar, styles.utilityBarLevel2)}></div> */}
						<div className={styles.mainColumnContent}>
							<div className={classNames(styles.utilityBar, styles.utilityBarLevel3)}>
								<ZdsButton variant="text" preset="neutral">
									<ZdsIcon name="arrow-left" library="fa-regular" size="sm"></ZdsIcon>
									Back
								</ZdsButton>
							</div>
							<div className={styles.mainColumnSplit}>
								<div className={styles.mainColumnSide}>
									<Pane>Side column</Pane>
								</div>
								<div className={styles.mainColumnMain}>
									<div className={styles.pageHeaderContainer}>
										<PageHeader title="Layout POC | Variant 1" iconName="table-layout" iconLibrary="fa-regular" />
									</div>
									<div className={styles.contentColumns}>
										<Pane className={classNames('zds-body', styles.contentColumn)}>Content</Pane>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LayoutPocVariant1;
