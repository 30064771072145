import { ZdsButton, ZdsIcon, ZdsSideSheet, ZdsTab, ZdsTabs } from '@zig-design-system/ui-components-react';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import styles from './layout-poc.module.scss';
import classNames from 'classnames';
import Pane from '../../../../global/components/Pane/Pane';
import SideMenu from '../../../../global/components/SideMenu/SideMenu';
import { useCallback, useState } from 'react';
import layoutPocSideMenuInfo from './layout-poc-side-menu-info';

const LayoutPocVariant3 = () => {
	const [isSideSheetExpanded, setIsSideSheetExpanded] = useState(false);

	const dynamicSideSheetExpansionClasses = classNames({
		[styles.dynamicSideSheetExpansion]: true,
		[styles.dynamicSideSheetExpansionExpanded]: isSideSheetExpanded,
	});

	const toggleDynamicSideSheet = useCallback(() => {
		setIsSideSheetExpanded(!isSideSheetExpanded);
	}, [isSideSheetExpanded]);

	return (
		<div className={classNames(styles.pageContainer, styles.pageContainerWhite)}>
			<div className={classNames(styles.utilityBar, styles.utilityBarLevel1)}></div>
			<div className={styles.mainRow}>
				<div className={styles.sideMenuContainer}>
					<SideMenu info={layoutPocSideMenuInfo} />
				</div>
				<div className={styles.sideSheetContainer}>
					<ZdsSideSheet position="left" />
				</div>
				<div className={styles.page}>
					<div className={styles.mainColumn}>
						<div className={classNames(styles.utilityBar, styles.utilityBarLevel2)}></div>
						<div className={styles.mainColumnContent}>
							<div className={classNames(styles.utilityBar, styles.utilityBarLevel3)}>
								<ZdsButton variant="text" preset="neutral">
									<ZdsIcon name="arrow-left" library="fa-regular" size="sm"></ZdsIcon>
									Back
								</ZdsButton>
							</div>
							<div className={styles.pageHeaderContainer}>
								<PageHeader title="Layout POC | Variant 3" iconName="table-layout" iconLibrary="fa-regular" />
							</div>
							<div>
								<ZdsTabs>
									<ZdsTab active>Tab 1</ZdsTab>
									<ZdsTab>Tab 2</ZdsTab>
									<ZdsTab>Tab 3</ZdsTab>
								</ZdsTabs>
							</div>
							<Pane className={classNames('zds-body', styles.contentColumn)}>
								<p className="zds-margin-bottom-3">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
									dolore magna aliqua. Vitae nunc sed velit dignissim. Non arcu risus quis varius quam. Justo laoreet
									sit amet cursus sit amet. Eu scelerisque felis imperdiet proin fermentum leo vel orci. A iaculis at
									erat pellentesque adipiscing commodo elit. Eget dolor morbi non arcu. Pharetra sit amet aliquam id
									diam maecenas ultricies mi eget. Faucibus vitae aliquet nec ullamcorper sit amet risus nullam eget.
									Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Fringilla urna porttitor rhoncus
									dolor purus non. Ullamcorper velit sed ullamcorper morbi tincidunt. Commodo odio aenean sed adipiscing
									diam donec adipiscing tristique. In nisl nisi scelerisque eu ultrices vitae auctor eu augue. Massa id
									neque aliquam vestibulum morbi. Odio pellentesque diam volutpat commodo sed egestas. Auctor eu augue
									ut lectus.
								</p>

								<p className="zds-margin-bottom-3">
									At volutpat diam ut venenatis tellus in metus vulputate eu. Eu facilisis sed odio morbi quis. Orci ac
									auctor augue mauris. Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque
									habitant. Egestas quis ipsum suspendisse ultrices gravida. Mattis pellentesque id nibh tortor id
									aliquet. Mollis nunc sed id semper risus in hendrerit gravida rutrum. Lacus suspendisse faucibus
									interdum posuere lorem ipsum. Arcu non odio euismod lacinia at quis risus sed. Malesuada fames ac
									turpis egestas integer eget aliquet nibh. Metus aliquam eleifend mi in nulla posuere. Tincidunt augue
									interdum velit euismod in pellentesque massa placerat duis.
								</p>

								<p className="zds-margin-bottom-3">
									Hac habitasse platea dictumst quisque sagittis. Blandit aliquam etiam erat velit scelerisque in dictum
									non. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Commodo quis
									imperdiet massa tincidunt nunc. Quis auctor elit sed vulputate. Sit amet luctus venenatis lectus magna
									fringilla. Viverra nam libero justo laoreet sit amet cursus sit. Rhoncus dolor purus non enim
									praesent. Urna neque viverra justo nec ultrices dui sapien eget. Odio pellentesque diam volutpat
									commodo. Tellus rutrum tellus pellentesque eu tincidunt. Eget mauris pharetra et ultrices. Nec
									ullamcorper sit amet risus nullam eget felis. Sed adipiscing diam donec adipiscing tristique.
								</p>

								<p className="zds-margin-bottom-3">
									Quis enim lobortis scelerisque fermentum dui faucibus in. Tortor at auctor urna nunc. Habitant morbi
									tristique senectus et netus et. Massa sed elementum tempus egestas sed sed risus pretium quam. Nunc
									congue nisi vitae suscipit tellus. Est ullamcorper eget nulla facilisi. At erat pellentesque
									adipiscing commodo elit at imperdiet dui accumsan. Suspendisse faucibus interdum posuere lorem ipsum
									dolor sit amet consectetur. Penatibus et magnis dis parturient. Tortor pretium viverra suspendisse
									potenti nullam. Nam at lectus urna duis convallis convallis tellus id. Tincidunt arcu non sodales
									neque sodales ut etiam. Eu tincidunt tortor aliquam nulla facilisi cras. Hendrerit dolor magna eget
									est lorem ipsum dolor.
								</p>

								<p className="zds-margin-bottom-3">
									Quisque non tellus orci ac auctor augue mauris. Ante in nibh mauris cursus mattis molestie. Enim eu
									turpis egestas pretium. Pellentesque eu tincidunt tortor aliquam. Nulla malesuada pellentesque elit
									eget gravida cum. Porttitor massa id neque aliquam vestibulum morbi blandit. Felis eget nunc lobortis
									mattis aliquam. Ac orci phasellus egestas tellus rutrum. Tempus urna et pharetra pharetra massa massa
									ultricies mi quis. Et ultrices neque ornare aenean euismod elementum nisi. Vitae aliquet nec
									ullamcorper sit amet risus. Commodo elit at imperdiet dui accumsan sit.
								</p>
							</Pane>
						</div>
					</div>
				</div>
				<div className={styles.dynamicSideSheetContainer}>
					<ZdsSideSheet position="right">
						<div slot="body" className={styles.dynamicSideSheetBodyWrapper}>
							<div className={dynamicSideSheetExpansionClasses}></div>
							<div className={styles.dynamicSideSheetNormal}>
								<ZdsButton
									className={styles.dynamicSideSheetButton}
									preset="loud"
									variant="text"
									size="sm"
									onZdsClick={toggleDynamicSideSheet}
								>
									<ZdsIcon name="book" library="fa-light"></ZdsIcon>
								</ZdsButton>
							</div>
						</div>
					</ZdsSideSheet>
				</div>
			</div>
		</div>
	);
};

export default LayoutPocVariant3;
