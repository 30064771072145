import { useEffect, useState } from 'react';

import { FormData } from '../../../../global/components/Form/Form.types';
import { Language } from '../../api/models';
import { SUPPORTED_LANGUAGES } from '../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';

const DEFAULT_LANGUAGE = Language.Dutch;

type InputLanguagesData = { [key in Language]: string };

export const useNaamPreviewData = (formData: FormData | null, language: Language) => {
	const [naam, setNaam] = useState('');

	useEffect(() => {
		if (formData) {
			const initFormDataValues: { [key: string]: InputLanguagesData } = {
				naam: {} as InputLanguagesData,
			};
			const formDataValues = SUPPORTED_LANGUAGES.reduce((acc, lang) => {
				Object.keys(initFormDataValues).forEach(
					field => (acc[field][lang] = formData?.[field].languageData?.[lang] ?? '')
				);
				return acc;
			}, initFormDataValues);

			const naamFormDataValue = formDataValues.naam;

			const naamInputValue =
				language === DEFAULT_LANGUAGE
					? naamFormDataValue[DEFAULT_LANGUAGE]
					: naamFormDataValue[language] || naamFormDataValue[DEFAULT_LANGUAGE];

			setNaam(naamInputValue);
		}
	}, [formData, language]);

	return { naam };
};
