import { FC } from 'react';
import { ZdsItem, ZdsTextarea } from '@zig-design-system/ui-components-react';
import { useTranslation } from 'react-i18next';
import {
	ApiPrikbordBerichtInitieel,
	CommunityType,
	IBericht,
	Relatie,
	RelatieAvatar,
	RelatieNaam,
} from '@zig.community/community-components';

import styles from './CreateMessage.module.scss';

export interface MessageFormProps {
	relatie: Relatie;
	communityCategorie?: IBericht<ApiPrikbordBerichtInitieel>['categorie'] | null;
	community: CommunityType;
	currentRelatie: Relatie;
	placeholder: string;
	bericht: string;
	onChangeBericht: (bericht: string) => void;
	required?: boolean;
}

const MessageForm: FC<MessageFormProps> = ({
	relatie,
	communityCategorie,
	community,
	currentRelatie,
	placeholder,
	bericht,
	onChangeBericht,
	required,
}) => {
	const { t } = useTranslation('global');

	return (
		<>
			<ZdsItem className="zds-bg-white zds-margin-y-1">
				<div slot="content-start" className="zds-flex zds-items-center">
					<RelatieAvatar relatie={relatie} currentRelatie={currentRelatie} />
				</div>
				<RelatieNaam relatie={relatie} />
				<div slot="secondary-text">{[community.naam, communityCategorie?.naam].filter(Boolean).join(' > ')}</div>
			</ZdsItem>
			<ZdsTextarea
				size="lg"
				className={styles.textarea}
				placeholder={placeholder}
				value={bericht || ''}
				onZdsInput={e => onChangeBericht(e.target.value)}
				required={required}
			>
				<span slot="validation-message" data-reason="valueMissing">
					{t('form.defaultValidationMessage.valueMissing')}
				</span>
			</ZdsTextarea>
		</>
	);
};

export default MessageForm;
