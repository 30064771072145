import { DefaultEntity, Referentiedata } from '../../../global/api/models';

export interface Contactgegeven extends DefaultEntity {
	detailSoort?: Referentiedata;

	// Description of the contact detail. For example: Phonenumber of son
	omschrijving?: string;

	// The status of the contact detail. Could be something like Created, Verified, etc.
	status?: Referentiedata;

	// The id of the associated relation (Relatie)
	relatie: Relatie | NatuurlijkPersoon | Rechtspersoon | Relatiegroep;

	// the value of the contact detail, like the e-mail address or the phonenumber
	waarde: string;

	// Preference of the contact detail
	voorkeur?: Referentiedata;

	// The level of confidentiality of the contact detail
	vertrouwelijkheid?: Referentiedata;
}

export interface RechtspersoonRelatie {
	id: string; // Unique identifier in Next Wave
	idRechtspersoon: string; // the id of the associated legal person (Relatie with soort = REC)
	idRelatie: string; // the is of the relation who is part of the relationgroup
	relatie: Relatie;
	rechtspersoon: Relatie;
}

export interface RelatiegroepRelatie {
	id: string; // Unique identifier in Next Wave
	idRelatiegroep: string; // the id of the associated relationgroup (Relatie with soort = GRO)
	idRelatie: string; // the is of the relation who is part of the relationgroup
	relaties?: Relatie[];
	relatiegroep: Relatie;
}

export interface Relatie {
	// The primary key of the source system
	id?: string;

	// The primary key of the entry in the external system
	idExtern?: string;

	// Non-VERA - Identifier of the source application
	idBron?: string;

	// A key assigned by government or other standardization body
	idGegevensbeheerder?: string;

	// The unique code (for example to display or search)
	code?: string;

	naam?: string;

	voornaam?: string;

	tussenvoegsels?: string;

	achternaam?: string;

	// The type of the relation: Natural person, Legal person or Relationgroup
	soort?: Referentiedata;

	// The detailtype of a relation: For examplex Household with an Relatiegroep, or the standard companytypes according the Dutch SBI for a legal person
	detailSoort?: Referentiedata;

	// The status of the relation, for example the case of a sleeping or active VVE legal person
	status?: Referentiedata;

	// The contactdetails of the relation
	contactgegevens?: Contactgegeven[];

	// The communication channel on which the relation prefers to be approached. For example: Phone, Email, Social media etc.
	communicatievoorkeur?: Referentiedata;
	rechtspersoons?: Rechtspersoon[];
	relaties?: Relatie[];
}

export interface NatuurlijkPersoon extends Relatie {
	// The citizen service number of the relation
	bsn?: string;

	// Birthdate of the relation
	geboortedatum?: Date;

	// Date of death
	overlijdensdatum?: Date;

	// The salutation or salutation is a (usually friendly) introductory phrase of a
	// letter or email. These are usually standard formulations with which the
	// writer addresses the addressee
	aanhef?: string;

	// The surname of the natural person as they wish to be addressed
	aanschrijfnaam?: string;

	// The lastname of the relation
	achternaam?: string;

	// The marital status
	burgerlijkeStaat?: Referentiedata;

	// Gender of the natural person
	geslacht?: Referentiedata;

	// In English there is no difference in the translation of the Dutch words
	// Voorletters and Initialen. We define the initialen as the first letter of
	// the first and last name
	initialen?: string;

	// Nationality
	nationaliteit?: Referentiedata;

	// Nickname
	roepnaam?: string;

	// The prefixes between first and lastname
	tussenvoegsels?: string;

	// Preferred language of the relation
	voorkeurstaal?: Referentiedata;

	// In English there is no difference in the translation of the Dutch words
	// Voorletters and Initialen.  We define the voorletters as the first letter of
	// the first names
	voorletters?: string;

	// Firstname of the relation
	voornaam?: string;
	account?: Account;
}

export interface Rechtspersoon extends Relatie {
	// Number of the Chamber of Commerce
	kvkNummer?: string;

	// Global location number (GLN)
	globaalLocatienummer?: string;

	// Complete name of the relation
	naam?: string;

	// The VAT number
	btwNummer?: string;

	// Form of organization, For example: BV, NV, stichting or vereniging. Also known as legal form
	organisatievorm?: Referentiedata;

	// The contactpersons of the relation
	relaties?: Relatie[];
}

export interface Relatiegroep extends Relatie {
	naam?: string;

	// The members of the relationgroup
	relaties?: Relatie[];
}

export interface Account extends DefaultEntity {
	status?: Referentiedata;
	relatie?: NatuurlijkPersoon;
	gebruikersnaam?: string;
	wachtwoorddatum?: string;
	organisatie: string;
	applicatierollen?: string[];
}

export const isNatuurlijkPersoon = (relatie?: Relatie): relatie is NatuurlijkPersoon => {
	return relatie?.soort?.code === 'NAT';
};

export const isRechtspersoon = (relatie?: Relatie): relatie is Rechtspersoon => {
	return relatie?.soort?.code === 'REC';
};

export const isRelatiegroep = (relatie?: Relatie): relatie is Relatiegroep => {
	return relatie?.soort?.code === 'GRO';
};
