import { Medewerkerrol } from '../../../api/models';
import { SortableOption, TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { useCallback } from 'react';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';

export const useMapSelectMedewerkerollenToTableData = (
	medewerkerrollen: {
		[key: number]: Medewerkerrol[];
	} | null,
	sortData: { [key: string]: SortableOption },
	checkedMedewerkerrollen: string[]
): TableData | null => {
	const { t } = useTranslation();

	const getHeader = useCallback(() => {
		const columnIds = ['naam', 'assigned'];

		return {
			labels: [t('global:domainShared.naam'), t('global:common.assigned')],
			columnIds,
			sortable: columnIds.map(id => (id ? sortData[id] || true : undefined)) as SortableOption[],
			width: [100, 50],
		} as TableData['header'];
	}, [sortData]);

	const getPage = useCallback(
		(medewerkerrollen: Medewerkerrol[]): TableRow[] => {
			return medewerkerrollen.map(medewerkerrol => {
				return {
					id: medewerkerrol.id ?? '',
					cells: [
						{ mainText: medewerkerrol.naam ?? '' },
						{ mainText: '', checkbox: { checked: checkedMedewerkerrollen.includes(medewerkerrol.id) } },
					],
				};
			});
		},

		[checkedMedewerkerrollen]
	);

	const pages = useTablePages(medewerkerrollen, getPage, checkedMedewerkerrollen);

	if (!pages) return null;

	return {
		header: getHeader(),
		body: { rows: pages },
	};
};
