import { createSlice } from '@reduxjs/toolkit';

import { getCurrentOrganisatieData, CurrentOrganisatieDataState, updateOrganisatie } from '.';

const initialState: CurrentOrganisatieDataState = {
	currentOrganisatieData: null,
	loading: false,
	updating: false,
	error: null,
};

export const currentOrganisatieDataSlice = createSlice({
	name: 'currentOrganisatieData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentOrganisatieData.pending, state => {
				state.loading = true;
				state.currentOrganisatieData = null;
			})
			.addCase(getCurrentOrganisatieData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentOrganisatieData = payload;
			})
			.addCase(getCurrentOrganisatieData.rejected, (state, { payload }) => {
				state.loading = false;
				state.currentOrganisatieData = null;
				state.error = payload ?? null;
			})
			.addCase(updateOrganisatie.pending, state => {
				state.updating = true;
			})
			.addCase(updateOrganisatie.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentOrganisatieData = payload;
			})
			.addCase(updateOrganisatie.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			});
	},
});

export const currentOrganisatieDataReducer = currentOrganisatieDataSlice.reducer;
