import { TFunction } from 'i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { Rechtspersoon } from '../../../../../api/models';
import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';

export const generateUpdateRechtsPersoonFormSchema = (relatieData: Rechtspersoon, t: TFunction): FormSchema => {
	const schema: FormSchema = {
		title: t('global:common.legalInformation') ?? '',
		formControls: [
			{
				name: 'id',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.id'),
						value: relatieData.id,
						disabled: true,
						required: true,
					},
				},
			},
			{
				name: 'idExtern',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.externId'),
						value: relatieData.idExtern,
						disabled: true,
					},
				},
			},
			{
				name: 'idBron',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.bronId'),
						value: relatieData.idBron,
						disabled: true,
					},
				},
			},
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						value: relatieData.code,
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: relatieData.soort?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIESOORT',
						code: relatieData.soort?.code,
						disabled: true,
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: relatieData.detailSoort?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'RELATIEDETAILSOORT',
						parentSoort: relatieData.soort?.code,
						code: relatieData.detailSoort?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'organisatievorm',
				submitPath: 'organisatievorm.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.organisatievorm'),
						value: relatieData.organisatievorm?.code,
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'ORGANISATIEVORM',
						code: relatieData.organisatievorm?.code,
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'naam',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.naam'),
						value: relatieData.naam,
						required: true,
					},
				},
			},
			{
				name: 'kvkNummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.kvkNummer'),
						value: relatieData.kvkNummer ?? '',
					},
				},
			},
			{
				name: 'btwNummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.btwNummer'),
						value: relatieData.btwNummer ?? '',
					},
				},
			},
			{
				name: 'globaalLocatienummer',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'relatie:relatie.globaalLocatienummer'),
						value: relatieData.globaalLocatienummer ?? '',
					},
				},
			},
		],
		layout: {
			columns: [
				['code', 'naam', 'organisatievorm'],
				['kvkNummer', 'globaalLocatienummer', 'btwNummer'],
			],
		},
		noSubmit: ['id', 'idExtern', 'idBron', 'soort'],
	};

	return schema;
};
