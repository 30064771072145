import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getEenheden, selectEenhedenData } from '../../redux/eenhedenData';
import UnitsTable from '../../components/UnitsTable/UnitsTable';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { useMapEenhedenToTableData } from '../../hooks/table/useMapEenhedenToTableData/useMapEenhedenToTableData';

export const PAGE_SIZE = 20;

const Units: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { eenheden, loading, error, itemsNum } = useAppSelector(selectEenhedenData);

	useEffect(() => {
		handleGetEenheden(0, true);
	}, []);

	const handleGetEenheden = (page: number, clear?: boolean) => {
		dispatch(getEenheden({ offset: page, limit: PAGE_SIZE, clear }));
	};

	const tableData = useMapEenhedenToTableData(eenheden ?? null);

	const OverviewContent = (
		<>
			{loading && <h3 data-testid="eenhedenListLoading">{t('global:common.loading')}...</h3>}
			{tableData && (
				<UnitsTable
					tableData={tableData}
					onPageChange={handleGetEenheden}
					pageSizes={[PAGE_SIZE]}
					itemsNum={itemsNum}
					tableTestId="eenhedenTable"
					loading={loading}
				/>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: OverviewContent,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.porfolioManagement'), link: '/vastgoedbeheer' },
		{ label: t('vastgoed:unitsPage.header.title') },
	];

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('vastgoed:unitsPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className="zds-flex-1">
				<Tabs tabsData={tabsData} />
			</Pane>
		</PageLayout>
	);
};

export default Units;
