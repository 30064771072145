import { FC, useState } from 'react';

import API from '../../../vastgoedbeheer/api';
import { Cluster } from '../../api/models';
import AdvancedSelectInput, {
	AdvancedSelectItem,
} from '../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';

export interface ClustersAdvancedSelectProps {
	selectedValues?: AdvancedSelectItem[];
	onChange: (data: AdvancedSelectItem[]) => void;
	validationError?: string;
	explanationText?: string;
	placeholder?: string;
}

const ClustersAdvancedSelect: FC<ClustersAdvancedSelectProps> = ({
	selectedValues = [],
	onChange,
	validationError,
	explanationText,
	placeholder,
}) => {
	const [clusters, setClusters] = useState<Cluster[] | null>(null);
	const [error, setError] = useState('');

	const searchClusters = async (term: string) => {
		try {
			const result = await API.getClusters({ term, limit: 10, expand: '', $fields: ['id', 'naam'], infDomain: true });
			setClusters(result.data);
		} catch {
			setError('global:form.errorMessage.get');
		}
	};

	return (
		<AdvancedSelectInput
			data={clusters ? clusters.filter(c => c.naam).map(c => ({ ...c, label: c.naam ?? '' })) : null}
			selectedValues={selectedValues}
			searchData={searchClusters}
			dataError={error}
			validationError={validationError}
			onChange={onChange}
			explanationText={explanationText}
			placeholder={placeholder}
		/>
	);
};

export default ClustersAdvancedSelect;
