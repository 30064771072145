import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { Organisatie } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { ListResponse } from '../../../../global/api';

export const getOrganisatiesData = createAsyncThunk<
	ListResponse<Organisatie>,
	{ term?: string; offset?: number; limit?: number; clear?: boolean },
	{ rejectValue: string }
>('organisatieData/getOrganisatiesData', async ({ term, offset, limit } = {}, { rejectWithValue }) => {
	try {
		const response = await OrganisatieAPI.getOrganisaties(term, offset, limit);

		return response;
	} catch (error) {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	}
});
