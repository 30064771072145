import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ZdsRadioGroupCustomEvent, ZdsCheckboxCustomEvent } from '@zig-design-system/ui-components';
import { ZdsButton, ZdsCheckbox, ZdsIcon } from '@zig-design-system/ui-components-react';

import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCurrentCommunityData, selectCurrentCommunityData } from '../../redux/currentCommunityData';
import { sideMenuInfo } from '../../routes';
import Messages from '../../components/Messages/Messages';
import PageLayoutWrapper from '../../../../global/components/PageLayoutWrapper/PageLayoutWrapper';
import CommunityDetails from '../../components/CommunityDetails/CommunityDetails';
import { useBerichtPermissions } from '../../hooks/useBerichtPermissions/useBerichtPermissions';
import MessageFiltersInappropriate from '../../components/Messages/MessageFilters/MessageFiltersInappropriate';
import { selectFilters, setFilters } from '../../redux/berichten';
import BackButton from '../../../../global/components/BackButton/BackButton';
import { PAGE_PARAM_NAME } from '../Communities/Communities';
import { useNewsFeature } from '../../hooks/useNewsFeature/useNewsFeature';
import styles from './CommunityInformation.module.scss';

const defaultSoortParam = 'all';
const defaultReportedParam = 'false';

const getInitSoortParam = (queryParams: URLSearchParams, isNewsFeatureEnabled: boolean) => {
	if (!isNewsFeatureEnabled) {
		return '';
	}

	const param = queryParams.get('soort') ?? '';
	return ['all', 'pri', 'nie'].includes(param) ? param : defaultSoortParam;
};
const getInitReportedParam = (queryParams: URLSearchParams, canFilterInappropriate: boolean) => {
	if (!canFilterInappropriate) return 'false';

	const param = queryParams.get('reported') ?? '';
	return ['true', 'false'].includes(param) ? param : defaultReportedParam;
};

const CommunityInformation: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [initialized, setInitialized] = useState(false);
	const params = useParams<{ id: string }>();
	const { showAll, showReported, showPRI, showNIE } = useAppSelector(selectFilters);
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const hasPermission = useBerichtPermissions();
	const canFilterInappropriate = hasPermission('COM_LEZ_MEL_ONG');
	const { isNewsFeatureEnabled } = useNewsFeature();

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const [soortParam, setSoortParam] = useState(getInitSoortParam(queryParams, isNewsFeatureEnabled));
	const [reportedParam, setReportedParam] = useState(getInitReportedParam(queryParams, canFilterInappropriate));
	const [isTimelineShown, setIsTimelineShown] = useState(false);
	const [fromPage, setFromPage] = useState(0);

	const reported = currentCommunityData?.aantalOngepast ?? 0;

	useEffect(() => {
		setFromPage(location.state?.fromPage ?? 0);
	}, []);

	useEffect(() => {
		const newShowPRI = ['all', 'pri', ''].includes(soortParam);
		const newShowNIE = ['all', 'nie'].includes(soortParam);

		if (soortParam) {
			queryParams.set('soort', soortParam);
		}
		queryParams.set('reported', reportedParam);
		navigate({ search: queryParams.toString() }, { replace: true });

		dispatch(
			setFilters({
				showPRI: newShowPRI,
				showNIE: newShowNIE,
				showAll: !canFilterInappropriate || reportedParam === 'false',
				showReported: canFilterInappropriate && reportedParam !== 'false',
			})
		);
		setInitialized(true);
	}, [soortParam, reportedParam]);

	useEffect(() => {
		const communityId = params.id;
		if (communityId) {
			void dispatch(getCurrentCommunityData(communityId));
		}
	}, [params]);

	const switchToShowAll = () => {
		if (!showAll || showReported) {
			setReportedParam('false');
		}
	};

	const switchToShowReported = () => {
		if (!showReported || showAll) {
			setReportedParam('true');
		}
	};

	const handleSelectFilterInappropriate = (e: ZdsRadioGroupCustomEvent<{ value: string }>) => {
		if (e.detail.value === 'all') {
			return switchToShowAll();
		}
		return switchToShowReported();
	};

	const handleFilterPRI = (e: ZdsCheckboxCustomEvent<{ shouldRenderInForm: boolean }>) => {
		const newValue = e.detail.shouldRenderInForm;
		if (newValue === showPRI) return;

		setSoortParam(getNewSoortParam({ pri: newValue, nie: showNIE }));
	};

	const handleFilterNIE = (e: ZdsCheckboxCustomEvent<{ shouldRenderInForm: boolean }>) => {
		const newValue = e.detail.shouldRenderInForm;
		if (newValue === showNIE) return;

		setSoortParam(getNewSoortParam({ pri: showPRI, nie: newValue }));
	};

	const handleShowTimelineChange = (shown: boolean) => {
		setIsTimelineShown(shown);
	};

	if (!params.id) return null;

	return (
		<PageLayoutWrapper sideMenuInfo={sideMenuInfo}>
			<div className="zds-flex zds-flex-1 zds-padding-4 zds-gap-8">
				<div className={styles.filtersContainer}>
					{isTimelineShown && (
						<>
							<div className="zds-headline-lg zds-margin-bottom-8">
								{t('community:communityInformationPage.messages.filter')}
							</div>
							{canFilterInappropriate && (
								<>
									<div className="zds-flex zds-items-center zds-gap-4 zds-margin-bottom-3">
										<div className="zds-headline-sm">{t('community:communityInformationPage.messages.show')}</div>
										<ZdsButton
											preset="danger"
											variant="text"
											className={styles.reportedButton}
											onZdsClick={switchToShowReported}
										>
											<ZdsIcon size="sm" name="triangle-exclamation" library="fa-solid" sizingType="height" />
											{reported}
										</ZdsButton>
									</div>
									<MessageFiltersInappropriate
										onSelectFilter={handleSelectFilterInappropriate}
										value={showAll ? 'all' : 'reported'}
									/>
								</>
							)}
							{isNewsFeatureEnabled && (
								<>
									<div className="zds-headline-sm zds-margin-bottom-3 zds-margin-top-6">
										{t('community:communityInformationPage.messages.typeOfMessage')}
									</div>
									<ZdsCheckbox name="messageType" value="pri" onZdsInputDidUpdate={handleFilterPRI} checked={showPRI}>
										<span>{t('community:communityInformationPage.messages.header.bulletinboard')}</span>
									</ZdsCheckbox>
									<ZdsCheckbox name="messageType" value="nie" onZdsInputDidUpdate={handleFilterNIE} checked={showNIE}>
										<span>{t('community:communityInformationPage.messages.header.newsfeed')}</span>
									</ZdsCheckbox>
								</>
							)}
						</>
					)}
				</div>
				<div className={styles.timelineContainer}>
					<div>
						<BackButton path={`/communitybeheer/community?${PAGE_PARAM_NAME}=${fromPage}`} />
					</div>
					{initialized && <Messages communityId={params.id} onShowTimelineChange={handleShowTimelineChange} />}
				</div>
				<CommunityDetails />
			</div>
		</PageLayoutWrapper>
	);
};

const getNewSoortParam = ({ pri, nie }: { pri: boolean; nie: boolean }): string => {
	if (pri && nie) return 'all';
	if (pri) return 'pri';
	if (nie) return 'nie';
	return 'none';
};

export default CommunityInformation;
