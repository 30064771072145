import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import DataTable, { TableIconSettings } from '../../../../../../global/components/DataTable/DataTable';
import { mapCommunityRelationsToTableData } from '../../../../utils/mapCommunityRelationsToTableData';
import {
	deleteCommunityCategoryRolRelatie,
	selectCurrentCommunityCategoryData,
} from '../../../../redux/currentCommunityCategoryData';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import AddCommunityCategoryFollowers from '../AddFollowers/AddCommunityCategoryFollowers';
import ConfirmationModal from '../../../../../../global/components/ConfirmationModal/ConfirmationModal';
import SubheaderItemsCount from '../../../../../../global/components/DataTable/SubheaderItemsCount/SubheaderItemsCount';
import styles from './CommunityCategoryMembers.module.scss';

const PAGE_SIZE = 5;

const CommunityCategoryMembers: FC = () => {
	const { t } = useTranslation();
	const { currentCommunityCategoryData, loading, error } = useAppSelector(selectCurrentCommunityCategoryData);
	const dispatch = useAppDispatch();
	const hasPermission = usePermissions();
	const [rolRelatieIdToDelete, setRolRelatieIdToDelete] = useState<string | null>(null);
	const modalRef = useRef<HTMLZdsModalElement | null>(null);

	const handleModalSave = () => {
		if (currentCommunityCategoryData && rolRelatieIdToDelete) {
			dispatch(
				deleteCommunityCategoryRolRelatie({
					communityCategoryId: currentCommunityCategoryData.id,
					rolRelatieId: rolRelatieIdToDelete,
				})
			);
		}
		handleModalClose();
	};

	const handleModalClose = () => {
		setRolRelatieIdToDelete(null);
	};

	const handleRolRelatieDeleteIconClick = (id: string) => {
		setRolRelatieIdToDelete(id);
		modalRef?.current?.show();
	};

	const followerRolRelaties = currentCommunityCategoryData?.rolRelaties?.filter(rol => rol.soort.code === 'VOL');

	return (
		<div className={styles.container}>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{followerRolRelaties && (
				<div className="zds-margin-top-4">
					<DataTable
						data={mapCommunityRelationsToTableData(followerRolRelaties, t)}
						tableTestId="communityCategoryMembersTable"
						iconOptions={
							hasPermission('COM_WIJ_COM_LED')
								? {
										...TableIconSettings.delete,
										onIconClick: handleRolRelatieDeleteIconClick,
								  }
								: undefined
						}
						pageSizes={[PAGE_SIZE]}
						header={{
							title: `${t('global:common.all')} ${t('community:community.followers').toLowerCase()}`,
							subheader: {
								Component: SubheaderItemsCount,
								props: {
									labelTotal: {
										singular: t('community:community.follower').toLowerCase(),
										plural: t('community:community.followers').toLowerCase(),
									},
								},
							},
						}}
					/>
				</div>
			)}
			{currentCommunityCategoryData && hasPermission('COM_WIJ_COM_LED') && (
				<AddCommunityCategoryFollowers communityCategorie={currentCommunityCategoryData} />
			)}
			{error && <ErrorMessage message={t(error)} />}
			<ConfirmationModal
				ref={modalRef}
				title={t('community:communityCategoryInformationPage.deleteMemberQuestion')}
				onSave={handleModalSave}
				onClose={handleModalClose}
				isDelete
			/>
		</div>
	);
};

export default CommunityCategoryMembers;
