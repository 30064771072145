import Constants from '../../../auth.config';

export const logout = (callback?: string) => {
	localStorage.removeItem('accessToken');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('act-as-organisatie');

	const idToken = localStorage.getItem('idToken');
	localStorage.removeItem('idToken');

	window.location.href = `${Constants.loginUrl}/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${
		callback ?? Constants.postLogoutCallback
	}`;
};
