import { Language } from '../../../apps/communitybeheer/api/models';
import { SUPPORTED_LANGUAGES } from '../../../apps/communitybeheer/components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { FormData, FormSchema, FormSubmitData } from '../../components/Form/Form.types';
import { getFieldSubmitData } from './getFieldSubmitData';

const DEFAULT_LANGUAGE = Language.Dutch;

export const getFormSubmitData = (
	formData: FormData | null,
	formSchema: FormSchema | null,
	dateFormat: string
): FormSubmitData | null => {
	if (!formData || !formSchema) return null;

	const translationControls = Object.keys(formData).filter(
		key => !(formSchema.noSubmit ?? []).includes(key) && formData[key].languageData
	);

	const translations = SUPPORTED_LANGUAGES.map(lang => {
		return translationControls.reduce((acc, key) => ({ ...acc, [key]: formData[key].languageData?.[lang] }), {
			taal: lang,
		});
	});

	const formSubmitData: FormSubmitData = Object.keys(formData)
		.filter(key => !(formSchema.noSubmit ?? []).includes(key) && !formData[key].languageData)
		.reduce((acc, key) => ({ ...acc, ...getFieldSubmitData(key, formSchema, formData, dateFormat) }), {});

	translationControls.forEach(name => {
		formSubmitData[name] = formData[name].languageData?.[DEFAULT_LANGUAGE];
	});

	if (translationControls.length) {
		formSubmitData.vertalingen = translations;
	}

	return formSubmitData;
};
