import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ZdsButton, ZdsCard, ZdsDivider, ZdsIcon } from '@zig-design-system/ui-components-react';

import { CommunityCategorie } from '../../../api/models';
import { SelectCategory } from './SelectCategory';
import { WriteMessage } from './WriteMessage';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import { createBericht, selectBerichten } from '../../../redux/berichten';
import { useAppDispatch, useAppSelector } from '../../../../../global/redux/store';
import { selectUserRelatie } from '../../../../../global/redux/shared/authorizationData';
import { useUploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import styles from './CreateMessage.module.scss';

export interface CreateMessageProps {
	onBackButtonClick: (refetch?: boolean) => void;
}

enum State {
	INIT,
	SELECT_CATEGORY,
	WRITE_MESSAGE,
}

const CreateMessage: FC<CreateMessageProps> = ({ onBackButtonClick }) => {
	const { t } = useTranslation();
	const relatie = useAppSelector(selectUserRelatie);
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const { loadingCreatingBericht, errorCreatingBericht } = useAppSelector(selectBerichten);
	const dispatch = useAppDispatch();
	const [selectedCategorie, setSelectedCategorie] = useState<CommunityCategorie | null>(null);
	const [currentState, setCurrentState] = useState<State>(State.INIT);
	const [bericht, setBericht] = useState('');
	const [creating, setCreating] = useState(false);

	const {
		fileData: allFileData,
		handleAddFile,
		handleRemoveFile,
	} = useUploadFileData({
		maxFiles: 1,
		mode: 'SWAP_LAST',
	});

	const fileData = allFileData[0];

	const categoriesNum = currentCommunityData?.categorieen?.length ?? 0;
	const canSend = !loadingCreatingBericht && !fileData?.error && (bericht.trim().length > 0 || fileData?.file);

	const handleSelectCategory = (category: CommunityCategorie) => {
		setSelectedCategorie(category);
		setCurrentState(State.WRITE_MESSAGE);
	};

	const handleBackButtonClick = () => {
		if (loadingCreatingBericht) return;

		setSelectedCategorie(null);

		if (currentState === State.WRITE_MESSAGE && categoriesNum > 0) {
			return setCurrentState(State.SELECT_CATEGORY);
		}

		onBackButtonClick();
	};

	const handleSubmit = async () => {
		if (!canSend) return;

		if (currentCommunityData) {
			dispatch(
				createBericht({
					communityId: currentCommunityData?.id,
					categoryId: selectedCategorie?.id,
					value: bericht,
					fileData: { base64: fileData?.base64, name: fileData?.file?.name ?? null },
				})
			);
		}

		setBericht('');
	};

	useEffect(() => {
		if (currentCommunityData) {
			setCurrentState(categoriesNum > 0 ? State.SELECT_CATEGORY : State.WRITE_MESSAGE);
		}
	}, [currentCommunityData]);

	useEffect(() => {
		setCreating(loadingCreatingBericht);
		if (currentState !== State.INIT && !loadingCreatingBericht && creating) {
			onBackButtonClick(true);
		}
	}, [loadingCreatingBericht]);

	if (currentState === State.INIT || !currentCommunityData) return null;

	return (
		<ZdsCard className={styles.card}>
			<ZdsButton slot="header-start" preset="muted" variant="text" aspect-square onZdsClick={handleBackButtonClick}>
				<ZdsIcon size="sm" name="arrow-left" library="fa-regular" />
			</ZdsButton>
			<div slot="header" className={styles.header}>
				{t('newPost.title')}
			</div>
			<ZdsButton
				slot="header-end"
				onZdsClick={handleSubmit}
				disabled={!canSend}
				className={classNames({
					[styles.hidden]: currentState !== State.WRITE_MESSAGE,
				})}
			>
				{t('newPost.submitButton')}
			</ZdsButton>

			<div slot="body">
				<ZdsDivider className={styles.divider} />
				{currentState === State.SELECT_CATEGORY && <SelectCategory onSelectedCategory={handleSelectCategory} />}
				{currentState === State.WRITE_MESSAGE && (
					<>
						<WriteMessage
							community={currentCommunityData}
							categorie={selectedCategorie}
							bericht={bericht}
							setBericht={setBericht}
							setFileData={handleAddFile}
							removeFile={() => handleRemoveFile(fileData.id)}
							fileData={fileData}
							author={relatie}
							loading={loadingCreatingBericht}
						/>
						{errorCreatingBericht && <h3>{t(errorCreatingBericht)}</h3>}
					</>
				)}
			</div>
		</ZdsCard>
	);
};

export default CreateMessage;
