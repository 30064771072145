import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Organisatie } from '../../../api/models';
import { getRelatieNaam } from '../../../../relatiebeheer/utils/getRelatieNaam';

export const useMapOrganisatiesToTableData = (
	organisaties: {
		[key: number]: Organisatie[];
	} | null
): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [t('global:domainShared.naam'), t('global:domainShared.omschrijving'), t('global:domainShared.soort')],
	};

	const getPage = useCallback((organisaties: Organisatie[]) => {
		return organisaties.map(organisatie => {
			return {
				id: organisatie.id ?? '',
				cells: [
					{ mainText: getRelatieNaam(organisatie.relatie) },
					{ mainText: organisatie.omschrijving ?? '' },
					{ mainText: getReferentiedataNaam(t, 'ORGANISATIESOORT', organisatie.soort?.code) },
				],
			};
		});
	}, []);

	const pages = useTablePages(organisaties, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
