import { createSlice } from '@reduxjs/toolkit';

import {
	getCurrentRelatieData,
	updateRelatie,
	CurrentRelatieDataState,
	createContactgegeven,
	updateContactgegeven,
	deleteContactgegeven,
} from '.';

const initialState: CurrentRelatieDataState = {
	currentRelatieData: null,
	loading: false,
	updating: false,
	error: null,
	contactgegevensData: {
		contactgegevens: [],
		loading: false,
		error: null,
		createDone: false,
		updateDone: false,
		deleteDone: false,
	},
};

export const currentRealtieDataSlice = createSlice({
	name: 'currentRelatieData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentRelatieData.pending, state => {
				state.loading = true;
				state.currentRelatieData = null;
				state.contactgegevensData.contactgegevens = [];
			})
			.addCase(getCurrentRelatieData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentRelatieData = payload;
				state.contactgegevensData.contactgegevens = payload.contactgegevens ?? [];
			})
			.addCase(getCurrentRelatieData.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			})
			.addCase(updateRelatie.pending, state => {
				state.updating = true;
			})
			.addCase(updateRelatie.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentRelatieData = payload;
			})
			.addCase(updateRelatie.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			})
			.addCase(createContactgegeven.pending, state => {
				state.contactgegevensData.loading = true;
				state.contactgegevensData.error = null;
				state.contactgegevensData.createDone = false;
				state.contactgegevensData.updateDone = false;
				state.contactgegevensData.deleteDone = false;
			})
			.addCase(createContactgegeven.fulfilled, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.createDone = true;
				state.contactgegevensData.contactgegevens.push(payload);
			})
			.addCase(createContactgegeven.rejected, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.createDone = true;
				state.contactgegevensData.error = payload ?? null;
			})
			.addCase(updateContactgegeven.pending, state => {
				state.contactgegevensData.loading = true;
				state.contactgegevensData.error = null;
				state.contactgegevensData.createDone = false;
				state.contactgegevensData.updateDone = false;
				state.contactgegevensData.deleteDone = false;
			})
			.addCase(updateContactgegeven.fulfilled, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.updateDone = true;
				const updatedContactIndex = state.contactgegevensData.contactgegevens.findIndex(c => c.id === payload.id);
				if (updatedContactIndex !== -1) {
					state.contactgegevensData.contactgegevens[updatedContactIndex] = payload;
				}
			})
			.addCase(updateContactgegeven.rejected, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.updateDone = true;
				state.contactgegevensData.error = payload ?? null;
			})
			.addCase(deleteContactgegeven.pending, state => {
				state.contactgegevensData.loading = true;
				state.contactgegevensData.error = null;
				state.contactgegevensData.createDone = false;
				state.contactgegevensData.updateDone = false;
				state.contactgegevensData.deleteDone = false;
			})
			.addCase(deleteContactgegeven.fulfilled, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.deleteDone = true;
				const deleteContactIndex = state.contactgegevensData.contactgegevens.findIndex(c => c.id === payload);
				if (deleteContactIndex !== -1) {
					state.contactgegevensData.contactgegevens.splice(deleteContactIndex, 1);
				}
			})
			.addCase(deleteContactgegeven.rejected, (state, { payload }) => {
				state.contactgegevensData.loading = false;
				state.contactgegevensData.deleteDone = true;
				state.contactgegevensData.error = payload ?? null;
			});
	},
});

export const currentRelatieDataReducer = currentRealtieDataSlice.reducer;
