import { useMemo } from 'react';
import { Bericht } from '../../api/models';
import { useBerichtPermissions } from '../useBerichtPermissions/useBerichtPermissions';

export type Operation = 'CREATE' | 'UPDATE' | 'DELETE';

export interface BerichtMenuOptions {
	edit?: boolean;
	delete?: boolean;
	deleteInappropriteContentReports?: boolean;
}

export const useBerichtMenuOptions = (
	bericht: Bericht,
	options: BerichtMenuOptions = { edit: true, delete: true, deleteInappropriteContentReports: true }
) => {
	const hasPermission = useBerichtPermissions();
	const canUpdateBericht = hasPermission('COM_WIJ_BER', bericht);
	const canDeleteBericht = hasPermission('COM_VER_BER', bericht);
	const canDeleteInappropriateReport = hasPermission('COM_VER_MEL_ONG');

	const numberOfInnapropriateReports = useMemo(() => {
		return bericht.emoties.filter(e => e.soort?.code === 'ONG').length;
	}, [bericht]);

	const showEdit = !!options.edit && canUpdateBericht;
	const showDelete = !!options.delete && canDeleteBericht;
	const showDeleteInappropriateReport =
		!!options.deleteInappropriteContentReports && canDeleteInappropriateReport && numberOfInnapropriateReports > 0;

	return { showEdit, showDelete, showDeleteInappropriateReport };
};
