import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getCurrentMedewerkerrolData, selectCurrentMedewerkerrolData } from '../../redux/currentMedewerkerrolData';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { sideMenuInfo } from '../../routes';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import MedewerkerrolOverview from './TabsContent/MedewerkerrolOverview/MedewerkerrolOverview';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';

const MedewerkerrolInformation: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { currentMedewerkerrolData, updating } = useAppSelector(selectCurrentMedewerkerrolData);
	const params = useParams<{ id: string }>();
	const hasPermission = usePermissions();
	const [selectedTab, setSelectedTab] = useState(0);
	const isExternal = !!currentMedewerkerrolData?.idExtern;

	useEffect(() => {
		if (params.id) {
			void dispatch(getCurrentMedewerkerrolData(params.id));
			setSelectedTab(0);
		}
	}, [params]);

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: currentMedewerkerrolData ? <MedewerkerrolOverview /> : null,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organisatie.medewerkerrollen'), link: '/organisatiebeheer/medewerkerrollen' },
		{ label: currentMedewerkerrolData?.naam ?? '' },
	];

	const saveButton = (
		<SaveButton dataTestId="updateMedewerkerrolDataSubmitButton" formId="updateMedewerkerrolForm" disabled={updating} />
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={currentMedewerkerrolData?.naam ?? ''}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={selectedTab === 0 && hasPermission('ORG_WIJ_ORG') && !isExternal && saveButton}
			/>
			<Pane className="zds-flex-1">
				<Tabs tabsData={tabsData} initActiveTab={selectedTab} onTabChange={setSelectedTab} />
			</Pane>
		</PageLayout>
	);
};

export default MedewerkerrolInformation;
