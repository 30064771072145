import { FC, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ZdsInputSearch } from '@zig-design-system/ui-components-react';
import { useTranslation } from 'react-i18next';
import { ZdsInputSearchCustomEvent } from '@zig-design-system/ui-components';

const SEARCH_PARAM_NAME = 'search';

export interface SearchProps {
	onSearch: (term: string) => void;
}

const Search: FC<SearchProps> = ({ onSearch }) => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const placeholder = t('global:common.searchPlaceholder');
	const [searchTerm, setSearchTerm] = useState(searchParams.get(SEARCH_PARAM_NAME) ?? '');

	const handleSearchChange = useCallback(
		(event: ZdsInputSearchCustomEvent<string>) => {
			const term = event.detail;

			if (term === searchTerm) return;

			setSearchTerm(term);
			updateSearchParams(term);
			onSearch(term);
		},
		[searchTerm]
	);

	const updateSearchParams = (term: string) => {
		if (term !== '') {
			setSearchParams({ search: term });
			return;
		}
		searchParams.delete(SEARCH_PARAM_NAME);
		setSearchParams(searchParams);
	};

	return (
		<ZdsInputSearch
			minSearchLength={1}
			value={searchTerm}
			onZdsSearch={handleSearchChange}
			placeholder={placeholder}
			className="zds-flex-grow-1 zds-margin-right-3"
		/>
	);
};

export default Search;
