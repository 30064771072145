import { ZdsButton, ZdsDivider, ZdsIcon, ZdsSideSheet, ZdsTab, ZdsTabs } from '@zig-design-system/ui-components-react';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import SideMenu, { SideMenuInfo } from '../../../../global/components/SideMenu/SideMenu';
import styles from './HomePage.module.scss';

const sideMenuInfo: SideMenuInfo = {
	linkBase: '/demo-zds',
	sections: [
		{
			title: 'Management',
			items: [
				{
					title: 'Clusters',
					link: '',
					iconName: 'object-group',
					notificationCount: 2,
				},
				{
					title: 'Amenities',
					link: '#',
					iconName: 'people-roof',
				},
				{
					title: 'Units',
					link: '#',
					iconName: 'house',
				},
			],
		},
		{
			title: 'Settings',
			items: [
				{
					title: 'Manage cluster types',
					link: '#',
					iconName: 'sliders-up',
				},
				{
					title: 'Manage amenities',
					link: '#',
					iconName: 'sliders-up',
				},
			],
		},
		{
			title: 'Settings',
			items: [
				{
					title: 'Sync configuration',
					link: '#',
					iconName: 'arrows-rotate',
				},
				{
					title: 'Sync logging',
					link: '#',
					iconName: 'arrows-rotate',
				},
			],
		},
	],
};

const HomePage = () => {
	return (
		<div className={styles.mainRow}>
			<SideMenu info={sideMenuInfo} />
			<div className={styles.page}>
				<div className={styles.mainColumn}>
					<PageHeader
						title="Demo ZDS Home"
						breadcrumbs={[{ label: 'Breadcrumb 1' }, { label: 'Breadcrumb 2' }]}
						iconName="house"
						iconLibrary="fa-regular"
					/>
					<Pane className={styles.contentPane}>
						<ZdsTabs className={styles.tabs}>
							<ZdsTab active>Overview</ZdsTab>
							<ZdsTab>Tab 2</ZdsTab>
							<ZdsTab>Tab 3</ZdsTab>
						</ZdsTabs>
						<ZdsDivider className={styles.divider} />
						<div className={styles.tabContent}>Content...</div>
					</Pane>
				</div>
				<ZdsSideSheet className={styles.sideSheet} position="right">
					<ZdsButton className={styles.sideSheetButtonLeft} slot="title-start" color="grey-600" variant="text">
						<ZdsIcon name="arrow-left" library="fa-regular"></ZdsIcon>
					</ZdsButton>
					<div slot="title">Side sheet title</div>
					<div slot="subtitle">Subtitle</div>
					<ZdsButton className={styles.sideSheetButtonRight} slot="title-end" color="grey-600" variant="text">
						<ZdsIcon name="xmark" library="fa-regular"></ZdsIcon>
					</ZdsButton>
					<div slot="header">
						<div className={styles.sideSheetImagePlaceholder}></div>
						<ZdsTabs className={styles.sideSheetTabs}>
							<ZdsTab active>Overview</ZdsTab>
							<ZdsTab>Tab 2</ZdsTab>
							<ZdsTab>Tab 3</ZdsTab>
						</ZdsTabs>
					</div>
					<div slot="body"></div>
					<div slot="footer" className="zds-flex zds-justify-content-end">
						<ZdsButton variant="text">Cancel</ZdsButton>
						<ZdsButton>Save</ZdsButton>
					</div>
				</ZdsSideSheet>
			</div>
		</div>
	);
};

export default HomePage;
