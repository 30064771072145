import { TFunction } from 'i18next';
import { ZdsInputDate } from '@zig-design-system/ui-components-react';

import { FormControl, InputDataType } from '../../components/Form/Form.types';
import { getStringTranslation } from '../getStringTranslation';

export const getDateFormControlData = ({
	t,
	dateFormat,
	value,
	name,
	required = false,
	disabled,
	withLabel = true,
	fullWidth,
}: {
	t: TFunction;
	dateFormat: string;
	value: InputDataType;
	name: string;
	required?: boolean;
	disabled?: boolean;
	withLabel?: boolean;
	fullWidth?: boolean;
}): FormControl => {
	return {
		name,
		control: {
			type: ZdsInputDate,
			props: {
				label: withLabel ? getStringTranslation(t, `global:domainShared.${name}`) : '',
				format: dateFormat,
				value: (value as string) ?? '',
				required,
				calendar: 'on-focus',
				...(disabled && { disabled: true }),
			},
		},
		layoutOptions: { labelPosition: withLabel ? 'side' : 'top', fullWidth: !!fullWidth || !withLabel },
	};
};
