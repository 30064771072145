import { RouteInfo } from '../../routes';
import CreateRelation from './pages/CreateRelation/CreateRelation';
import Relations from './pages/Relations/Relations';
import RelationInformation from './pages/RelationInformation/RelationInformation';
import Preferences from '../../global/pages/Preferences/Preferences';
import { SideMenuInfo } from '../../global/components/SideMenu/SideMenu';
import Profile from '../../global/pages/Profile/Profile';

export const sideMenuInfo: SideMenuInfo = {
	linkBase: '/relatiebeheer',
	sections: [
		{
			items: [
				{
					title: 'relatie:relatie.relaties',
					link: 'relatie',
					iconName: 'calendar',
				},
			],
		},
	],
};

const routes: RouteInfo[] = [
	{
		path: 'relatie',
		subRoutes: [
			{
				path: '',
				Component: () => <Relations />,
			},
			{
				path: ':id',
				Component: () => <RelationInformation />,
			},
			{
				path: 'create',
				Component: () => <CreateRelation />,
			},
		],
	},
	{
		path: 'persoonlijkeinstellingen',
		Component: () => <Preferences sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: 'profiel',
		Component: () => <Profile sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: '',
		redirect: '/relatiebeheer/relatie',
		Component: () => null,
	},
];

export default routes;
