import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSchema } from '../../../../../global/components/Form/Form.types';
import { ZdsInputText } from '@zig-design-system/ui-components-react';
import ArrayForm, { TableSchema } from '../../../components/ArrayForm/ArrayForm';

export interface IamApplication {
	type: string;
	id: string;
	name: string;
	urls: string;
}

interface CreateApplicationsProps {
	applications: IamApplication[];
	setApplications: (applications: IamApplication[]) => void;
}

const CreateApplications: FC<CreateApplicationsProps> = ({ applications, setApplications }) => {
	const { t } = useTranslation(['global', 'organisatie']);

	const applicationFormSchema: FormSchema = {
		formControls: [
			{
				name: 'id',
				control: {
					type: ZdsInputText,
					props: {
						label: 'ID',
						required: true,
					},
				},
			},
			{
				name: 'name',
				control: {
					type: ZdsInputText,
					props: {
						label: 'Naam',
						required: true,
					},
				},
			},
			{
				name: 'type',
				control: {
					type: ZdsInputText,
					props: {
						label: 'Type',
						required: true,
					},
				},
			},
			{
				name: 'urls',
				control: {
					type: ZdsInputText,
					props: {
						label: "Url's",
						required: true,
					},
				},
			},
		],
	};

	const applicationTableSchema: TableSchema = {
		columns: [
			{
				header: 'ID',
				mapper: (item: IamApplication) => ({ mainText: item.id }),
			},
			{
				header: 'Name',
				mapper: (item: IamApplication) => ({ mainText: item.name }),
			},
			{
				header: 'Type',
				mapper: (item: IamApplication) => ({ mainText: item.type }),
			},
			{
				header: "Url's",
				mapper: (item: IamApplication) => ({ mainText: item.urls }),
			},
		],
	};

	return (
		<>
			<div className="zds-headline-md">{t('Applicaties')}</div>
			<ArrayForm
				formSchema={applicationFormSchema}
				tableSchema={applicationTableSchema}
				items={applications}
				onChange={setApplications}
				formId="createApplication"
				formTestId="createApplicationTest"
			/>
		</>
	);
};

export default CreateApplications;
