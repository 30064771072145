import { DefaultEntity, EntityPayload, Referentiedata, ReferentiedataPayload } from '../../../global/api/models';
import { Relatie } from '../../relatiebeheer/api/models';

export interface Community extends DefaultEntity {
	status: Referentiedata;
	bijlagen?: Informatieobject[];
	clusters: Cluster[];
	kleur?: string;
	naam: string;
	omschrijving?: string;
	rolRelaties?: RolRelatie[];
	toelichting?: string;
	categorieen?: CommunityCategorie[];
	toewijzing: Referentiedata;
	aantalBerichten: number;
	aantalOngepast: number;
	vertalingen?: CommunityVertaling[];
}

export interface RolRelatie extends DefaultEntity {
	relatie: Relatie;
	soort: Referentiedata;
}

export interface CommunityCategorie extends DefaultEntity {
	status?: Referentiedata;
	bijlagen?: string[];
	community: Community;
	naam: string;
	omschrijving?: string;
	rolRelaties?: RolRelatie[];
	toelichting?: string;
	aantalBerichten: number;
	vertalingen?: CommunityCategorieVertaling[];
}

export interface Cluster extends DefaultEntity {
	naam?: string;
	omschrijving?: string;
	bovenliggendeCluster?: Cluster;
	relaties?: string[];
	overeenkomsten?: string[];
	rolRelaties?: RolRelatie[];
}

export interface Informatieobject extends DefaultEntity {
	detailSoort?: Referentiedata;
	titel?: string;
	beschrijving?: string;
	registratiedatum?: Date;
	publicatiedatum?: Date;
	auteur?: string;
	bestand?: string;
	bestandsgrootte?: number;
	bestandslocatie?: string;
	bestandsnaam?: string;
	externePublicatie?: boolean;
	externePublicatietekst?: string;
	formaat?: Referentiedata;
	gebruiksrechten?: string;
	taal?: Referentiedata;
	versie?: number;
	vertrouwelijkheid?: Referentiedata;
}

export enum Language {
	Dutch = 'nl',
	English = 'en',
	German = 'de',
	French = 'fr',
	Finnish = 'fi',
}

export interface Vertaling {
	taal: Language;
}

export interface BerichtVertaling extends Vertaling {
	onderwerp?: string;
	beschrijving?: string;
}

export interface CommunityVertaling extends Vertaling {
	naam?: string;
	omschrijving?: string;
	toelichting?: string;
}

export interface CommunityCategorieVertaling extends Vertaling {
	naam?: string;
	omschrijving?: string;
	toelichting?: string;
}

export interface Bericht extends DefaultEntity {
	detailSoort?: Referentiedata;
	status: Referentiedata;
	archiefActiedatum?: Date;
	beschrijving: string;
	bijlagen?: Informatieobject[];
	bovenliggendBericht?: Bericht;
	community: Community;
	onderwerp?: string;
	publicatiedatum?: Date;
	reacties?: Bericht[];
	reageeroptie?: Referentiedata;
	categorie?: CommunityCategorie;
	vertrouwelijkheid?: Referentiedata;
	rolRelaties?: RolRelatie[];
	emoties: BerichtEmotie[];
	aantalOngepast: number;
	vertalingen?: BerichtVertaling[];
	pinBerichtTotDatum?: Date;
	verstuurPushNotificatie?: boolean;
}

export interface BerichtEmotie extends DefaultEntity {
	bericht: Bericht;
	detailSoort: Referentiedata;
	relatie: Relatie;
	toelichting?: string;
}

export interface CreateBerichtPayload {
	soort: ReferentiedataPayload;
	detailSoort: ReferentiedataPayload;
	reageeroptie?: ReferentiedataPayload;
	status: ReferentiedataPayload;
	categorie?: EntityPayload;
	community: EntityPayload;
	bovenliggendBericht?: EntityPayload;
	beschrijving: string;
	rolRelaties: RolRelatiePayload[];
	bijlagen?: {
		soort: ReferentiedataPayload;
		bestand: string;
		bestandsnaam: string | null;
	}[];
}

export interface UpdateBerichtPayload {
	beschrijving: string;
	bijlagen?: {
		soort: ReferentiedataPayload;
		bestand: string;
		bestandsnaam: string | null;
	}[];
}

export interface CreateBerichtEmotiePayload {
	soort: ReferentiedataPayload;
	detailSoort: ReferentiedataPayload;
	bericht: EntityPayload;
	relatie: EntityPayload;
}

export interface Informatieobject extends DefaultEntity {
	titel?: string;
	beschrijving?: string;
	registratiedatum?: Date;
	publicatiedatum?: Date;
	auteur?: string;
	bestand?: string;
	bestandsgrootte?: number;
	bestandslocatie?: string;
	bestandsnaam?: string;
	externePublicatie?: boolean;
	externePublicatietekst?: string;
	formaat?: Referentiedata;
	gebruiksrechten?: string;
	taal?: Referentiedata;
	versie?: number;
	vertrouwelijkheid?: Referentiedata;
}

export interface NatuurlijkPersoonInfo {
	id: string;

	naam: string;

	soort: Referentiedata;
}

export type RolRelatiePayload = {
	soort: ReferentiedataPayload;
	relatie: EntityPayload;
};
