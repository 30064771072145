import { createSlice } from '@reduxjs/toolkit';
import { MedewerkersState, getMedewerkers } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: MedewerkersState = { itemsNum: 0, medewerkers: null, loading: false, error: null };

export const medewerkersSlice = createSlice({
	name: 'medewerkers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getMedewerkers.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.medewerkers = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getMedewerkers.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.medewerkers = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.medewerkers);
			})
			.addCase(getMedewerkers.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const medewerkersReducer = medewerkersSlice.reducer;
