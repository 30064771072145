import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsRadio, ZdsRadioGroup } from '@zig-design-system/ui-components-react';
import { ZdsRadioGroupCustomEvent } from '@zig-design-system/ui-components';

interface MessageFiltersInappropriateProps {
	onSelectFilter: (e: ZdsRadioGroupCustomEvent<{ value: string }>) => void;
	value: string;
}

const MessageFiltersInappropriate: FC<MessageFiltersInappropriateProps> = ({ value, onSelectFilter }) => {
	const { t } = useTranslation();

	return (
		<ZdsRadioGroup onZdsInputDidUpdate={onSelectFilter}>
			<ZdsRadio name="options" value="all" checked={value === 'all'}>
				<span>{t('community:communityInformationPage.messages.allMessages')}</span>
			</ZdsRadio>
			<ZdsRadio name="options" value="reported" checked={value === 'reported'}>
				<span>{t('community:communityInformationPage.messages.reportedInappropriateContent')}</span>
			</ZdsRadio>
		</ZdsRadioGroup>
	);
};

export default MessageFiltersInappropriate;
