import { useCallback } from 'react';
import { FormData, FormSchema, FormControl as FormControlType } from '../../components/Form/Form.types';
import FormControl from '../../components/Form/FormControl/FormControl';
import { InputWrapperProps } from '../../components/Form/InputWrapper/InputWrapper';
import { Language } from '../../../apps/communitybeheer/api/models';

export const useRenderFormControl = ({
	formSchema,
	formData,
	language,
	onUpdateFormData,
}: {
	formSchema: FormSchema | null;
	formData: FormData | null;
	onUpdateFormData: (event: CustomEvent, inputData: FormControlType, language: Language) => void;
	language?: Language;
}) => {
	const getFormControl = (name: string) => formSchema?.formControls.find(fc => fc.name === name);

	const renderFormControl = useCallback(
		(name: string, labelWidth?: InputWrapperProps['labelWidth']) =>
			formData && (
				<FormControl
					sectionHeaderOrFormControl={getFormControl(name)}
					formData={formData}
					onUpdateFormData={onUpdateFormData}
					language={language}
					labelWidth={labelWidth}
				/>
			),
		[formSchema, formData, language, onUpdateFormData]
	);

	return renderFormControl;
};
