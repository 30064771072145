import { ComponentProps, FC, ReactNode, useEffect, useState } from 'react';
import { ZdsButton, ZdsDivider, ZdsIcon, ZdsTab, ZdsTabs } from '@zig-design-system/ui-components-react';
import { ZdsTabsCustomEvent } from '@zig-design-system/ui-components';

import styles from './Tabs.module.scss';

export interface IconOptions {
	name: string;
	library: string;
	size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'inherit';
}

export interface TabData {
	name: string;
	content: ReactNode;
	prefixIconOptions?: IconOptions;
	withBorder?: boolean;
}

export interface TabsProps {
	tabsData: TabData[];
	initActiveTab?: number;
	onTabChange?: (tab: number) => void;
}

const Tabs: FC<TabsProps> = ({ tabsData, initActiveTab = 0, onTabChange }) => {
	const [selectedTab, setSelectedTab] = useState(initActiveTab);

	useEffect(() => {
		setSelectedTab(initActiveTab);
	}, [initActiveTab]);

	const onTabActivate = (event: ZdsTabsCustomEvent<{ index?: number }>) => {
		const index = event.detail?.index;
		if (index !== undefined && index >= 0 && index < tabsData.length) {
			setSelectedTab(index);
			if (onTabChange) {
				onTabChange(index);
			}
		}
	};

	return (
		<>
			<ZdsTabs className={styles.tabs} onZdsTabActivate={onTabActivate}>
				{tabsData.map((tab, i) => {
					const props: ComponentProps<typeof ZdsTab> = {};
					if (i === selectedTab) props.active = true;
					const TabWrapper = tab.withBorder ? ZdsButton : 'div';

					return (
						<ZdsTab key={tab.name} {...props} className="zds-relative">
							<TabWrapper preset="neutral" variant="outlined" className="zds-flex zds-items-center zds-gap-2">
								{tab.prefixIconOptions && (
									<ZdsIcon
										name={tab.prefixIconOptions.name}
										library={tab.prefixIconOptions.library}
										size={tab.prefixIconOptions.size}
									/>
								)}
								{tab.name}
							</TabWrapper>
							<div className={styles.tabCover} />
						</ZdsTab>
					);
				})}
			</ZdsTabs>
			<ZdsDivider className={styles.divider} />
			<div className={styles.tabContent}>{tabsData[selectedTab]?.content}</div>
		</>
	);
};

export default Tabs;
