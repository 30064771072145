import { FormData, FormSchema, isCheckbox, isSwitch } from '../../components/Form/Form.types';

export const getFormDataFromSchema = ({ formControls }: FormSchema): FormData => {
	return formControls.reduce((acc, fc) => {
		const control = fc.control;
		let value = isCheckbox(control) ? control.props.checked : control.props.value;
		if (isSwitch(control)) value = !!value;

		return {
			...acc,
			[fc.name]: {
				value: value ?? '',
				languageData: control.props.languageData,
				type: control.type,
			},
		};
	}, {});
};
