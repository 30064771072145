import { IBericht, Relatie } from '@zig.community/community-components';

import { NatuurlijkPersoon } from '../../../relatiebeheer/api/models';

// Needed because RelatieAvatar expects all name properties to exist - should be deleted after the RelatieAvatar fix
export const getEmoties = (bericht: IBericht) => bericht.emoties.map(mapRelatie);

export const getRolRelaties = (bericht: IBericht) => bericht.rolRelaties.map(mapRelatie);

const mapRelatie = <T extends { relatie: Relatie }>(obj: T) => {
	return {
		...obj,
		relatie: {
			...obj.relatie,
			achternaam: (obj.relatie as NatuurlijkPersoon).achternaam ?? '',
			voornaam: (obj.relatie as NatuurlijkPersoon).voornaam ?? '',
		},
	};
};
