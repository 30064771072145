import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import Form from '../../../../global/components/Form/Form';
import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import styles from './CreateMedewerker.module.scss';
import { generateCreateMedewerkerFormSchema } from '../../utils/form/schema/medewerker/create';
import { ZdsDivider } from '@zig-design-system/ui-components-react';
import OrganisatieAPI from '../../api';
import { useNavigate } from 'react-router';

const CreateMedewerker: FC = () => {
	const { t, i18n } = useTranslation(['global', 'organisatie']);
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const formSchema = generateCreateMedewerkerFormSchema(t, i18n.language);

	const compoundForm = {
		forms: [
			{
				id: 'createMedewerkerRelatieForm',
				main: true,
			},
			{
				id: 'createMedewerkerMedewerkerForm',
			},
			{
				id: 'createMedewerkerAccountForm',
			},
		],
	};

	const handleSubmit = (formData: object, formId: string) => {
		getCompoundFormData(formData, formId, compoundForm, async (formData: any) => {
			delete formData.createMedewerkerRelatieForm.nationaliteit;

			try {
				const request = {
					organisatorischeEenheden: [],
					...formData.createMedewerkerMedewerkerForm,
					code: formData.createMedewerkerRelatieForm.code,
					relatie: {
						...formData.createMedewerkerRelatieForm,
						account: { ...formData.createMedewerkerAccountForm, soort: { code: 'GEB' } },
					},
				};

				const id = await OrganisatieAPI.createMedewerker(request);
				navigate(`/organisatiebeheer/organisatie/medewerker/${id}`);
			} catch (error) {
				setError('global:form.errorMessage.create');
			}
		});
	};

	const getCompoundFormData = (
		submitData: object,
		formId: string,
		compoundForm: { forms: { id: string; main?: boolean }[]; data?: any },
		callback: (formData: object) => void
	): void => {
		const isMainForm = compoundForm.forms.find(definition => definition.id === formId)?.main;

		if (isMainForm) {
			compoundForm.data = {};

			compoundForm.forms.forEach(definition => {
				if (!definition?.main) {
					const formElement: HTMLElement | null = document.getElementById(definition.id);
					formElement && (formElement as HTMLZdsFormElement).submit();
				}
			});
		}

		if (compoundForm.data) {
			compoundForm.data[formId] = submitData;

			if (compoundForm.forms.every(definition => Object.keys(compoundForm.data).includes(definition.id))) {
				const compoundData = compoundForm.data;
				delete compoundForm.data;
				callback(compoundData);
			}
		}
	};

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:medewerkersPage.header.title'), link: '/organisatiebeheer/medewerkers' },
		{ label: t('global:common.create') },
	];

	const saveButton = <SaveButton dataTestId="createMedewerkerSubmitButton" formId="createMedewerkerRelatieForm" />;

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('organisatie:medewerkersPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={saveButton}
			/>
			<Pane className={styles.contentPane}>
				{error && (
					<div className="zds-padding-bottom-lg">
						<ErrorMessage message={t(error)} />
					</div>
				)}
				{formSchema && (
					<div
						data-testid="createMedewerkerForm"
						className="zds-margin-left-xl zds-margin-top-lg"
						id="createMedewerkerFormContainer"
					>
						<Form
							schema={formSchema.relatieSchema}
							onSubmit={data => handleSubmit(data, 'createMedewerkerRelatieForm')}
							formId="createMedewerkerRelatieForm"
						/>

						<div className="zds-margin-top-md">
							<ZdsDivider />
						</div>

						<Form
							schema={formSchema.medewerkerSchema}
							onSubmit={data => handleSubmit(data, 'createMedewerkerMedewerkerForm')}
							formId="createMedewerkerMedewerkerForm"
						/>

						<div className="zds-margin-top-md">
							<ZdsDivider />
						</div>

						<Form
							schema={formSchema.accountSchema}
							onSubmit={data => handleSubmit(data, 'createMedewerkerAccountForm')}
							formId="createMedewerkerAccountForm"
						/>

						<div className="zds-margin-top-md zds-text-right">{saveButton}</div>
					</div>
				)}
			</Pane>
		</PageLayout>
	);
};

export default CreateMedewerker;
