import { createSlice } from '@reduxjs/toolkit';

import { getRelatiesData, RelatieDataState } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: RelatieDataState = {
	relatieData: null,
	loading: false,
	error: null,
	itemsNum: 0,
};

export const realtieDataSlice = createSlice({
	name: 'relatieData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getRelatiesData.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.relatieData = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getRelatiesData.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.relatieData = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.relatieData);
			})
			.addCase(getRelatiesData.rejected, (state, { payload }) => {
				state.loading = false;
				state.relatieData = null;
				state.error = payload ?? null;
			});
	},
});

export const relatieDataReducer = realtieDataSlice.reducer;
