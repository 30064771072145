import { FC, ReactNode, useEffect } from 'react';
import { ZdsPopout } from '@zig-design-system/ui-components-react';

import styles from './OptionMenu.module.scss';

interface MessageMenuProps {
	id: string;
	isVisible: boolean;
	setIsVisible: (value: boolean) => void;
	children: ReactNode;
	className?: string;
}

const OptionMenu: FC<MessageMenuProps> = ({ id, isVisible, setIsVisible, className, children }) => {
	useEffect(() => {
		const handleDocumentClick = () => {
			if (isVisible) {
				setIsVisible(false);
			}
		};
		document.addEventListener('click', handleDocumentClick);
		document.addEventListener('zdsClick', handleDocumentClick as EventListener);

		return () => {
			document.removeEventListener('click', handleDocumentClick);
			document.removeEventListener('zdsClick', handleDocumentClick as EventListener);
		};
	}, [isVisible]);

	return (
		<div className={styles.container}>
			<div id={`option-menu-${id}`}></div>
			<ZdsPopout target={`#option-menu-${id}`} open={isVisible} position="start" className={className}>
				{children}
			</ZdsPopout>
		</div>
	);
};

export default OptionMenu;
