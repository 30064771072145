import { combineReducers } from '@reduxjs/toolkit';
import { communitiesReducer as communitiesDataReducer } from './communities';
import { currentCommunityDataReducer } from './currentCommunityData';
import { currentCommunityCategoryDataReducer } from './currentCommunityCategoryData';
import { berichtenReducer } from './berichten';
import { relatiesReducer } from './relaties';

export const communitiesReducer = combineReducers({
	communities: communitiesDataReducer,
	currentCommunityData: currentCommunityDataReducer,
	currentCommunityCategoryData: currentCommunityCategoryDataReducer,
	berichten: berichtenReducer,
	relaties: relatiesReducer,
});
