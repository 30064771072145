import { FC } from 'react';
import { useNavigate } from 'react-router';
import { ZdsButtonCustomEvent } from '@zig-design-system/ui-components';

import { Organisatie } from '../../api/models';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import { IconOptions } from '../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';
import DataTable from '../../../../global/components/DataTable/DataTable';
import { useMapOrganisatiesToTableData } from '../../hooks/table/useMapOrganisationsToTableData/useMapOrganisationsToTableData';

export interface OrganizationsTableProps {
	organisatieData?: { [key: number]: Organisatie[] };
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
}

const OrganizationsTable: FC<OrganizationsTableProps> = ({ organisatieData, onPageChange, pageSize, itemsNum }) => {
	const navigate = useNavigate();
	const hasPermission = usePermissions();

	const handleRowClick = (id: string) => {
		navigate(`/organisatiebeheer/organisatie/${id}`);
	};

	const switchToOrganisation = (id: string, event: ZdsButtonCustomEvent<MouseEvent>) => {
		event.stopPropagation();

		localStorage.setItem('act-as-organisatie', id);

		navigate('/');
	};

	const iconOptions: IconOptions | undefined = hasPermission('ORG_BEH_ORG')
		? {
				name: 'arrows-cross',
				onIconClick: switchToOrganisation,
				library: 'fa-regular',
				size: 'xxs',
				preset: 'secondary',
				variant: 'outlined',
		  }
		: undefined;

	const tableData = useMapOrganisatiesToTableData(organisatieData ?? null);

	return (
		<DataTable
			data={tableData}
			onRowClick={handleRowClick}
			tableTestId="organizationsTable"
			iconOptions={iconOptions}
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
		/>
	);
};

export default OrganizationsTable;
