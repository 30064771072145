import { ZdsAvatar, ZdsDivider, ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import ContextMenu from '../ContextMenu/ContextMenu';
import { selectUser } from '../../redux/shared/authorizationData';
import { useAppSelector } from '../../redux/store';
import { logout } from '../../utils/auth/logout';
import styles from './AccountSection.module.scss';

interface AccountSectionProps {
	tempRelativePath: string;
}

const AccountSection = ({ tempRelativePath }: AccountSectionProps) => {
	const user = useAppSelector(selectUser);
	const accountSectionRef = useRef(null);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const initials = `${user?.voornaam?.charAt(0).toUpperCase() ?? ''}${
		user?.achternaam?.slice(0, user?.voornaam ? 1 : 2).toUpperCase() ?? ''
	}`.toUpperCase();

	const isActingAsOrganisation = localStorage.getItem('act-as-organisatie');
	const stopActingAsOrganisation = () => {
		localStorage.removeItem('act-as-organisatie');
		navigate('/');
	};

	return (
		<div ref={accountSectionRef} className={styles.accountSectionContainer}>
			<div className={styles.accountSection} data-testid="accountSection">
				<div id="appbar__account-section-inner" className={styles.accountSectionInner}>
					<ZdsAvatar className={styles.accountAvatar}>{initials}</ZdsAvatar>
					<div className={styles.accountInfo}>
						<div className={styles.accountName}>{user?.naam}</div>
						<div className={styles.accountTitle}>{user?.medewerker?.omschrijving}</div>
					</div>
					<div className={styles.accountChevronContainer}>
						<ZdsIcon name="chevron-down" library="fa-light" size="xs" />
					</div>
				</div>
			</div>

			<ContextMenu
				target="#appbar__account-section-inner"
				targetRef={accountSectionRef}
				className={styles.accountMenu}
				position="end"
			>
				<div className={classNames('zds-headline-sm', styles.accountMenuTitle)}>{user?.naam}</div>
				<ZdsDivider />
				<ZdsItem
					onZdsClick={() => {
						navigate(tempRelativePath + '/profiel');
					}}
				>
					<div slot="primary-text">{t('account.profile')}</div>
				</ZdsItem>
				<ZdsItem
					onZdsClick={() => {
						navigate(tempRelativePath + '/persoonlijkeinstellingen');
					}}
				>
					<div slot="primary-text">{t('account.preferences')}</div>
				</ZdsItem>
				<ZdsDivider />
				{isActingAsOrganisation && (
					<ZdsItem
						onZdsClick={() => {
							stopActingAsOrganisation();
						}}
					>
						<div slot="content-start">
							<ZdsIcon name="rotate_left" size="sm"></ZdsIcon>
						</div>
						<div slot="primary-text">Stop schakelmodus</div>
					</ZdsItem>
				)}
				<ZdsItem
					onZdsClick={() => {
						logout();
					}}
				>
					<div slot="content-start">
						<ZdsIcon name="power_settings_new" size="sm"></ZdsIcon>
					</div>
					<div slot="primary-text">{t('account.logOut')}</div>
				</ZdsItem>
			</ContextMenu>
		</div>
	);
};

export default AccountSection;
