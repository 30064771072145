import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Medewerkerrol } from '../../api/models';
import DataTable from '../../../../global/components/DataTable/DataTable';
import { useMapMedewerkerrollenToTableData } from '../../hooks/table/useMapMedewerkerrollenToTableData/useMapMedewerkerrollenToTableData';

export interface MedewerkerrollenTableProps {
	medewerkerrollen?: { [key: number]: Medewerkerrol[] };
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
	currentPage: number;
}

const MedewerkerrollenTable: FC<MedewerkerrollenTableProps> = ({
	medewerkerrollen,
	onPageChange,
	pageSize,
	itemsNum,
	currentPage,
}) => {
	const navigate = useNavigate();
	const tableData = useMapMedewerkerrollenToTableData(medewerkerrollen ?? null);

	const handleRowClick = (id: string) => {
		navigate(`/organisatiebeheer/medewerkerrollen/${id}`);
	};

	return (
		<DataTable
			data={tableData}
			tableTestId="medewerkerrollenTable"
			initPage={currentPage}
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onRowClick={handleRowClick}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
		/>
	);
};

export default MedewerkerrollenTable;
