import { RouteInfo } from '../../routes';
import { SideMenuInfo } from '../../global/components/SideMenu/SideMenu';
import Preferences from '../../global/pages/Preferences/Preferences';
import Communities from './pages/Communities/Communities';
import CommunityCategoryInformation from './pages/CommunityCategoryInformation/CommunityCategoryInformation';
import Profile from '../../global/pages/Profile/Profile';
import CommunityInformation from './pages/CommunityInformation/CommunityInformation';
import EditCommunity from './pages/EditCommunity/EditCommunity';
import CreateCommunity from './pages/CreateCommunity/CreateCommunity';
import CreateCommunityCategory from './pages/CreateCommunityCategory/CreateCommunityCategory';

export const sideMenuInfo: SideMenuInfo = {
	linkBase: '/communitybeheer',
	sections: [
		{
			title: 'global:common.management',
			items: [
				{
					title: 'community:community.community',
					link: 'community',
					iconName: 'object-group',
				},
			],
		},
	],
};

const routes: RouteInfo[] = [
	{
		path: 'community',
		subRoutes: [
			{
				path: '',
				Component: () => <Communities />,
			},
			{
				path: 'create',
				Component: () => <CreateCommunity />,
				permissions: ['COM_AAN_COM'],
			},
			{
				path: ':id',
				subRoutes: [
					{
						path: '',
						Component: () => <CommunityInformation />,
					},
					{
						path: 'categorie',
						subRoutes: [
							{
								path: '',
								Component: () => <EditCommunity tab={2} />,
							},
							{
								path: 'create',
								Component: () => <CreateCommunityCategory />,
								permissions: ['COM_WIJ_COM_DET'],
							},
							{
								path: ':id',
								Component: () => <CommunityCategoryInformation />,
							},
						],
					},
					{
						path: 'edit',
						Component: () => <EditCommunity tab={0} />,
					},
					{
						path: 'leden',
						Component: () => <EditCommunity tab={1} />,
					},
				],
			},
		],
	},
	{
		path: 'persoonlijkeinstellingen',
		Component: () => <Preferences sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: 'profiel',
		Component: () => <Profile sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: '',
		redirect: '/communitybeheer/community',
		Component: () => null,
	},
];

export default routes;
