import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { ZdsInputDate, ZdsInputText, ZdsSelect, ZdsTextarea } from '@zig-design-system/ui-components-react';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { TFunction } from 'i18next';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getDateFormat } from '../../../../../../../global/utils/date/getDatePattern';

export const generateCreateOrganisatieFormSchema = (t: TFunction, language: string): FormSchema => {
	const dateFormat = getDateFormat(language);

	const schema: FormSchema = {
		title: t('global:common.about') ?? '',
		formControls: [
			{
				name: 'code',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.code'),
						required: true,
					},
				},
			},
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'ORGANISATIESOORT',
						code: '',
						disabled: false,
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'omschrijving',
				control: {
					type: ZdsTextarea,
					props: {
						label: getStringTranslation(t, 'global:domainShared.omschrijving'),
					},
				},
			},
			{
				name: 'begindatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'global:domainShared.begindatum'),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
			{
				name: 'einddatum',
				control: {
					type: ZdsInputDate,
					props: {
						label: getStringTranslation(t, 'global:domainShared.einddatum'),
						format: dateFormat,
						calendar: 'on-focus',
					},
				},
			},
		],
		layout: {
			columns: [['code', 'omschrijving', 'begindatum', 'einddatum'], ['soort']],
		},
	};

	return schema;
};
