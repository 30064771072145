import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsCard, ZdsDivider, ZdsIcon } from '@zig-design-system/ui-components-react';

import { Bericht } from '../../../api/models';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import { selectBerichten, updateBericht } from '../../../redux/berichten';
import { useAppDispatch, useAppSelector } from '../../../../../global/redux/store';
import { WriteMessage } from '../CreateMessage/WriteMessage';
import { NatuurlijkPersoon } from '../../../../relatiebeheer/api/models';
import { IBericht, RelatieNatuurlijkPersoon } from '@zig.community/community-components';
import { getRolRelaties } from '../../ZigCommunity/mapBerichtRelatieUtils';
import { useUploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import styles from './EditMessage.module.scss';

export interface EditMessageProps {
	bericht: Bericht;
	parentBericht?: Bericht;
	onBackButtonClick: () => void;
}

const EditMessage: FC<EditMessageProps> = ({ bericht, parentBericht, onBackButtonClick }) => {
	const { t } = useTranslation();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const { loadingUpdatingBericht, errorUpdatingBericht } = useAppSelector(selectBerichten);
	const dispatch = useAppDispatch();
	const [text, setText] = useState(bericht.beschrijving ?? '');
	const [isUpdating, setIsUpdating] = useState(false);

	const initBijlage = bericht.bijlagen?.[0];

	const {
		fileData: allFileData,
		handleAddFile,
		handleRemoveFile,
	} = useUploadFileData({
		maxFiles: 1,
		mode: 'SWAP_LAST',
		initFileData: [
			{
				id: initBijlage?.id ?? '',
				file: initBijlage?.bestand
					? ({ name: initBijlage.bestandsnaam, size: initBijlage.bestandsgrootte } as File)
					: null,
				error: null,
				base64: initBijlage?.bestand ?? null,
			},
		],
	});

	const fileData = allFileData[0];

	const canSend = !loadingUpdatingBericht && !fileData?.error && (text.trim().length > 0 || !!fileData.file);

	const handleBackButtonClick = () => {
		if (loadingUpdatingBericht) return;

		onBackButtonClick();
	};

	const handleSubmit = async () => {
		if (!canSend) return;

		dispatch(
			updateBericht({
				id: bericht.id,
				value: text,
				fileData: { base64: fileData?.base64, name: fileData?.file?.name ?? null },
			})
		);
		setIsUpdating(true);
	};

	useEffect(() => {
		if (!loadingUpdatingBericht && isUpdating) {
			setIsUpdating(false);

			if (!errorUpdatingBericht) {
				onBackButtonClick();
			}
		}
	}, [loadingUpdatingBericht, errorUpdatingBericht]);

	if (!currentCommunityData || !bericht) return null;

	const finalBericht = { ...bericht, rolRelaties: getRolRelaties(bericht as unknown as IBericht) };

	const author = finalBericht.rolRelaties?.find(rol => rol.soort.code === 'AUT')?.relatie as NatuurlijkPersoon;

	return (
		<ZdsCard className={styles.card}>
			<ZdsButton slot="header-start" preset="muted" variant="text" aspect-square onZdsClick={handleBackButtonClick}>
				<ZdsIcon size="sm" name="arrow-left" library="fa-regular" />
			</ZdsButton>
			<div slot="header" className={styles.header}>
				{t('editPost.title')}
			</div>
			<ZdsButton slot="header-end" onZdsClick={handleSubmit} disabled={!canSend}>
				{t('editPost.submitButton')}
			</ZdsButton>

			<div slot="body">
				<ZdsDivider className={styles.divider} />
				<WriteMessage
					author={author as unknown as RelatieNatuurlijkPersoon}
					community={currentCommunityData}
					categorie={bericht.categorie ?? parentBericht?.categorie ?? null}
					bericht={text}
					setBericht={setText}
					setFileData={handleAddFile}
					removeFile={() => handleRemoveFile(fileData.id)}
					fileData={fileData}
					loading={loadingUpdatingBericht}
				/>
				{errorUpdatingBericht && <h3>{t(errorUpdatingBericht)}</h3>}
			</div>
		</ZdsCard>
	);
};

export default EditMessage;
