import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsDivider, ZdsIcon, ZdsPopout } from '@zig-design-system/ui-components-react';
import { IBericht } from '@zig.community/community-components';

import { getRelatieNaam } from '../../../../relatiebeheer/utils/getRelatieNaam';
import { Relatie } from '../../../../relatiebeheer/api/models';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { getFormattedDate } from '../../../../../global/utils/date/formatDate/formatDate';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { BerichtEmotie } from '../../../api/models';
import styles from './InappropirateContentMessage.module.scss';

interface InappropriateContentMessageProps {
	bericht: IBericht;
	isReaction?: boolean;
}

export const InappropriateContentMessage: FC<InappropriateContentMessageProps> = ({ bericht, isReaction }) => {
	const [isPopoutVisible, setIsPopoutVisible] = useState(false);
	const reportedInappropriateContent = bericht.emoties.filter(e => e.soort.code === 'ONG');
	const { t, i18n } = useTranslation();
	const dateFormat = getDateFormat(i18n.language);
	const tDomain = 'community:communityInformationPage.messages';

	let hidePopoutTimeout: ReturnType<typeof setTimeout>;

	const showPopout = () => {
		if (hidePopoutTimeout) {
			clearTimeout(hidePopoutTimeout);
		}
		setIsPopoutVisible(true);
	};

	const hidePopout = () => {
		if (hidePopoutTimeout) {
			clearTimeout(hidePopoutTimeout);
		}
		hidePopoutTimeout = setTimeout(() => {
			setIsPopoutVisible(false);
		}, 200);
	};

	return (
		<>
			{reportedInappropriateContent.length > 0 && (
				<div className="zds-relative">
					<div className={`zds-color-danger zds-padding-top-3${isReaction ? ' zds-padding-bottom-3' : ''}`}>
						<button onMouseOver={showPopout} onMouseOut={hidePopout} className={styles.dangerText}>
							<ZdsIcon
								size="sm"
								name="triangle-exclamation"
								library="fa-solid"
								sizingType="height"
								className="zds-margin-right-2"
							/>
							{t(`${tDomain}.inappropriateContent`)}
						</button>
					</div>
					<ZdsPopout
						onMouseOver={showPopout}
						onMouseOut={hidePopout}
						open={isPopoutVisible}
						className={styles.inappropireContentModal}
					>
						{reportedInappropriateContent.map((e, index) => {
							const emotie = e as unknown as BerichtEmotie;
							return (
								<div key={`ong-${emotie.id}`}>
									<div>
										{t(`${tDomain}.by`)} {getRelatieNaam(emotie.relatie as unknown as Relatie)} {t(`${tDomain}.on`)}{' '}
										{getFormattedDate(e.datum, dateFormat)}
									</div>
									<div>
										{t(`${tDomain}.reason`)}:{' '}
										{getReferentiedataNaam(t, emotie.detailSoort?.soort, emotie.detailSoort?.code)}
									</div>
									{emotie.toelichting && (
										<div>
											{t(`${tDomain}.remarks`)}: {emotie.toelichting}
										</div>
									)}
									{index < reportedInappropriateContent.length - 1 && (
										<ZdsDivider className="zds-margin-top-4 zds-margin-bottom-4" />
									)}
								</div>
							);
						})}
					</ZdsPopout>
				</div>
			)}
		</>
	);
};
