import { TFunction } from 'i18next';

import { SelectOptions } from '../../components/Form/Form.types';
import zigData from '../zigDataSdk/zigDataSdk';
import { Referentiedata } from '../../api/models';
import { getReferentiedataNaam } from '../translations/getReferentiedataNaam';

export interface ReferentiedataSelectOptions {
	t: TFunction;
	soort: string;
	parentSoort?: string;
	code?: string;
	disabled?: boolean;
	hasEmptyValue?: boolean;
}

export const getReferentiedataSelectOptions = ({
	t,
	soort,
	parentSoort,
	code = '',
	disabled,
	hasEmptyValue,
}: ReferentiedataSelectOptions): SelectOptions[] => {
	const soortData = (zigData.getDataBySoort(soort) as Referentiedata[]).filter(ref =>
		parentSoort ? ref.parent?.code === parentSoort : true
	);

	const selectOptions: SelectOptions[] = soortData.map(({ soort, code }) => ({
		label: getReferentiedataNaam(t, soort, code),
		value: code,
		disabled,
	}));

	if (hasEmptyValue) {
		selectOptions.unshift({
			label: `<${t('global:common.noValue').toLowerCase()}>`,
			value: '',
			disabled,
		});
	}

	if (code && !soortData.find(d => d.code === code)) {
		selectOptions.push({
			label: code,
			value: code,
			disabled,
		});
	}

	return selectOptions;
};
