export const fileSize = (sizeInBytes: number): string => {
	if (sizeInBytes === 0) return '0 B';

	const BASE = 1024;
	const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

	let sizeIndex = 0;
	let sizeInUnit = sizeInBytes;

	while (sizeInUnit >= BASE && sizeIndex < SIZE_UNITS.length - 1) {
		sizeInUnit = sizeInUnit / BASE;
		sizeIndex++;
	}

	let sizeRounded: string;
	if (SIZE_UNITS[sizeIndex] === 'MB') {
		sizeRounded = sizeInUnit.toFixed(1);
	} else {
		sizeRounded = sizeInUnit.toFixed(0);
	}

	return `${sizeRounded} ${SIZE_UNITS[sizeIndex]}`;
};
