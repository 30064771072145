import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon, ZdsModal } from '@zig-design-system/ui-components-react';

import styles from './UnitsTableModal.module.scss';
import ClusterEenheden, {
	ClusterEenhedenTableMode,
} from '../../pages/ClusterInformation/TabsContent/ClusterEenheden/ClusterEenheden';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';

export interface UnitsTableModalProps {
	onAddSuccess: () => void;
}

const UnitsTableModal = forwardRef<HTMLZdsModalElement, UnitsTableModalProps>(({ onAddSuccess }, ref) => {
	const { t } = useTranslation();
	const [adding, setAdding] = useState(false);
	const [selectedNum, setSelectedNum] = useState(0);
	const [error, setError] = useState('');
	const [showContent, setShowContent] = useState(false);

	const handleModalHide = () => {
		return setTimeout(() => {
			setShowContent(false);
		}, 500);
	};

	const handleModalShow = () => {
		setShowContent(true);
	};

	return (
		<div className={styles.modalContainer}>
			<ZdsModal ref={ref} onZdsModalHide={handleModalHide} onZdsModalShow={handleModalShow}>
				<div className="zds-flex zds-justify-content-between zds-items-center zds-padding-y-6" slot="header">
					<div className="zds-bold">{t('vastgoed:clustersPage.table.addUnits')}</div>
					<ZdsButton zds-modal-action="close" variant="text" preset="neutral">
						<ZdsIcon name="close" size="sm"></ZdsIcon>
					</ZdsButton>
				</div>
				<div className="zds-body zds-padding-x-6 zds-padding-y-3" slot="body">
					{showContent && (
						<ClusterEenheden
							tableTestId="addClusterEenhedenTable"
							mode={ClusterEenhedenTableMode.ADD}
							onSelectChange={setSelectedNum}
							adding={adding}
							setAdding={() => {
								setAdding(false);
								onAddSuccess();
							}}
							onError={setError}
						/>
					)}
				</div>
				<div className="zds-flex zds-flex-1 zds-justify-content-between zds-padding-6" slot="footer">
					<div>{error && <ErrorMessage message={t(error)} />}</div>
					<div className="zds-flex zds-justify-content-end zds-gap-3">
						{selectedNum > 0 && (
							<ZdsButton
								preset="primary"
								onZdsClick={() => {
									setAdding(true);
									setError('');
								}}
								disabled={adding}
							>
								{t('vastgoed:clustersPage.table.addToCluster')}
							</ZdsButton>
						)}
						<ZdsButton preset="primary" zds-modal-action="close" disabled={adding}>
							{t('global:common.close')}
						</ZdsButton>
					</div>
				</div>
			</ZdsModal>
		</div>
	);
});

export default UnitsTableModal;
