import { RouteInfo } from '../../routes';
import { Logout, OAuthCallback, Organisatie, Profile, Protected } from './DemoAppWrapper';

const routes: RouteInfo[] = [
	{
		path: '',
		Component: () => (
			<Protected>
				<Profile />
			</Protected>
		),
	},
	{
		path: 'oauth_callback',
		Component: OAuthCallback,
	},
	{
		path: 'organisatie',
		Component: () => (
			<Protected>
				<Organisatie />
			</Protected>
		),
	},
	{
		path: 'logout',
		Component: Logout,
	},
];

export default routes;
