import { ListResponse } from '../../api';

export const getUpdatedTableStateData = <T>(
	payload: ListResponse<T>,
	page: number,
	dataToUpdate: { [key: number]: T[] } | null
) => {
	const data = payload.data;
	return !dataToUpdate ? { [page]: data } : { ...dataToUpdate, [page]: data };
};
