import { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ZdsButton, ZdsItem } from '@zig-design-system/ui-components-react';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getRelatiesData, selectRelatieData } from '../../redux/relatieData';
import RelationsTable from '../../components/RelationsTable/RelationsTable';
import Tabs from '../../../../global/components/Tabs/Tabs';
import ContextMenu from '../../../../global/components/ContextMenu/ContextMenu';
import { relatiesoortData } from '../../../../global/utils/zigDataSdk/zigDataSdk';
import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { getReferentiedataNaam } from '../../../../global/utils/translations/getReferentiedataNaam';
import Search from '../../../../global/components/Search/Search';
import styles from './Relations.module.scss';

const PAGE_SIZE = 20;

const Relations: FC = () => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation(['global', 'relatie']);
	const { relatieData, loading, error, itemsNum } = useAppSelector(selectRelatieData);
	const addRelatieButtonRef = useRef(null);
	const [term, setTerm] = useState(searchParams.get('search') ?? undefined);

	useEffect(() => {
		handleGetRelaties(0, true);
	}, [term]);

	const handleGetRelaties = (page: number, clear?: boolean) => {
		dispatch(getRelatiesData({ term, offset: page, limit: PAGE_SIZE, clear }));
	};

	const OverviewContent = (
		<>
			{loading && <h3 data-testid="relatieListLoading">{t('global:common.loading')}...</h3>}
			{relatieData && (
				<RelationsTable
					relatieData={relatieData}
					onPageChange={handleGetRelaties}
					pageSize={PAGE_SIZE}
					itemsNum={itemsNum}
				/>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);

	const tabsData = [
		{
			name: t('relatie:relationInformationPage.tabs.overview'),
			content: OverviewContent,
		},
		{
			name: t('relatie:relationInformationPage.tabs.addresses'),
			content: <div />,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.relationManagement'), link: '/relatiebeheer' },
		{ label: t('relatie:relationsPage.header.title') },
	];

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('relatie:relationsPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className="zds-flex">
				<Search
					onSearch={term => {
						setTerm(term.trim());
					}}
				/>
				<div className="zds-flex zds-justify-content-end">
					<ZdsButton id="add-relatie-button" ref={addRelatieButtonRef}>
						+ {t('relatie:relationsPage.header.addNew')}
					</ZdsButton>
				</div>
				<ContextMenu target="#add-relatie-button" targetRef={addRelatieButtonRef} className="zds-flex" position="end">
					{relatiesoortData.map(({ soort, code }) => (
						<ZdsItem key={code} link={`/relatiebeheer/relatie/create?soort=${code}`}>
							<div slot="primary-text">{getReferentiedataNaam(t, soort, code)}</div>
						</ZdsItem>
					))}
				</ContextMenu>
			</Pane>
			<Pane className={styles.contentPane}>
				<Tabs tabsData={tabsData} />
			</Pane>
		</PageLayout>
	);
};

export default Relations;
