import { FC, useEffect, useState } from 'react';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import Form from '../../../../global/components/Form/Form';
import {
	getCurrentOrganisatieData,
	selectCurrentOrganisatieData,
	updateOrganisatie,
} from '../../redux/currentOrganisatieData';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { FormSchema, FormSubmitData } from '../../../../global/components/Form/Form.types';
import Tabs from '../../../../global/components/Tabs/Tabs';
import { generateUpdateOrganisatieFormSchema } from '../../utils/form/schema/organization/update/organisatie';
import { isRechtspersoon } from '../../../relatiebeheer/api/models';
import BackButton from '../../../../global/components/BackButton/BackButton';
import SaveButton from '../../../../global/components/Form/SaveButton';
import { useAppPermissions } from '../../../../global/hooks/useAppPermissions/useAppPermissions';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { useOrganisatieSideMenu } from '../../hooks/useOrganisatieSideMenu';
import styles from './OrganizationInformation.module.scss';

const OrganizationInformation: FC = () => {
	const navigate = useNavigate();
	const { currentOrganisatieData, loading, updating, error } = useAppSelector(selectCurrentOrganisatieData);
	const dispatch = useAppDispatch();
	const params = useParams<{ id: string }>();
	const { t, i18n } = useTranslation();
	const [formSchema, setFormSchema] = useState<FormSchema | null>(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const hasAppPermissions = useAppPermissions();
	const sideMenuInfo = useOrganisatieSideMenu();

	const relatie = isRechtspersoon(currentOrganisatieData?.relatie) ? currentOrganisatieData?.relatie : null;

	const isMissingRelatie = !!currentOrganisatieData && !relatie;

	useEffect(() => {
		if (params.id) {
			void dispatch(getCurrentOrganisatieData(params.id));
		}
	}, [params]);

	useEffect(() => {
		setFormSchema(
			currentOrganisatieData ? generateUpdateOrganisatieFormSchema(currentOrganisatieData, t, i18n.language) : null
		);
	}, [currentOrganisatieData]);

	const handleOnSubmit = (data: FormSubmitData) => {
		if (currentOrganisatieData?.id && !updating) {
			const updateData = {
				id: currentOrganisatieData.id,
				body: data,
			};
			void dispatch(updateOrganisatie(updateData));
		}
	};

	const handleNavigateToRelatie = () => {
		if (relatie) {
			navigate(`/relatiebeheer/relatie/${relatie.id}`);
		}
	};

	const OverviewContent = (
		<>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{!loading && formSchema && !isMissingRelatie && (
				<div data-testid="organisatieForm" className="zds-margin-left-8">
					<Form schema={formSchema} onSubmit={handleOnSubmit} formId="organisatieForm" />
					{hasAppPermissions('REL') && (
						<div className="zds-margin-top-6">
							<ZdsButton data-testid="relatieRedirectButton" variant="outlined" onZdsClick={handleNavigateToRelatie}>
								{t('relatie:relatie.relatie')}
								<ZdsIcon name="arrow-right" library="fa-regular"></ZdsIcon>
							</ZdsButton>
						</div>
					)}
				</div>
			)}
			{isMissingRelatie && <h3>{t('global:form.errorMessage.missingData')}</h3>}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: OverviewContent,
		},
		{
			name: t('organisatie:organisatie.organisatorischeEenheden'),
			content: <div />,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organizationsPage.header.title'), link: '/organisatiebeheer/organisatie' },
		{ label: t('organisatie:organizationInformationPage.header.title') },
	];

	const saveButton = (
		<SaveButton dataTestId="updateOrganisatieDataSubmitButton" formId="organisatieForm" disabled={updating} />
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={relatie?.naam ?? ''}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={selectedTab === 0 && saveButton}
			/>
			<Pane className={styles.contentPane}>
				<Tabs tabsData={tabsData} onTabChange={setSelectedTab} />
			</Pane>
		</PageLayout>
	);
};

export default OrganizationInformation;
