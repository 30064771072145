import { ComponentProps, FC, ReactNode } from 'react';
import { ZdsButtonCustomEvent } from '@zig-design-system/ui-components';
import { ZdsButton, ZdsDataTableRow, ZdsIcon } from '@zig-design-system/ui-components-react';

import { TableData } from '../DataTable';
import DataTableContentCell, { TableCell } from './DataTableContentCell';

export interface IconOptions {
	name: string;
	onIconClick?: (id: string, event: ZdsButtonCustomEvent<MouseEvent>) => void;
	library: string;
	preset: ComponentProps<typeof ZdsButton>['preset'];
	variant: ComponentProps<typeof ZdsButton>['variant'];
	size: ComponentProps<typeof ZdsIcon>['size'];
	iconHeader?: ReactNode;
}

export interface TableRow {
	id: string;
	isSelectable?: boolean;
	isPreselected?: boolean;
	noIcon?: boolean;
	cells: TableCell[];
}

export interface DataTableProps {
	row: TableRow;
	onRowClick?: (id: string) => void;
	header: TableData['header'];
	iconOptions?: IconOptions;
	isSelected?: boolean;
	isSelectable?: boolean;
	onChange?: (value: boolean) => void;
}

const DataTableContentRow: FC<DataTableProps> = ({
	row,
	onRowClick,
	header,
	iconOptions,
	isSelected,
	isSelectable,
	onChange,
}) => {
	const handleRowClick = (id: string) => {
		if (onRowClick) {
			onRowClick(id);
		}
	};

	const finalIconOptions = !row.noIcon && iconOptions;

	const handleIconClick = (event: ZdsButtonCustomEvent<MouseEvent>, iconOptions: IconOptions) => {
		iconOptions.onIconClick?.(row.id, event);
	};

	return (
		<ZdsDataTableRow
			row-id={row.id}
			onZdsClick={() => handleRowClick(row.id)}
			hoverType="clickable"
			{...(isSelectable && { selectable: true })}
			selected={isSelected}
			{...(finalIconOptions && { actionsVisibility: 'always' })}
		>
			{row.cells.map((cellContent, cellInd) => {
				const cellId = `tableCell${row.id}${header?.labels[cellInd]}`.replace(/[^0-9A-Z]+/gi, '');

				return (
					<DataTableContentCell
						key={cellId}
						cellId={cellId}
						header={header}
						cellInd={cellInd}
						cellContent={cellContent}
						onChange={onChange}
					/>
				);
			})}
			{finalIconOptions && (
				<div className="zds-flex zds-flex-row zds-justify-content-end" slot="actions" data-testid="dataRowButton">
					<ZdsButton
						preset={finalIconOptions.preset}
						aspectSquare
						variant={finalIconOptions.variant}
						onZdsClick={event => handleIconClick(event, finalIconOptions)}
						slot="actions"
						pill
					>
						<ZdsIcon name={finalIconOptions.name} library={finalIconOptions.library} size={finalIconOptions.size} />
					</ZdsButton>
				</div>
			)}
		</ZdsDataTableRow>
	);
};

export default DataTableContentRow;
