import React, { forwardRef } from 'react';
import { ZdsButton, ZdsCarousel, ZdsIcon } from '@zig-design-system/ui-components-react';
import {
	BerichtBijlage,
	EmotieButton,
	EmotieButtonOptions,
	Emoties,
	IBericht,
	isApiBijlage,
	ReactieProps,
	RelatieAvatar,
	RelatieNaam,
	usePostedAt,
} from '@zig.community/community-components';
import { InappropriateContentMessage } from '../Messages/InappropriateContentMessage/InappropriateContentMessage';
import { useBerichtPermissions } from '../../hooks/useBerichtPermissions/useBerichtPermissions';
import { useBerichtMenuOptions } from '../../hooks/useBerichtMenuOptions/useBerichtMenuOptions';
import { Bericht } from '../../api/models';
import { Relatie } from '../../../relatiebeheer/api/models';
import styles from './Bericht.module.scss';

export interface ContentBericht {
	content: IBericht;
	onShowMenu: (reactieId: IBericht['id']) => void;
	emotieButtonOptions: EmotieButtonOptions;
	currentRelatie: Relatie;
}

export const useAuteur = (bericht?: any) => {
	return bericht?.rolRelaties.find((rolRelatie: any) => rolRelatie.soort.code === 'AUT')?.relatie;
};
export const Reactie = forwardRef<HTMLDivElement, ReactieProps>(
	({ content, onShowMenu, emotieButtonOptions, currentRelatie }, ref) => {
		const relativeTime = usePostedAt();
		const auteur = useAuteur(content);
		const hasPermission = useBerichtPermissions();
		const { showEdit, showDeleteInappropriateReport } = useBerichtMenuOptions(content as unknown as Bericht);
		const hasMenuItems = showEdit || showDeleteInappropriateReport;
		return (
			<div
				ref={ref}
				className={`${styles.reactieComponent} zds-flex zds-padding-top-4 zds-padding-bottom-3 zds-padding-x-3 zds-align-start`}
			>
				<RelatieAvatar relatie={auteur} currentRelatie={currentRelatie} />
				<div className={`${styles.contentDiv} zds-flex zds-flex-col zds-align-start zds-padding-left-3`}>
					<div className={styles.reactie}>
						<div className={styles.commentContent}>
							<div className={styles.text}>
								<RelatieNaam relatie={auteur} />
								{hasPermission('COM_LEZ_MEL_ONG') && (
									<InappropriateContentMessage bericht={content} isReaction={true} />
								)}
								<div
									className={`${styles.item} zds-flex zds-flex-col zds-gap-1 zds-items-start zds-self-stretch zds-item__secondary-text`}
								>
									{content?.beschrijving}
									{content?.bijlagen && content.bijlagen.length > 0 && (
										<div style={{ width: 150 }}>
											<ZdsCarousel fullscreenAble={true} pagination={false} style={{ height: 150 }}>
												{content?.bijlagen.map(bijlage => (
													<BerichtBijlage
														key={isApiBijlage(bijlage) ? bijlage.id : bijlage.bestand}
														bijlage={bijlage}
													/>
												))}
											</ZdsCarousel>
										</div>
									)}
								</div>
							</div>
							{hasMenuItems && (
								<div className={styles.contentEnd}>
									<div className="zds-flex zds-items-start">
										<ZdsButton
											onZdsClick={() => {
												onShowMenu(content.id);
											}}
											aspectSquare
											pill
											preset="muted"
											variant="text"
											overhang="horizontal"
											className={styles.menuButton}
										>
											<ZdsIcon size="sm" name="ellipsis-vertical" library="fa-regular" />
										</ZdsButton>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="zds-body-sm zds-flex zds-justify-content-between zds-items-center">
						<div className={`${styles.muted} zds-flex zds-items-baseline zds-padding-y-1`}>
							<div className="zds-truncate">{relativeTime(content?.aanmaakdatum)}</div>
							<div className="zds-flex-none">
								<EmotieButton
									bericht={content}
									currentRelatie={currentRelatie}
									{...emotieButtonOptions}
									variant="small"
								/>
							</div>
						</div>
						<div className="zds-flex zds-justify-content-between zds-items-center">
							{content?.emoties && (
								<Emoties bericht={content} currentRelatie={currentRelatie} emotieButtonOptions={emotieButtonOptions} />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
);
