import Appbar from '../../global/components/Appbar/Appbar';
import { WrapperComponent } from '../../routes';
import styles from './DemoZdsAppWrapper.module.scss';

const DemoZdsAppWrapper: WrapperComponent = ({ children }) => {
	return (
		<div className={styles.root}>
			<Appbar tempRelativeAppSwitcherPath="/demo-zds" appName="ZDS demo" />
			{children}
		</div>
	);
};

export default DemoZdsAppWrapper;
