import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ZdsButton, ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';

import ContextMenu from '../../../../../global/components/ContextMenu/ContextMenu';
import { Language } from '../../../api/models';
import styles from './LanguageSelect.module.scss';

export interface LanguageData {
	value: Language;
	label: string;
	flag: string;
}

export interface LanguageSelectProps {
	onChange: (data: LanguageData) => void;
}

export const LANGUAGE_DATA: LanguageData[] = [
	{ value: Language.Dutch, label: 'global:languages.dutch', flag: '🇳🇱' },
	{ value: Language.English, label: 'global:languages.english', flag: '🇬🇧' },
	{ value: Language.German, label: 'global:languages.german', flag: '🇩🇪' },
	{ value: Language.Finnish, label: 'global:languages.finnish', flag: '🇫🇮' },
	{ value: Language.French, label: 'global:languages.french', flag: '🇫🇷' },
];

export const SUPPORTED_LANGUAGES = LANGUAGE_DATA.map(l => l.value);

const LanguageSelect: FC<LanguageSelectProps> = ({ onChange }) => {
	const { t } = useTranslation();
	const languageSelectRef1 = useRef<HTMLZdsSelectElement>(null);
	const languageSelectRef2 = useRef<HTMLZdsSelectElement>(null);
	const languageSelectRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const itemRefs = [languageSelectRef1, languageSelectRef2];

	const next = () => {
		const nextIndex = (selectedIndex + 1) % LANGUAGE_DATA.length;
		setSelectedIndex(nextIndex);
		itemRefs[nextIndex]?.current?.click();
	};

	const prev = () => {
		const nextIndex = (selectedIndex + LANGUAGE_DATA.length - 1) % LANGUAGE_DATA.length;
		setSelectedIndex(nextIndex);
		itemRefs[nextIndex]?.current?.click();
	};

	useEffect(() => {
		onChange(LANGUAGE_DATA[selectedIndex]);
	}, [selectedIndex]);

	const selectId = 'language-select';

	return (
		<div className="zds-flex">
			<div className="zds-flex zds-items-center zds-gap-2">
				<div
					className={classNames(styles.select, { [styles['select--open']]: isOpen })}
					id={selectId}
					ref={languageSelectRef}
				>
					<div className="zds-flex zds-items-center zds-gap-3">
						<div className={styles.flag}>{LANGUAGE_DATA[selectedIndex].flag}</div>
						{t(LANGUAGE_DATA[selectedIndex].label)}
					</div>
					<ZdsIcon
						className={classNames(styles.icon, { [styles['icon--open']]: isOpen })}
						name="angle-down"
						library="fa-solid"
						size="xs"
					></ZdsIcon>
				</div>
				<div className={styles.arrows}>
					<ZdsButton preset="neutral" variant="text" onZdsClick={prev}>
						<ZdsIcon name="angle-left" library="fa-regular" size="sm"></ZdsIcon>
					</ZdsButton>
					<ZdsButton preset="neutral" variant="text" onZdsClick={next}>
						<ZdsIcon name="angle-right" library="fa-regular" size="sm"></ZdsIcon>
					</ZdsButton>
				</div>
			</div>
			<ContextMenu
				target={`#${selectId}`}
				targetRef={languageSelectRef}
				className={styles.menu}
				position="end"
				onChange={setIsOpen}
			>
				{LANGUAGE_DATA.map((languageData, i) => (
					<ZdsItem key={languageData.value} onZdsClick={() => setSelectedIndex(i)}>
						<div slot="primary-text" className="zds-flex zds-items-center zds-gap-3">
							<span className={styles.flag}>{languageData.flag}</span>
							{t(languageData.label)}
						</div>
					</ZdsItem>
				))}
			</ContextMenu>
		</div>
	);
};

export default LanguageSelect;
