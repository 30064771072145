import { Permission, Userinfo } from '../../../../global/api/models';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import { selectUser } from '../../../../global/redux/shared/authorizationData';
import { useAppSelector } from '../../../../global/redux/store';
import { Bericht, Community } from '../../api/models';
import { selectCurrentCommunityData } from '../../redux/currentCommunityData';

const memberRolRelatieSoortCodes = ['COM', 'LID'];

export const useBerichtPermissions = () => {
	const hasPermission = usePermissions();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const user = useAppSelector(selectUser);

	return (permission: Permission, bericht?: Bericht): boolean => {
		switch (permission) {
			case 'CREATE':
				return hasBerichtCreatePermission(currentCommunityData, user);
			case 'COM_WIJ_BER':
			case 'COM_VER_BER':
				return hasBerichtUpdateOrDeletePermission(
					hasPermission(permission),
					currentCommunityData,
					user,
					permission,
					bericht
				);
			case 'COM_LEZ_MEL_ONG':
			case 'COM_VER_MEL_ONG':
				return hasPermission(permission);
			default:
				return false;
		}
	};
};

const getUserRolRelatie = (user: Userinfo, community?: Community | null) => {
	return community?.rolRelaties?.find(
		rol => rol.relatie && rol.relatie.id === user.relatieId && memberRolRelatieSoortCodes.includes(rol.soort.code)
	)?.soort.code;
};

const hasBerichtCreatePermission = (community?: Community | null, user?: Userinfo | null) => {
	if (!user?.relatieId) return false;

	return !!getUserRolRelatie(user, community);
};

const hasBerichtUpdateOrDeletePermission = (
	hasPermission: boolean,
	community?: Community | null,
	user?: Userinfo | null,
	permission?: Permission,
	bericht?: Bericht
) => {
	if (!user?.relatieId) return false;

	const userRolRelatie = getUserRolRelatie(user, community);

	if (!userRolRelatie || !bericht) return false;

	const isBerichtAuthor = !!bericht.rolRelaties?.find(
		rol => rol.relatie.id === user.relatieId && rol.soort.code === 'AUT'
	);

	if (permission === 'COM_WIJ_BER' || permission === 'COM_VER_BER') {
		return isBerichtAuthor || (hasPermission && userRolRelatie === 'COM');
	}

	return false;
};
