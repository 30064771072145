import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsForm } from '@zig-design-system/ui-components-react';

import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import { Language } from '../../../api/models';
import LanguageSelect, { LanguageData } from '../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import StepContent from '../../../components/StepContent/StepContent';
import { useCommunityCategoryCreateFormSchema } from '../../../hooks/form/schema/create/communityCategory/useCommunityCategoryCreateFormSchema';
import { useLanguageSelect } from '../../../hooks/useLanguageSelect/useLanguageSelect';
import styles from './CommunityCategoryGeneralInfo.module.scss';

export type CommunityCategoryGeneralInfoSubmitData = {
	formSubmitData: FormSubmitData;
};

export interface CommunityCategoryGeneralInfoProps {
	initData: CommunityCategoryGeneralInfoSubmitData | null;
	loading?: boolean;
	errorMessage?: string;
	onSave: (data: CommunityCategoryGeneralInfoSubmitData) => void;
	onBack: (data: CommunityCategoryGeneralInfoSubmitData) => void;
}

const formId = 'createCommunityCategoryGeneralInfoForm';

const CommunityCategoryGeneralInfo: FC<CommunityCategoryGeneralInfoProps> = ({
	initData,
	loading,
	errorMessage,
	onSave,
	onBack,
}) => {
	const { t, i18n } = useTranslation();
	const [formData, setFormData] = useState<FormData | null>(null);
	const dateFormat = getDateFormat(i18n.language);
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();

	const formSchema = useCommunityCategoryCreateFormSchema({
		data: formData,
		initData: initData?.formSubmitData,
		language: selectedLanguage,
		selectedFields: ['toelichting'],
	});

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async (cb: (data: CommunityCategoryGeneralInfoSubmitData) => void) => {
		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		cb({ formSubmitData });
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
	};

	const handleSubmitClick = () => {
		setTimeout(() => {
			(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
		}, 0);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			<div className="zds-flex zds-justify-content-between zds-items-center  zds-margin-bottom-8">
				<div>
					<div className="zds-headline-lg">{t('global:domainShared.naam')}</div>
				</div>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			<StepContent
				formSchema={formSchema}
				formData={formData}
				loading={loading}
				errorMessage={errorMessage}
				backButton={{
					label: 'global:common.back',
					onClick: () => void handleSubmit(onBack),
				}}
				nextButton={{
					label: 'global:common.finish',
					onClick: handleSubmitClick,
				}}
			>
				<ZdsForm onZdsSubmit={() => handleSubmit(onSave)} id={formId}>
					<div className={styles.formInputsContainer}>{renderFormControl('toelichting', 'large')}</div>
				</ZdsForm>
			</StepContent>
		</div>
	);
};

export default CommunityCategoryGeneralInfo;
