import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ZdsDivider, ZdsIcon } from '@zig-design-system/ui-components-react';

import zigLogo from '../../images/zig-logo.svg';
import ContextMenu from '../ContextMenu/ContextMenu';
import AppItem from './AppItem/AppItem';
import { useAppPermissions } from '../../hooks/useAppPermissions/useAppPermissions';
import styles from './AppSection.module.scss';

interface AppSectionProps {
	appName: string;
}

const AppSection = ({ appName }: AppSectionProps) => {
	const appSwitcherRef = useRef(null);
	const { t } = useTranslation();
	const hasAppPermissions = useAppPermissions();

	const PermissionREL = hasAppPermissions('REL');
	const PermissionORG = hasAppPermissions('ORG');
	const PermissionCOM = hasAppPermissions('COM');
	const PermissionVAS = hasAppPermissions('VAS');

	const hasAnyPermission = PermissionREL || PermissionORG || PermissionCOM || PermissionVAS;

	return (
		<div className={styles.appSectionContainer}>
			<div className={styles.appSection}>
				<div ref={appSwitcherRef} className={styles.appSwitcher}>
					<ZdsIcon name="apps" id="appbar__app-switcher-icon" />
				</div>
				<div className={styles.logo}>
					<img src={zigLogo} alt="Logo Zig" />
				</div>
				<div className={styles.appTitle}>{appName}</div>
			</div>

			{hasAnyPermission && (
				<ContextMenu
					target="#appbar__app-switcher-icon"
					targetRef={appSwitcherRef}
					className={styles.appSwitcherMenu}
					position="start"
				>
					<div className={styles.appSwitcherMenuTitleContainer}>
						<div className={classNames('zds-headline-sm', styles.appSwitcherMenuTitle)}>Switch to...</div>
					</div>
					<ZdsDivider />
					<div className={classNames('zds-label', styles.appSwitcherMenuLabel)}>Apps</div>
					{PermissionREL && (
						<AppItem
							title={t('apps.relationManagement')}
							link={'/relatiebeheer'}
							iconName="user-gear"
							iconColor="primary"
						/>
					)}
					{PermissionORG && (
						<AppItem
							title={t('apps.organizationManagement')}
							link={'/organisatiebeheer'}
							iconName="user-gear"
							iconColor="primary"
						/>
					)}
					{PermissionVAS && (
						<AppItem
							title={t('apps.porfolioManagement')}
							link={'/vastgoedbeheer'}
							iconName="user-gear"
							iconColor="primary"
						/>
					)}
					{PermissionCOM && (
						<AppItem
							title={t('apps.communityManagement')}
							link={'/communitybeheer'}
							iconName="user-gear"
							iconColor="primary"
						/>
					)}
				</ContextMenu>
			)}
		</div>
	);
};

export default AppSection;
