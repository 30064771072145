import { FormData, FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { useAppSelector } from '../../../../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../../../redux/currentCommunityData';
import { useCommunityCreateFormSchema } from './useCommunityCreateFormSchema';

interface CommunityUpdateFormSchemaOptions {
	data: FormData | null;
	language: string;
	selectedFields?: string[];
}

export const useCommunityUpdateFormSchema = ({
	data,
	language,
	selectedFields,
}: CommunityUpdateFormSchemaOptions): FormSchema | null => {
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);

	const { naam, soort, omschrijving, toelichting, begindatum, einddatum, vertalingen } = currentCommunityData ?? {};

	const initData = {
		naam,
		soort: soort as any,
		omschrijving,
		toelichting,
		begindatum: begindatum as unknown as string,
		einddatum: einddatum as unknown as string,
		vertalingen,
	};

	const formSchema = useCommunityCreateFormSchema({
		data,
		initData,
		language,
		selectedFields,
	});

	return formSchema;
};
