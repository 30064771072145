import { FC } from 'react';
import { ZdsCard } from '@zig-design-system/ui-components-react';
import { RelatieAvatar, RelatieRechtspersoon } from '@zig.community/community-components';

import { useAppSelector } from '../../../../../global/redux/store';
import { selectUserRelatie } from '../../../../../global/redux/shared/authorizationData';
import { Rechtspersoon } from '../../../../relatiebeheer/api/models';
import { Language } from '../../../api/models';
import styles from './PushNotificationPreview.module.scss';

export interface PushNotificationPreviewProps {
	organisatieRelatie: Rechtspersoon;
	title: string;
	message: string;
	show: boolean;
	language: Language;
}

const PushNotificationPreview: FC<PushNotificationPreviewProps> = ({
	organisatieRelatie,
	title,
	message,
	show,
	language,
}) => {
	const currentUserRelatie = useAppSelector(selectUserRelatie);

	return (
		<div className={styles.previewContainer}>
			{show && (
				<ZdsCard>
					<div slot="body" className="zds-flex zds-flex-1 zds-items-center">
						<div className={styles.avatar}>
							<RelatieAvatar
								currentRelatie={currentUserRelatie}
								relatie={{ voorkeurstaal: { code: 'nl' }, ...organisatieRelatie } as RelatieRechtspersoon}
							/>
						</div>
						<div className="zds-flex-1">
							<div className="zds-flex zds-justify-content-between">
								<div className="zds-headline-xs zds-loud zds-flex-1">{title}</div>
								<div className={styles.headerEnd}>{language === Language.English ? 'now' : 'nu'}</div>
							</div>
							<div className="zds-margin-top-1">{message}</div>
						</div>
					</div>
				</ZdsCard>
			)}
		</div>
	);
};

export default PushNotificationPreview;
