import { FC, useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsForm } from '@zig-design-system/ui-components-react';

import { FormControl, FormData, FormSchema, FormSubmitData, InputDataType } from './Form.types';
import { getDateFormat } from '../../utils/date/getDatePattern';
import { getFormDataFromSchema } from '../../utils/form/getFormDataFromSchema';
import { createFormColumns } from '../../utils/form/createFormColumns';
import { validateDates } from '../../utils/form/validateDates';
import { getFormInputValue } from '../../utils/form/getFormInputValue';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FormControlComponent from './FormControl/FormControl';
import { getFormSubmitData } from '../../utils/form/getFormSubmitData';
import { updateFormData } from '../../utils/form/updateFormData';
import styles from './Form.module.scss';

export interface FormProps {
	schema: FormSchema;
	onSubmit: (data: FormSubmitData) => void;
	onInputChange?: (data: { name: string; value: InputDataType }) => void;
	onDataChange?: (changed: boolean) => void;
	formId: string;
}

const Form: FC<FormProps> = ({ schema, onSubmit, formId, onInputChange, onDataChange }) => {
	const { t, i18n } = useTranslation('global');
	const [fcColumns, setFcColumns] = useState<(string | FormControl)[][]>(createFormColumns(schema));
	const [initFormData, setInitFormData] = useState(getFormDataFromSchema(schema));
	const [formData, setFormData] = useState<FormData | null>(getFormDataFromSchema(schema));
	const [formValidationError, setFormValidationError] = useState('');
	const dateFormat = getDateFormat(i18n.language);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl) => {
		const value = getFormInputValue(event, inputData);
		updateFormData({ event, formData, inputData, setFormData });

		onInputChange?.({ name: inputData.name, value });
	};

	const handleSubmit = () => {
		const submitData = getFormSubmitData(formData, schema, dateFormat);
		if (!submitData) return null;

		const validationErrorMessage = validateDates(submitData, initFormData, dateFormat);
		if (validationErrorMessage) {
			setFormValidationError(validationErrorMessage);
			return;
		}
		setFormValidationError('');
		onSubmit(submitData);
	};

	useEffect(() => {
		onDataChange?.(Object.entries(initFormData).some(([key, value]) => formData?.[key]?.value !== value.value));
	}, [formData, initFormData]);

	useEffect(() => {
		const newFormData = getFormDataFromSchema(schema);
		setFcColumns(createFormColumns(schema));
		setFormData(newFormData);
		setInitFormData(newFormData);
	}, [schema]);

	if (!formData) return null;

	return (
		<>
			{schema.title && <div className="zds-headline-xl zds-margin-bottom-3">{schema.title}</div>}
			<ZdsForm onZdsSubmit={handleSubmit} id={formId}>
				<div className={styles.formContent}>
					{fcColumns.map(fcColumn => {
						const columnItems = fcColumn.map(fcc => (typeof fcc === 'string' ? '' : fcc.name)).join('');
						return (
							<div className="zds-flex zds-flex-col zds-flex-1 zds-gap-y-4" key={`formColumn${columnItems}`}>
								{fcColumn.map(sectionHeaderOrFormControl => (
									<FormControlComponent
										key={
											typeof sectionHeaderOrFormControl === 'string'
												? sectionHeaderOrFormControl
												: sectionHeaderOrFormControl.name
										}
										sectionHeaderOrFormControl={sectionHeaderOrFormControl}
										formData={formData}
										onUpdateFormData={handleUpdateFormData}
									/>
								))}
							</div>
						);
					})}
				</div>
			</ZdsForm>
			{formValidationError && <ErrorMessage message={t(formValidationError)} />}
		</>
	);
};

export const FormColumn = ({ children }: { children: ReactNode }) => (
	<div className="zds-flex zds-flex-col zds-flex-1 zds-gap-y-4">{children}</div>
);

export default Form;
