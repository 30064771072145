import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ZdsButton } from '@zig-design-system/ui-components-react';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import MedewerkersTable from '../../components/MedewerkersTable/MedewerkersTable';
import { getMedewerkers, selectMedewerkers } from '../../redux/medewerkers';
import { useOrganisatieSideMenu } from '../../hooks/useOrganisatieSideMenu';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import Search from '../../../../global/components/Search/Search';

export const PAGE_SIZE = 20;

const Medewerkers: FC = () => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const { medewerkers, loading, error, itemsNum } = useAppSelector(selectMedewerkers);
	const [term, setTerm] = useState(searchParams.get('search') ?? undefined);
	const sideMenuInfo = useOrganisatieSideMenu();
	const hasPermission = usePermissions();

	useEffect(() => {
		handleGetMedewerkers(0, true);
	}, [term]);

	const handleGetMedewerkers = (page: number, clear?: boolean) => {
		dispatch(getMedewerkers({ term, offset: page, limit: PAGE_SIZE, clear }));
	};

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:medewerkersPage.header.title') },
	];

	return (
		<>
			{hasPermission('ORG_LEZ_MED') && (
				<PageLayout sideMenuInfo={sideMenuInfo}>
					<PageHeader
						title={t('organisatie:medewerkersPage.header.title')}
						iconName="message-lines"
						iconLibrary="fa-solid"
						breadcrumbs={breadcrumbs}
					/>
					<Pane className="zds-flex">
						<Search onSearch={term => setTerm(term.trim())} />
						<div className="zds-flex zds-justify-content-end">
							{hasPermission('ORG_AAN_MED') && (
								<ZdsButton id="add-relatie-button" link="/organisatiebeheer/medewerkers/create">
									+ {t('organisatie:medewerkersPage.header.addNew')}
								</ZdsButton>
							)}
						</div>
					</Pane>
					<Pane className="zds-flex-1">
						{loading && <h3 data-testid="communitiesListLoading">{t('global:common.loading')}...</h3>}
						{medewerkers && (
							<MedewerkersTable
								medewerkers={medewerkers}
								onPageChange={handleGetMedewerkers}
								pageSize={PAGE_SIZE}
								itemsNum={itemsNum}
							/>
						)}
						{error && <ErrorMessage message={t(error)} />}
					</Pane>
				</PageLayout>
			)}
		</>
	);
};

export default Medewerkers;
