import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsStep, ZdsStepper } from '@zig-design-system/ui-components-react';
import { ZdsStepperCustomEvent } from '@zig-design-system/ui-components';

import { useAppSelector } from '../../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../../communitybeheer/redux/currentCommunityData';

interface CreateNewsMessageStepperProps {
	activeStep: number;
	setActiveStep: (step: number) => void;
}

const CreateNewsMessageStepper = forwardRef<HTMLZdsStepperElement, CreateNewsMessageStepperProps>(
	({ activeStep, setActiveStep }, ref) => {
		const { t } = useTranslation();
		const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);

		const handleStepperNavigate = (event: ZdsStepperCustomEvent<any>) => {
			setActiveStep(event.detail.step);
		};

		return (
			<ZdsStepper variant="vertical" activeStep={activeStep} ref={ref} onZdsStepperNavigate={handleStepperNavigate}>
				<ZdsStep status="complete" disabled>
					{t('community:communityInformationPage.messages.createNewsMessage.stepper.audience')}
					<div slot="subtitle"> {currentCommunityData?.naam}</div>
				</ZdsStep>
				<ZdsStep>
					{t('community:communityInformationPage.messages.createNewsMessage.stepper.channelsAndContent')}
					<div slot="subtitle">{t('global:common.current')}</div>
				</ZdsStep>
				<ZdsStep> {t('global:common.publish')}</ZdsStep>
			</ZdsStepper>
		);
	}
);

export default CreateNewsMessageStepper;
