import { FC } from 'react';

import { sideMenuInfo } from '../../routes';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';

const CollectiveObjects: FC = () => {
	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div />
		</PageLayout>
	);
};

export default CollectiveObjects;
