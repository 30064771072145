import { FC, ReactNode } from 'react';
import { ZdsBreadcrumb, ZdsBreadcrumbs, ZdsIcon } from '@zig-design-system/ui-components-react';

import Pane from '../Pane/Pane';
import StatusBadge from '../../../apps/relatiebeheer/components/StatusBadge/StatusBadge';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
	title: string;
	breadcrumbs?: {
		link?: string;
		label: string;
	}[];
	iconName: string;
	iconLibrary: HTMLZdsIconElement['library'];
	status?: string;
	contentEnd?: ReactNode;
	description?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
	title,
	breadcrumbs,
	iconName,
	iconLibrary,
	status,
	contentEnd,
	description,
}) => {
	return (
		<Pane>
			<div className={styles.pageHeader} data-testid="pageHeader">
				<div className={styles.contentMain}>
					<div className={styles.pageHeaderIconContainer}>
						<ZdsIcon name={iconName} library={iconLibrary} size="lg" />
					</div>
					<div className="zds-flex zds-flex-col zds-gap-1 zds-self-stretch zds-padding-top-2">
						{description && <div className="zds-muted">{description}</div>}
						{breadcrumbs && (
							<ZdsBreadcrumbs className={styles.breadcrumbs}>
								{breadcrumbs?.map(({ link, label }) => (
									<ZdsBreadcrumb key={label} link={link}>
										{label}
									</ZdsBreadcrumb>
								))}
							</ZdsBreadcrumbs>
						)}
						<div className="zds-flex zds-gap-4 zds-items-center">
							<div className="zds-headline-xxl">{title}</div>
							{status && <StatusBadge status={status} />}
						</div>
					</div>
				</div>
				{contentEnd}
			</div>
		</Pane>
	);
};

export default PageHeader;
