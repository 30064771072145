import { createAsyncThunk } from '@reduxjs/toolkit';

import RelatiesAPI from '../../../../apps/relatiebeheer/api';
import OrganisatiesAPI from '../../../../apps/organisatiebeheer/api';
import { getErrorMessageForGetRequest } from '../../../utils/errorHandlers/getErrorMessageForGetRequest';
import { Account, NatuurlijkPersoon } from '../../../../apps/relatiebeheer/api/models';
import { Medewerker } from '../../../../apps/organisatiebeheer/api/models';

export const getProfileData = createAsyncThunk<
	{ account: Account; medewerker: Medewerker; relatie: NatuurlijkPersoon },
	{ relatieId: string },
	{ rejectValue: string }
>('profileData/getProfileData', async ({ relatieId }, { rejectWithValue }) => {
	try {
		const relatie = (await RelatiesAPI.getRelatie(relatieId)) as NatuurlijkPersoon;
		const medewerkers = await OrganisatiesAPI.getRelatieMedewerkers(relatieId);

		if (!relatie?.account) {
			return rejectWithValue(getErrorMessageForGetRequest('global:form.errorMessage.get'));
		}

		return { relatie, account: relatie.account, medewerker: medewerkers[0] ?? null };
	} catch (error) {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	}
});
