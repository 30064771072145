import { TFunction } from 'i18next';
import { ZdsInputText, ZdsSelect } from '@zig-design-system/ui-components-react';

import { FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { Contactgegeven } from '../../../../../api/models';

export const generateCreateContactgegevenFormSchema = (
	contactgegeven: Contactgegeven | null,
	t: TFunction,
	disabled?: boolean
): FormSchema => {
	const schema: FormSchema = {
		formControls: [
			{
				name: 'soort',
				submitPath: 'soort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.soort'),
						value: contactgegeven?.soort?.code ?? 'EMA',
						required: true,
						...(disabled && { disabled: true }),
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'CONTACTGEGEVENSOORT',
						code: contactgegeven?.soort?.code ?? 'EMA',
					}),
				},
			},
			{
				name: 'detailSoort',
				submitPath: 'detailSoort.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.detailSoort'),
						value: contactgegeven?.detailSoort?.code ?? '',
						...(disabled && { disabled: true }),
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'CONTACTGEGEVENDETAILSOORT',
						code: contactgegeven?.detailSoort?.code ?? '',
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'status',
				submitPath: 'status.code',
				control: {
					type: ZdsSelect,
					props: {
						label: getStringTranslation(t, 'global:domainShared.status'),
						value: contactgegeven?.status?.code ?? '',
						...(disabled && { disabled: true }),
					},
					options: getReferentiedataSelectOptions({
						t,
						soort: 'CONTACTGEGEVENSTATUS',
						code: contactgegeven?.status?.code ?? '',
						hasEmptyValue: true,
					}),
				},
			},
			{
				name: 'omschrijving',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.omschrijving'),
						value: contactgegeven?.omschrijving ?? '',
						...(disabled && { disabled: true }),
					},
				},
			},
			{
				name: 'waarde',
				control: {
					type: ZdsInputText,
					props: {
						label: getStringTranslation(t, 'global:domainShared.waarde'),
						value: contactgegeven?.waarde ?? '',
						required: true,
						...(disabled && { disabled: true }),
					},
				},
			},
		],
		layout: {
			columns: [
				['soort', 'omschrijving', 'status'],
				['detailSoort', 'waarde'],
			],
		},
	};

	return schema;
};
