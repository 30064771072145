import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import CommunityAPI from '../../api';
import { Bericht, BerichtEmotie, CreateBerichtEmotiePayload } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { getCurrentCommunityData, incrementCommunityCategoryMessageCount } from '../currentCommunityData';
import { RootState } from '../../../../global/redux/store';
import { BerichtFilters } from './types';

export const BERICHTEN_PAGE_SIZE = 10;

export const getBerichten = createAsyncThunk<
	{ berichten: Bericht[]; totalItems: number },
	{ communityId: string; offset: number },
	{ rejectValue: string }
>('berichten/getBerichten', async ({ communityId, offset }, { rejectWithValue, getState }) => {
	const filters = (getState() as RootState).communities.berichten.filters;
	return CommunityAPI.getBerichten(communityId, offset, BERICHTEN_PAGE_SIZE, filters).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});

export const refetchLastGetBerichten = createAsyncThunk<
	{ berichten: Bericht[]; totalItems: number } | null,
	void,
	{ rejectValue: string }
>('berichten/refetchLastGetBerichten', async (_args, { rejectWithValue, getState }) => {
	const berichtenState = (getState() as RootState).communities.berichten.berichten;
	const communityId = (getState() as RootState).communities.berichten.communityId;
	const filters = (getState() as RootState).communities.berichten.filters;
	if (!berichtenState || !communityId) {
		return null;
	}
	const currentlyFetched = berichtenState.berichten.length;
	const totalItems = berichtenState.totalItems;
	if (currentlyFetched === totalItems) {
		return berichtenState;
	}
	const offset = Math.floor((currentlyFetched - 1) / BERICHTEN_PAGE_SIZE);
	return CommunityAPI.getBerichten(communityId, offset, BERICHTEN_PAGE_SIZE, filters).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});

export const resetBerichten = createAsyncThunk<
	{ berichten: Bericht[]; totalItems: number },
	{ communityId?: string },
	{ rejectValue: string }
>('berichten/resetBerichten', async ({ communityId }, { rejectWithValue, getState }) => {
	if (!communityId) return { berichten: [], totalItems: 0 };

	const filters = (getState() as RootState).communities.berichten.filters;

	return CommunityAPI.getBerichten(communityId, 0, BERICHTEN_PAGE_SIZE, filters).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});

export const getBericht = createAsyncThunk<Bericht, { berichtId: string }, { rejectValue: string }>(
	'berichten/getBericht',
	async ({ berichtId }, { rejectWithValue }) => {
		return CommunityAPI.getBericht(berichtId).catch(error => rejectWithValue(getErrorMessageForGetRequest(error)));
	}
);

export const likeBericht = createAsyncThunk<
	BerichtEmotie,
	{ berichtId: string; relatieId: string },
	{ rejectValue: string }
>('berichten/likeBericht', async ({ berichtId, relatieId }, { rejectWithValue }) => {
	const newEmotie: CreateBerichtEmotiePayload = {
		soort: { code: 'EMO' },
		detailSoort: { code: 'LEU' },
		bericht: { id: berichtId },
		relatie: { id: relatieId },
	};

	return CommunityAPI.createBerichtEmotie(newEmotie).catch(() => rejectWithValue('global:form.errorMessage.create'));
});

export const deleteBerichtEmotie = createAsyncThunk<boolean, { emotieId: string }, { rejectValue: string }>(
	'berichten/deleteBerichtEmotie',
	async ({ emotieId }, { rejectWithValue }) => {
		return CommunityAPI.deleteBerichtEmotie(emotieId).catch(() => {
			return rejectWithValue('global:form.errorMessage.delete');
		});
	}
);

export const createBericht = createAsyncThunk<
	Bericht,
	{
		communityId: string;
		categoryId?: string;
		value: string;
		fileData: {
			base64: string | null;
			name: string | null;
		};
	},
	{ rejectValue: string }
>(
	'berichten/createBericht',
	async ({ communityId, categoryId, value, fileData }, { dispatch, rejectWithValue, getState }) => {
		try {
			const author = (getState() as RootState).authorization.user?.relatieId as string;

			const response = await CommunityAPI.createInitialBericht({ communityId, categoryId, value, fileData, author });
			if (categoryId) dispatch(incrementCommunityCategoryMessageCount(categoryId));

			return response;
		} catch {
			return rejectWithValue('global:form.errorMessage.create');
		}
	}
);

export const updateBericht = createAsyncThunk<
	Bericht,
	{
		id: string;
		value: string;
		fileData: {
			base64: string | null;
			name: string | null;
		} | null;
	},
	{ rejectValue: string }
>('berichten/updateBericht', async ({ id, value, fileData }, { rejectWithValue }) => {
	try {
		return await CommunityAPI.updateBericht({ id, value, fileData });
	} catch {
		return rejectWithValue('global:form.errorMessage.update');
	}
});

export const deleteBericht = createAsyncThunk<void, { berichtId: string }, { rejectValue: string }>(
	'berichten/deleteBericht',
	async ({ berichtId }, { dispatch, rejectWithValue, getState }) => {
		try {
			const state = getState() as RootState;
			const communityId = state.communities.berichten.communityId;
			const isComment = state.communities.berichten.selectedBericht?.reacties?.some(r => r.id === berichtId);

			await CommunityAPI.deleteBericht(berichtId);
			if (!isComment) {
				dispatch(refetchLastGetBerichten());
			}
			if (communityId) {
				dispatch(getCurrentCommunityData(communityId));
			}

			return;
		} catch {
			return rejectWithValue('global:form.errorMessage.delete');
		}
	}
);

export const createReactie = createAsyncThunk<
	Bericht,
	{
		communityId: string;
		categoryId?: string;
		berichtId: string;
		value: string;
		soort?: string;
		fileData: {
			base64: string | null;
			name: string | null;
		};
	},
	{ rejectValue: string }
>(
	'berichten/createReactie',
	async ({ communityId, categoryId, berichtId, value, fileData, soort }, { dispatch, rejectWithValue, getState }) => {
		try {
			const author = (getState() as RootState).authorization.user?.relatieId as string;

			const response = await CommunityAPI.createReactieBericht({
				communityId,
				bovenliggendBerichtId: berichtId,
				value,
				fileData,
				author,
				soort,
			});
			if (categoryId) dispatch(incrementCommunityCategoryMessageCount(categoryId));

			return response;
		} catch {
			return rejectWithValue('global:form.errorMessage.create');
		}
	}
);

export const setFilters = createAction('berichten/setFilters', (filters: Partial<BerichtFilters>) => {
	return { payload: filters };
});

export const resetFilters = createAction('berichten/resetFilters');

export const deleteInappropriateReports = createAsyncThunk<void, { berichtId: string }, { rejectValue: string }>(
	'berichten/deleteInappropriateReports',
	async ({ berichtId }, { dispatch, rejectWithValue, getState }) => {
		try {
			const communityId = (getState() as RootState).communities.berichten.communityId;
			const response = await CommunityAPI.deleteInappropriateReports(berichtId);

			dispatch(refetchLastGetBerichten());
			if (communityId) {
				dispatch(getCurrentCommunityData(communityId));
			}

			return response;
		} catch {
			return rejectWithValue('global:form.errorMessage.delete');
		}
	}
);

export const updateBerichtInList = createAction('berichten/updateBerichtInList', (bericht: Bericht) => {
	return { payload: bericht };
});
