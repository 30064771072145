import { createSlice } from '@reduxjs/toolkit';

import { RelatiesState, getNatuurlijkPersoonRelaties } from '.';

const initialState: RelatiesState = {
	relaties: null,
	loading: false,
	error: null,
};

export const realtiesSlice = createSlice({
	name: 'relatieData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getNatuurlijkPersoonRelaties.pending, state => {
				state.loading = true;
				state.relaties = null;
			})
			.addCase(getNatuurlijkPersoonRelaties.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.relaties = payload;
			})
			.addCase(getNatuurlijkPersoonRelaties.rejected, (state, { payload }) => {
				state.loading = false;
				state.relaties = null;
				state.error = payload ?? null;
			});
	},
});

export const relatiesReducer = realtiesSlice.reducer;
