import { ComponentProps, ElementType, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsDivider, ZdsRadio } from '@zig-design-system/ui-components-react';

import {
	FormControl as FormControlType,
	FormControlData,
	FormData,
	isCheckbox,
	isRadioGroup,
	isSelect,
	isSwitch,
} from '../Form.types';
import InputWrapper, { InputWrapperProps } from '../InputWrapper/InputWrapper';
import { defaultValidationMessages } from '../config';
import { Language } from '../../../../apps/communitybeheer/api/models';

interface FormControlProps {
	sectionHeaderOrFormControl?: string | FormControlType;
	formData: FormData;
	onUpdateFormData: (event: CustomEvent, inputData: FormControlType, language: Language) => void;
	language?: Language;
	labelWidth?: InputWrapperProps['labelWidth'];
}

const FormControl: FC<FormControlProps> = ({
	sectionHeaderOrFormControl,
	formData,
	onUpdateFormData,
	language = Language.Dutch,
	labelWidth,
}) => {
	const { t } = useTranslation();

	if (!sectionHeaderOrFormControl) return null;

	if (typeof sectionHeaderOrFormControl === 'string') {
		return (
			<div className="zds-margin-top-4">
				<div className="zds-headline-sm">{sectionHeaderOrFormControl}</div>
				<ZdsDivider />
			</div>
		);
	}

	const formControl = sectionHeaderOrFormControl;
	const formControlData = formControl.control;
	const Component = formControlData.type as ElementType;
	const props = formControlData.props as ComponentProps<typeof Component>;
	const languageData = formData[formControl.name].languageData;

	if (isCheckbox(formControlData) || isSwitch(formControlData)) {
		props.checked = formData[formControl.name].value;
	} else {
		props.value = languageData ? languageData[language] : formData[formControl.name].value;
	}

	const label = props.label;
	const finalProps = { ...props };
	delete finalProps.label;

	const renderDefaultValidationMessages = () =>
		defaultValidationMessages.map(({ reason, message }, i) => (
			<span slot="validation-message" data-reason={reason} key={`${reason}${i}ValidationMessage`}>
				{t(message)}
			</span>
		));

	const renderCustomValidationMessages = (formControlData: FormControlData) =>
		formControlData.validationMessages &&
		Object.keys(formControlData.validationMessages)?.map((key, i) => (
			<span slot="validation-message" data-reason={key} key={`${key}${i}CustomValidationMessage`}>
				{formControlData.validationMessages?.[key]}
			</span>
		));

	const renderSelectOptions = (formControlData: FormControlData) => {
		return (
			isSelect(formControlData) &&
			formControlData.options.map(({ value, label, disabled }) => (
				<option key={value} value={value} label={label} disabled={disabled} />
			))
		);
	};

	const renderRadioButtons = (inputData: FormControlType) => {
		return (
			isRadioGroup(inputData.control) &&
			inputData.control.options.map(({ value, label, name }) => (
				<ZdsRadio key={value} value={value} name={name} checked={formData[inputData.name].value === value}>
					{label}
				</ZdsRadio>
			))
		);
	};

	const handleInputUpdate = (e: CustomEvent) => {
		onUpdateFormData(e, formControl, language);
	};

	return (
		<InputWrapper
			key={formControl.name}
			label={label}
			formControl={formControl}
			fullInputWidth={formControl.layoutOptions?.fullWidth}
			labelPosition={formControl.layoutOptions?.labelPosition}
			labelAligh={formControl.layoutOptions?.labelAlign}
			labelWidth={labelWidth}
		>
			<Component name={formControl.name} {...finalProps} onZdsInputDidUpdate={handleInputUpdate}>
				{renderDefaultValidationMessages()}
				{renderCustomValidationMessages(formControlData)}
				{renderSelectOptions(formControlData)}
				{renderRadioButtons(formControl)}
				{finalProps.children}
			</Component>
		</InputWrapper>
	);
};

export default FormControl;
