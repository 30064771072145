import { FormControl, FormSchema } from '../../components/Form/Form.types';

export const COLUMN_SECTION_HEADER_PREFIX = '::';

export const createFormColumns = ({ formControls, layout }: FormSchema): (string | FormControl)[][] => {
	if (!layout) return [formControls];

	return layout.columns.map(col => {
		return col.reduce((acc, name) => {
			if (name.startsWith(COLUMN_SECTION_HEADER_PREFIX)) {
				const sectionHeader = name.slice(COLUMN_SECTION_HEADER_PREFIX.length);
				acc.push(sectionHeader);
			}
			const control = formControls.find(fc => fc.name === name);
			if (control) {
				acc.push(control);
			}
			return acc;
		}, [] as (string | FormControl)[]);
	});
};
