import { FC, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import { getReferentiedataSelectOptions } from '../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { CommunityCategorie, RolRelatie, RolRelatiePayload } from '../../../../api/models';
import { getCurrentCommunityData, selectCurrentCommunityData } from '../../../../redux/currentCommunityData';
import { Relatie } from '../../../../../relatiebeheer/api/models';
import { createCommunityCategoryRolRelatie } from '../../../../redux/currentCommunityCategoryData';
import AddMemberForm from '../../../../components/AddMemberForm/AddMemberForm';

const allowedCommunityrolSoortCodes = ['VOL'];

interface AddCommunityCategoryFollowersProps {
	communityCategorie: CommunityCategorie;
}

const AddCommunityCategoryFollowers: FC<AddCommunityCategoryFollowersProps> = ({ communityCategorie }) => {
	const dispatch = useAppDispatch();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const [selectedRol, setSelectedRol] = useState<string | null>(null);
	const [relaties, setRelaties] = useState<Relatie[]>([]);
	const [selectedRelatie, setSelectedRelatie] = useState<string | null>(null);

	const getUniqueRelaties = (rolRelaties: RolRelatie[]) => {
		return removeCommunityCategoryAut(rolRelaties)
			.map(rr => rr.relatie)
			.filter((relatie, index, self) => self.findIndex(r => r.id === relatie.id) === index);
	};

	const removeCommunityCategoryAut = (rolRelaties: RolRelatie[]) => {
		const aut = communityCategorie.rolRelaties?.find(rr => rr.soort.code === 'AUT');

		return aut ? rolRelaties.filter(rr => rr.relatie.id !== aut.relatie.id) : rolRelaties;
	};

	const allRelaties = useMemo(() => {
		return getUniqueRelaties(currentCommunityData?.rolRelaties ?? []);
	}, [currentCommunityData]);

	useEffect(() => {
		dispatch(getCurrentCommunityData(communityCategorie.community.id));
	}, []);

	useEffect(() => {
		searchRelaties('');
	}, [allRelaties]);

	const searchRelaties = (term: string) => {
		setSelectedRelatie(null);
		setRelaties(allRelaties.filter(r => !term || r.naam?.toLowerCase().includes(term.toLowerCase())));
	};
	const handleSubmit = () => {
		if (!selectedRol || !selectedRelatie) {
			return;
		}

		const newRolRelatie: RolRelatiePayload = { soort: { code: selectedRol }, relatie: { id: selectedRelatie } };

		dispatch(
			createCommunityCategoryRolRelatie({
				id: communityCategorie.id,
				body: newRolRelatie,
			})
		);
	};

	const handleToggleForm = () => {
		setSelectedRelatie(null);
	};

	const communityRolItems = getReferentiedataSelectOptions({
		t,
		soort: 'COMMUNITYROLSOORT',
	}).filter(c => allowedCommunityrolSoortCodes.includes(c.value));

	const autocompleteItems = (relaties ?? [])
		.map(r => ({
			key: r.id as string,
			value: r.naam as string,
		}))
		.sort((a, b) => a.value.localeCompare(b.value))
		.slice(0, 10);

	const isSaveDisabled =
		!selectedRelatie ||
		!selectedRol ||
		!!communityCategorie.rolRelaties?.some(
			({ relatie, soort }) => relatie.id === selectedRelatie && selectedRol === soort.code
		);

	return (
		<AddMemberForm
			rolItems={communityRolItems}
			autocompleteItems={autocompleteItems}
			selectedRelatie={selectedRelatie}
			isSaveDisabled={isSaveDisabled}
			onFormToggle={handleToggleForm}
			onRolChange={setSelectedRol}
			onAutocompleteChange={searchRelaties}
			onAutocompleteSelect={setSelectedRelatie}
			onSubmit={handleSubmit}
		/>
	);
};

export default AddCommunityCategoryFollowers;
