import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { ZdsButton, ZdsIcon, ZdsItem, ZdsSkeletonRectangle } from '@zig-design-system/ui-components-react';

import { useBerichtPermissions } from '../../../hooks/useBerichtPermissions/useBerichtPermissions';
import ContextMenu from '../../../../../global/components/ContextMenu/ContextMenu';
import CommunitiesAdvancedSelect, {
	mapCommunityToAdvancedSelectItem,
} from '../../CommunitiesAdvancedSelect/CommunitiesAdvancedSelect';
import { AdvancedSelectItem } from '../../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';
import { useAppSelector } from '../../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import { useNewsFeature } from '../../../hooks/useNewsFeature/useNewsFeature';
import styles from './MessageHeader.module.scss';

interface MessagesHeaderProps {
	onWriteBericht: () => void;
	onCreateNieuwsbericht: () => void;
}

const MessagesHeader: FC<MessagesHeaderProps> = ({ onWriteBericht, onCreateNieuwsbericht }) => {
	const { t } = useTranslation();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const hasPermission = useBerichtPermissions();
	const { isNewsFeatureEnabled } = useNewsFeature();
	const canCreate = hasPermission('CREATE');
	const composeButtonRef = useRef<HTMLZdsButtonElement>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams<{ id: string }>();

	const composeButtonId = 'composeMessageButton';

	const handleSelectedCommunitiesChange = (newSelectedCommunities: AdvancedSelectItem[]) => {
		const id = newSelectedCommunities[0].id;

		if (id && id !== params.id) {
			navigate(`/communitybeheer/community/${id}${location.search}`);
		}
	};

	if (!currentCommunityData)
		return (
			<div className={styles.container}>
				<ZdsSkeletonRectangle class="zds-flex-1" height="74" />
			</div>
		);

	return (
		<div className={styles.container}>
			<CommunitiesAdvancedSelect
				selectedValues={[mapCommunityToAdvancedSelectItem(currentCommunityData)]}
				onChange={handleSelectedCommunitiesChange}
				multiple={false}
				clearable={false}
				iconType="big-bg-radius"
				inputTextType="multiline"
			/>
			{canCreate && (
				<div className={styles.composeButtonContainer}>
					<ZdsButton
						id={composeButtonId}
						ref={composeButtonRef}
						onZdsClick={() => !isNewsFeatureEnabled && onWriteBericht()}
					>
						<ZdsIcon library="fa-solid" name="pen" size="xs" />
						{t('community:communityInformationPage.messages.header.compose')}
					</ZdsButton>
					{isNewsFeatureEnabled && (
						<ContextMenu
							target={`#${composeButtonId}`}
							targetRef={composeButtonRef}
							className="zds-flex"
							position="end"
						>
							<ZdsItem onZdsClick={onWriteBericht}>
								<div slot="primary-text">{t('community:communityInformationPage.messages.header.bulletinboard')}</div>
							</ZdsItem>
							<ZdsItem onZdsClick={onCreateNieuwsbericht}>
								<div slot="primary-text">{t('community:communityInformationPage.messages.header.news')}</div>
							</ZdsItem>
						</ContextMenu>
					)}
				</div>
			)}
		</div>
	);
};

export default MessagesHeader;
