import { TFunction } from 'i18next';

import { Referentiedata } from '../../api/models';
import zigData from '../zigDataSdk/zigDataSdk';

export const getReferentiedataNaam = (t: TFunction, soort?: string, code = ''): string => {
	if (!soort) return '';

	const referentiedata = zigData.getDataBySoortAndCode(soort, code) as Referentiedata[];

	return referentiedata.length > 0 ? t(`referentiedata:${soort}.${code}`) : code;
};
