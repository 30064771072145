import { FC } from 'react';
import { ZdsSpinner } from '@zig-design-system/ui-components-react';

import styles from './Spinner.module.scss';

const Spinner: FC = () => (
	<div className={styles.spinnerContainer}>
		<ZdsSpinner indeterminate />
	</div>
);

export default Spinner;
