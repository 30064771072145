import { FC, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import { sideMenuInfo } from '../../routes';
import CreateCommunityStepper from '../../components/CreateCommunityStepper/CreateCommunityStepper';
import NameAndModerators, { NameAndModeratorsSubmitData } from './NameAndModerators/NameAndModerators';
import GeneralInfo, { GeneralInfoSubmitData } from './GeneralInfo/GeneralInfo';
import SetAudience, { SetAudienceSubmitData } from './SetAudience/SetAudience';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { mergeFormsVertalingen } from '../../../../global/utils/form/mergeFormsVertalingen';
import CommunityAPI, { CreateCommunityPayload } from '../../api';
import { getRolRelatieSubmitData } from '../../utils/form/getRolRelatieSubmitData';
import styles from './CreateCommunity.module.scss';

const CreateCommunity: FC = () => {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const [title, setTitle] = useState('');
	const [nameAndModeratorsSubmitData, setNameAndModeratorsSubmitData] = useState<NameAndModeratorsSubmitData | null>(
		null
	);
	const [generalInfoSubmitData, setGeneralInfoSubmitData] = useState<GeneralInfoSubmitData | null>(null);
	const [audienceSubmitData, setAudienceSubmitData] = useState<SetAudienceSubmitData | null>(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const stepperRef = useRef<HTMLZdsStepperElement | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (audienceSubmitData && activeStep === 2) {
			handleSubmit();
		}
	}, [audienceSubmitData]);

	const handleCommunityNameChange = (name: string) => {
		setTitle(name);
	};

	const getStepsContent = () => {
		switch (activeStep) {
			case 0:
				return (
					<NameAndModerators
						initData={nameAndModeratorsSubmitData}
						selectedMembers={audienceSubmitData?.selectedMembers}
						onCommunityNameChange={handleCommunityNameChange}
						onSave={(data: NameAndModeratorsSubmitData) => {
							setNameAndModeratorsSubmitData(data);
							setActiveStep(1);
						}}
					/>
				);
			case 1:
				return (
					<GeneralInfo
						initData={generalInfoSubmitData}
						onSave={(data: GeneralInfoSubmitData) => {
							setGeneralInfoSubmitData(data);
							setActiveStep(2);
						}}
						onBack={(data: GeneralInfoSubmitData) => {
							setGeneralInfoSubmitData(data);
							setActiveStep(0);
						}}
					/>
				);
			case 2:
				return (
					<SetAudience
						initData={audienceSubmitData}
						selectedManagers={nameAndModeratorsSubmitData?.selectedManagers}
						onSave={(data: SetAudienceSubmitData) => {
							setAudienceSubmitData(data);
						}}
						onBack={(data: SetAudienceSubmitData) => {
							setAudienceSubmitData(data);
							setActiveStep(1);
						}}
						loading={loading}
						errorMessage={error}
					/>
				);
			default:
				return null;
		}
	};

	const handleSubmit = () => {
		const allFormsSubmitData = [
			nameAndModeratorsSubmitData?.formSubmitData,
			generalInfoSubmitData?.formSubmitData,
			audienceSubmitData?.formSubmitData,
		].filter(Boolean) as FormSubmitData[];

		const formSubmitData = allFormsSubmitData.reduce((acc, data) => ({ ...acc, ...data }), {});
		formSubmitData.vertalingen = mergeFormsVertalingen(allFormsSubmitData);

		const toewijzing = (audienceSubmitData?.formSubmitData.toewijzing as any)?.code;
		formSubmitData.soort = { code: toewijzing === 'AUT' ? 'STA' : 'INT' };

		const clustersSubmitData = audienceSubmitData?.selectedClusters.map(c => ({ id: c.id })) ?? [];

		const managerIds = nameAndModeratorsSubmitData?.selectedManagers.map(m => m.id) ?? [];
		const memberIds = audienceSubmitData?.selectedMembers.map(m => m.id) ?? [];
		const managersSubmitData = managerIds.map(id => getRolRelatieSubmitData('COM', id));
		const membersSubmitData = memberIds
			.filter(id => !managerIds.includes(id))
			.map(id => getRolRelatieSubmitData('LID', id));
		const rolRelatiesSubmitData = [...managersSubmitData, ...membersSubmitData];

		createCommunity(formSubmitData, clustersSubmitData, rolRelatiesSubmitData);
	};

	const createCommunity = async (
		formSubmitData: FormSubmitData,
		clustersSubmitData: CreateCommunityPayload['clustersSubmitData'],
		rolRelatiesSubmitData: CreateCommunityPayload['rolRelatiesSubmitData']
	) => {
		try {
			setLoading(true);

			const result = await CommunityAPI.createCommunity({
				payload: formSubmitData,
				clustersSubmitData,
				rolRelatiesSubmitData,
				fileData: [generalInfoSubmitData?.backgroundPhoto, generalInfoSubmitData?.profilePhoto].map(fd =>
					fd ? { base64: fd.base64, name: fd.file?.name ?? null } : null
				),
			});

			onSave(result.id);
		} catch {
			setError(t('global:form.errorMessage.create') ?? '');
		} finally {
			setLoading(false);
		}
	};

	const onSave = (id: string) => {
		navigate(`/communitybeheer/community/${id}/edit`, { replace: true });
	};

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={title}
				iconName="message-lines"
				iconLibrary="fa-solid"
				description={t('community:createCommunityPage.header.subtitle') ?? ''}
			/>
			<div className={styles.container}>
				<Pane className={styles.stepperContainer}>
					<CreateCommunityStepper
						activeStep={activeStep}
						setActiveStep={setActiveStep}
						baseLabel="community:createCommunityPage.stepper"
						steps={['nameAndModerators', 'generalInfo', 'setAudience', 'publish']}
						ref={stepperRef}
					/>
				</Pane>
				<Pane className={styles.mainContainer}>{getStepsContent()}</Pane>
			</div>
		</PageLayout>
	);
};

export default CreateCommunity;
