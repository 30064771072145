import { Medewerkerrol } from '../../api/models';
import DataTable, { SortableOption } from '../../../../global/components/DataTable/DataTable';
import { useMapSelectMedewerkerollenToTableData } from '../../hooks/table/useMapSelectMedewerkerollenToTableData/useMapSelectMedewerkerollenToTableData';
import { FC } from 'react';

interface SelectMedewerkerollenTable {
	medewerkerrollen: { [key: number]: Medewerkerrol[] } | null;
	onPageChange: (page: number) => void;
	pageSize: number;
	itemsNum: number;
	currentPage: number;
	onSearchChange?: (searchData: { [key: string]: string }) => void;
	onSortChange?: (sortData: { columnId: string; sort: SortableOption }) => void;
	onCheckedChange?: (id: string, checked: boolean) => void;
	sortData: {
		[key: string]: SortableOption;
	};
	selectedMedewerkerollen: string[];
}

const SelectMedewerkerollenTable: FC<SelectMedewerkerollenTable> = ({
	medewerkerrollen,
	pageSize,
	itemsNum,
	currentPage,
	onPageChange,
	onSearchChange,
	onSortChange,
	onCheckedChange,
	sortData,
	selectedMedewerkerollen,
}) => {
	const tableData = useMapSelectMedewerkerollenToTableData(medewerkerrollen, sortData, selectedMedewerkerollen);

	return (
		<DataTable
			showHeaderWithNoItems
			data={tableData}
			tableTestId="selectMedewerkerrollenTable"
			initPage={currentPage}
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onSearchChange={onSearchChange}
			onSortChange={onSortChange}
			onCheckedChange={onCheckedChange}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
		/>
	);
};

export default SelectMedewerkerollenTable;
