import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsDivider, ZdsForm, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import ErrorMessage from '../../../../../global/components/ErrorMessage/ErrorMessage';
import { Language } from '../../../api/models';
import LanguageSelect, { LanguageData } from '../../../components/CreateNewsMessageModal/LanguageSelect/LanguageSelect';
import { useCommunityCreateFormSchema } from '../../../hooks/form/schema/create/community/useCommunityCreateFormSchema';
import { validateDates } from '../../../../../global/utils/form/validateDates';
import RelatiesAdvancedSelect from '../../../components/RelatiesAdvancedSelect/RelatiesAdvancedSelect';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import { AdvancedSelectItem } from '../../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';
import { useNaamPreviewData } from '../../../hooks/useNaamPreviewData.ts/useNaamPreviewData';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { DEFAULT_LANGUAGE, useLanguageSelect } from '../../../hooks/useLanguageSelect/useLanguageSelect';
import styles from './NameAndModerators.module.scss';

export type NameAndModeratorsSubmitData = { formSubmitData: FormSubmitData; selectedManagers: AdvancedSelectItem[] };

export interface NameAndModeratorsProps {
	initData: NameAndModeratorsSubmitData | null;
	selectedMembers?: AdvancedSelectItem[];
	onCommunityNameChange: (name: string) => void;
	onSave: (data: NameAndModeratorsSubmitData) => void;
}

// split into two forms because zds-advanced-select can't be inside zds-form
const formId1 = 'createCommunityInitInfoForm1';
const formId2 = 'createCommunityInitInfoForm2';
const initFormsValid = { [formId1]: false, [formId2]: false };

const NameAndModerators: FC<NameAndModeratorsProps> = ({
	initData,
	selectedMembers,
	onCommunityNameChange,
	onSave,
}) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [formData, setFormData] = useState<FormData | null>(null);
	const [error, setError] = useState('');
	const [selectedManagers, setSelectedManagers] = useState<AdvancedSelectItem[]>(initData?.selectedManagers ?? []);
	const [selectedManagersValidationError, setSelectedManagersValidationError] = useState('');
	const [formsValid, setFormsValid] = useState<{ [key: string]: boolean }>(initFormsValid);
	const [formSubmitData, setFormSubmitData] = useState<FormSubmitData | null>(null);
	const dateFormat = getDateFormat(i18n.language);
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();

	const formSchema = useCommunityCreateFormSchema({
		data: formData,
		initData: initData?.formSubmitData,
		language: selectedLanguage,
		dateOptions: { withLabel: false },
	});

	const { naam } = useNaamPreviewData(formData, selectedLanguage);

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	useEffect(() => {
		onCommunityNameChange(naam);
	}, [naam]);

	useEffect(() => {
		if (formSubmitData && Object.keys(formsValid).every(key => !!formsValid[key])) {
			setError('');
			setSelectedManagersValidationError('');
			onSave({ formSubmitData, selectedManagers });
		}
	}, [formsValid, formSubmitData]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		updateFormData({ event, formData, inputData, setFormData, language });
	};

	const handleSubmit = async (formId: string) => {
		if (!formData) return;

		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (
			selectedLanguage !== DEFAULT_LANGUAGE &&
			!((formData?.naam.languageData?.[DEFAULT_LANGUAGE] as string).trim() ?? '')
		) {
			return setError('community:createCommunityPage.form.dutchNameMissingErrorMessage');
		}

		const datesInvalidError = validateDates(formSubmitData, formData, dateFormat);
		if (datesInvalidError) {
			return setError(datesInvalidError);
		}

		if (!validateManagers()) return;

		setFormsValid(old => ({ ...old, [formId]: true }));
		setFormSubmitData(formSubmitData);
	};

	const validateManagers = () => {
		if (!selectedManagers.length) {
			setSelectedManagersValidationError('community:createCommunityPage.form.communityManagerRequiredErrorMessage');
			return false;
		}
		return true;
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
		setError('');
	};

	const handleSelectedManagersChange = (newSelectedManagers: AdvancedSelectItem[]) => {
		setSelectedManagers(newSelectedManagers);
		setError('');
		if (newSelectedManagers.length) {
			setSelectedManagersValidationError('');
		}
	};

	const handleSubmitClick = () => {
		setFormsValid(initFormsValid);
		setTimeout(() => {
			setError('');
			validateManagers();
			(document.querySelector(`#${formId1}`) as HTMLFormElement)?.submit();
			(document.querySelector(`#${formId2}`) as HTMLFormElement)?.submit();
		}, 0);
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	const content = () =>
		formSchema &&
		formData && (
			<div className="zds-flex zds-flex-col zds-justify-content-between zds-flex-1 zds-gap-6">
				<div className={styles.formContainer}>
					<ZdsForm onZdsSubmit={() => handleSubmit(formId1)} id={formId1}>
						<div className={styles.formInputsContainer}>{renderFormControl('naam', 'large')}</div>
					</ZdsForm>
					<div className="zds-flex zds-gap-3">
						<div className={styles.label}>{t('community:createCommunityPage.form.communityManager')}</div>
						<RelatiesAdvancedSelect
							selectedValues={selectedManagers}
							ignoreValues={selectedMembers}
							onChange={handleSelectedManagersChange}
							validationError={selectedManagersValidationError}
							placeholder={t('community:createCommunityPage.form.communityManagerPlaceholder') ?? ''}
						/>
					</div>
					<ZdsForm onZdsSubmit={() => handleSubmit(formId2)} id={formId2}>
						<div className={styles.formInputsContainer}>
							{renderFormControl('omschrijving', 'large')}
							<div className="zds-headline-lg zds-color-inherit">
								{t('community:createCommunityPage.form.publicationStatus')}
							</div>
							<div className="zds-flex zds-gap-4">
								<div className={styles.label}>{`${t('global:domainShared.begindatum')} / ${t(
									'global:domainShared.einddatum'
								)}`}</div>
								<div className="zds-flex zds-items-center zds-gap-3">
									{renderFormControl('begindatum', 'large')}
									<div>{t('global:common.until').toLowerCase()}</div>
									{renderFormControl('einddatum', 'large')}
								</div>
							</div>
						</div>
					</ZdsForm>
					{error && <ErrorMessage message={t(error)} />}
				</div>
				<div className="zds-flex zds-flex-col zds-gap-6">
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
					<div className="zds-flex zds-justify-content-between">
						<ZdsButton
							preset="neutral"
							onZdsClick={() => {
								navigate('/communitybeheer/community');
							}}
						>
							<ZdsIcon name="arrow-left" library="fa-solid" />
							{t('global:common.cancel')}
						</ZdsButton>
						<ZdsButton onZdsClick={handleSubmitClick}>
							{t('global:common.next')}
							<ZdsIcon name="arrow-right" library="fa-solid" />
						</ZdsButton>
					</div>
				</div>
			</div>
		);

	return (
		<div className="zds-flex zds-flex-col zds-flex-1">
			<div className="zds-flex zds-justify-content-between zds-items-center  zds-margin-bottom-12">
				<div className="zds-headline-lg">{t('community:createCommunityPage.stepper.nameAndModerators')}</div>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			{content()}
		</div>
	);
};

export default NameAndModerators;
