import { ZdsInputText } from '@zig-design-system/ui-components-react';
import { getStringTranslation } from '../../../../../../../../global/utils/getStringTranslation';
import { TFunction } from 'i18next';
import { FormSchema } from '../../../../../../../../global/components/Form/Form.types';

export const generateIamFormSchema = (t: TFunction): FormSchema => {
	const schema: FormSchema = {
		title: 'IAM gegevens', //t('global:common.about') ?? '',
		formControls: [
			{
				name: 'iam_realmName',
				control: {
					type: ZdsInputText,
					props: {
						id: 'iam_realmName',
						label: getStringTranslation(t, 'Realm naam'),
						required: true,
					},
				},
			},
		],
		layout: {
			columns: [['iam_realmName']],
		},
	};

	return schema;
};
