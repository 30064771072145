import { createSlice } from '@reduxjs/toolkit';

import {
	getCurrentCommunityData,
	CurrentCommunityDataState,
	updateCommunity,
	getCommunityCategoriesData,
	incrementCommunityCategoryMessageCount,
	createCommunityRolRelatie,
	deleteCommunityRolRelatie,
} from '.';

export const initialState: CurrentCommunityDataState = {
	currentCommunityData: null,
	loading: false,
	updating: false,
	error: null,
	currentCommunityCategories: null,
	loadingCommunityCategories: false,
	errorCommunnityCategories: null,
	errorUpdateCommunityRolRelatie: null,
	categoriesTotalNum: 0,
	categoriesActiveNum: 0,
};

export const currentCommunityDataSlice = createSlice({
	name: 'currentCommunityData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCurrentCommunityData.pending, state => {
				state.loading = true;
				state.currentCommunityData = null;
				state.currentCommunityCategories = null;
				state.errorCommunnityCategories = null;
				state.categoriesTotalNum = 0;
				state.categoriesActiveNum = 0;
			})
			.addCase(getCurrentCommunityData.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.currentCommunityData = payload;
			})
			.addCase(getCurrentCommunityData.rejected, (state, { payload }) => {
				state.loading = false;
				state.currentCommunityData = null;
				state.error = payload ?? null;
			})
			.addCase(updateCommunity.pending, state => {
				state.updating = true;
			})
			.addCase(updateCommunity.fulfilled, (state, { payload }) => {
				state.updating = false;
				state.currentCommunityData = payload;
			})
			.addCase(updateCommunity.rejected, (state, { payload }) => {
				state.updating = false;
				state.error = payload ?? null;
			})
			.addCase(getCommunityCategoriesData.pending, state => {
				state.loadingCommunityCategories = true;
				state.errorCommunnityCategories = null;
			})
			.addCase(getCommunityCategoriesData.fulfilled, (state, { payload, meta }) => {
				state.loadingCommunityCategories = false;

				const page = meta.arg.offset ?? 0;
				const data = payload.categoriesData.data;
				state.currentCommunityCategories = !state.currentCommunityCategories
					? { [page]: data }
					: { ...state.currentCommunityCategories, [page]: data };
				state.categoriesTotalNum = payload.categoriesData.itemsNum;
				state.categoriesActiveNum = payload.activeNum;
			})
			.addCase(getCommunityCategoriesData.rejected, (state, { payload }) => {
				state.loadingCommunityCategories = false;
				state.errorCommunnityCategories = payload ?? null;
			})
			.addCase(incrementCommunityCategoryMessageCount, (state, { payload }) => {
				if (!state.currentCommunityCategories) return;

				const categoryData = Object.values(state.currentCommunityCategories)
					.flat()
					.find(c => c.id === payload.categoryId);
				if (categoryData) {
					categoryData.aantalBerichten = (categoryData.aantalBerichten ?? 0) + 1;
				}
			})
			.addCase(createCommunityRolRelatie.pending, state => {
				state.errorUpdateCommunityRolRelatie = null;
			})
			.addCase(createCommunityRolRelatie.rejected, (state, { payload }) => {
				state.errorUpdateCommunityRolRelatie = payload ?? null;
			})
			.addCase(deleteCommunityRolRelatie.pending, state => {
				state.errorUpdateCommunityRolRelatie = null;
			})
			.addCase(deleteCommunityRolRelatie.rejected, (state, { payload }) => {
				state.errorUpdateCommunityRolRelatie = payload ?? null;
			});
	},
});

export const currentCommunityDataReducer = currentCommunityDataSlice.reducer;
