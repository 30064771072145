import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ChipArrayInput from '../../components/Form/ChipArrayInput/ChipArrayInput';
import styles from './Profile.module.scss';

type Value = Date | string | string[];

interface ProfileSectionProps {
	title: string;
	data: { label: string; value?: Value; isDate?: boolean }[];
}

const isDate = (value?: Value, isDate?: boolean): value is string => {
	return !!(isDate && value && typeof value === 'string');
};
const isArray = (value?: Value): value is string[] => {
	return !!value && Array.isArray(value);
};

const ProfileSection: FC<ProfileSectionProps> = ({ title, data }) => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<div className="zds-headline-xl">{t(title)}</div>
			<div className="zds-margin-3">
				{data.map(d => {
					const className = classNames({
						'zds-flex': true,
						'zds-padding-y-3': !isArray(d.value),
						[styles.minPadding]: isArray(d.value),
					});
					return (
						<div key={`${title}${d.label}`} className={className}>
							<p className={styles.label}>{t(d.label)}:</p>
							<div className="zds-flex-1">
								{isDate(d.value, d.isDate) &&
									new Date(d.value).toLocaleDateString(i18n.language, {
										month: '2-digit',
										day: '2-digit',
										year: 'numeric',
									})}
								{isArray(d.value) && <ChipArrayInput value={d.value.map(v => ({ key: v, label: v }))} />}
								{!isDate(d.value, d.isDate) && !isArray(d.value) && d.value?.toString()}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default ProfileSection;
