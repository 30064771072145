import { FC } from 'react';
import { ZdsButton } from '@zig-design-system/ui-components-react';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
	disabled?: boolean;
	dataTestId: string;
	formId: string;
}

const SaveButton: FC<SaveButtonProps> = ({ formId, disabled, dataTestId }) => {
	const { t } = useTranslation();

	return (
		<ZdsButton
			data-testid={dataTestId}
			disabled={disabled}
			onZdsClick={() => {
				(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
			}}
		>
			{t('global:common.save')}
		</ZdsButton>
	);
};

export default SaveButton;
