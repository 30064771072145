import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import SubheaderItemsCount from '../../../../global/components/DataTable/SubheaderItemsCount/SubheaderItemsCount';
import DataTable, { TableData } from '../../../../global/components/DataTable/DataTable';
import { IconOptions } from '../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';

export interface UnitsTableProps {
	tableData: TableData;
	onPageChange: (page: number) => void;
	pageSizes: number[];
	initPageSize?: number;
	itemsNum: number;
	onPageSizeChange?: (pageSize: number) => void;
	subheaderEndContent?: ReactNode;
	iconOptions?: IconOptions;
	showHeaderWithNoItems?: boolean;
	selectedIds?: string[];
	onSelectionChange?: () => void;
	onSearchChange?: (searchData: { [key: string]: string }) => void;
	tableTestId: string;
	loading?: boolean;
}

const UnitsTable: FC<UnitsTableProps> = ({
	tableData,
	onPageChange,
	pageSizes,
	initPageSize,
	onPageSizeChange,
	itemsNum,
	subheaderEndContent,
	iconOptions,
	showHeaderWithNoItems,
	selectedIds,
	onSearchChange,
	onSelectionChange,
	tableTestId,
	loading,
}) => {
	const { t } = useTranslation();

	return (
		<DataTable
			data={tableData}
			tableTestId={tableTestId}
			pageSizes={pageSizes}
			initPageSize={initPageSize}
			onPageSizeChange={onPageSizeChange}
			onPageChange={page => {
				window.scrollTo(0, 0);
				onPageChange(page);
			}}
			iconOptions={iconOptions}
			totalItemsNum={itemsNum}
			header={{
				subheaderEndContent,
				subheader: {
					Component: SubheaderItemsCount,
					props: {
						labelTotal: {
							singular: t('vastgoed:vastgoed.eenheid').toLowerCase(),
							plural: t('vastgoed:vastgoed.eenheden').toLowerCase(),
						},
					},
				},
			}}
			selectedIds={selectedIds}
			onSelectionChange={onSelectionChange}
			onSearchChange={onSearchChange}
			showHeaderWithNoItems={showHeaderWithNoItems}
			loading={loading}
		/>
	);
};

export default UnitsTable;
