import { Language } from '../../../apps/communitybeheer/api/models';

export const getDateFormat = (_?: string): string => {
	const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
	const formatter = new Intl.DateTimeFormat(Language.Dutch, options).formatToParts();

	return formatter
		.map(({ type, value }) => {
			switch (type) {
				case 'month':
					return 'MM';
				case 'day':
					return 'dd';
				case 'year':
					return 'yyyy';
				default:
					return value;
			}
		})
		.join('');
};
