import { FC, useEffect, useState } from 'react';

import API, { getBijlagen } from '../../api';
import { Community, Informatieobject } from '../../api/models';
import AdvancedSelectInput, {
	AdvancedSelectItem,
} from '../../../../global/components/AdvancedSelectInput/AdvancedSelectInput';

export const mapCommunityToAdvancedSelectItem = (
	community: Community,
	bijlagenData?: { id: string; bijlagen: Informatieobject[] }[]
): AdvancedSelectItem => {
	const communityBijlagen = bijlagenData?.find(b => b.id === community.id)?.bijlagen ?? community.bijlagen;

	const profilePicture = communityBijlagen?.filter(doc => doc.detailSoort?.code === 'PRO')[0];
	return { id: community.id, label: community.naam ?? '', secondaryText: 'Community', icon: profilePicture };
};

export interface CommunitiesAdvancedSelectProps {
	selectedValues?: AdvancedSelectItem[];
	onChange: (data: AdvancedSelectItem[]) => void;
	validationError?: string;
	explanationText?: string;
	placeholder?: string;
	iconType?: string;
	inputTextType?: string;
	multiple?: boolean;
	clearable?: boolean;
	showSelected?: boolean;
}

const CommunitiesAdvancedSelect: FC<CommunitiesAdvancedSelectProps> = ({
	selectedValues = [],
	onChange,
	validationError,
	explanationText,
	placeholder,
	iconType,
	inputTextType,
	multiple,
	clearable,
	showSelected,
}) => {
	const [communities, setCommunities] = useState<Community[] | null>(null);
	const [communityBijlagen, setCommunityBijlagen] = useState<{ id: string; bijlagen: Informatieobject[] }[]>();
	const [error, setError] = useState('');

	useEffect(() => {
		loadImages();
	}, [communities]);

	const loadImages = async () => {
		if (!communities) return;

		const newBijlagen = await Promise.all(
			communities.map(async c => {
				const bijlagen = await getBijlagen(c);
				return { id: c.id, bijlagen };
			})
		);

		setCommunityBijlagen(newBijlagen);
	};

	const searchCommunities = async (term: string) => {
		try {
			const result = await API.getCommunities({ term, offset: 0, limit: 10 });
			setCommunities(result.data);
		} catch {
			setError('global:form.errorMessage.get');
		}
	};

	return (
		<AdvancedSelectInput
			data={
				communities
					? communities.filter(c => c.naam).map(c => mapCommunityToAdvancedSelectItem(c, communityBijlagen))
					: null
			}
			selectedValues={selectedValues}
			searchData={searchCommunities}
			dataError={error}
			validationError={validationError}
			onChange={onChange}
			explanationText={explanationText}
			placeholder={placeholder}
			multiple={multiple}
			clearable={clearable}
			iconType={iconType}
			inputTextType={inputTextType}
			showSelected={showSelected}
		/>
	);
};

export default CommunitiesAdvancedSelect;
