import { createAsyncThunk } from '@reduxjs/toolkit';

import VastgoedAPI from '../../api';
import { Cluster } from '../../../communitybeheer/api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Eenheid } from '../../api/models';
import { ListResponse } from '../../../../global/api';

export const getCurrentClusterData = createAsyncThunk<Cluster, string, { rejectValue: string }>(
	'currentClusterData/getClusterData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await VastgoedAPI.getCluster(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const getClusterEenheden = createAsyncThunk<
	ListResponse<Eenheid>,
	{ clusterId: string; offset?: number; limit?: number; clear?: boolean; searchData: { [key: string]: string } },
	{ rejectValue: string }
>('vastgoed/getClusterEenheden', async ({ clusterId, searchData, offset, limit }, { rejectWithValue }) => {
	return VastgoedAPI.getEenheden({ clusterId, searchData, offset, limit }).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});
