import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import Form from '../../../../global/components/Form/Form';
import OrganisatieAPI from '../../api';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import SaveButton from '../../../../global/components/Form/SaveButton';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { useMedewerkerrolFormSchema } from '../../hooks/form/schema/useMedewerkerrolFormSchema';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { sideMenuInfo } from '../../routes';
import BackButton from '../../../../global/components/BackButton/BackButton';

const CreateMedewerkerrol: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasPermission = usePermissions();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const formSchema = useMedewerkerrolFormSchema();

	const handleSubmit = useCallback(async (data: FormSubmitData) => {
		if (!hasPermission('ORG_WIJ_ORG')) return;

		try {
			setLoading(true);
			setError('');

			const id = await OrganisatieAPI.createMedewerkerrol(data);
			navigate(`/organisatiebeheer/medewerkerrollen/${id}`);
		} catch (error) {
			setError('global:form.errorMessage.create');
		} finally {
			setLoading(false);
		}
	}, []);

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organisatie.medewerkerrollen'), link: '/organisatiebeheer/medewerkerrollen' },
		{ label: t('global:common.create') },
	];

	const saveButton = (
		<SaveButton dataTestId="createMedewerkerrolDataSubmitButton" formId="createMedewerkerrolForm" disabled={loading} />
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<div>
				<BackButton />
			</div>
			<PageHeader
				title={t('organisatie:organisatie.medewerkerrollen')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
				contentEnd={hasPermission('ORG_WIJ_ORG') && saveButton}
			/>
			<Pane className="zds-flex-1">
				<div className="zds-margin-left-8">
					{loading && <h3>{t('global:common.loading')}...</h3>}
					{!loading && formSchema && (
						<div data-testid="createMedewerkerrolForm">
							<Form schema={formSchema} onSubmit={handleSubmit} formId="createMedewerkerrolForm" />
						</div>
					)}
					{error && <ErrorMessage message={t(error)} />}
				</div>
			</Pane>
		</PageLayout>
	);
};

export default CreateMedewerkerrol;
