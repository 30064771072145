import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getMedewerkerrollen, selectMedewerkerrollen } from '../../redux/medewerkerrollen';
import MedewerkerrollenTable from '../../components/MedewerkerrollenTable/MedewerkerrollenTable';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import Tabs from '../../../../global/components/Tabs/Tabs';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { usePermissions } from '../../../../global/hooks/usePermissions/usePermissions';
import { sideMenuInfo } from '../../routes';
import styles from './Medewerkerrollen.module.scss';

export const PAGE_SIZE = 20;
export const PAGE_PARAM_NAME = 'page';

const getInitPage = (param: string | null) => {
	if (!param || isNaN(Number(param))) return 0;

	return Number(param);
};

const Medewerkerrollen: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasPermission = usePermissions();
	const [searchParams, setSearchParams] = useSearchParams();
	const { medewerkerrollen, itemsNum, loading, error } = useAppSelector(selectMedewerkerrollen);
	const [currentPage, setCurrentPage] = useState(getInitPage(searchParams.get(PAGE_PARAM_NAME)));
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => {
		handlePageChange(currentPage);
	}, []);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		updateSearchParams(newPage);
		handleGetMedewerkerrollen(newPage);
	};

	const handleGetMedewerkerrollen = (page: number) => {
		dispatch(getMedewerkerrollen({ offset: page, limit: PAGE_SIZE }));
	};

	const updateSearchParams = (newPage: number) => {
		if (newPage !== 0) {
			setSearchParams({ [PAGE_PARAM_NAME]: newPage.toString() });
			return;
		}
		searchParams.delete(PAGE_PARAM_NAME);
		setSearchParams(searchParams);
	};

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: (
				<>
					{medewerkerrollen && (
						<MedewerkerrollenTable
							medewerkerrollen={medewerkerrollen}
							onPageChange={handlePageChange}
							pageSize={PAGE_SIZE}
							itemsNum={itemsNum}
							currentPage={currentPage}
						/>
					)}
					{loading && <h3 data-testid="medewerkerrollenListLoading">{t('global:common.loading')}...</h3>}
					{error && <ErrorMessage message={t(error)} />}
				</>
			),
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organisatie.medewerkerrollen') },
	];

	const navigateToCommunityCreate = () => {
		navigate('/organisatiebeheer/medewerkerrollen/create');
	};

	const addNewMedewerkerrollenButton = (
		<ZdsButton
			data-testid="addNewMedewerkerrollenButton"
			className={styles.addNewButton}
			onZdsClick={navigateToCommunityCreate}
		>
			<ZdsIcon library="fa-regular" name="plus" size="xs" />
			{t('global:common.addNew')}
		</ZdsButton>
	);

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('organisatie:organisatie.medewerkerrollen')}
				iconName="message-lines"
				iconLibrary="fa-solid"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className="zds-flex-1 zds-relative">
				{selectedTab === 0 && hasPermission('ORG_WIJ_ORG') && addNewMedewerkerrollenButton}
				<Tabs tabsData={tabsData} onTabChange={setSelectedTab} />
			</Pane>
		</PageLayout>
	);
};

export default Medewerkerrollen;
