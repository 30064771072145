import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { clearError, getAuthData, selectAuthorizationData } from '../../redux/shared/authorizationData';
import Constants from '../../../auth.config';

const OAuthCallback: FC = () => {
	const dispatch = useAppDispatch();
	const { error, loading } = useAppSelector(selectAuthorizationData);

	const accessToken = localStorage.getItem('accessToken');

	useEffect(() => {
		if (!loading && !accessToken && !error) {
			dispatch(getAuthData(Constants.callbackUrl));
		}
	}, []);

	useEffect(() => {
		if (error) {
			dispatch(clearError());
		}
	}, [error]);

	return null;
};

export default OAuthCallback;
