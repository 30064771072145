import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router';
import {
	ZdsBadge,
	ZdsIcon,
	ZdsSideMenu,
	ZdsSideMenuItem,
	ZdsSideMenuSection,
} from '@zig-design-system/ui-components-react';
import { TFunction } from 'i18next';

import { useAppSelector } from '../../redux/store';
import { selectUser } from '../../redux/shared/authorizationData';
import { usePermissions } from '../../hooks/usePermissions/usePermissions';
import { Permission } from '../../api/models';

export interface SideMenuItem {
	title: string;
	link: string;
	iconName: string;
	notificationCount?: number;
	permissions?: Permission[];
}

export interface SideMenuSection {
	title?: string;
	items: SideMenuItem[];
}

export interface SideMenuInfo {
	sections: SideMenuSection[];
	linkBase: string;
}

interface SideMenuProps {
	info: SideMenuInfo;
}

const itemHasPermission = (item: SideMenuItem, hasPermission: (p: Permission) => boolean) =>
	!item.permissions || item.permissions.some(hasPermission);

const renderSection = (
	section: SideMenuSection,
	linkBase: string,
	location: Location,
	collapsed: boolean,
	t: TFunction,
	hasPermission: (p: Permission) => boolean
) => {
	const key = section.items.map(item => item.link).join(',');
	const collapsedStyle: CSSProperties = { visibility: 'hidden', height: 0 };

	if (!section.items.some(item => itemHasPermission(item, hasPermission))) return null;

	return (
		<ZdsSideMenuSection key={key}>
			{section.title && (
				<div slot="label" {...(collapsed && { style: collapsedStyle })}>
					{t(section.title)}
				</div>
			)}
			{section.items
				.filter(item => itemHasPermission(item, hasPermission))
				.map(item => renderItem(item, linkBase, location, t))}
		</ZdsSideMenuSection>
	);
};

const renderItem = (item: SideMenuItem, linkBase: string, location: Location, t: TFunction) => {
	const absoluteLink = item.link === '' ? linkBase : `${linkBase}/${item.link}`;
	const isActive = location.pathname === absoluteLink;

	return (
		<ZdsSideMenuItem key={item.link} slot="body" link={absoluteLink} active={isActive || undefined}>
			<ZdsIcon slot="icon" name={item.iconName} library="fa-light"></ZdsIcon>
			<div slot="title">{t(item.title)}</div>
			{item.notificationCount && (
				<ZdsBadge slot="badge" color="danger" pill>
					{item.notificationCount}
				</ZdsBadge>
			)}
		</ZdsSideMenuItem>
	);
};

const sideMenuCollapsedLSKey = 'sideMenuCollapsed';

const getInitCollapsed = (lsKey: string) => {
	const lsCollapsed = localStorage.getItem(lsKey);

	if (lsCollapsed) return lsCollapsed === 'true';

	const isWideScreen = window.innerWidth > 1720;
	localStorage.setItem(lsKey, String(!isWideScreen));

	return !isWideScreen;
};

const SideMenu = ({ info }: SideMenuProps) => {
	const location = useLocation();
	const { t } = useTranslation();
	const user = useAppSelector(selectUser);
	const fullSideMenuCollapsedLSKey = `${sideMenuCollapsedLSKey}-${user?.relatieId ?? ''}`;
	const [collapsed, setCollapsed] = useState(getInitCollapsed(fullSideMenuCollapsedLSKey));
	const hasPermission = usePermissions();

	const handleToggleCollapse = (collapse: boolean) => {
		setCollapsed(collapse);
		localStorage.setItem(fullSideMenuCollapsedLSKey, String(collapse));
	};

	return (
		<ZdsSideMenu
			collapsed={collapsed}
			onZdsSideMenuCollapse={() => handleToggleCollapse(true)}
			onZdsSideMenuExpand={() => handleToggleCollapse(false)}
		>
			{info.sections.map(section => renderSection(section, info.linkBase, location, collapsed, t, hasPermission))}
		</ZdsSideMenu>
	);
};

export default SideMenu;
