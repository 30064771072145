import { TFunction } from 'i18next';

import { TableData } from '../../../../global/components/DataTable/DataTable';
import { getRelatieNaam } from '../../../relatiebeheer/utils/getRelatieNaam';
import { RolRelatie } from '../../api/models';
import { getReferentiedataNaam } from '../../../../global/utils/translations/getReferentiedataNaam';

export const mapCommunityRelationsToTableData = (rolRelaties: RolRelatie[], t: TFunction): TableData => {
	const header = {
		labels: [t('global:domainShared.naam'), t('global:domainShared.rol')],
	};

	const body = {
		rows: rolRelaties.map(rol => {
			return {
				id: rol.id,
				cells: [
					{ mainText: getRelatieNaam(rol.relatie) },
					{
						mainText: rol.soort
							? getReferentiedataNaam(t, rol.soort.soort, rol.soort.code)
							: `<${t('community:communityInformationPage.noRoleDefined')}>`,
					},
				],
			};
		}),
	};

	return {
		header,
		body,
	};
};
