import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';
import { Subject, catchError, switchMap } from 'rxjs';

import { useAppSelector } from '../../../../../../global/redux/store';
import { selectCurrentClusterData } from '../../../../redux/currentClusterData';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { TableIconSettings } from '../../../../../../global/components/DataTable/DataTable';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import { useMapClusterEenhedenToTableData } from '../../../../hooks/table/useMapClusterEenhedenToTableData/useMapClusterEenhedenToTableData';
import ConfirmationModal from '../../../../../../global/components/ConfirmationModal/ConfirmationModal';
import ContextMenu from '../../../../../../global/components/ContextMenu/ContextMenu';
import VastgoedAPI from '../../../../api';
import UnitsTable from '../../../../components/UnitsTable/UnitsTable';
import { getErrorMessageForGetRequest } from '../../../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Eenheid } from '../../../../api/models';
import UnitsTableModal from '../../../../components/UnitsTableModal/UnitsTableModal';

export const PAGE_SIZES = [5, 25, 50];
export const INIT_PAGE_SIZE = PAGE_SIZES[2];

export enum ClusterEenhedenTableMode {
	VIEW,
	ADD,
}

export interface ClusterEenhedenProps {
	tableTestId: string;
	mode?: ClusterEenhedenTableMode;
	onSelectChange?: (num: number) => void;
	adding?: boolean;
	setAdding?: (v: boolean) => void;
	onError?: (error: string) => void;
}

const fetchDataSubject = new Subject<void>();

const ClusterEenheden: FC<ClusterEenhedenProps> = ({
	mode = ClusterEenhedenTableMode.VIEW,
	tableTestId,
	onSelectChange,
	adding,
	setAdding,
	onError,
}) => {
	const { currentClusterData } = useAppSelector(selectCurrentClusterData);
	const { t } = useTranslation();
	const hasPermission = usePermissions();
	const [eenheidIdToDelete, setEenheidIdToDelete] = useState<string | null>(null);
	const [actionError, setActionError] = useState('');
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [searchData, setSearchData] = useState<{ [key: string]: string }>({});
	const [selectedPageSize, setSelectedPageSize] = useState(INIT_PAGE_SIZE);
	const [eenheden, setEenheden] = useState<{
		[key: number]: Eenheid[];
	} | null>(null);
	const [totalNum, setTotalNum] = useState(0);
	const [loading, setLoading] = useState(false);
	const [getError, setGetError] = useState('');
	const [preselected, setPreselected] = useState<string[]>([]);
	const deleteModalRef = useRef<HTMLZdsModalElement | null>(null);
	const addModalRef = useRef<HTMLZdsModalElement | null>(null);
	const actionsButtonRef = useRef<HTMLZdsButtonElement>(null);
	const actionsButtonId = 'actionsButton';
	const canEdit = hasPermission('VAS_WIJ_CLU') && !currentClusterData?.idExtern;
	const errorMessage = getError ?? actionError;
	const finalSelectedIds = eenheidIdToDelete ? [eenheidIdToDelete] : selectedIds;
	const isViewMode = mode === ClusterEenhedenTableMode.VIEW;
	const isAddMode = mode === ClusterEenhedenTableMode.ADD;

	useEffect(() => {
		const subscription = fetchDataSubject
			.pipe(
				switchMap(() => {
					setLoading(true);
					setSelectedIds([]);
					setGetError('');

					return fetchEenheden();
				}),
				catchError((error: any): any => {
					setGetError(getErrorMessageForGetRequest(error));

					return { data: [], itemsNum: 0 };
				})
			)
			.subscribe((result: any) => {
				setEenheden({ [currentPage]: result.data });
				setTotalNum(result.itemsNum);
				setLoading(false);
			});

		return () => subscription.unsubscribe();
	}, [currentClusterData, currentPage, searchData, selectedPageSize]);

	useEffect(() => {
		fetchDataSubject.next();
	}, [currentClusterData, currentPage, searchData, selectedPageSize]);

	useEffect(() => {
		handlePageChange(0);
	}, [currentClusterData]);

	useEffect(() => {
		if (!isAddMode || !eenheden || !currentClusterData) return;

		setPreselected(
			Object.values(eenheden)
				.flat()
				.filter(e => e.clusters?.some(c => c.id === currentClusterData.id))
				.map(e => e.id)
		);
	}, [eenheden, isAddMode, currentClusterData]);

	useEffect(() => {
		onSelectChange?.(selectedIds.length);
	}, [selectedIds]);

	useEffect(() => {
		onError?.(actionError);
	}, [actionError]);

	useEffect(() => {
		if (adding) {
			clusterEeenhedenAction('ADD');
		}
	}, [adding]);

	const fetchEenheden = () => {
		return VastgoedAPI.getEenheden({
			clusterId: isViewMode ? currentClusterData?.id : undefined,
			searchData,
			offset: currentPage,
			limit: selectedPageSize,
		});
	};

	const handleModalSave = async () => {
		await clusterEeenhedenAction('DELETE');
		handleDeleteModalClose();
	};

	const handleDeleteModalClose = () => {
		setEenheidIdToDelete(null);
	};

	const handleDeleteModalShow = () => {
		deleteModalRef?.current?.show();
	};

	const handleEenheidDeleteIconClick = (id: string) => {
		setEenheidIdToDelete(id);
		handleDeleteModalShow();
	};

	const handlePageChange = (page: number) => {
		if (!currentClusterData || page < 0) return;
		setCurrentPage(page);
	};

	const handleSelectionChange = async () => {
		const selectedRowIds = (await (
			document.getElementById(`${tableTestId}-core`) as any
		)?.getSelectedRows()) as string[];
		setSelectedIds(selectedRowIds.filter(id => !preselected.includes(id)));
		setEenheidIdToDelete(null);
	};

	const handleSearchChange = (data: { [key: string]: string }) => {
		setSearchData(data);
	};

	const handlePageSizeChange = (size: number) => {
		setSelectedPageSize(size);
	};

	const handleAddSuccess = () => {
		fetchDataSubject.next();
	};

	const clusterEeenhedenAction = async (operation: 'ADD' | 'DELETE') => {
		if (!currentClusterData || !finalSelectedIds.length || !eenheden) return;

		const isAddOperation = operation === 'ADD';

		const errorEenheden: Eenheid[] = [];

		const selectedEenheden = Object.values(eenheden)
			.flat()
			.filter(e => finalSelectedIds.includes(e.id));

		for (const eenheid of selectedEenheden) {
			try {
				if (isAddOperation) {
					await VastgoedAPI.addEenheidToCluster(currentClusterData.id, eenheid);
				} else {
					await VastgoedAPI.deleteClusterEenheid(currentClusterData.id, eenheid.id);
				}
			} catch {
				errorEenheden.push(eenheid);
			}
		}

		if (isAddOperation) {
			setAdding?.(false);
		}

		if (errorEenheden.length === finalSelectedIds.length) {
			return setActionError(t(`global:form.errorMessage.${isAddOperation ? 'update' : 'delete'}`) ?? '');
		}

		fetchDataSubject.next();
		setActionError(getEenhedenNamesErrorMessage(errorEenheden, isAddMode));
	};

	const getEenhedenNamesErrorMessage = (errorEenheden: Eenheid[], isAddOperation: boolean) => {
		const errorEenhedenNamesString = errorEenheden
			.map(e => e.naam)
			.filter(Boolean)
			.join(', ');

		if (!errorEenhedenNamesString) return '';

		return (
			t(`vastgoed:clusterInformationPage.errorWhile${isAddOperation ? 'Adding' : 'Deleting'}Units`, {
				units: errorEenhedenNamesString,
			}) ?? ''
		);
	};

	const tableData = useMapClusterEenhedenToTableData(eenheden, canEdit, preselected);

	const subheaderEndContent =
		isViewMode && canEdit ? (
			<div className="zds-flex zds-gap-2 zds-items-center" slot="header-end">
				{selectedIds.length > 0 && (
					<>
						<div className="zds-headline-sm zds-bold zds-margin-right-2">
							{t(`vastgoed:clustersPage.table.actionLabel.${selectedIds.length > 1 ? 'plural' : 'singular'}`, {
								num: selectedIds.length,
							})}
						</div>
						<ZdsButton preset="neutral" variant="outlined" id={actionsButtonId} ref={actionsButtonRef}>
							{t('global:common.action')}
							<ZdsIcon library="fa-regular" name="chevron-down" size="xs" />
						</ZdsButton>
						<ContextMenu
							target={`#${actionsButtonId}`}
							targetRef={actionsButtonRef}
							className="zds-flex"
							position="end"
						>
							<ZdsItem onZdsClick={handleDeleteModalShow}>
								<div slot="primary-text">{t('global:common.delete')}</div>
							</ZdsItem>
						</ContextMenu>
					</>
				)}
				<ZdsButton
					data-testid="addNewClusterEenheidButton"
					onZdsClick={() => {
						addModalRef?.current?.show();
					}}
				>
					<ZdsIcon library="fa-regular" name="plus" size="xs" />
					{t('global:common.addNew')}
				</ZdsButton>
			</div>
		) : null;

	return (
		<div className="zds-flex zds-flex-col zds-flex-1 zds-justify-content-between">
			<div>
				{currentClusterData && (
					<div className="zds-margin-top-4">
						{tableData && (
							<UnitsTable
								tableData={tableData}
								pageSizes={PAGE_SIZES}
								initPageSize={INIT_PAGE_SIZE}
								onPageSizeChange={handlePageSizeChange}
								onPageChange={page => {
									window.scrollTo(0, 0);
									handlePageChange(page);
								}}
								iconOptions={
									isViewMode && canEdit
										? {
												...TableIconSettings.delete,
												onIconClick: handleEenheidDeleteIconClick,
										  }
										: undefined
								}
								itemsNum={totalNum}
								subheaderEndContent={subheaderEndContent}
								selectedIds={selectedIds}
								onSelectionChange={handleSelectionChange}
								onSearchChange={handleSearchChange}
								showHeaderWithNoItems
								tableTestId={tableTestId}
								loading={loading}
							/>
						)}
					</div>
				)}
			</div>
			<div>{errorMessage && <ErrorMessage message={t(errorMessage)} />}</div>
			{isViewMode && (
				<ConfirmationModal
					ref={deleteModalRef}
					title={t(
						`vastgoed:clusterInformationPage.deleteEenheidQuestion.${
							finalSelectedIds.length > 1 ? 'plural' : 'singular'
						}`
					)}
					onSave={handleModalSave}
					onClose={handleDeleteModalClose}
					isDelete
				/>
			)}
			{isViewMode && <UnitsTableModal ref={addModalRef} onAddSuccess={handleAddSuccess} />}
		</div>
	);
};

export default ClusterEenheden;
