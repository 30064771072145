import PageHeader from '../../../global/components/PageHeader/PageHeader';
import { SideMenuInfo } from '../../../global/components/SideMenu/SideMenu';
import ContentPane from '../components/ContentPane/ContentPane';
import DemoZdsPage from '../components/DemoZdsPage/DemoZdsPage';

const sideMenuInfo: SideMenuInfo = {
	linkBase: '/demo-zds/notify',
	sections: [
		{
			items: [
				{
					title: 'Home',
					link: '',
					iconName: 'house',
				},
			],
		},
	],
};

const DemoNotify = () => {
	return (
		<DemoZdsPage sideMenuInfo={sideMenuInfo}>
			<PageHeader title="Notify" iconName="bells" iconLibrary="fa-regular" />
			<ContentPane>
				<div className="zds-headline-sm">Status</div>
				<p>PARTIALLY EXISTING (now integral part of KVS)</p>

				<div className="zds-headline-sm">Actors</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Description</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Required services</div>
				<p>TBA</p>

				<div className="zds-headline-sm">User stories</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Future development plans</div>
				<p>TBA</p>
			</ContentPane>
		</DemoZdsPage>
	);
};

export default DemoNotify;
