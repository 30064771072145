import React from 'react';
import { ZdsIcon, ZdsButton } from '@zig-design-system/ui-components-react';
import {
	ApiNieuwsberichtInitieel,
	ApiPrikbordBerichtInitieel,
	EmotieButton,
	EmotieButtonOptions,
	IBericht,
	Reageeroptie,
	Relatie,
} from '@zig.community/community-components';
import { useTranslation } from 'react-i18next';

import { useBerichtPermissions } from '../../hooks/useBerichtPermissions/useBerichtPermissions';

type BerichtFooterProps = {
	content: IBericht<ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel>;
	onComment?: (berichtId: IBericht['id']) => void;
	currentRelatie: Relatie;
	emotieButtonOptions: EmotieButtonOptions;
};

export const BerichtFooter = ({ content, onComment, emotieButtonOptions, currentRelatie }: BerichtFooterProps) => {
	const { t } = useTranslation();
	const hasPermission = useBerichtPermissions();
	const canCreate = hasPermission('CREATE');

	return (
		<div className="zds-flex zds-justify-content-between zds-space-1">
			<EmotieButton bericht={content} currentRelatie={currentRelatie} {...emotieButtonOptions} />
			{canCreate && content.reageeroptie.code !== Reageeroptie.EMO && (
				<ZdsButton
					onZdsClick={() => !!onComment && onComment(content.id)}
					preset="neutral"
					variant="text"
					overhang="horizontal"
				>
					<ZdsIcon size="sm" name="message" library="fa-regular" />
					{t('reactions.comment')}
				</ZdsButton>
			)}
		</div>
	);
};
