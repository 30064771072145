import { FC } from 'react';
import { ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';

import styles from './AppItem.module.scss';

interface IconWithBackgroundProps {
	name: string;
	library: HTMLZdsIconElement['library'];
	color: string;
}

const IconWithBackground: FC<IconWithBackgroundProps> = ({ name, library, color }) => {
	const cssBackgroundColor = `var(--zds-color-${color})`;

	return (
		<div className={styles.iconWithBackground} style={{ backgroundColor: cssBackgroundColor }}>
			<ZdsIcon name={name} library={library} />
		</div>
	);
};

interface AppItemProps {
	title: string;
	subtitle?: string;
	link: string;
	iconName: string;
	iconColor: string;
	contentEnd?: boolean;
}

const AppItem: FC<AppItemProps> = ({ title, subtitle, link, iconName, iconColor, contentEnd = true }) => {
	return (
		<ZdsItem link={link} className={styles.appItem}>
			<div slot="content-start">
				<IconWithBackground name={iconName} library="fa-regular" color={iconColor}></IconWithBackground>
			</div>
			<div slot="primary-text" className={styles.appItemTitle}>
				{title}
			</div>
			<div slot="secondary-text" className={styles.appItemSubtitle}>
				{subtitle}
			</div>
			{contentEnd && (
				<div slot="content-end">
					<ZdsIcon name="chevron-right" library="fa-regular" size="xs" />
				</div>
			)}
		</ZdsItem>
	);
};

export default AppItem;
