import { RootState } from '../../../../global/redux/store';
import { CommunitiesState } from '.';
import { Community } from '../../api/models';

export const selectCommunities = (state: RootState): CommunitiesState => state.communities.communities;
export const selectCommunitiesList = (state: RootState): Community[] | null => {
	const communitiesData = state.communities.communities.communities;

	if (!communitiesData) return null;

	const communities = Object.values(communitiesData).flat();

	return communities.length ? communities : null;
};
