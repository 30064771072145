import { useTranslation } from 'react-i18next';
import Appbar from '../../global/components/Appbar/Appbar';

import { WrapperComponent } from '../../routes';
import { useAppPermissions } from '../../global/hooks/useAppPermissions/useAppPermissions';
import NotFound from '../../global/components/NotFound/NotFound';
import styles from './CommunityManagementAppWrapper.module.scss';

const CommunityManagementAppWrapper: WrapperComponent = ({ children }) => {
	const { t } = useTranslation('global');
	const hasAppPermissions = useAppPermissions();

	const content = !hasAppPermissions('COM') ? <NotFound /> : children;

	return (
		<div className={styles.root}>
			<Appbar tempRelativeAppSwitcherPath="/communitybeheer" appName={t('apps.communityManagement')} />
			<div className={styles.content}>{content}</div>
		</div>
	);
};

export default CommunityManagementAppWrapper;
