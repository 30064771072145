import { createSelector } from '@reduxjs/toolkit';
import { RelatieNatuurlijkPersoon, RelatieSoort } from '@zig.community/community-components';

import { RootState } from '../../../../global/redux/store';
import { Userinfo } from '../../../api/models';
import { AuthorizationDataSate } from './types';

export const selectAuthorizationData = (state: RootState): AuthorizationDataSate => state.authorization;

export const selectUser = (state: RootState): Userinfo | null => state.authorization.user;

export const selectRelatieId = (state: RootState): string | undefined => state.authorization.user?.relatieId;

export const selectUserRelatie = createSelector([selectUser], user => {
	return {
		id: user?.relatieId,
		naam: user?.naam ?? '',
		voornaam: user?.voornaam ?? '',
		achternaam: user?.achternaam ?? '',
		soort: { code: RelatieSoort.NATUURLIJK_PERSOON },
		medewerker: user?.medewerker,
	} as RelatieNatuurlijkPersoon;
});
