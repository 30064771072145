import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableSubheaderProps } from '../../../../../global/components/DataTable/DataTable.types';

const CommunitiesTableSubheader: FC<DataTableSubheaderProps> = ({ totalItemsNum, labelTotal }) => {
	const { t } = useTranslation();

	if (!labelTotal) return null;

	return (
		<div className="zds-headline-md">
			{t(labelTotal[totalItemsNum === 1 ? 'singular' : 'plural'], { num: totalItemsNum })}
		</div>
	);
};

export default CommunitiesTableSubheader;
