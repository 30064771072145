import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ZdsButton } from '@zig-design-system/ui-components-react';

import PageHeader from '../../../../global/components/PageHeader/PageHeader';
import Pane from '../../../../global/components/Pane/Pane';
import { useAppDispatch, useAppSelector } from '../../../../global/redux/store';
import { getOrganisatiesData, selectOrganisatieData } from '../../redux/organisatieData';
import OrganizationsTable from '../../components/OrganizationsTable/OrganizationsTable';
import Tabs from '../../../../global/components/Tabs/Tabs';
import PageLayout from '../../../../global/components/PageLayout/PageLayout';
import ErrorMessage from '../../../../global/components/ErrorMessage/ErrorMessage';
import { useOrganisatieSideMenu } from '../../hooks/useOrganisatieSideMenu';
import { useOrganisatiePermissions } from '../../hooks/useOrganisatiePermissions';
import Search from '../../../../global/components/Search/Search';

export const PAGE_SIZE = 20;

const Organizations: FC = () => {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const hasPermission = useOrganisatiePermissions();
	const { organisatieData, loading, error, itemsNum } = useAppSelector(selectOrganisatieData);
	const sideMenuInfo = useOrganisatieSideMenu();
	const [term, setTerm] = useState(searchParams.get('search') ?? undefined);

	useEffect(() => {
		handleGetOrganisaties(0, true);
	}, [term]);

	const handleGetOrganisaties = (page: number, clear?: boolean) => {
		dispatch(getOrganisatiesData({ term, offset: page, limit: PAGE_SIZE, clear }));
	};

	const OverviewContent = (
		<>
			{loading && <h3 data-testid="organisatieListLoading">{t('global:common.loading')}...</h3>}
			{organisatieData && (
				<OrganizationsTable
					organisatieData={organisatieData}
					onPageChange={handleGetOrganisaties}
					pageSize={PAGE_SIZE}
					itemsNum={itemsNum}
				/>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</>
	);

	const tabsData = [
		{
			name: t('global:common.overview'),
			content: OverviewContent,
		},
	];

	const breadcrumbs = [
		{ label: t('global:apps.organizationManagement'), link: '/organisatiebeheer' },
		{ label: t('organisatie:organizationsPage.header.title') },
	];

	return (
		<PageLayout sideMenuInfo={sideMenuInfo}>
			<PageHeader
				title={t('organisatie:organizationsPage.header.title')}
				iconName="user"
				iconLibrary="fa-regular"
				breadcrumbs={breadcrumbs}
			/>
			<Pane className="zds-flex">
				<Search
					onSearch={term => {
						setTerm(term.trim());
					}}
				/>
				<div className="zds-flex zds-justify-content-end">
					{hasPermission('ORG_LEZ_MED') && (
						<ZdsButton id="add-organisatie-button" link="/organisatiebeheer/organisatie/create">
							+ {t('organisatie:organizationsPage.header.addNew')}
						</ZdsButton>
					)}
				</div>
			</Pane>
			<Pane className="zds-flex-1">
				<Tabs tabsData={tabsData} />
			</Pane>
		</PageLayout>
	);
};

export default Organizations;
