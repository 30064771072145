import PageHeader from '../../../global/components/PageHeader/PageHeader';
import { SideMenuInfo } from '../../../global/components/SideMenu/SideMenu';
import ContentPane from '../components/ContentPane/ContentPane';
import DemoZdsPage from '../components/DemoZdsPage/DemoZdsPage';

const sideMenuInfo: SideMenuInfo = {
	linkBase: '/demo-zds/account',
	sections: [
		{
			items: [
				{
					title: 'My profile',
					link: '',
					iconName: 'user',
				},
				{
					title: 'My preferences',
					link: '#',
					iconName: 'sliders',
				},
			],
		},
	],
};

const DemoAccount = () => {
	return (
		<DemoZdsPage sideMenuInfo={sideMenuInfo}>
			<PageHeader title="Account" iconName="message-lines" iconLibrary="fa-regular" />
			<ContentPane>
				<div className="zds-headline-sm">Status</div>
				<p>NEW</p>

				<div className="zds-headline-sm">Actors</div>
				<p>All users</p>

				<div className="zds-headline-sm">Description</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Required services</div>
				<p>TBA</p>

				<div className="zds-headline-sm">User stories</div>
				<p>TBA</p>

				<div className="zds-headline-sm">Future development plans</div>
				<p>TBA</p>
			</ContentPane>
		</DemoZdsPage>
	);
};

export default DemoAccount;
