import { FormData, FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { useAppSelector } from '../../../../../../../global/redux/store';
import { selectCurrentCommunityCategoryData } from '../../../../../redux/currentCommunityCategoryData';
import { useCommunityCategoryCreateFormSchema } from './useCommunityCategoryCreateFormSchema';

interface CommunityCategoryUpdateFormSchemaOptions {
	data: FormData | null;
	language: string;
	selectedFields?: string[];
}

export const useCommunityCategoryUpdateFormSchema = ({
	data,
	language,
	selectedFields,
}: CommunityCategoryUpdateFormSchemaOptions): FormSchema | null => {
	const { currentCommunityCategoryData } = useAppSelector(selectCurrentCommunityCategoryData);

	const { naam, soort, omschrijving, toelichting, begindatum, einddatum, vertalingen } =
		currentCommunityCategoryData ?? {};

	const initData = {
		naam,
		soort: soort as any,
		omschrijving,
		toelichting,
		begindatum: begindatum as unknown as string,
		einddatum: einddatum as unknown as string,
		vertalingen,
	};

	const formSchema = useCommunityCategoryCreateFormSchema({
		data,
		initData,
		language,
		selectedFields,
	});

	return formSchema;
};
