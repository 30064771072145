import { RouteInfo } from '../../routes';
import HomePage from './pages/HomePage/HomePage';
import Organizations from './pages/Organizations/Organizations';
import Preferences from '../../global/pages/Preferences/Preferences';
import { SideMenuInfo } from '../../global/components/SideMenu/SideMenu';
import OrganizationInformation from './pages/OrganizationInformation/OrganizationInformation';
import Profile from '../../global/pages/Profile/Profile';
import Medewerkers from './pages/Medewerkers/Medewerkers';
import CreateOrganization from './pages/CreateOrganization/CreateOrganization';
import CreateMedewerker from './pages/CreateMedewerker/CreateMedewerker';
import Medewerkerrollen from './pages/Medewerkerrollen/Medewerkerrollen';
import MedewerkerrolInformation from './pages/MedewerkerrolInformation/MedewerkerrolInformation';
import MedewerkerInformation from './pages/MedewerkerInformation/MedewerkerInformation';
import CreateMedewerkerrol from './pages/CreateMedewerkerrol/CreateMedewerkerrol';

export const sideMenuInfo: SideMenuInfo = {
	linkBase: '/organisatiebeheer',
	sections: [
		{
			title: 'global:common.management',
			items: [
				{
					title: 'organisatie:organisatie.organisatie',
					link: 'organisatie',
					iconName: 'house',
				},
				{
					title: 'organisatie:organisatie.organisatorischeEenheden',
					link: 'organisatorischeeenheden',
					iconName: 'object-group',
				},
				{
					title: 'organisatie:organisatie.medewerkers',
					link: 'medewerkers',
					iconName: 'people-roof',
				},
			],
		},
		{
			title: 'global:common.settings',
			items: [
				{
					title: 'organisatie:organisatie.medewerkerrollen',
					link: 'medewerkerrollen',
					iconName: 'sliders-up',
					permissions: ['ORG_WIJ_ORG'],
				},
			],
		},
	],
};

const routes: RouteInfo[] = [
	{
		path: 'organisatie',
		subRoutes: [
			{
				path: '',
				Component: () => <Organizations />,
			},
			{
				path: 'create',
				Component: () => <CreateOrganization />,
				permissions: ['ORG_LEZ_MED'],
			},
			{
				path: ':id',
				Component: () => <OrganizationInformation />,
			},
		],
	},
	{
		path: 'organisatorischeeenheden',
		Component: () => <HomePage />, // TBD
	},
	{
		path: 'medewerkers',
		subRoutes: [
			{
				path: '',
				Component: () => <Medewerkers />,
			},
			{
				path: 'create',
				Component: () => <CreateMedewerker />,
			},
			{
				path: ':id',
				Component: () => <MedewerkerInformation />,
			},
		],
	},
	{
		path: 'medewerkerrollen',
		subRoutes: [
			{
				path: '',
				Component: () => <Medewerkerrollen />,
				permissions: ['ORG_WIJ_ORG'],
			},
			{
				path: 'create',
				Component: () => <CreateMedewerkerrol />,
				permissions: ['ORG_WIJ_ORG'],
			},
			{
				path: ':id',
				Component: () => <MedewerkerrolInformation />,
				permissions: ['ORG_WIJ_ORG'],
			},
		],
	},
	{
		path: 'persoonlijkeinstellingen',
		Component: () => <Preferences sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: 'profiel',
		Component: () => <Profile sideMenuInfo={sideMenuInfo} />,
	},
	{
		path: '',
		redirect: '/organisatiebeheer/organisatie',
		Component: () => null,
	},
];

export default routes;
