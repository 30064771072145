import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../../global/components/Form/Form';
import { FormSchema, FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { selectCurrentRelatieData, updateRelatie } from '../../../../redux/currentRelatieData';
import { generateUpdateRelatieFormSchema } from '../../../../utils/form/schema/update';
import styles from './RelationOverview.module.scss';

const RelationOverview: FC = () => {
	const { currentRelatieData, loading, updating, error } = useAppSelector(selectCurrentRelatieData);
	const dispatch = useAppDispatch();
	const { t, i18n } = useTranslation();
	const [formSchema, setFormSchema] = useState<FormSchema | null>(null);

	useEffect(() => {
		setFormSchema(currentRelatieData ? generateUpdateRelatieFormSchema(currentRelatieData, t, i18n.language) : null);
	}, [currentRelatieData]);

	const handleOnSubmit = (data: FormSubmitData) => {
		if (currentRelatieData?.id && !updating) {
			const updateData = {
				id: currentRelatieData.id,
				body: data,
			};
			void dispatch(updateRelatie(updateData));
		}
	};

	return (
		<div className={styles.container}>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{!loading && formSchema && (
				<div data-testid="relatieForm" className="zds-margin-left-8">
					<Form schema={formSchema} onSubmit={handleOnSubmit} formId="relatieForm" />
				</div>
			)}
			{error && <ErrorMessage message={t(error)} />}
		</div>
	);
};

export default RelationOverview;
