import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { i18n as i18nCommunityComponents } from '@zig.community/community-components';

import { DEFAULT_LANGUAGE } from './config';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: DEFAULT_LANGUAGE,
		debug: false,
		ns: ['global', 'referentiedata', 'relatie', 'organisatie', 'community', 'vastgoed'],
		defaultNS: 'global',
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json`,
			requestOptions: {
				cache: 'no-store',
			},
		},
	});

i18n.on('languageChanged', lng => {
	i18nCommunityComponents.changeLanguage(lng);
});

export default i18n;
