import SideMenu, { SideMenuInfo } from '../../../../global/components/SideMenu/SideMenu';
import styles from './DemoZdsPage.module.scss';

interface DemoZdsPageProps {
	sideMenuInfo?: SideMenuInfo;
	children: React.ReactNode;
}

const DemoZdsPage = ({ sideMenuInfo, children }: DemoZdsPageProps) => {
	return (
		<div className={styles.mainRow}>
			{sideMenuInfo && <SideMenu info={sideMenuInfo} />}
			<div className={styles.page}>
				<div className={styles.mainColumn}>{children}</div>
			</div>
		</div>
	);
};

export default DemoZdsPage;
