import { createSlice } from '@reduxjs/toolkit';

import { getEenheden, EenhedenDataState } from '.';
import { getUpdatedTableStateData } from '../../../../global/utils/table/getUpdatedTableStateData';

const initialState: EenhedenDataState = {
	eenheden: null,
	loading: false,
	error: null,
	itemsNum: 0,
};

export const realtieDataSlice = createSlice({
	name: 'eenhedenData',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getEenheden.pending, (state, { meta }) => {
				state.loading = true;
				state.error = null;
				if (meta.arg.clear) {
					state.eenheden = null;
					state.itemsNum = 0;
				}
			})
			.addCase(getEenheden.fulfilled, (state, { payload, meta }) => {
				state.loading = false;
				state.itemsNum = payload.itemsNum;
				state.eenheden = getUpdatedTableStateData(payload, meta.arg.offset ?? 0, state.eenheden);
			})
			.addCase(getEenheden.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const eenhedenDataReducer = realtieDataSlice.reducer;
