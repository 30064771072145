import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { FormControl, InputLayoutOptions, isTextArea } from '../Form.types';
import styles from './InputWrapper.module.scss';

export interface InputWrapperProps {
	label?: string;
	formControl?: FormControl;
	fullInputWidth?: boolean;
	noLabelWidth?: boolean;
	children: ReactNode;
	labelPosition?: InputLayoutOptions['labelPosition'];
	labelWidth?: 'normal' | 'large';
	labelAligh?: InputLayoutOptions['labelAlign'];
}

const InputWrapper: FC<InputWrapperProps> = ({
	label,
	formControl,
	fullInputWidth,
	noLabelWidth,
	children,
	labelPosition = 'side',
	labelWidth = 'normal',
	labelAligh = 'center',
}) => {
	const labelClasses = classNames(styles.label, {
		[styles['label__alignStart']]: formControl && (isTextArea(formControl.control) || labelAligh === 'start'),
		[styles['label--noWidth']]: noLabelWidth,
		[styles['label--large']]: labelWidth === 'large',
	});

	const inputClasses = classNames(styles.formControl, {
		[styles['formControl--fullWidth']]: fullInputWidth,
	});

	const wrapperClasses = classNames(styles.formControlWrapper, {
		[styles['formControlWrapper__column']]: labelPosition === 'top',
	});

	return (
		<div className={wrapperClasses}>
			{(label || labelPosition === 'side') && <span className={labelClasses}>{label}</span>}
			<div className={inputClasses}>{children}</div>
		</div>
	);
};

export default InputWrapper;
