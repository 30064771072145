import { Feature } from '../../api/models';
import { selectUser } from '../../redux/shared/authorizationData';
import { useAppSelector } from '../../redux/store';

export const useFeatures = () => {
	const user = useAppSelector(selectUser);

	return (feature: Feature): boolean => {
		return !!user?.features?.[feature];
	};
};
