import axios from 'axios';

export const getErrorMessageForGetRequest = (error: any) => {
	const status = axios.isAxiosError(error) ? error.response?.status : null;

	if (!status) {
		return (error as Error)?.message;
	}

	if (status === 404) {
		return 'global:form.errorMessage.missingData';
	}

	return 'global:form.errorMessage.get';
};
