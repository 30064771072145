import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsDivider } from '@zig-design-system/ui-components-react';
import {
	ApiNieuwsberichtInitieel,
	ApiPrikbordBerichtInitieel,
	CommunityType,
	IBericht,
} from '@zig.community/community-components';

import { Bericht as BerichtComponent } from '../../ZigCommunity/Bericht';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import { useAppSelector } from '../../../../../global/redux/store';
import { UploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import { Bericht, Community, Informatieobject } from '../../../api/models';
import { Referentiedata, Userinfo } from '../../../../../global/api/models';
import { Relatie } from '../../../../relatiebeheer/api/models';
import { selectUser } from '../../../../../global/redux/shared/authorizationData';
import styles from './MessagePreview.module.scss';

export interface MessagePreviewProps {
	fileData: UploadFileData[];
	relatie?: Relatie;
	onderwerp?: string;
	beschrijving?: string;
	reageeroptie: string;
	pinBerichtTotDatum?: string;
}

const MessagePreview: FC<MessagePreviewProps> = ({
	fileData,
	relatie,
	reageeroptie,
	onderwerp,
	beschrijving,
	pinBerichtTotDatum,
}) => {
	const { t } = useTranslation();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const user = useAppSelector(selectUser);
	const [bericht, setBericht] = useState<IBericht<ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel> | null>(null);
	const [bijlagen, setBijlagen] = useState<Informatieobject[]>([]);

	useEffect(() => {
		if (currentCommunityData) {
			setBericht(
				getPreviewBericht({
					community: currentCommunityData,
					relatie,
					onderwerp,
					beschrijving,
					reageeroptie,
					pinBerichtTotDatum,
					user,
				}) as unknown as IBericht<ApiNieuwsberichtInitieel | ApiPrikbordBerichtInitieel>
			);
		}
	}, [currentCommunityData, relatie, reageeroptie, onderwerp, beschrijving, pinBerichtTotDatum, fileData]);

	useEffect(() => {
		setBijlagen([]);
		setTimeout(() => {
			setBijlagen(
				fileData.map(fd => ({ bestand: fd.base64, soort: { code: 'FOT' } })) as unknown as Informatieobject[]
			);
		}, 0);
	}, [fileData]);

	if (!bericht) return null;

	return (
		<div className={styles.previewContainer}>
			<div className="zds-headline-lg zds-padding-4">
				{t('community:communityInformationPage.messages.createNewsMessage.preview.title')}
			</div>
			<ZdsDivider className={styles.divider} />
			<div className={styles.messageContainer}>
				<BerichtComponent
					content={{ ...bericht, bijlagen: bijlagen as any }}
					community={currentCommunityData as unknown as CommunityType}
					isPreview
				/>
			</div>
			<div className="zds-padding-4">
				<div className={styles.explanatoryBox}>
					{t('community:communityInformationPage.messages.createNewsMessage.preview.explanatoryText')}
				</div>
			</div>
		</div>
	);
};

const getPreviewBericht = ({
	community,
	relatie,
	onderwerp,
	beschrijving = '',
	reageeroptie,
	pinBerichtTotDatum,
	user,
}: {
	community: Community;
	relatie?: Relatie;
	onderwerp?: string;
	beschrijving?: string;
	reageeroptie: string;
	pinBerichtTotDatum?: string;
	bijlagen?: Informatieobject[];
	user: Userinfo | null;
}): Bericht | null => {
	if (!relatie) return null;

	const today = new Date().toISOString() as any;

	return {
		id: '',
		code: '',
		soort: {
			code: 'NIE',
		} as Referentiedata,
		detailSoort: {
			code: 'INI',
		} as Referentiedata,
		status: {
			code: 'PUB',
		} as Referentiedata,
		reageeroptie: { code: reageeroptie } as Referentiedata,
		bijlagen: [],
		community,
		onderwerp,
		beschrijving,
		rolRelaties: [
			{
				id: '',
				soort: {
					code: 'AUT',
				} as Referentiedata,
				relatie,
			},
		],
		aanmaakdatum: today,
		pinBerichtTotDatum: pinBerichtTotDatum ?? (null as any),
		aantalOngepast: 0,
		emoties: [
			{
				id: '',
				soort: {
					code: 'EMO',
				} as Referentiedata,
				detailSoort: {
					code: 'LEU',
				} as Referentiedata,
				relatie: { ...relatie, id: '' },
				bericht: {} as any,
			},
		],
		reacties: [
			{
				id: '',
				community,
				soort: {
					code: 'PRI',
				} as Referentiedata,
				detailSoort: {
					code: 'REA',
				} as Referentiedata,
				status: {
					code: 'PUB',
				} as Referentiedata,
				beschrijving: '',
				bijlagen: [],
				emoties: [],
				onderwerp: '',
				publicatiedatum: today,
				reageeroptie: {
					code: 'EMO',
				} as Referentiedata,
				rolRelaties: [
					{
						id: '',
						soort: {
							code: 'AUT',
						} as Referentiedata,
						begindatum: today,
						relatie: { id: user?.relatieId },
					},
				],
				aantalOngepast: 0,
			},
		],
	};
};

export default MessagePreview;
