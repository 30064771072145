import { FC } from 'react';
import classNames from 'classnames';
import { ZdsButton, ZdsIcon } from '@zig-design-system/ui-components-react';

import { fileSize } from '../../../utils/fileSize/fileSize';
import styles from './FileItem.module.scss';

export interface FileTypeIconProps {
	isFileTypeError: boolean;
	className: string;
}

const FileTypeIcon: FC<FileTypeIconProps> = ({ isFileTypeError, className }) => (
	<ZdsIcon
		className={className}
		library={isFileTypeError ? 'fa-regular' : 'fa-solid'}
		name={isFileTypeError ? 'circle-exclamation' : 'file-image'}
		size="lg"
	/>
);

const FileInfo: FC<{
	name: string;
	size: number;
	isFileTypeError: boolean;
	errorMessage?: string | null;
}> = ({ name, size, isFileTypeError, errorMessage }) => (
	<div className={styles.fileInfo}>
		<div className={styles.name}>{name}</div>
		<div className={styles.size}>{fileSize(size)}</div>
		{isFileTypeError && <div className={styles.errorMessage}>{errorMessage}</div>}
	</div>
);

export interface FileItemProps {
	file: File | null;
	error: string | null;
	base64: string | null;
	onRemove: () => void;
}

const FileItem: FC<FileItemProps> = ({ file, error, base64, onRemove }) => {
	if (!file) return null;

	const { name, size } = file;

	const fileTypeIconClassName = classNames({ [styles.error]: !!error });
	const fileItemClassName = classNames({ [styles.fileItem]: true, [styles.error]: !!error });

	return (
		<div className={fileItemClassName}>
			<div className={styles.mainContent}>
				{base64 ? (
					<div className={styles.type}>
						{error ? (
							<FileTypeIcon isFileTypeError={!!error} className={fileTypeIconClassName} />
						) : (
							<img src={base64} alt={name} className={styles.thumbnail} />
						)}
					</div>
				) : (
					<div className={styles.type}>
						<FileTypeIcon isFileTypeError={!!error} className={fileTypeIconClassName} />
					</div>
				)}
				<FileInfo name={name} size={size} isFileTypeError={!!error} errorMessage={error} />
			</div>
			<div className={styles.remove}>
				<ZdsButton preset="muted" variant="text" aspect-square onZdsClick={() => onRemove()}>
					<ZdsIcon name="delete" />
				</ZdsButton>
			</div>
		</div>
	);
};

export default FileItem;
