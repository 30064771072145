import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsIcon, ZdsItem } from '@zig-design-system/ui-components-react';
import { IBericht } from '@zig.community/community-components';

import { useAppDispatch } from '../../../../../global/redux/store';
import { deleteBericht, deleteInappropriateReports } from '../../../redux/berichten';
import OptionMenu from '../../../../../global/components/OptionMenu/OptionMenu';
import ConfirmationModal from '../../../../../global/components/ConfirmationModal/ConfirmationModal';
import { Bericht } from '../../../api/models';
import { BerichtMenuOptions, useBerichtMenuOptions } from '../../../hooks/useBerichtMenuOptions/useBerichtMenuOptions';
import styles from './MessageMenu.module.scss';

interface MessageMenuProps {
	bericht: IBericht;
	isMenuVisible: boolean;
	setIsMenuVisible: (value: boolean) => void;
	onDelete?: () => void;
	onEdit?: () => void;
	options?: BerichtMenuOptions;
}

const MessageMenu: FC<MessageMenuProps> = ({
	bericht,
	isMenuVisible,
	setIsMenuVisible,
	onDelete,
	onEdit,
	options = { edit: true, delete: true, deleteInappropriteContentReports: true },
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { showEdit, showDelete, showDeleteInappropriateReport } = useBerichtMenuOptions(
		bericht as unknown as Bericht,
		options
	);
	const deleteModalRef = useRef<HTMLZdsModalElement | null>(null);
	const inappropriateModalRef = useRef<HTMLZdsModalElement | null>(null);

	const berichtId = bericht.id as unknown as string;
	const numberOfInnapropriateReports = useMemo(() => {
		return bericht.emoties.filter(e => e.soort.code === 'ONG').length;
	}, [bericht]);

	const hasMenuItems = showEdit || showDelete || showDeleteInappropriateReport;

	return (
		<>
			<OptionMenu
				id={berichtId}
				isVisible={isMenuVisible && !!hasMenuItems}
				setIsVisible={setIsMenuVisible}
				className={styles.messageMenuPopout}
			>
				<div className={styles.menuHeader}>
					<ZdsButton preset="muted" variant="text" aspectSquare>
						<ZdsIcon name="close" size="sm"></ZdsIcon>
					</ZdsButton>
				</div>
				<div className={styles.menuContent}>
					<div className={styles.title}>{t('community:communityInformationPage.messages.messageOptionsTitle')}</div>
					<div className={styles.optionsContainer}>
						{showEdit && (
							<ZdsItem onZdsClick={onEdit}>
								<div slot="primary-text">{t('community:communityInformationPage.messages.editMessage')}</div>
								<ZdsIcon slot="content-start" size="sm" name="pen" library="fa-regular" />
							</ZdsItem>
						)}
						{showDeleteInappropriateReport && (
							<ZdsItem onZdsClick={() => inappropriateModalRef?.current?.show()}>
								<div slot="primary-text">
									{t('community:communityInformationPage.messages.deleteInappropriateReport')}
								</div>
								<ZdsIcon slot="content-start" size="sm" name="face-angry" library="fa-regular" />
							</ZdsItem>
						)}
						{showDelete && (
							<ZdsItem onZdsClick={() => deleteModalRef?.current?.show()} className="zds-color-danger">
								<div slot="primary-text">{t('global:common.delete')}</div>
								<ZdsIcon slot="content-start" size="sm" name="trash-can" library="fa-regular" />
							</ZdsItem>
						)}
					</div>
				</div>
			</OptionMenu>
			<ConfirmationModal
				ref={deleteModalRef}
				title={t('community:communityInformationPage.messages.deleteMessageQuestion')}
				onSave={() => {
					dispatch(deleteBericht({ berichtId }));
					onDelete?.();
				}}
				isDelete
			></ConfirmationModal>
			<ConfirmationModal
				ref={inappropriateModalRef}
				title={
					numberOfInnapropriateReports === 1
						? t('community:communityInformationPage.messages.confirmDeleteInappropriateReportForOne')
						: t('community:communityInformationPage.messages.confirmDeleteInappropriateReportForMany')
				}
				onSave={() => {
					dispatch(deleteInappropriateReports({ berichtId }));
				}}
				isDelete
			></ConfirmationModal>
		</>
	);
};

export default MessageMenu;
