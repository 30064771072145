import { usePermissions } from '../../../global/hooks/usePermissions/usePermissions';
import { Permission } from '../../../global/api/models';

export const useOrganisatiePermissions = () => {
	const hasPermission = usePermissions();

	return (permission: Permission): boolean => {
		return hasPermission(permission);
	};
};
