import { ComponentType, FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ZdsDataTable,
	ZdsDataTableCore,
	ZdsDataTableHeaderCell,
	ZdsDataTableHeaderRow,
	ZdsPagination,
	ZdsSelect,
} from '@zig-design-system/ui-components-react';
import { ZdsPaginationCustomEvent, ZdsSelectCustomEvent } from '@zig-design-system/ui-components';

import DataTableContentRow, { IconOptions, TableRow } from './DataTableContentRow/DataTableContentRow';

export const TableIconSettings: {
	[key: string]: {
		name: IconOptions['name'];
		library: IconOptions['library'];
		preset: IconOptions['preset'];
		variant: IconOptions['variant'];
		size: IconOptions['size'];
	};
} = {
	delete: { name: 'trash', library: 'fa-solid', preset: 'muted', variant: 'text', size: 'sm' },
};

export type SortableOption = boolean | 'asc' | 'desc';

export interface TableHeaderData {
	labels: string[];
	columnIds?: (string | null)[];
	sortable?: SortableOption[];
	width?: (number | null)[];
}

export type SimpleTableRows = TableRow[];
export type PaginatedTableRows = { [key: number]: TableRow[] };

export const isSimpleTableRows = (rows?: TableBodyData['rows']): rows is SimpleTableRows => {
	return !!rows && Array.isArray(rows);
};

export const isPaginatedTableRows = (rows?: TableBodyData['rows']): rows is PaginatedTableRows => {
	return !!rows && !Array.isArray(rows);
};

export interface TableBodyData {
	rows: PaginatedTableRows | SimpleTableRows;
}

export interface TableData {
	header: TableHeaderData;
	body: TableBodyData;
}

export interface DataTableProps {
	data: TableData | null;
	onRowClick?: (id: string) => void;
	tableTestId: string;
	iconOptions?: IconOptions;
	pageSizes?: number[];
	onPageSizeChange?: (pageSize: number) => void;
	initPageSize?: number;
	showHeaderWithNoItems?: boolean;
	onPageChange?: (page: number) => void;
	header?: {
		title?: string;
		subheader?: { Component: ComponentType<any>; props: any };
		endContent?: ReactNode;
		subheaderEndContent?: ReactNode;
	};
	selectedIds?: string[];
	noTableHeader?: boolean;
	totalItemsNum?: number;
	onSelectionChange?: () => void;
	onSearchChange?: (searchData: { [key: string]: string }) => void;
	onSortChange?: (sortData: { columnId: string; sort: SortableOption }) => void;
	initPage?: number;
	onCheckedChange?: (id: string, checked: boolean) => void;
	loading?: boolean;
}

const getInitSelectedPageSize = (pageSizes?: number[], initPageSize?: number) => {
	if (initPageSize && pageSizes?.includes(initPageSize)) return initPageSize;
	return pageSizes?.[0] ? pageSizes[0] : 0;
};

const DataTable: FC<DataTableProps> = ({
	data,
	onRowClick,
	tableTestId,
	iconOptions,
	pageSizes,
	initPageSize,
	onPageSizeChange,
	onPageChange,
	header,
	selectedIds,
	noTableHeader,
	totalItemsNum = 0,
	onSelectionChange,
	onSearchChange,
	onSortChange,
	showHeaderWithNoItems,
	initPage = 0,
	onCheckedChange,
	loading = false,
}) => {
	const { t } = useTranslation();
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(initPage);
	const [tableRows, setTableRows] = useState<TableRow[]>();
	const [selectedPageSize, setSelectedPageSize] = useState(getInitSelectedPageSize(pageSizes, initPageSize));

	const rows = data?.body.rows;
	const finalTotalItemsNum = isSimpleTableRows(rows) ? rows.length : totalItemsNum;

	useEffect(() => {
		if (!data) return;

		if (selectedPageSize) {
			const newTotalPages = Math.ceil(finalTotalItemsNum / selectedPageSize);
			if (currentPage >= newTotalPages) {
				changePage(newTotalPages ? newTotalPages - 1 : 0);
			}
			setTotalPages(newTotalPages);
		}
	}, [data, currentPage, selectedPageSize, totalItemsNum]);

	useEffect(() => {
		if (!data) return;

		const rows = data?.body.rows;
		if (isPaginatedTableRows(rows)) {
			const newRows = rows[currentPage];
			if (newRows) {
				setTableRows(newRows);
			}
		}
		if (isSimpleTableRows(rows)) {
			if (!selectedPageSize) return setTableRows(rows);
			const firstPageIndex = currentPage * selectedPageSize;
			const lastPageIndex = firstPageIndex + selectedPageSize;
			return setTableRows(rows.slice(firstPageIndex, lastPageIndex));
		}
	}, [data, currentPage, selectedPageSize]);

	const addColumnSearch = () => {
		data?.header.columnIds?.forEach((columnId, i) => {
			if (!columnId || tableRows?.some(row => row.cells[i].checkbox)) return;

			if (tableRows?.length) {
				document
					.querySelector(`[data-testid="${tableTestId}"] [column-id="${columnId}"]`)
					?.setAttribute('column-search', 'true');
			}
		});
	};

	useEffect(() => {
		if (tableRows) {
			setTimeout(() => {
				addColumnSearch();
			}, 300);
		}
	}, [tableRows]);

	const handlePageChange = (event: ZdsPaginationCustomEvent<{ page: number }>) => {
		changePage(event.detail.page - 1);
	};

	const handlePageSizeChange = (event: ZdsSelectCustomEvent<string>) => {
		const newPageSize = +event.target.value;
		if (selectedPageSize === newPageSize) return;

		setSelectedPageSize(newPageSize);
		changePage(0);
		onPageSizeChange?.(newPageSize);
	};

	const changePage = (page: number) => {
		if (currentPage === page) return;

		setCurrentPage(page);
		onPageChange?.(page);
	};

	const SubheaderComponent = header?.subheader?.Component;
	const subheaderComponentProps = header?.subheader?.props;

	if (!data || !tableRows) return null;

	if (!finalTotalItemsNum && !header) {
		return <h3 className="zds-padding-bottom-6">{t('global:form.errorMessage.missingData')}</h3>;
	}

	return (
		<ZdsDataTable data-testid={tableTestId}>
			{header && (
				<>
					{header.title && (
						<div slot="header-start">
							<div className="zds-headline-xl">{header.title}</div>
						</div>
					)}
					{header.endContent && (
						<div slot="header-end">
							<div className="zds-headline-xl">{header.endContent}</div>
						</div>
					)}
				</>
			)}
			{SubheaderComponent && (
				<div slot="subheader-start">
					<SubheaderComponent
						{...subheaderComponentProps}
						totalItemsNum={finalTotalItemsNum}
						tableRowsVisible={tableRows?.length ?? 0}
					/>
				</div>
			)}
			{header?.subheaderEndContent && <div slot="subheader-end">{header?.subheaderEndContent}</div>}
			<ZdsDataTableCore
				slot="core"
				id={`${tableTestId}-core`}
				onZdsSelectionChange={onSelectionChange}
				onZdsSearchChange={e => onSearchChange?.(e.detail?.searchState)}
				onZdsSortChange={e => onSortChange?.(e.detail)}
			>
				{!noTableHeader && (
					<ZdsDataTableHeaderRow slot="header">
						{(showHeaderWithNoItems || finalTotalItemsNum > 0) && (
							<>
								{data.header.labels.map((label, i) => {
									const width = data.header.width?.[i];
									const columnId = data.header.columnIds?.[i];
									const sortable = data.header.sortable?.[i] || false;

									return (
										<ZdsDataTableHeaderCell
											key={label}
											{...(width && { style: { width: `${width}px` } })}
											{...(columnId && { columnId })}
											sortable={sortable}
										>
											{label}
										</ZdsDataTableHeaderCell>
									);
								})}
							</>
						)}
					</ZdsDataTableHeaderRow>
				)}
				{!loading &&
					tableRows?.map(row => (
						<DataTableContentRow
							key={row.id}
							row={row}
							onRowClick={onRowClick}
							header={data.header}
							iconOptions={iconOptions}
							isSelected={selectedIds?.includes(row.id) || row.isPreselected}
							isSelectable={row.isSelectable}
							onChange={checked => onCheckedChange?.(row.id, checked)}
						/>
					))}
			</ZdsDataTableCore>

			<div className="zds-flex zds-items-center zds-gap-8" slot="footer-start">
				{loading && <h3>{t('global:common.loading')}...</h3>}
				{!loading && pageSizes && pageSizes.length > 1 && (
					<div className="zds-flex zds-items-center zds-gap-3">
						<div className="zds-muted">{t('global:table.rowsPerPage')}:</div>
						<ZdsSelect value={selectedPageSize.toString()} onZdsInputDidUpdate={handlePageSizeChange}>
							{pageSizes.map(size => (
								<option key={size} value={size}>
									{size}
								</option>
							))}
						</ZdsSelect>
					</div>
				)}
			</div>
			<div slot="footer-end">
				{totalPages > 1 && (
					<ZdsPagination onZdsPageChange={handlePageChange} pages={totalPages} page={currentPage + 1} />
				)}
			</div>
		</ZdsDataTable>
	);
};

export default DataTable;
