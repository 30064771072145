import { FC } from 'react';
import { ZdsAccordion, ZdsAccordionGroup, ZdsIcon } from '@zig-design-system/ui-components-react';
import { useTranslation } from 'react-i18next';
import {
	CommunityCategorieAccordionHeader,
	CommunityCategorieAccordionItem,
} from '@zig.community/community-components';

import { useAppSelector } from '../../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import { CommunityCategorie } from '../../../api/models';

export interface SelectCategoryProps {
	onSelectedCategory: (categorie: CommunityCategorie) => void;
}

export const SelectCategory: FC<SelectCategoryProps> = ({ onSelectedCategory }) => {
	const { t } = useTranslation();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);

	if (!currentCommunityData) return null;

	return (
		<ZdsAccordionGroup>
			<ZdsAccordion key={currentCommunityData.id} open={true} className="zds-padding-x-8">
				<div slot="header">
					<CommunityCategorieAccordionHeader community={currentCommunityData as any} />
				</div>
				<div slot="body">
					<div className="zds-overline">{t('newPost.chooseTopic')}</div>
					{currentCommunityData.categorieen?.map(categorie => (
						<CommunityCategorieAccordionItem
							key={categorie.id}
							categorie={{ ...categorie, rolRelaties: [] } as any}
							onClick={() => onSelectedCategory(categorie)}
							contentEnd={<ZdsIcon name="arrow_forward" size="md" />}
						/>
					))}
				</div>
			</ZdsAccordion>
		</ZdsAccordionGroup>
	);
};
