import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsInputText, ZdsSelect, ZdsSwitch } from '@zig-design-system/ui-components-react';

import { FormData, FormSchema } from '../../../../../../../global/components/Form/Form.types';
import { getStringTranslation } from '../../../../../../../global/utils/getStringTranslation';
import { useAppSelector } from '../../../../../../../global/redux/store';
import { selectUser } from '../../../../../../../global/redux/shared/authorizationData';
import { getReferentiedataSelectOptions } from '../../../../../../../global/utils/form/getReferentiedataSelectOptions';
import { UploadFileData } from '../../../../../../../global/hooks/useUploadFileData/useUploadFileData';
import TextArea from '../../../../../../../global/components/Form/TextArea/TextArea';
import { Relatie } from '../../../../../../relatiebeheer/api/models';
import { Bericht } from '../../../../../api/models';
import { getLocalDateTimeString } from '../../../../../../../global/utils/date/getLocalDateTimeString';
import { useInputLanguageDataValue } from '../../../../useInputLanguageDataValue/useInputLanguageDataValue';

interface NieuwsberichtFormSchemaOptions {
	requiredMessage: boolean;
	showPinBerichtTotDatumInput: boolean;
	setFileData: (dat: UploadFileData) => void;
	uploadDisabled: boolean;
	relatieOptions: Relatie[];
	language: string;
	data: FormData | null;
	initMessage?: Bericht | null;
}

export const getRelatieOptionLabel = (relatie: any): string => {
	const omschrijving = relatie?.medewerker?.omschrijving ? ` (${relatie?.medewerker?.omschrijving})` : '';

	return `${relatie?.naam}${omschrijving}`;
};

export const useNieuwsberichtFormSchema = ({
	requiredMessage,
	showPinBerichtTotDatumInput,
	setFileData,
	uploadDisabled,
	relatieOptions,
	data,
	language,
	initMessage,
}: NieuwsberichtFormSchemaOptions): FormSchema | null => {
	const { t } = useTranslation();
	const user = useAppSelector(selectUser);
	const [initialized, setInitialized] = useState(!initMessage);

	const [schema, setSchema] = useState<FormSchema | null>(null);

	const switchLabel = t('community:communityInformationPage.messages.createNewsMessage.pushNotifications.switchLabel');

	const initMessageRelatieId = initMessage?.rolRelaties?.find(r => r.soort.code === 'AUT')?.relatie.id;
	const initPinDatum = initMessage?.pinBerichtTotDatum
		? getLocalDateTimeString(new Date(initMessage.pinBerichtTotDatum).toISOString())
		: '';
	const initPinBerichtTotDatum = initMessage?.pinBerichtTotDatum ? 'T' : 'F';
	const relatieSelectOptions = relatieOptions.map(r => ({ label: getRelatieOptionLabel(r), value: r.id ?? '' }));

	const relatieValue = initialized ? (data?.relatie.value as string) ?? user?.relatieId : initMessageRelatieId;
	const reageeroptieValue = initialized
		? (data?.reageeroptie.value as string) ?? 'OPE'
		: initMessage?.reageeroptie?.code;
	const pinValue = initialized ? (data?.pin.value as string) ?? 'F' : initPinBerichtTotDatum;
	const pinBerichtTotDatumValue = initialized
		? (data?.pin.value === 'T' && (data?.pinBerichtTotDatum.value as string)) || ''
		: initPinDatum;
	const verstuurPushNotificatieValue = initialized
		? !!data?.verstuurPushNotificatie.value
		: !!initMessage?.verstuurPushNotificatie;

	const getInputLanguageData = useInputLanguageDataValue({
		formData: data,
		vertalingen: initMessage?.vertalingen,
		initialized,
	});

	useEffect(() => {
		const newSchema: FormSchema = {
			formControls: [
				{
					name: 'relatie',
					submitObject: value => ({
						rolRelaties: [
							{
								soort: {
									code: 'AUT',
								},
								relatie: {
									id: value,
								},
							},
						],
					}),
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.sender'
							),
							value: relatieValue,
							...(!!initMessage && { disabled: true }),
						},
						options: relatieSelectOptions,
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'soort',
					submitPath: 'soort.code',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.messageTemplate'
							),
							value: 'NIE',
						},
						options: [
							{
								label: getStringTranslation(
									t,
									'community:communityInformationPage.messages.createNewsMessage.form.newsMessage'
								),
								value: 'NIE',
							},
						],
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'onderwerp',
					control: {
						type: ZdsInputText,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.title'
							),
							placeholder: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.titlePlaceholder'
							),
							languageData: getInputLanguageData('onderwerp'),
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'beschrijving',
					control: {
						type: TextArea,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.timelineMessage'
							),
							placeholder: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.messagePlaceholder'
							),
							setFileData,
							uploadDisabled,
							required: requiredMessage,
							languageData: getInputLanguageData('beschrijving'),
						},
					},
					layoutOptions: { fullWidth: true, labelPosition: 'top' },
				},
				{
					name: 'reageeroptie',
					submitPath: 'reageeroptie.code',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.socialInteractions'
							),
							value: reageeroptieValue,
						},
						options: getReferentiedataSelectOptions({
							t,
							soort: 'BERICHTREACTIEOPTIE',
							code: 'OPE',
						}),
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'pin',
					control: {
						type: ZdsSelect,
						props: {
							label: getStringTranslation(
								t,
								'community:communityInformationPage.messages.createNewsMessage.form.pinOnTopOfTimeline'
							),
							value: pinValue,
						},
						options: [
							{
								label: getStringTranslation(
									t,
									'community:communityInformationPage.messages.createNewsMessage.form.dontPin'
								),
								value: 'F',
							},
							{
								label: getStringTranslation(
									t,
									'community:communityInformationPage.messages.createNewsMessage.form.pinUntil'
								),
								value: 'T',
							},
						],
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'pinBerichtTotDatum',
					control: {
						type: ZdsInputText,
						props: {
							type: 'datetime-local',
							required: true,
							value: pinBerichtTotDatumValue,
						},
					},
					layoutOptions: { fullWidth: true },
				},
				{
					name: 'verstuurPushNotificatie',
					control: {
						type: ZdsSwitch,
						props: {
							name: 'verstuurPushNotificatie',
							value: verstuurPushNotificatieValue as any,
							children: switchLabel,
							label: switchLabel,
							checked: verstuurPushNotificatieValue,
						},
					},
					layoutOptions: { fullWidth: true },
				},
			],
			noSubmit: ['pin'],
		};

		if (!showPinBerichtTotDatumInput) {
			newSchema.noSubmit?.push('pinBerichtTotDatum');
		}

		if (initMessage) {
			newSchema.noSubmit?.push('relatie', 'soort');
		}

		setSchema(newSchema);
		setInitialized(true);
	}, [requiredMessage, showPinBerichtTotDatumInput, uploadDisabled, language, initMessage]);

	return schema;
};
