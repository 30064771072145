import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsBadge } from '@zig-design-system/ui-components-react';

import { getReferentiedataNaam } from '../../../../global/utils/translations/getReferentiedataNaam';

export interface StatusBadgeProps {
	status: string;
}

const STATUS_COLOR: { [key: string]: string } = {
	ACT: 'success',
	INA: 'danger',
	SLA: 'accent-01',
};

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
	const { t } = useTranslation();

	const soort = status === 'CON' ? 'BERICHTSTATUS' : 'RELATIESTATUS';
	const statusLabel = getReferentiedataNaam(t, soort, status);

	return <ZdsBadge color={STATUS_COLOR[status] || 'info'}>{statusLabel}</ZdsBadge>;
};

export default StatusBadge;
