import { createAsyncThunk } from '@reduxjs/toolkit';
import RelatieAPI from '../../api';
import { NatuurlijkPersoonInfo } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getNatuurlijkPersoonRelaties = createAsyncThunk<
	NatuurlijkPersoonInfo[],
	{ term?: string; offset?: number; limit?: number },
	{ rejectValue: string }
>('relaties/getRelaties', async ({ term, offset, limit } = {}, { rejectWithValue }) => {
	return RelatieAPI.getNatuurlijkPersoonRelaties(term, offset, limit).catch(error =>
		rejectWithValue(getErrorMessageForGetRequest(error))
	);
});
