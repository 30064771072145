import api from '../../../axiosConfig';
import { ListResponse, getListResponse } from '../../../global/api';
import { FormSubmitData } from '../../../global/components/Form/Form.types';
import { getSearchFilterData } from '../../../global/utils/api/getSearchFilterData';
import { FilterMap } from '../../../global/utils/api/parseFilters.types';
import { Cluster } from '../../communitybeheer/api/models';
import { Eenheid, Overeenkomsten } from './models';

export const Endpoints = {
	eenheden: 'bvg/v1/eenheden',
	clusters: 'bvg/v1/clusters',
	infClusters: 'vastgoed/v1/clusters',
	overeenkomsten: 'bog/v1/overeenkomsten',
};

const EenhedenFilterMap: FilterMap = {
	'adres.straatnaam': 'contains',
	'adres.huisnummer': 'compare',
	'adres.huisnummerToevoeging': 'eq',
	'adres.postcode': 'contains',
	'adres.woonplaats': 'contains',
	'soort.code': 'contains',
	etage: 'compare',
	'status.code': 'contains',
};

const getEenheden = async ({
	clusterId,
	searchData = {},
	offset = 0,
	limit = 100,
}: {
	clusterId?: string;
	offset?: number;
	limit?: number;
	searchData?: { [key: string]: string };
}): Promise<ListResponse<Eenheid>> => {
	const searchFilterData = getSearchFilterData({ searchData, filterMap: EenhedenFilterMap });

	const filterCluster = clusterId ? `clusters[id] eq ${clusterId}` : null;

	const finalFilter = [filterCluster, ...searchFilterData].filter(Boolean).join(' and ');

	const response = await api.get<Eenheid[]>(Endpoints.eenheden, {
		params: {
			$filter: finalFilter,
			pagina: offset,
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getClusters = async ({
	term,
	offset = 0,
	limit = 100,
	$fields,
	expand,
	infDomain,
}: {
	term?: string;
	offset?: number;
	limit?: number;
	$fields?: string[];
	expand?: string;
	infDomain?: boolean;
}): Promise<ListResponse<Cluster>> => {
	const filterClusterNaam = term ? `naam contains ${term}` : '';
	const response = await api.get<Cluster[]>(infDomain ? Endpoints.infClusters : Endpoints.clusters, {
		params: {
			pagina: offset,
			...(filterClusterNaam && { $filter: filterClusterNaam }),
			...($fields && { $fields }),
			...(typeof expand === 'string' && { expand }),
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getCluster = async (id: string): Promise<Cluster> => {
	const response = await api.get<Cluster>(`${Endpoints.clusters}/${id}`);

	return response.data;
};

const updateCluster = async (id: string, body: FormSubmitData): Promise<Cluster> => {
	const response = await api.patch<Cluster>(`${Endpoints.clusters}/${id}`, body);

	return response.data;
};

const deleteClusterEenheid = async (clusterId: string, eenheidId: string): Promise<void> => {
	await api.delete<void>(`${Endpoints.clusters}/${clusterId}/eenheden/${eenheidId}`);
};

const createCluster = async (body: Cluster): Promise<Cluster> => {
	const response = await api.post<Cluster>(`${Endpoints.clusters}`, body);
	return response.data;
};

const addEenheidToCluster = async (clusterId: string, eenheid: Eenheid): Promise<Eenheid> => {
	const body = { clusters: [...(eenheid.clusters?.map(c => ({ id: c.id })) ?? []), { id: clusterId }] };
	const response = await api.patch<Eenheid>(`${Endpoints.eenheden}/${eenheid.id}`, body);

	return response.data;
};

const getOvereenkomsten = async ({
	offset = 0,
	limit = 100,
}: {
	offset?: number;
	limit?: number;
}): Promise<ListResponse<Overeenkomsten>> => {
	const response = await api.get<Overeenkomsten[]>(Endpoints.overeenkomsten, {
		params: {
			pagina: offset,
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

export default {
	getEenheden,
	getClusters,
	getCluster,
	updateCluster,
	deleteClusterEenheid,
	createCluster,
	addEenheidToCluster,
	getOvereenkomsten,
};
