import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import CommunityAPI, { UpdateCommunityPayload } from '../../api';
import { Community, CommunityCategorie, RolRelatiePayload } from '../../api/models';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { getErrorMessageForDeleteRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForDeleteRequest';
import { ListResponse } from '../../../../global/api';

export const getCurrentCommunityData = createAsyncThunk<Community, string, { rejectValue: string }>(
	'currentCommunityData/getCommunityData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await CommunityAPI.getCommunity(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateCommunity = createAsyncThunk<
	Community,
	{ id: string; payload: UpdateCommunityPayload },
	{ rejectValue: string }
>('currentCommunityData/updateCommunity', async ({ id, payload }, { rejectWithValue }) => {
	try {
		await CommunityAPI.updateCommunity(id, payload);
		const response = await CommunityAPI.getCommunity(id);

		return response;
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});

export const getCommunityCategoriesData = createAsyncThunk<
	{ categoriesData: ListResponse<CommunityCategorie>; activeNum: number },
	{ communityId: string; offset?: number; limit?: number },
	{ rejectValue: string }
>('currentCommunityData/getCommunityCategoriesData', async ({ communityId, offset, limit }, { rejectWithValue }) => {
	try {
		const categoriesData = await CommunityAPI.getCommunityCategories(communityId, offset, limit);

		const activeNum = (await CommunityAPI.getNumberOfActiveCommunityCategories(communityId)).aantalCategorieenActief;

		return { categoriesData, activeNum };
	} catch (error) {
		return rejectWithValue(getErrorMessageForGetRequest(error));
	}
});

export const createCommunityRolRelatie = createAsyncThunk<
	void,
	{ id: string; body: RolRelatiePayload },
	{ rejectValue: string }
>('currentCommunityData/createCommunityRolRelatie', async ({ id, body }, { rejectWithValue, dispatch }) => {
	try {
		await CommunityAPI.createCommunityRolRelatie(id, body);
		dispatch(getCurrentCommunityData(id));
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.create');
	}
});

export const deleteCommunityRolRelatie = createAsyncThunk<
	void,
	{ communityId: string; rolRelatieId: string },
	{ rejectValue: string }
>(
	'currentCommunityData/deleteCommunityRolRelatie',
	async ({ communityId, rolRelatieId }, { rejectWithValue, dispatch }) => {
		try {
			await CommunityAPI.deleteCommunityRolRelatie(communityId, rolRelatieId);
			dispatch(getCurrentCommunityData(communityId));
		} catch (error) {
			return rejectWithValue(getErrorMessageForDeleteRequest(error, 'community'));
		}
	}
);

export const incrementCommunityCategoryMessageCount = createAction(
	'currentCommunityData/incrementCommunityCategoryMessageCount',
	(categoryId: string) => {
		return {
			payload: {
				categoryId,
			},
		};
	}
);
