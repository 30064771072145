import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableSubheaderProps } from '../DataTable.types';

const SubheaderItemsCount: FC<DataTableSubheaderProps> = ({ totalItemsNum, tableRowsVisible, labelTotal }) => {
	const { t } = useTranslation();

	return (
		<div className="zds-flex zds-items-center zds-gap-3">
			{labelTotal && (
				<div className="zds-headline-md zds-bold">{`${totalItemsNum} ${
					labelTotal[totalItemsNum === 1 ? 'singular' : 'plural']
				}`}</div>
			)}
			<div className="zds-headline-md zds-bold zds-muted">{`${tableRowsVisible} ${t(
				'global:common.visible'
			).toLowerCase()}`}</div>
		</div>
	);
};

export default SubheaderItemsCount;
