import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { Organisatie } from '../../api/models';
import { FormSubmitData } from '../../../../global/components/Form/Form.types';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';

export const getCurrentOrganisatieData = createAsyncThunk<Organisatie, string, { rejectValue: string }>(
	'currentOrganisatieData/getOrganisatieData',
	async (id, { rejectWithValue }) => {
		try {
			const response = await OrganisatieAPI.getOrganisatie(id);

			if (!response) {
				throw new Error('global:form.errorMessage.missingData');
			}

			return response;
		} catch (error) {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		}
	}
);

export const updateOrganisatie = createAsyncThunk<
	Organisatie,
	{ id: string; body: FormSubmitData },
	{ rejectValue: string }
>('currentOrganisatieData/updateOrganisatie', async ({ id, body }, { rejectWithValue }) => {
	try {
		await OrganisatieAPI.updateOrganisatie(id, body);
		const response = await OrganisatieAPI.getOrganisatie(id);

		return response;
	} catch (error) {
		return rejectWithValue('global:form.errorMessage.update');
	}
});
