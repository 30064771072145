import api from '../../../axiosConfig';
import { Contactgegeven, Relatie } from './models';
import { FormSubmitData } from '../../../global/components/Form/Form.types';
import { ListResponse, getListResponse } from '../../../global/api';

export const Endpoints = {
	relaties: 'relaties/v1/relaties',
	contactgegevens: 'relaties/v1/contactgegevens',
};

const getRelaties = async (term?: string, offset = 0, limit = 20): Promise<ListResponse<Relatie>> => {
	const response = await api.get<Relatie[]>(Endpoints.relaties, {
		params: {
			naam: term,
			pagina: offset,
			expand: 'contactgegevens',
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getRelatie = async (id: string): Promise<Relatie> => {
	const response = await api.get<Relatie>(`${Endpoints.relaties}/${id}`);

	return response.data;
};

const updateRelatie = async (id: string, body: FormSubmitData): Promise<Relatie> => {
	const response = await api.patch<Relatie>(`${Endpoints.relaties}/${id}`, body);

	return response.data;
};

const createRelatie = async (body: FormSubmitData): Promise<string> => {
	const response = await api.post<{ id: string }>(Endpoints.relaties, body);

	return response.data.id;
};

const createContactgegeven = async (body: FormSubmitData): Promise<string> => {
	const response = await api.post<{ id: string }>(Endpoints.contactgegevens, body);

	return response.data.id;
};

const updateContactgegeven = async (id: string, body: FormSubmitData): Promise<Contactgegeven> => {
	const response = await api.patch<Contactgegeven>(`${Endpoints.contactgegevens}/${id}`, body);

	return response.data;
};

const deleteContactgegeven = async (id: string): Promise<void> => {
	await api.delete<Contactgegeven>(`${Endpoints.contactgegevens}/${id}`);
};

export default {
	getRelaties,
	getRelatie,
	createRelatie,
	updateRelatie,
	createContactgegeven,
	updateContactgegeven,
	deleteContactgegeven,
};
