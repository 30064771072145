import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ZdsButton, ZdsDivider, ZdsForm, ZdsIcon } from '@zig-design-system/ui-components-react';
import classNames from 'classnames';

import Tabs, { TabData } from '../../../../../global/components/Tabs/Tabs';
import { FormControl, FormData, FormSubmitData } from '../../../../../global/components/Form/Form.types';
import { getFormDataFromSchema } from '../../../../../global/utils/form/getFormDataFromSchema';
import { getDateFormat } from '../../../../../global/utils/date/getDatePattern';
import {
	getRelatieOptionLabel,
	useNieuwsberichtFormSchema,
} from '../../../hooks/form/schema/create/nieuwsbericht/useNieuwsberichtFormSchema';
import FileItem from '../../../../../global/components/UploadFile/FileItem/FileItem';
import { useAppDispatch, useAppSelector } from '../../../../../global/redux/store';
import { selectCurrentCommunityData } from '../../../redux/currentCommunityData';
import ErrorMessage from '../../../../../global/components/ErrorMessage/ErrorMessage';
import { useUploadFileData } from '../../../../../global/hooks/useUploadFileData/useUploadFileData';
import CommunityAPI from '../../../api';
import MessagePreview from '../MessagePreview/MessagePreview';
import { selectUser, selectUserRelatie } from '../../../../../global/redux/shared/authorizationData';
import { getFormInputValue } from '../../../../../global/utils/form/getFormInputValue';
import { NatuurlijkPersoon, Rechtspersoon, Relatie } from '../../../../relatiebeheer/api/models';
import LanguageSelect, { LanguageData } from '../LanguageSelect/LanguageSelect';
import { useNieuwsberichtPreviewData } from '../../../hooks/useNieuwsberichtPreviewData/useNieuwsberichtPreviewData';
import { Bericht, Language } from '../../../api/models';
import PushNotificationPreview from '../PushNotificationPreview/PushNotificationPreview';
import { usePermissions } from '../../../../../global/hooks/usePermissions/usePermissions';
import { updateBerichtInList } from '../../../redux/berichten';
import { getFormSubmitData } from '../../../../../global/utils/form/getFormSubmitData';
import { updateFormData } from '../../../../../global/utils/form/updateFormData';
import { useRenderFormControl } from '../../../../../global/hooks/useRenderFormControl/useRenderFormControl';
import { useLanguageSelect } from '../../../hooks/useLanguageSelect/useLanguageSelect';
import styles from './ChannelsAndContent.module.scss';

export interface ChannelsAndContentProps {
	step: number;
	onSave: () => void;
	initMessage?: Bericht | null;
}

const MAX_FILES = 5;
const MAX_FILES_SIZE = 10 * 1024;

export interface NewsFormData {
	relatie: string;
	soort: string;
	onderwerp: string;
	beschrijving: string;
	reageeroptie: string;
	pin: string;
	pinBerichtTotDatum: string;
}

const ChannelsAndContent: FC<ChannelsAndContentProps> = ({ step, onSave, initMessage }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const { currentCommunityData } = useAppSelector(selectCurrentCommunityData);
	const [selectedTab, setSelectedTab] = useState(0);
	const [totalMembers, setTotalMembers] = useState(0);
	const [formData, setFormData] = useState<FormData | null>(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const { fileData, handleAddFile, handleRemoveFile, validationError } = useUploadFileData({
		maxFiles: MAX_FILES,
		maxFilesSize: MAX_FILES_SIZE,
		initFileData: initMessage?.bijlagen?.map(b => ({
			id: b.id,
			file: b.bestand ? ({ name: b.bestandsnaam, size: b.bestandsgrootte } as File) : null,
			error: null,
			base64: b.bestand ?? null,
		})),
	});
	const dateFormat = getDateFormat(i18n.language);
	const user = useAppSelector(selectUser);
	const { selectedLanguage, setSelectedLanguage } = useLanguageSelect();
	const { onderwerp, beschrijving } = useNieuwsberichtPreviewData(formData, selectedLanguage);
	const currentUserRelatie = useAppSelector(selectUserRelatie) as unknown as NatuurlijkPersoon;
	const hasPermission = usePermissions();

	const organisatieRelatie = {
		id: user?.medewerker?.organisatie.relatie.id ?? '',
		naam: user?.medewerker?.organisatie.relatie.naam ?? '',
		soort: { code: 'REC' },
	} as Rechtspersoon;

	const initMessageAut = initMessage?.rolRelaties?.find(r => r.soort.code === 'AUT')?.relatie;

	const relatieOptions: Relatie[] = !initMessageAut ? [currentUserRelatie, organisatieRelatie] : [initMessageAut];

	const showPinBerichtTotDatumInput = formData?.pin.value === 'T';

	const formSchema = useNieuwsberichtFormSchema({
		requiredMessage: fileData.length === 0 && selectedLanguage === Language.Dutch,
		showPinBerichtTotDatumInput,
		setFileData: handleAddFile,
		uploadDisabled: MAX_FILES === fileData.length,
		relatieOptions,
		language: selectedLanguage,
		data: formData,
		initMessage,
	});

	useEffect(() => {
		if (formSchema && !formData) {
			setFormData(getFormDataFromSchema(formSchema));
		}
	}, [formSchema]);

	useEffect(() => {
		if (currentCommunityData) {
			setTotalMembers(currentCommunityData.rolRelaties?.filter(r => r.soort.code === 'LID').length ?? 0);
		}
	}, [currentCommunityData]);

	const handleUpdateFormData = (event: CustomEvent, inputData: FormControl, language: Language) => {
		const modifyFormData = (fd: FormData) => {
			const value = getFormInputValue(event, inputData);
			if (inputData.name === 'pin' && value === 'F' && fd.pinBerichtTotDatum) {
				fd.pinBerichtTotDatum.value = '';
			}
		};

		updateFormData({ event, formData, inputData, setFormData, language, modifyFormData });
	};

	const validatePinBerichtTotDatum = (submitData: FormSubmitData) => {
		const pinBerichtTotDatumValue = submitData.pinBerichtTotDatum as string;
		if (!pinBerichtTotDatumValue) return true;

		return new Date().getTime() <= new Date(pinBerichtTotDatumValue).getTime();
	};

	const handleSubmit = async () => {
		const formSubmitData = getFormSubmitData(formData, formSchema, dateFormat);
		if (!formSubmitData) return;

		if (
			selectedLanguage !== Language.Dutch &&
			!((formData?.beschrijving.languageData?.nl as string).trim() ?? '') &&
			fileData.length === 0
		) {
			return setError('community:communityInformationPage.messages.createNewsMessage.dutchTextMissingErrorMessage');
		}

		if (initMessage && !formSubmitData.pinBerichtTotDatum) {
			formSubmitData.pinBerichtTotDatum = null;
		}
		if (initMessage && initMessage.pinBerichtTotDatum === formSubmitData.pinBerichtTotDatum) {
			delete formSubmitData.pinBerichtTotDatum;
		}

		if (!validatePinBerichtTotDatum(formSubmitData)) {
			return setError('community:communityInformationPage.messages.createNewsMessage.pinUntilValidationErrorMessage');
		}
		setError('');

		if (!initMessage) {
			await createNieuwsbericht(formSubmitData);
		} else {
			await updateNieuwsbericht(initMessage.id, formSubmitData);
		}
	};

	const createNieuwsbericht = async (payload: FormSubmitData) => {
		const staticSubmitData: FormSubmitData = {
			detailSoort: { code: 'INI' },
			status: { code: 'PUB' },
			publicatiedatum: new Date().toISOString(),
			community: { id: currentCommunityData?.id },
		};

		const finalPayload: FormSubmitData = { ...staticSubmitData, ...payload };

		try {
			setLoading(true);

			await CommunityAPI.createNieuwsbericht({
				payload: finalPayload,
				fileData: fileData.map(fd => ({ base64: fd.base64, name: fd.file?.name ?? null })),
			});

			onSave();
		} catch {
			setError('global:form.errorMessage.create');
		} finally {
			setLoading(false);
		}
	};

	const updateNieuwsbericht = async (id: string, payload: FormSubmitData) => {
		try {
			setLoading(true);

			const result = await CommunityAPI.updateNieuwsbericht({
				id,
				payload,
				fileData: fileData.map(fd => ({ base64: fd.base64, name: fd.file?.name ?? null })),
			});

			dispatch(updateBerichtInList(result));

			onSave();
		} catch {
			setError('global:form.errorMessage.update');
		} finally {
			setLoading(false);
		}
	};

	const handleLanguageChange = (data: LanguageData) => {
		setSelectedLanguage(data);
		setError('');
	};

	const renderFormControl = useRenderFormControl({
		formSchema,
		formData,
		onUpdateFormData: handleUpdateFormData,
		language: selectedLanguage,
	});

	const timelineMessage = t(
		`community:communityInformationPage.messages.createNewsMessage.timelineMessage.${
			totalMembers === 1 ? 'singular' : 'plural'
		}`,
		{
			num: totalMembers,
		}
	);

	const fileInvalid = fileData.some(fd => fd.error);
	const selectedRelatie = relatieOptions.find(r => r.id === formData?.relatie.value);

	const formId = 'createNieuwsberichtForm';

	const timelineContent = formSchema && formData && (
		<div className="zds-flex zds-flex-col zds-gap-6">
			<div className="zds-flex zds-justify-content-between zds-gap-12">
				<div className="zds-flex-1">
					<div className={styles.timelineMessage}>{timelineMessage}</div>
					<div className={styles.formContainer}>
						<ZdsForm onZdsSubmit={handleSubmit} id={formId}>
							<div className={styles.formInputsContainer}>
								{renderFormControl('relatie')}
								{renderFormControl('soort')}
								<div className="zds-margin-top-8">{renderFormControl('onderwerp')}</div>
								{renderFormControl('beschrijving')}
								<div className="zds-flex zds-gap-3 zds-margin-top-2 zds-margin-bottom-8">
									{!!fileData.length && (
										<div className={styles.label}>
											{t('community:communityInformationPage.messages.createNewsMessage.form.attachments')}
										</div>
									)}
									<div className={styles.fileItemsContainer}>
										{fileData.map(fd => (
											<FileItem key={fd.id} {...fd} onRemove={() => handleRemoveFile(fd.id)} />
										))}
										{validationError && (
											<ErrorMessage
												message={t(
													'community:communityInformationPage.messages.createNewsMessage.attachmentsSizeErrorMessage',
													{ maxSize: `${MAX_FILES_SIZE.toFixed(0)}KB` }
												)}
											/>
										)}
									</div>
								</div>
								{renderFormControl('reageeroptie')}
								{renderFormControl('pin')}
								{showPinBerichtTotDatumInput && renderFormControl('pinBerichtTotDatum')}
							</div>
						</ZdsForm>
						{error && <ErrorMessage message={t(error)} />}
					</div>
				</div>
				<MessagePreview
					fileData={!validationError && !fileInvalid ? fileData : []}
					relatie={selectedRelatie}
					onderwerp={onderwerp}
					beschrijving={beschrijving}
					pinBerichtTotDatum={(formData.pinBerichtTotDatum.value as string) || undefined}
					reageeroptie={formData.reageeroptie.value as string}
				/>
			</div>
			<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
			{hasPermission('COM_WIJ_BER_PUS') && (
				<>
					<div className="zds-flex zds-justify-content-between zds-gap-12">
						<div className="zds-flex zds-flex-1 zds-flex-col zds-gap-3">
							<div className="zds-headline-lg zds-color-inherit">
								{t('community:communityInformationPage.messages.createNewsMessage.pushNotifications.title')}
							</div>
							<div className={styles.formInputsContainer}>
								{renderFormControl('verstuurPushNotificatie')}
								{formData.verstuurPushNotificatie.value && (
									<div className={styles.sendPushNotificationNote}>
										<div>
											{t('community:communityInformationPage.messages.createNewsMessage.pushNotifications.note.title')}:
										</div>
										<ul>
											{[1, 2].map(v => (
												<li key={`pushNotificationsMessage${v}`}>
													{t(
														`community:communityInformationPage.messages.createNewsMessage.pushNotifications.note.message${v}`
													)}
												</li>
											))}
										</ul>
									</div>
								)}
							</div>
						</div>
						{selectedRelatie && (
							<PushNotificationPreview
								organisatieRelatie={organisatieRelatie}
								title={getRelatieOptionLabel(selectedRelatie)}
								message={onderwerp}
								show={!!formData.verstuurPushNotificatie.value}
								language={selectedLanguage}
							/>
						)}
					</div>
					<ZdsDivider className={classNames(styles.divider, styles['divider--fullWidth'])} />
				</>
			)}
			<div className="zds-flex zds-justify-content-end">
				<ZdsButton
					disabled={loading || !!validationError || fileInvalid}
					onZdsClick={() => {
						(document.querySelector(`#${formId}`) as HTMLFormElement)?.submit();
					}}
				>
					<ZdsIcon name="paper-plane-top" library="fa-solid" /> {t('global:common.submit')}
				</ZdsButton>
			</div>
		</div>
	);

	const tabsData: TabData[] = [
		{
			name: t('community:communityInformationPage.messages.createNewsMessage.tabs.timeline'),
			content: timelineContent,
			prefixIconOptions: { name: 'diagram-subtask', library: 'fa-regular', size: 'sm' },
		},
		// {
		// 	name: t('community:communityInformationPage.messages.createNewsMessage.tabs.email'),
		// 	content: <div />,
		// 	prefixIconOptions: { name: 'plus', library: 'fa-regular', size: 'xxs' },
		// 	withBorder: true,
		// },
	];

	return (
		<div>
			<div className="zds-flex zds-justify-content-between zds-items-start">
				<div className="zds-headline-lg zds-margin-bottom-6">
					{step}. {t('community:communityInformationPage.messages.createNewsMessage.stepper.channelsAndContent')}
				</div>
				<LanguageSelect onChange={handleLanguageChange} />
			</div>
			<Tabs tabsData={tabsData} initActiveTab={selectedTab} onTabChange={setSelectedTab} />
		</div>
	);
};

export default ChannelsAndContent;
